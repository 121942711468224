import type { Condition } from "../../../types/models/shares";
import { BadgeList } from "../../design-system/BadgeList";
import { useRestrictiveConditionOptions } from "../SelectRestrictiveConditions";

type ClauseBadgeListProps = {
  condition: Condition;
};

const ClauseBadgeList: React.FunctionComponent<ClauseBadgeListProps> = ({
  condition,
}) => {
  const conditionOptions = useRestrictiveConditionOptions();
  const enabledConditions = conditionOptions.filter(
    (option) => !!condition[option.value]
  );
  if (enabledConditions.length === 0) {
    return null;
  }

  return (
    <BadgeList
      labels={enabledConditions.map((clause) => clause.label)}
      limit={5}
    />
  );
};

export { ClauseBadgeList };
