import type { FunctionComponent } from "react";
import { createContext, useContext, useMemo } from "react";

import type { TDraftShareType } from "../../../types/models/draft";
import { sumByKey } from "../../../utils/math";

type ShareClassesProviderContext = {
  totalShares: number;
  numberOfSharesLeftToDistribute: number;
};

const ShareClassesContext = createContext<
  ShareClassesProviderContext | undefined
>(undefined);

type ShareClassesProviderProps = {
  children: React.ReactNode;
  value: { formValues: TDraftShareType[]; totalShares: number | undefined };
};

const ShareClassesProvider: FunctionComponent<ShareClassesProviderProps> = ({
  children,
  value: { totalShares: maybeTotalShares, formValues },
}) => {
  const valueMemoized: ShareClassesProviderContext =
    useMemo((): ShareClassesProviderContext => {
      const totalShares = maybeTotalShares ?? 0;
      const numberOfSharesLeftToDistribute =
        totalShares - sumByKey(formValues, "numberOfShares");

      return { totalShares, numberOfSharesLeftToDistribute };
    }, [maybeTotalShares, formValues]);

  return (
    <ShareClassesContext.Provider value={valueMemoized}>
      {children}
    </ShareClassesContext.Provider>
  );
};

const useShareClasses = (): ShareClassesProviderContext => {
  const context = useContext(ShareClassesContext);
  if (context === undefined) {
    throw Error("useShareClasses must be used within a ShareClassesProvider");
  }

  return context;
};

export type { ShareClassesProviderProps };
export { ShareClassesProvider, useShareClasses };
