const randomElementInList = (elements: string[]): string => {
  const randomIndex = Math.floor(Math.random() * elements.length);
  const maybeElement = elements[randomIndex];

  if (maybeElement === undefined) {
    throw TypeError("No element in list");
  }

  return maybeElement;
};

export { randomElementInList };
