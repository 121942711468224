import type { Ref, SVGProps } from "react";
import { forwardRef } from "react";

const KvantaIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    ref={ref}
    fill="currentColor"
    height="34"
    viewBox="0 0 29 34"
    width="29"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M0 14.477h3.495l.688-.688 7.943-7.943 2.35-2.35 2.349 2.35 7.943 7.943.688.688h3.497L14.477 0 0 14.477zM28.953 17.8h-3.497l-.688.688-7.943 7.943-2.35 2.35-2.35-2.35-7.942-7.943-.688-.688H0l14.477 14.476L28.953 17.8zM14.475 8.195l-7.943 7.943 7.943 7.943 7.943-7.943-7.943-7.943z"
      clipRule="evenodd"
    />
  </svg>
);

const ForwardRef = forwardRef(KvantaIcon);

export { ForwardRef as KvantaIcon };
