import type React from "react";

// This is a quick and temporary css tooltip implementation to solve a certain use case for now
// For a better alternative, JS implementations exist
// Which wont need a surrounding div (trigger/content and tooltip content can be elsewhere in dom)
// Smart positioning with JS which takes into account screen constraints
// Better accessibility
// Things i looked at
// Popper.js, we have a dependency to it in our calendar however it is a deprecated wrapper, did not want to create more dependency
// Instead see: https://floating-ui.com/docs/tooltip (what we could use for date picker and this)
// Also looked at older libs like react-tooltip, reach/tooltip, react-tooltip-lite and more moderns like radix, ariakit, using headlessui/popover, nextui and many others
// I do think think the component api will look like this though (children the trigger, content the tooltip content)
// The underlying implementation should change later with a better solution which can also expose color variants, placement, delay etc
// For now, don't want to include a lib or roll out a custom one where use case is rather immature which would rather not need Tooltips.

type Props = { children: React.ReactElement; content: React.ReactNode };

const Tooltip: React.FunctionComponent<Props> = ({ children, content }) => {
  const hasContentToDisplay =
    content !== "" && content !== null && content !== undefined;

  return hasContentToDisplay ? (
    <div className="tw-group tw-relative tw-inline-flex">
      {children}
      <div className="after:content-[''] tw-absolute -tw-top-3 tw-left-1/2 tw-z-10 tw-hidden -tw-translate-x-1/2 -tw-translate-y-full tw-whitespace-nowrap tw-rounded tw-bg-gray-700 tw-px-2 tw-py-1 tw-text-center tw-text-sm tw-text-white after:tw-absolute after:tw-left-1/2 after:tw-top-[100%] after:-tw-translate-x-1/2 after:tw-border-8 after:tw-border-x-transparent after:tw-border-b-transparent after:tw-border-t-gray-700 group-hover:tw-flex">
        {content}
      </div>
    </div>
  ) : (
    children
  );
};

export { Tooltip };
