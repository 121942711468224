import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useInitEmailVerificationMutation } from "../../../../api/rest/entities";
import { Button } from "../../../../components/design-system/Button";
import { Dialog } from "../../../../components/design-system/Dialog";
import { FormErrorList } from "../../../../components/design-system/FormGroup";
import { VerificationEmailSent } from "./VerificationEmailSent";

type VeriftEmailProps = {
  orgNumber: string;
  onClose?: () => void;
};

function getSkipsFromLocalStorage() {
  return JSON.parse(
    window.sessionStorage.getItem("skippedMailVerification") ?? "{}"
  );
}

const setSkipVerficationInLocalStorage = (orgNumber: string) => {
  const currentSkips = getSkipsFromLocalStorage();
  const newSkips = { ...currentSkips, [orgNumber]: true };
  window.sessionStorage.setItem(
    "skippedMailVerification",
    JSON.stringify(newSkips)
  );
};

const VerifyEmail = ({ orgNumber, onClose = () => {} }: VeriftEmailProps) => {
  const i18n = useTranslation();
  const [showEmailVerificationModal, setShowEmailVerificationModal] =
    useState(false);

  const emailVerificationMutation = useInitEmailVerificationMutation(
    orgNumber,
    {
      onSuccess: () => setShowEmailVerificationModal(true),
    }
  );

  if (showEmailVerificationModal) {
    return <VerificationEmailSent orgNumber={orgNumber} />;
  }

  const onCloseClick = () => {
    setSkipVerficationInLocalStorage(orgNumber);
    onClose();
  };

  const onVerifyClick = () => {
    onCloseClick();
    emailVerificationMutation.mutate();
  };

  return (
    <Dialog
      canClose
      isOpen
      onClose={onCloseClick}
      actions={
        <Button
          color="primary"
          isLoading={emailVerificationMutation.isLoading}
          variant="solid"
          onClick={onVerifyClick}
        >
          {i18n.t("label.verify")}
        </Button>
      }
      isLoading={emailVerificationMutation.isLoading}
      title={i18n.t("userActionRequired.VerifyEmail.title")}
    >
      <p>{i18n.t("userActionRequired.VerifyEmail.subText")}</p>
      {emailVerificationMutation.error && (
        <FormErrorList error={emailVerificationMutation.error} />
      )}
    </Dialog>
  );
};

export { VerifyEmail };
