import type React from "react";
import { useTranslation } from "react-i18next";

import { clsxm } from "../../utils/tailwind";
import {
  CheckmarkIcon,
  ErrorIcon,
  InfoIcon,
  WarningIcon,
} from "../design-system/icons";

type AlertType = "error" | "neutral" | "success" | "warning";

type NoDataProps = {
  title?: React.ReactNode;
  description?: React.ReactNode;
  type?: AlertType;
  actions?: React.ReactNode;
};
const NoData = ({
  type = "neutral",
  title,
  description,
  actions,
}: NoDataProps) => {
  const i18n = useTranslation();
  const Icon = {
    success: CheckmarkIcon,
    error: ErrorIcon,
    warning: WarningIcon,
    neutral: InfoIcon,
  }[type];
  const titleToDisplay = title || i18n.t("label.noData.title");
  const descriptionToDisplay =
    description || i18n.t("label.noData.description");

  return (
    <div className="tw-flex tw-w-full tw-flex-col tw-items-center tw-gap-2 tw-px-2 tw-py-8">
      <div>
        <Icon
          className={clsxm("tw-h-8 tw-w-8", {
            "tw-text-success": type === "success",
            "tw-text-warning": type === "warning",
            "tw-text-error": type === "error",
          })}
        />
      </div>
      <div className="tw-text-center">
        <div className="tw-text-lg">{titleToDisplay}</div>
        <div className="tw-text-secondary">{descriptionToDisplay}</div>
      </div>
      {actions && (
        <div className="tw-flex tw-items-center tw-gap-4">{actions}</div>
      )}
    </div>
  );
};

export { NoData };
export type { AlertType };
