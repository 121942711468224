import type { ClassValue } from "clsx";
import clsx from "clsx";
import { extendTailwindMerge } from "tailwind-merge";

const customTwMerge = extendTailwindMerge({
  prefix: "tw-",
});

/**
 * Merges and resolves tailwind classnames
 *
 * ### Comparison between clsx and clsxm
 *
 * Example using regular clsx:
 * ```ts
 * clsx("tw-mx-auto tw-max-w-7xl", "tw-max-w-3xl") // => "tw-mx-auto tw-max-w-7xl tw-max-w-3xl"
 * ```
 *
 * Example using this tailwind-aware clsxm:
 * ```ts
 * clsxm("tw-mx-auto tw-max-w-7xl", "tw-max-w-3xl") // => "tw-mx-auto tw-max-w-3xl"
 * ```
 */
const clsxm = (...classes: ClassValue[]) => customTwMerge(clsx(...classes));

export { clsxm };
