import {
  ArrowCounterClockwise,
  Book,
  BookOpen,
  CheckSquare,
  Globe,
  Headset,
  ListDashes,
  SignOut,
  TrendUp,
  User,
  UsersThree,
} from "@phosphor-icons/react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink, useLocation } from "react-router-dom";

import { useCurrentCompany } from "../../context/account";
import { useSession } from "../../context/session";
import { useCompanyEvents } from "../../hooks/useCompanyEvents";
import { useToggles } from "../../hooks/useToggles";
import { getLocale, locales, setLocale } from "../../i18n";
import { APP_ROUTE } from "../../routes/constants";
import type { CompanyInformation } from "../../types/models/administration";
import type { CompanyInvolvement } from "../../types/models/company";
import { formatRefId } from "../../utils/format";
import { hasRequiredPermission } from "../../utils/permissions";
import { clsxm } from "../../utils/tailwind";
import { Description } from "../design-system/Description";
import { CrossIcon, LogoIcon, MobileMenu } from "../design-system/icons";
import { KvantaIcon } from "../design-system/icons/LogoIcon";
import { SideMenu } from "../MobileSideMenu";
import { NavbarMenu } from "./NavbarMenu";
import { getPageName } from "./Navigation.utils";
import { UserProfile } from "./UserProfile";

type NewNavigationProps = {
  className?: string;
};

type NavItem = {
  key: string;
  label: string | JSX.Element;
  to?: string;
  onClick?: () => void;
  icon?: JSX.Element;
  hasPermission: boolean;
  activeForLinks?: RegExp;
  isBeta?: boolean;
};

const companyHaveApprovedEvents = (
  events: ReturnType<typeof useCompanyEvents>
) => {
  const ledgerWithNoApprovedEvents =
    !events.approvedEvents || events.approvedEvents?.length === 0;
  if (ledgerWithNoApprovedEvents) {
    return false;
  }

  return true;
};

const useCheckPermission = () => {
  const currentCompany = useCurrentCompany();
  const { user } = useSession();
  const events = useCompanyEvents(currentCompany?.orgNumber);

  if (!currentCompany) {
    return false;
  }

  const userRole = (currentCompany as CompanyInvolvement)?.access;
  const supportRole =
    user?.role === "Administrator"
      ? (currentCompany as CompanyInformation)?.supportRole
      : null;

  return (
    hasRequiredPermission("Editor", currentCompany, user) ||
    ((userRole === "Viewer" || (!userRole && supportRole === "Viewer")) &&
      companyHaveApprovedEvents(events))
  );
};

const useCompanyLinks = () => {
  const i18n = useTranslation();
  const newLocale = getLocale() === "en-US" ? "sv-SE" : "en-US";
  const currentCompany = useCurrentCompany();
  const { user, onSignout } = useSession();
  const { isFeatureEnabled } = useToggles();
  const havePermissionToViewMenu = useCheckPermission();

  if (!user || !currentCompany) {
    return [];
  }

  return [
    {
      key: "shareholders",
      label: i18n.t("label.shareholders"),
      to: `${APP_ROUTE.COMPANIES}/${currentCompany.orgNumber}/shares/holders`,
      icon: <UsersThree />,
      hasPermission:
        havePermissionToViewMenu && currentCompany.status !== "Onboarding",
      isKeyLink: true,
    },
    {
      key: "ledger",
      label: i18n.t("label.shareLedger"),
      to:
        currentCompany.status === "Onboarding"
          ? `${APP_ROUTE.COMPANIES}/${currentCompany.orgNumber}/onboarding`
          : `${APP_ROUTE.COMPANIES}/${currentCompany.orgNumber}/shares/blocks`,
      icon: <Book />,
      hasPermission: havePermissionToViewMenu,
      isKeyLink: true,
    },
    {
      key: "actions",
      label: i18n.t("label.corporateActions"),
      to: `${APP_ROUTE.COMPANIES}/${currentCompany.orgNumber}/events`,
      icon: <ListDashes />,
      hasPermission:
        havePermissionToViewMenu && currentCompany.status !== "Onboarding",
      isKeyLink: true,
    },
    {
      key: "options",
      label: i18n.t("label.options"),
      to: `${APP_ROUTE.COMPANIES}/${currentCompany.orgNumber}/options`,
      icon: <TrendUp />,
      hasPermission: hasRequiredPermission("Editor", currentCompany, user),
      isKeyLink: false,
      isBeta: true,
    },
    {
      key: "policy",
      label: i18n.t("label.policy"),
      to: `${APP_ROUTE.COMPANIES}/${currentCompany.orgNumber}/settings/policy`,
      icon: <CheckSquare />,
      hasPermission:
        havePermissionToViewMenu && currentCompany.status !== "Onboarding",
      isKeyLink: false,
    },
    {
      key: "users",
      label: i18n.t("label.users"),
      to: `${APP_ROUTE.COMPANIES}/${currentCompany.orgNumber}/settings/users`,
      icon: <User />,
      hasPermission:
        havePermissionToViewMenu && currentCompany.status !== "Onboarding",
      isKeyLink: false,
    },
    {
      key: "entities",
      label: i18n.t("label.entities"),
      to: `${APP_ROUTE.COMPANIES}/${currentCompany.orgNumber}/settings/entities`,
      icon: <UsersThree />,
      hasPermission:
        havePermissionToViewMenu && currentCompany.status !== "Onboarding",
      isKeyLink: false,
    },
    {
      key: "auditLog",
      label: i18n.t("label.auditLog"),
      to: `${APP_ROUTE.COMPANIES}/${currentCompany.orgNumber}/audit`,
      icon: <ListDashes />,
      hasPermission: isFeatureEnabled("ENABLE_AUDIT_LOG"),
      isKeyLink: false,
    },
    {
      key: "aboutCompany",
      label: i18n.t("label.aboutCompany"),
      to: `${APP_ROUTE.COMPANIES}/${currentCompany.orgNumber}/company-information`,
      icon: <BookOpen />,
      hasPermission: havePermissionToViewMenu,
      isKeyLink: false,
    },
    {
      key: "switchCompany",
      label: i18n.t("label.switchCompany"),
      to: `/#ledgers`,
      icon: <ArrowCounterClockwise />,
      hasPermission: true,
      isKeyLink: false,
    },
    {
      key: "language",
      label: locales[newLocale],
      onClick: () => setLocale(newLocale),
      icon: <Globe />,
      hasPermission: true,
      isKeyLink: false,
    },
    {
      key: "logout",
      label: i18n.t("label.logout"),
      onClick: () => onSignout(),
      icon: <SignOut />,
      hasPermission: true,
      isKeyLink: false,
    },
    {
      key: "support",
      label: i18n.t("label.support"),
      to: `${APP_ROUTE.COMPANIES}/${currentCompany.orgNumber}/settings/support`,
      icon: <Headset />,
      hasPermission:
        havePermissionToViewMenu && currentCompany.status !== "Onboarding",
      isKeyLink: false,
    },
  ].filter((link) => link.hasPermission);
};

const NewNavigation: React.FunctionComponent<NewNavigationProps> = ({
  className,
}) => {
  const i18n = useTranslation();
  const currentCompany = useCurrentCompany();
  const [isOpen, setIsOpen] = useState(false);
  const { user, onSignout } = useSession();
  const companyLinks = useCompanyLinks();
  const location = useLocation();
  const isOwnershipPath = location.pathname.includes("/ownership");

  const currentPageName = useMemo(() => {
    const pageName = getPageName(location.pathname);

    if (pageName) {
      return i18n.t(pageName);
    }

    return null;
  }, [location.pathname, i18n]);

  if (!user) {
    return null;
  }

  const filteredLinks = companyLinks.filter((link) => link.isKeyLink);

  return (
    <>
      <nav
        className={clsxm(
          "tw-sticky tw-top-0 tw-z-20 tw-inline-block tw-min-w-full tw-bg-neutral-800 tw-text-white print:tw-hidden",
          className
        )}
      >
        <div className="tw-m-auto tw-max-w-7xl tw-px-4 tw-py-4 md:tw-px-6">
          <div className="tw-flex tw-h-10 tw-items-center tw-justify-between">
            <div className="tw-flex tw-items-center tw-gap-8">
              <Link to={APP_ROUTE.HOME} className="max-md:tw-hidden">
                <LogoIcon className="tw-text-white" />
              </Link>
              {currentCompany ? (
                <div className="tw-flex tw-items-center tw-gap-3 md:tw-hidden">
                  <Link to={APP_ROUTE.HOME}>
                    <KvantaIcon />
                  </Link>
                  <div className="">
                    <Description
                      title={currentCompany.name}
                      description={formatRefId({
                        refId: currentCompany.orgNumber,
                        countryCode: currentCompany.countryCode,
                        type: "Company",
                      })}
                      titleSize="base"
                      titleWeight="bold"
                      theme="whiteGray"
                    />
                  </div>
                </div>
              ) : (
                <Link to={APP_ROUTE.HOME} className="md:tw-hidden">
                  <LogoIcon className="tw-text-white" />
                </Link>
              )}
              <>
                {isOpen && (
                  <SideMenu
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    companyLinks={companyLinks}
                    currentCompany={currentCompany}
                    user={user}
                  />
                )}
                {!isOwnershipPath && (
                  <div>
                    <div className="tw-flex tw-justify-between">
                      <div className="tw-flex tw-gap-4">
                        <ul className="tw-flex tw-items-center max-md:tw-hidden">
                          {filteredLinks.map(
                            ({ label, to, activeForLinks }) => (
                              <li key={to}>
                                <NavLink
                                  className={({ isActive }) =>
                                    clsxm(
                                      "tw-whitespace-nowrap tw-rounded tw-px-4 tw-py-2 tw-font-medium tw-text-white hover:tw-no-underline",
                                      {
                                        "tw-bg-neutral-700":
                                          isActive ||
                                          (activeForLinks &&
                                            location.pathname.match(
                                              activeForLinks
                                            )),
                                      }
                                    )
                                  }
                                  to={to}
                                >
                                  {label}
                                </NavLink>
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </>
            </div>
            {currentCompany && !isOwnershipPath ? (
              <>
                <div className="max-md:tw-hidden">
                  <NavbarMenu
                    user={user}
                    companyName={currentCompany.name}
                    currentCompany={currentCompany}
                    companyLinks={companyLinks.filter(
                      (link) => !link.isKeyLink
                    )}
                  />
                </div>
                <button
                  className="tw-block md:tw-hidden"
                  onClick={() => setIsOpen(!isOpen)}
                  type="button"
                >
                  {isOpen ? (
                    <div className="tw-flex tw-items-center tw-py-2 tw-pb-3">
                      <h6 className="tw-mr-2">{currentPageName}</h6>
                      <CrossIcon className="h-6 w-6" />
                    </div>
                  ) : (
                    <div className="tw-flex tw-items-center tw-py-2 tw-pb-3">
                      <h6 className="tw-mr-2">{currentPageName}</h6>
                      <MobileMenu className="h-6 w-6" />
                    </div>
                  )}
                </button>
              </>
            ) : (
              <>
                <div className="max-md:tw-hidden">
                  <UserProfile user={user} onSignout={onSignout} />
                </div>
                <button
                  className="tw-mr-2 tw-block md:tw-hidden"
                  onClick={() => setIsOpen(!isOpen)}
                  type="button"
                >
                  {isOpen ? (
                    <div className="tw-flex tw-items-center tw-py-2 tw-pb-3">
                      <h6 className="tw-mr-6">{currentPageName}</h6>
                      <CrossIcon className="h-6 w-6" />
                    </div>
                  ) : (
                    <div className="tw-flex tw-items-center tw-py-2 tw-pb-3">
                      <h6 className="tw-mr-6">{currentPageName}</h6>
                      <MobileMenu className="h-6 w-6" />
                    </div>
                  )}
                </button>
              </>
            )}
          </div>
        </div>
      </nav>
    </>
  );
};

export { NewNavigation };
export type { NavItem };
