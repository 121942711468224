import type { DropdownIndicatorProps } from "react-select";
import { components } from "react-select";

import { ChevronDownIcon } from "../icons";

const DropdownIndicator = (props: DropdownIndicatorProps) => (
  <components.DropdownIndicator {...props}>
    <ChevronDownIcon className="tw-r-2 tw-h-5 tw-w-5" />
  </components.DropdownIndicator>
);

export { DropdownIndicator };
