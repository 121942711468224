import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import type {
  useAddUserMutation,
  useDeleteUserMutation,
  useUpdateUsersMutation,
} from "../../../../api/rest/users";
import type {
  NewUserRole,
  Role,
  SupportAccess,
} from "../../../../types/models/users";
import { useSupportAccessLogic } from "../../../../utils/support-access";
import { supportUserId } from "../../../../utils/user";
import { Button } from "../../../design-system/Button";
import {
  FormError,
  FormErrorList,
  FormGroup,
} from "../../../design-system/FormGroup";
import { SelectRole } from "../../../SelectRole";

const formId = "edit-support-form";

type NewUserSupport = {
  role: SupportAccess;
  entityId: string;
};

type Props = {
  enabled: boolean;
  title: string;
  onClose?: () => void;
  addUserMutation: ReturnType<typeof useAddUserMutation>;
  deleteUserMutation: ReturnType<typeof useDeleteUserMutation>;
  updateUsersMutation: ReturnType<typeof useUpdateUsersMutation>;
  defaultValues: NewUserSupport;
  currentSupportRole: SupportAccess;
};

const SelectSupportAccess = (props: Props) => {
  const {
    enabled,
    defaultValues,
    addUserMutation,
    updateUsersMutation,
    deleteUserMutation,
    currentSupportRole,
  } = props;
  const i18n = useTranslation();
  const { handleSubmit, control } = useForm<NewUserSupport>({
    mode: "onChange",
    defaultValues,
  });

  const [selectedRole, setSelectedRole] = useState<SupportAccess | undefined>(
    defaultValues.role
  );

  const { handleDelete, handleAddOrUpdate, NO_ACCESS_ROLE } =
    useSupportAccessLogic(props);

  const isAddingUser = addUserMutation.isLoading;
  const isUpdatingUser = updateUsersMutation.isLoading;
  const isDeletingUser = deleteUserMutation.isLoading;
  const isLoading = isAddingUser || isUpdatingUser || isDeletingUser;

  const handleSubmitForm = handleSubmit((data) => {
    if (data.role === NO_ACCESS_ROLE) {
      handleDelete();

      return;
    }
    const userData: NewUserRole = {
      role: data.role as Role,
      entityId: supportUserId,
    };
    handleAddOrUpdate(userData, currentSupportRole);
  });

  return (
    <div>
      <form className="tw-space-y-4" onSubmit={handleSubmitForm} id={formId}>
        <FormGroup>
          <Controller
            name="role"
            control={control}
            render={({ field: { onChange, value }, fieldState }) => (
              <>
                <SelectRole
                  value={value}
                  onChange={(val) => {
                    onChange(val);
                    setSelectedRole(val);
                  }}
                  enabled={enabled}
                  noAccess
                />
                <FormError>{fieldState.error?.message}</FormError>
              </>
            )}
            rules={{ required: i18n.t("error.validation.required") }}
          />
        </FormGroup>
        {addUserMutation.error && (
          <FormErrorList error={addUserMutation.error} />
        )}
        <div className="tw-flex tw-w-full tw-justify-between">
          <div className="tw-flex tw-space-x-4">
            <Button
              type="submit"
              variant="solid"
              color="primary"
              isLoading={isLoading}
              form={formId}
              disabled={!enabled || selectedRole === defaultValues.role}
            >
              {i18n.t("label.save")}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export { SelectSupportAccess };
