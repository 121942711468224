import optionsImgUrl from "./options.png";

const Options: React.FC = () => {
  return (
    <img
      alt="options"
      className="tw-right-0 tw-ml-auto tw-mr-[-140px] max-md:tw-hidden"
      src={optionsImgUrl}
    />
  );
};

export default Options;
