import type { KeyPair } from "@capchapdev/kvanta-postchain-client";
import { useQuery } from "@tanstack/react-query";

import * as config from "../../config";
import { getKeyPair, getUserFromStorage } from "../../context/session";
import type { LoggedInUser } from "../../types/models/auth";
import * as monitoring from "../../utils/monitoring";
import type { IRequestError } from "..";
import { Errors, RequestError } from "..";
import { BAD_REQUEST } from "../rest/utils";
import type { UseQueryOptions } from "../types";
import { blockchainClient } from "./client";
import { sendQuery } from "./transaction";

const getSessionExpirationBlock = (keyPair: KeyPair) =>
  sendQuery(
    blockchainClient
      .query<number>()
      .name("auth.get_session_expiration_block")
      .addParameter("pubkey", keyPair.publicKey.toString("hex"))
  );

const renewSession = (keyPair: KeyPair): Promise<unknown> => {
  monitoring.addBreadcrumb({
    category: "auth",
    message: "auth.renew_session",
    level: "debug",
  });

  return blockchainClient
    .transaction()
    .addOperation("auth.renew_session", keyPair.publicKey)
    .sign(keyPair)
    .addNop()
    .send()
    .catch((error) => {
      monitoring.addBreadcrumb({
        category: "auth",
        message: "renewal failed",
        level: "debug",
      });

      let requestError;
      try {
        requestError = new RequestError({
          errors: JSON.parse(error.shortReason),
          status: BAD_REQUEST,
        });
      } catch (e) {
        requestError = new RequestError(Errors.unauthenticated);
      }
      requestError.name = "Session Renew Error";
      requestError.message = "Session renewal failed";

      if (
        // swallowing that error code because it only means redundant call. why error though instead of just success?
        !requestError.errors.some(
          ({ message }) =>
            message.code === "error.verification.session.notYetRenewable"
        )
      ) {
        throw new RequestError(requestError);
      }
    });
};

type RenewAuth = { keyPair: KeyPair; user: LoggedInUser } | null;

const useRenewSessionQuery = (
  options?: UseQueryOptions<unknown, IRequestError, RenewAuth>
) =>
  useQuery<unknown, IRequestError, RenewAuth>(
    ["renewSession"],
    async () => {
      monitoring.addBreadcrumb({
        category: "auth",
        message: "useRenewSessionQuery",
        level: "debug",
      });

      try {
        const keyPairFromStorage = getKeyPair();
        const userFromStorage = getUserFromStorage();

        if (keyPairFromStorage === undefined || userFromStorage === undefined) {
          monitoring.addBreadcrumb({
            category: "auth",
            message: "No valid session found",
            level: "debug",
          });

          return null;
        }
        const [_currentBlock, _sessionExpirationBlock] =
          await Promise.allSettled([
            await blockchainClient.getBlockHeight(),
            await getSessionExpirationBlock(keyPairFromStorage),
          ]);

        if (_currentBlock.status === "rejected") {
          monitoring.addBreadcrumb({
            category: "auth",
            message: "getBlockHeight failed",
            data:
              _currentBlock.reason instanceof Error
                ? {
                    message: _currentBlock.reason.message,
                    name: _currentBlock.reason.name,
                  }
                : undefined,
            level: "debug",
          });
          return null;
        }

        if (_sessionExpirationBlock.status === "rejected") {
          monitoring.addBreadcrumb({
            category: "auth",
            message: "getSessionExpirationBlock failed",
            data:
              _sessionExpirationBlock.reason instanceof Error
                ? {
                    message: _sessionExpirationBlock.reason.message,
                    name: _sessionExpirationBlock.reason.name,
                  }
                : undefined,
            level: "debug",
          });
          return null;
        }

        const currentBlock = _currentBlock.value;
        const sessionExpirationBlock = _sessionExpirationBlock.value;

        if (sessionExpirationBlock <= currentBlock) {
          monitoring.addBreadcrumb({
            category: "auth",
            message: "sessionExpirationBlock <= currentBlock",
            level: "debug",
          });

          return null;
        }
        if (
          currentBlock >
          sessionExpirationBlock - config.renewSessionsWhenBlocksLeft
        ) {
          monitoring.addBreadcrumb({
            category: "auth",
            message: "renewing session",
            level: "debug",
          });

          await renewSession(keyPairFromStorage);
        }

        return {
          keyPair: keyPairFromStorage,
          user: userFromStorage,
        }; // do nothing?
      } catch (err) {
        monitoring.addBreadcrumb({
          category: "auth",
          message: "renewing session",
          data: err instanceof Error ? { message: err.message } : undefined,
          level: "debug",
        });

        return null;
      }
    },
    options
  );

export { useRenewSessionQuery };
