import type { FunctionComponent } from "react";

import { clsxm } from "../../utils/tailwind";

type ActionButtonGroupProps = {
  children?: React.ReactNode;
  className?: string;
};

const ActionButtonGroup: FunctionComponent<ActionButtonGroupProps> = ({
  children,
  className,
}) =>
  children ? (
    <div
      className={clsxm(
        "tw-flex tw-flex-col tw-content-start tw-gap-2 print:tw-hidden md:tw-flex-row",
        className
      )}
    >
      {children}
    </div>
  ) : null;

export { ActionButtonGroup };
export type { ActionButtonGroupProps };
