import { useTranslation } from "react-i18next";

import type { OptionProps, SingleValueProps } from "../design-system/Select";
import {
  components,
  Option as SelectOption,
  Select,
} from "../design-system/Select";
import { EntityTypeOptions } from "../Entities/CreateEntityForm/CreateEntityForm";

type CustomEntityType = "PrivateWithSSN" | "PrivateWithPassport" | "Company";
type OptionType = { value: CustomEntityType; label: string };

const Option = (props: OptionProps<OptionType, false>) => {
  const { data } = props;

  return <SelectOption {...props}>{data.label}</SelectOption>;
};

const SingleValue = (props: SingleValueProps<OptionType, false>) => {
  const { data } = props;

  return (
    <components.SingleValue {...props}>{data.label}</components.SingleValue>
  );
};

const SelectEntityType = ({
  value,
  enabledOptions,
  onChange,
  disabled,
}: {
  value?: CustomEntityType;
  enabledOptions: EntityTypeOptions[];
  onChange: (entityType?: CustomEntityType) => void;
  disabled?: boolean;
}) => {
  const i18n = useTranslation();
  const typeOptions: { value: CustomEntityType; label: string }[] = [
    { value: "PrivateWithSSN", label: i18n.t("label.private") },
    {
      value: "PrivateWithPassport",
      label: i18n.t("label.privateWithPassport"),
    },
    { value: "Company", label: i18n.t("label.company") },
  ];

  return (
    <Select
      value={typeOptions.find((c) => c.value === value)}
      options={typeOptions.filter((o) => enabledOptions.includes(o.value))}
      onChange={(newValue) => onChange(newValue?.value)}
      components={{ Option, SingleValue }}
      menuPosition="fixed"
      isDisabled={disabled}
      isSearchable={false}
    />
  );
};

export { SelectEntityType };
export type { CustomEntityType };
