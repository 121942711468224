import { useQuery } from "@tanstack/react-query";

import { useSession } from "../../context/session";
import {
  type CompanyInvolvement,
  CompanyInvolvementsSchema,
} from "../../types/models/company";
import type {
  ApprovalInfoResponse,
  Ledger,
  LedgerVersion,
  Shareblock,
  Shareholder,
  ShareType,
  ShareTypeClause,
} from "../../types/models/shares";
import { ApprovalInfoResponseSchema } from "../../types/models/shares";
import * as monitoring from "../../utils/monitoring";
import { getActiveBlocks } from "../../utils/shares";
import type { IRequestError } from "..";
import type { ApprovalRule } from "../rest/approval-rule-policy";
import { unmaskValues } from "../rest/company";
import type { UseQueryOptions } from "../types";
import { blockchainClient } from "./client";
import { extractRefIdFromUnmaskedValue } from "./events";
import { sendQuery } from "./transaction";

const getInvolvements = async (
  pubKey: string
): Promise<CompanyInvolvement[]> => {
  const response = await sendQuery(
    blockchainClient
      .query<CompanyInvolvement[]>()
      .name("ledger_access.get_company_involvements")
      .addParameter("pubkey", pubKey)
  );

  const result = CompanyInvolvementsSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(
      TypeError(
        "Error parsing blockchain response using CompanyInvolvementsSchema"
      ),
      { contexts: { result } }
    );

    return response;
  }

  return result.data;
};

const getShareblocks = (
  orgNumber: string,
  ledgerVersion: LedgerVersion | "" = ""
): Promise<Shareblock[]> =>
  sendQuery(
    blockchainClient
      .query<Shareblock[]>()
      .name("ledger.get_share_blocks")
      .addParameter("org_number", orgNumber)
      .addParameter("date", ledgerVersion)
  );

const getApprovalInfo = async (orgNumber: string, date: string) => {
  const response = await sendQuery(
    blockchainClient
      .query<ApprovalInfoResponse | null>()
      .name("ledger.get_approval_info")
      .addParameter("org_number", orgNumber)
      .addParameter("date", date)
  );

  ApprovalInfoResponseSchema.parse(response);

  return response;
};

const getActiveApprovalRule = (orgNumber: string) =>
  sendQuery(
    blockchainClient
      .query<ApprovalRule>()
      .name("ledger.get_active_approval_rule")
      .addParameter("org_number", orgNumber)
  );

type ShareTypeResponse = Omit<ShareType, "condition"> & {
  condition: {
    consent: 0 | 1;
    preemption: 0 | 1;
    offerOfFirstRefusal: 0 | 1;
    conversion: 0 | 1;
    redemption: 0 | 1;
  };
};

const getShareTypes = async (orgNumber: string, date: string) => {
  const data = await sendQuery(
    blockchainClient
      .query<ShareTypeResponse[]>()
      .name("ledger.get_share_types")
      .addParameter("org_number", orgNumber)
      .addParameter("date", date)
  );

  return data.map(({ condition, ...rest }) => ({
    ...rest,
    condition: Object.fromEntries(
      Object.keys(condition).map((curr) => [
        curr,
        !!condition[curr as ShareTypeClause],
      ])
    ),
  }));
};
const getLedger = (orgNumber: string, date: string) =>
  sendQuery(
    blockchainClient
      .query<Ledger>()
      .name("ledger.get_ledger_meta")
      .addParameter("org_number", orgNumber)
      .addParameter("date", date)
  );

const useLedgerQuery = (
  orgNumber: string,
  ledgerVersion: LedgerVersion | "" | undefined,
  options?: UseQueryOptions<unknown, IRequestError, Ledger, string[]>
) =>
  useQuery(
    ["ledger", orgNumber, ledgerVersion || ""],
    () => getLedger(orgNumber, ledgerVersion || ""),
    {
      enabled:
        options?.enabled !== undefined
          ? options.enabled
          : !!orgNumber && ledgerVersion !== undefined,
      ...options,
    }
  );

const useCompaniesQuery = () => {
  const { keyPair } = useSession();
  const pubKey = keyPair?.publicKey.toString("hex") || "";

  return useQuery(["companyInvolvements"], () => getInvolvements(pubKey));
};

const useShareblocksQuery = (
  orgNumber: string | undefined,
  ledgerVersion: LedgerVersion | "" | undefined,
  options: UseQueryOptions<unknown, IRequestError, Shareblock[], string[]> = {}
) =>
  useQuery<unknown, IRequestError, Shareblock[], string[]>(
    ["companyShareblocks", orgNumber || "", ledgerVersion || ""],
    async (): Promise<Shareblock[]> => {
      const shareblocks = await getShareblocks(orgNumber || "", ledgerVersion);

      /**
       * !IMPORTANT! We're patching the response here, because we need to have
       * the unmasked refIds later on. We're adding a property to each
       * EventEntity called "unmaskedRefId" which contains the unmasked refId
       * found in the entityMap, but more importantly, this is not added to the
       * type-definition, because we don't want to pollute it since we'll remove
       * it at a later point anyways.
       */
      const maskedRefIds = shareblocks.map((block) => block.holder.refId);
      const uniqueMaskedIds = [...new Set(maskedRefIds)];
      const unmasked = await unmaskValues(
        orgNumber || "",
        uniqueMaskedIds,
        true
      );

      const patchedShareblocks: Shareblock[] = shareblocks.map((block) => ({
        ...block,
        holder: {
          ...block.holder,
          unmaskedRefId: extractRefIdFromUnmaskedValue(
            unmasked[block.holder.refId]
          ),
        },
      }));

      return patchedShareblocks;
    },
    {
      enabled:
        options?.enabled !== undefined
          ? options.enabled
          : !!orgNumber && ledgerVersion !== undefined,
      ...options,
    }
  );

const useApprovalInfoQuery = (
  orgNumber: string,
  date: string | undefined,
  options: UseQueryOptions<
    ApprovalInfoResponse | null,
    IRequestError,
    ApprovalInfoResponse | null,
    string[]
  > = {}
) =>
  useQuery<
    ApprovalInfoResponse | null,
    IRequestError,
    ApprovalInfoResponse | null,
    string[]
  >(
    ["approvalInfo", orgNumber, date || ""],
    () => getApprovalInfo(orgNumber, date || ""),
    {
      enabled:
        options?.enabled !== undefined
          ? options.enabled
          : !!orgNumber && date !== undefined,
      ...options,
    }
  );

const useActiveApprovalRuleQuery = (
  orgNumber: string,
  options?: UseQueryOptions<ApprovalRule, IRequestError, ApprovalRule, string[]>
) =>
  useQuery<ApprovalRule, IRequestError, ApprovalRule, string[]>(
    ["approvalActive", orgNumber],
    () => getActiveApprovalRule(orgNumber),
    {
      enabled: options?.enabled !== undefined ? options.enabled : !!orgNumber,
      ...options,
    }
  );

const useShareTypesQuery = (
  orgNumber: string,
  date: string | undefined,
  options?: UseQueryOptions<unknown, IRequestError, ShareType[], string[]>
) =>
  useQuery(
    ["shareTypes", orgNumber, date || ""],
    () => getShareTypes(orgNumber, date || ""),
    {
      enabled:
        options?.enabled !== undefined ? options.enabled : date !== undefined,
      ...options,
    }
  );

const useShareholdersQuery = (
  orgNumber: string,
  ledgerVersion?: LedgerVersion
) => {
  const shareBlocksQuery = useShareblocksQuery(orgNumber, ledgerVersion || "");
  const getShareholders = (blocks: Shareblock[]) => {
    const activeBlocks = getActiveBlocks(blocks);
    const map = activeBlocks.reduce<Record<string, Shareholder>>(
      (prev, curr) => {
        const holder = prev[curr.holder.id];
        const { holder: _, ...blockValue } = curr;

        return {
          ...prev,
          [curr.holder.id]: {
            ...curr.holder,
            blocks: [...(holder?.blocks ?? []), blockValue],
          },
        };
      },
      {}
    );
    return Object.values(map);
  };

  return {
    ...shareBlocksQuery,
    data: getShareholders(shareBlocksQuery.data || []),
  };
};

export {
  useActiveApprovalRuleQuery,
  useApprovalInfoQuery,
  useCompaniesQuery,
  useLedgerQuery,
  useShareblocksQuery,
  useShareholdersQuery,
  useShareTypesQuery,
};
