import { FilePdf } from "@phosphor-icons/react";
import type React from "react";
import { useTranslation } from "react-i18next";

import { ShareHolderWithDilution } from "../../pages/CompanyShares/ShareHolders.utils";
import type { LedgerVersionDetails } from "../../pages/CompanyShares/useLedgerVersions";
import type { CompanyInformation } from "../../types/models/administration";
import type { CompanyInvolvement } from "../../types/models/company";
import { EntitiesMap } from "../../types/models/entities";
import {
  Shareblock,
  ShareBlockHistory,
  ShareTypesMap,
} from "../../types/models/shares";
import { makeAndDownloadExcel } from "../../utils/excel";
import {
  generateShareholderData,
  generateShareledgerData,
} from "../../utils/excel-utils";
import { clsxm } from "../../utils/tailwind";
import { ExcelIcon } from "../design-system/icons";
import { Menu } from "../design-system/Menu";
import { SelectVersion } from "../SelectVersion";
import { useRestrictiveConditionOptions } from "../ShareTypes/SelectRestrictiveConditions";

type BottomBarProps = {
  selectedVersion?: LedgerVersionDetails;
  setSelectedVersion: (version?: LedgerVersionDetails) => void;
  ledgerVersions: LedgerVersionDetails[];
  isSortedHoldersEmpty?: boolean;
  currentCompany: CompanyInvolvement | CompanyInformation;
  versionLabel: string;
  isShareLedger: boolean;
  totalVotes?: number;
  totalShares?: number;
  totalSharesByType?: Record<string, number>;
  mappedShareHolders?: ShareHolderWithDilution[];
  displayDiluted?: boolean;
  dilutedTotals?: { totalVotes: number; totalShares: number };
  sortedBlocks?: Shareblock[];
  entitiesMap?: EntitiesMap;
  shareTypesMap?: ShareTypesMap;
  shareBlockHistory?: ShareBlockHistory[];
  isDownloadLoading: boolean;
  downloadPdf: () => void;
};

const BottomBar: React.FC<BottomBarProps> = ({
  selectedVersion,
  setSelectedVersion,
  ledgerVersions,
  isSortedHoldersEmpty,
  currentCompany,
  versionLabel,
  isShareLedger,
  totalVotes,
  totalShares,
  totalSharesByType,
  mappedShareHolders,
  displayDiluted,
  dilutedTotals,
  sortedBlocks,
  entitiesMap,
  shareTypesMap,
  shareBlockHistory,
  isDownloadLoading,
  downloadPdf,
}) => {
  const i18n = useTranslation();
  const conditionOptions = useRestrictiveConditionOptions();

  return (
    <div
      id="bottom-bar"
      className=" tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-z-50 tw-flex tw-gap-2 tw-border-t-[1px] tw-bg-white tw-px-4 tw-py-3 sm:tw-hidden"
    >
      <SelectVersion
        selectedVersion={selectedVersion}
        availableVersions={ledgerVersions}
        onChange={setSelectedVersion}
        menuPlacement="top"
        className="tw-w-full"
      />

      <Menu>
        <Menu.Button
          type="button"
          className="tw-rounded tw-p-3"
          isDropdown={false}
          isLoading={isDownloadLoading}
          onClick={() => downloadPdf()}
        >
          <FilePdf className="tw-h-6 tw-w-6" />
        </Menu.Button>
      </Menu>
      <Menu>
        <Menu.Button
          className={clsxm("tw-rounded tw-p-3", {
            "tw-cursor-not-allowed tw-opacity-50": isSortedHoldersEmpty,
          })}
          disabled={isSortedHoldersEmpty}
          isDropdown={false}
          type="button"
          onClick={() => {
            if (isSortedHoldersEmpty) {
              return;
            }

            const data = isShareLedger
              ? generateShareledgerData(
                  sortedBlocks!,
                  entitiesMap!,
                  shareTypesMap!,
                  shareBlockHistory!,
                  conditionOptions,
                  i18n
                )
              : generateShareholderData(
                  mappedShareHolders ?? [],
                  displayDiluted!,
                  {
                    votes: totalVotes ?? 0,
                    shares: totalShares ?? 0,
                    sharesByType: totalSharesByType ?? {},
                    dilutedShares: dilutedTotals!.totalShares,
                    dilutedVotes: dilutedTotals!.totalVotes,
                  },
                  i18n
                );

            makeAndDownloadExcel(data, {
              currentCompany,
              title: isShareLedger
                ? i18n.t("label.shareLedger")
                : i18n.t("label.shareholders"),
              version: versionLabel,
            });
          }}
        >
          <ExcelIcon className="tw-h-6 tw-w-6" />
        </Menu.Button>
      </Menu>
    </div>
  );
};

export { BottomBar };
