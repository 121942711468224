import { ReactElement, ReactNode, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import {
  useChildEventsQuery,
  useParentEventsQuery,
  useVersionsQuery,
} from "../../../api/blockchain/events";
import { useApprovalRuleProposalQuery } from "../../../api/blockchain/users";
import { useCompanyUtils, useCurrentCompany } from "../../../context/account";
import { useSession } from "../../../context/session";
import { splitEvents } from "../../../hooks/useCompanyEvents/split-events";
import type { CompanyInformation } from "../../../types/models/administration";
import type { CompanyInvolvement } from "../../../types/models/company";
import type { EntitiesMap } from "../../../types/models/entities";
import type {
  CompanyFoundationEvent,
  DecreaseCapitalCancelSharesEvent,
  DecreaseCapitalEvent,
  IncreaseCapitalBonusIssueEvent,
  IncreaseCapitalEvent,
  LedgerApprovalInitializedEvent,
  PledgedSharesUpdateEvent,
  ShareAllocationEvent,
  ShareCertificatesUpdateEvent,
  ShareClassUpdateEvent,
  ShareIssueEvent,
  ShareReclassificationByClassEvent,
  ShareReclassificationByRangeEvent,
  ShareSplitEvent,
  ShareTransferEvent,
  TParentEvent,
} from "../../../types/models/events";
import { hiddenLedgerEvents } from "../../../types/models/events";
import type { LedgerVersion } from "../../../types/models/shares";
import {
  getFormattedDate,
  getFormattedLedgerVersion,
} from "../../../utils/date";
import { formatNumber } from "../../../utils/format";
import * as monitoring from "../../../utils/monitoring";
import { hasRequiredPermission } from "../../../utils/permissions";
import { calcSumWithinRange, isRangeValid } from "../../../utils/shares";
import { clsxm } from "../../../utils/tailwind";
import { Button } from "../../design-system/Button";
import { Disclosure } from "../../design-system/Disclosure";
import {
  CertificateIcon,
  CrossIcon,
  FoundationIcon,
  IncreaseCapitalIcon,
  PledgedIcon,
  ReclassificationIcon,
  ReduceCapitalIcon,
  RestoreIcon,
  ShareClassUpdateIcon,
  ShareIssueIcon,
  SplitIcon,
  TransferIcon,
  TrashIcon,
} from "../../design-system/icons";
import { List, ListItem, UList } from "../../design-system/List";
import { Loading } from "../../design-system/Loading";
import { TooltipV2 } from "../../design-system/Tooltip/TooltipV2";
import { IconDescription } from "../../IconDescription";
import { RestoreShares } from "../../RestoreShares";
import { useRestrictiveConditionOptions } from "../../ShareTypes/SelectRestrictiveConditions";
import {
  filterEvents,
  getEntityWithFallback,
  isFirstEventInList,
} from "./EventsTable.utils";

const EventWrapper = ({
  children,
  toggleOpen,
}: {
  children: React.ReactNode;
  toggleOpen: () => void;
}) => {
  const isMobileDevice = useMediaQuery({
    query: "(max-width: 640px)",
  });
  const isDesktopOrTabletDevice = useMediaQuery({
    query: "(min-width: 641px)",
  });

  return (
    <div>
      {isMobileDevice && (
        <button
          type="button"
          id="mobile"
          className="tw-relative tw-my-1 tw-w-full tw-rounded tw-text-left "
          style={
            isMobileDevice
              ? {
                  boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.08)",
                }
              : {}
          }
          onClick={toggleOpen}
        >
          {children}
        </button>
      )}
      {isDesktopOrTabletDevice && <div>{children}</div>}
    </div>
  );
};

const DisclosurePanelWrapper = ({
  children,
  open,
  toggleProps,
}: {
  children: React.ReactNode;
  open: boolean;
  toggleProps: ThreeDotChevronUpToggleProps;
}) => {
  return (
    <div
      className={clsxm(
        "tw-flex tw-flex-col tw-gap-4 tw-overflow-hidden tw-px-4 tw-ease-in-out max-sm:tw-transition-all max-sm:tw-duration-500",
        {
          "tw-max-h-[1000px] tw-opacity-100": open,
          "tw-max-h-0 tw-opacity-0": !open,
        }
      )}
    >
      <div className="tw-flex tw-flex-col tw-gap-4 tw-pb-4">
        <UList>{children}</UList>
        <button
          type="button"
          onClick={(e) => e.stopPropagation()}
          className="sm:tw-hidden"
        >
          <ThreeDotChevronUpToggle {...toggleProps} />
        </button>
      </div>
    </div>
  );
};

const Wrapper = ({ children }: { children: React.ReactNode }) => (
  <div className="tw-flex tw-gap-6  tw-px-4 tw-py-4 max-sm:tw-flex-row-reverse max-sm:tw-py-4 md:tw-flex-row md:tw-items-center md:tw-gap-0">
    {children}
  </div>
);

const Version = ({ ledgerVersion }: { ledgerVersion?: LedgerVersion }) => {
  const formatedLedgerVersion = getFormattedLedgerVersion(ledgerVersion);
  const date = getFormattedDate(formatedLedgerVersion.date);

  return (
    <div className="tw-flex tw-flex-wrap tw-items-center tw-gap-1 tw-text-sm tw-text-secondary md:tw-block md:tw-min-w-[10rem] md:tw-gap-0">
      {date}
    </div>
  );
};

type ThreeDotChevronUpToggleProps = {
  open: boolean;
  onOpenChange: (value: boolean) => void;
  event: TParentEvent;
  label: string;
  enableExpand: boolean;
  "data-testid"?: string;
};

const ThreeDotChevronUpToggle: React.FunctionComponent<
  ThreeDotChevronUpToggleProps
> = ({
  open,
  onOpenChange,
  event,
  label,
  enableExpand,
  ...props
}: ThreeDotChevronUpToggleProps) => {
  const i18n = useTranslation();
  const { user } = useSession();

  const [isRestoreSharesOpen, setIsRestoreSharesOpen] = useState(false);
  const [isDeleteSharesOpen, setIsDeleteSharesOpen] = useState(false);
  const currentCompany = useCurrentCompany();
  const eventsQuery = useParentEventsQuery({
    orgNumber: currentCompany?.orgNumber,
    offset: 0,
    limit: Number.MAX_SAFE_INTEGER,
  });

  const versionsQuery = useVersionsQuery(currentCompany!.orgNumber);
  const handleSharesChange = () => {
    versionsQuery.refetch();
    eventsQuery.refetch();
  };
  const currentVersion = versionsQuery.isSuccess
    ? versionsQuery.data[0]!
    : undefined;

  const pendingApprovalRuleProposalQuery = useApprovalRuleProposalQuery(
    currentCompany!.orgNumber
  );
  const isApprovalPolicyPending =
    pendingApprovalRuleProposalQuery.isSuccess &&
    !!pendingApprovalRuleProposalQuery.data;

  const hasRollbackPending =
    eventsQuery.isSuccess &&
    eventsQuery.data.data.find((e) => e.type === "LedgerRollbackPending");
  const eventsByStatus =
    eventsQuery.isSuccess && splitEvents(eventsQuery.data.data);

  const isDraft = !!(
    eventsByStatus && eventsByStatus.draftEvents.find((i) => i.id === event.id)
  );
  const pendingEvents =
    eventsByStatus &&
    eventsByStatus.pendingEvents.filter(
      (e) => !hiddenLedgerEvents.includes(e.type)
    );
  const approvedEvents =
    eventsByStatus &&
    eventsByStatus.approvedEvents.filter(
      (e) => !hiddenLedgerEvents.includes(e.type)
    );

  const hasPendingEvents = pendingEvents && pendingEvents.length > 0;

  let possibleToRollback = false;
  if (eventsByStatus) {
    if (eventsByStatus.draftEvents.find((i) => i.id === event.id)) {
      possibleToRollback = !isFirstEventInList(
        eventsByStatus.draftEvents,
        event
      );
    } else if (eventsByStatus.pendingEvents.find((i) => i.id === event.id)) {
      possibleToRollback =
        !isFirstEventInList(eventsByStatus.pendingEvents, event) ||
        filterEvents(eventsByStatus.draftEvents).length > 0;
    } else if (eventsByStatus.approvedEvents.find((i) => i.id === event.id)) {
      possibleToRollback =
        !isFirstEventInList(eventsByStatus.approvedEvents, event) ||
        filterEvents(eventsByStatus.draftEvents).length > 0 ||
        filterEvents(eventsByStatus.pendingEvents).length > 0;
    }
  }

  const isFirstPending = !!(
    pendingEvents &&
    pendingEvents.length > 0 &&
    pendingEvents[0]?.id === event.id
  );

  const isFirstApproved = !!(
    approvedEvents &&
    approvedEvents.length > 0 &&
    approvedEvents[0]?.id === event.id
  );

  const hasPendingApproval = hasRollbackPending || hasPendingEvents;
  const enableRollback =
    isDraft ||
    isFirstPending ||
    (!hasPendingApproval && isFirstApproved) ||
    (!hasPendingApproval && !isApprovalPolicyPending);
  const showRollback = possibleToRollback;

  const isCompanyFoundation = event.type === "CompanyFoundation";
  const enableReset =
    isDraft || (!hasPendingApproval && !isApprovalPolicyPending);

  if (!currentCompany) {
    return null;
  }

  return (
    <div className="tw-flex tw-items-center" data-testid={props["data-testid"]}>
      {currentCompany &&
        hasRequiredPermission("Editor", currentCompany, user) && (
          <div>
            <div className="tw-flex tw-items-center ">
              {showRollback && (
                <div>
                  <TooltipV2
                    content={
                      !enableRollback &&
                      i18n.t(
                        isApprovalPolicyPending
                          ? "initiatePolicy.approvalPolicyRequired"
                          : "approvalPolicy.rollBackLedgerToThisEvent.disabled"
                      )
                    }
                  >
                    <Button
                      disabled={!enableRollback}
                      onClick={() => setIsRestoreSharesOpen(true)}
                      variant="clean"
                    >
                      <RestoreIcon />
                    </Button>
                  </TooltipV2>
                </div>
              )}
              {isCompanyFoundation && (
                <div>
                  <TooltipV2
                    content={
                      !enableReset &&
                      i18n.t(
                        isApprovalPolicyPending
                          ? "initiatePolicy.approvalPolicyRequired"
                          : "approvalPolicy.rollBackLedgerToThisEvent.disabled"
                      )
                    }
                  >
                    <Button
                      disabled={!enableReset}
                      onClick={() => setIsDeleteSharesOpen(true)}
                      variant="clean"
                    >
                      <TrashIcon className="tw-text-error" />
                    </Button>
                  </TooltipV2>
                </div>
              )}
            </div>
          </div>
        )}
      {isRestoreSharesOpen && currentVersion && (
        <RestoreShares
          onClose={() => {
            setIsRestoreSharesOpen(false);
          }}
          currentVersion={currentVersion}
          events={eventsQuery.isSuccess ? eventsQuery.data.data : []}
          version={{ value: event.date, label }}
          currentCompany={currentCompany}
          onSuccess={() => {
            handleSharesChange();
            setIsRestoreSharesOpen(false);
          }}
        />
      )}
      {isDeleteSharesOpen && currentVersion && (
        <RestoreShares
          onClose={() => {
            setIsDeleteSharesOpen(false);
          }}
          currentVersion={currentVersion}
          events={[]}
          version={undefined}
          currentCompany={currentCompany}
          onSuccess={() => {
            handleSharesChange();
            setIsDeleteSharesOpen(false);
          }}
        />
      )}
      {enableExpand && (
        <Disclosure className="max-sm:tw-hidden">
          <Disclosure.Button
            open={open}
            onClick={() => {
              onOpenChange(!open);
            }}
          />
        </Disclosure>
      )}
    </div>
  );
};

const EventLine = ({
  title,
  icon,
  description,
  panelContent,
  event,
  label,
  border,
  open,
  onOpenChange,
}: {
  title: string;
  icon: ReactNode;
  description: ReactElement | string;
  panelContent?: ReactElement | ReactElement[];
  event: TParentEvent;
  label: string;
  border: boolean;
  open: boolean;
  onOpenChange: (value: boolean) => void;
}) => {
  const isDesktopOrTabletDevice = useMediaQuery({
    query: "(min-width: 641px)",
  });

  if (isDesktopOrTabletDevice) {
    return (
      <>
        <tr className={clsxm({ "tw-border-b": border && !open })}>
          <td className="tw-w-1/4 tw-p-4">
            <Version ledgerVersion={event.date} />
          </td>
          <td className="tw-w-1/2 tw-p-4">
            <IconDescription
              className="max-sm:tw-gap-2"
              description={
                <div className="max-sm:tw-hidden">{description}</div>
              }
              icon={icon}
              title={title}
              titleWeight="medium"
            />
          </td>
          <td className="tw-w-1/4 tw-p-4 max-sm:tw-hidden">
            <div className="tw-flex tw-justify-end max-sm:tw-hidden">
              <ThreeDotChevronUpToggle
                data-testid="event-line-menu"
                open={open}
                onOpenChange={onOpenChange}
                event={event}
                label={label}
                enableExpand={!!panelContent}
              />
            </div>
          </td>
        </tr>
        {open && (
          <tr className={clsxm({ "tw-border-b": border })}>
            <td
              colSpan={3}
              className="tw-px-6 tw-py-2 tw-text-sm tw-text-secondary"
            >
              {panelContent}
            </td>
          </tr>
        )}
      </>
    );
  }

  return (
    <Disclosure>
      <EventWrapper toggleOpen={() => onOpenChange(!open)}>
        <Wrapper>
          <Version ledgerVersion={event.date} />
          <div className="tw-flex tw-flex-1 tw-items-center tw-justify-between">
            <IconDescription
              className="max-sm:tw-gap-2"
              description={
                <div className="max-sm:tw-hidden">{description}</div>
              }
              icon={icon}
              title={title}
              titleWeight="medium"
            />

            <div className="tw-flex tw-justify-end max-sm:tw-hidden">
              <ThreeDotChevronUpToggle
                data-testid="event-line-menu"
                open={open}
                onOpenChange={onOpenChange}
                event={event}
                label={label}
              />
            </div>
          </div>
        </Wrapper>
        <Disclosure.Panel static>
          <DisclosurePanelWrapper
            open={open}
            toggleProps={{
              open,
              onOpenChange,
              event,
              label,
            }}
          >
            {panelContent || (
              <ListItem className="tw-text-sm tw-text-secondary">
                {description}
              </ListItem>
            )}
          </DisclosurePanelWrapper>
        </Disclosure.Panel>
      </EventWrapper>
    </Disclosure>
  );
};

const ShareAllocationEventLine = ({
  event,
  entitiesMap,
}: {
  event: ShareAllocationEvent;
  entitiesMap: EntitiesMap;
}) => {
  const { recipient, shares } = event;

  return (
    <Trans
      i18nKey="events.allocation.content"
      values={{
        shares: formatNumber(calcSumWithinRange(shares)),
        start: formatNumber(shares.start),
        end: formatNumber(shares.end),
        recipient: getEntityWithFallback(entitiesMap, recipient).name,
        type: shares.type,
      }}
    />
  );
};

const ShareAllocationEventList = ({
  parentEventId,
  currentCompany,
  entitiesMap,
}: {
  parentEventId: string;
  currentCompany: CompanyInvolvement | CompanyInformation;
  entitiesMap: EntitiesMap;
}) => {
  const childEventsQuery = useChildEventsQuery(
    currentCompany.orgNumber,
    parentEventId
  );
  if (childEventsQuery.isLoading) {
    return <Loading />;
  }
  if (!childEventsQuery.data) {
    return null;
  }

  return (
    <UList className="tw-gap-y-2">
      {childEventsQuery.data.map((e) => (
        <ListItem
          key={e.shares.start}
          className="tw-p-0 tw-text-sm tw-text-secondary"
        >
          <ShareAllocationEventLine event={e} entitiesMap={entitiesMap} />
        </ListItem>
      ))}
    </UList>
  );
};

const UnhandledEvent = ({ event }: { event: TParentEvent }) => (
  <div className="tw-flex tw-items-center tw-gap-x-4 tw-bg-error/25 tw-px-4 tw-py-2">
    {`UnhandledEvent: ${event.type}`}
  </div>
);

const useEventDetails = (
  event: TParentEvent,
  currentCompany: CompanyInvolvement | CompanyInformation,
  entitiesMap: EntitiesMap
) => {
  const i18n = useTranslation();
  const { formatCurrency } = useCompanyUtils();

  const eventsData = {
    CompanyFoundation: () => {
      const e = event as CompanyFoundationEvent;

      return {
        title: i18n.t("events.companyFoundation.title"),
        icon: <FoundationIcon />,
        description: (
          <Trans
            i18nKey="events.companyFoundation.content"
            values={{
              shares: formatNumber(e.shares.total),
              capital: formatCurrency(e.shares.capital),
            }}
          />
        ),
        panelContent: (
          <ShareAllocationEventList
            currentCompany={currentCompany}
            entitiesMap={entitiesMap}
            parentEventId={e.id}
          />
        ),
      };
    },
    ShareIssue: () => {
      const e = event as ShareIssueEvent;

      return {
        title: i18n.t("events.issue.title"),
        icon: <ShareIssueIcon />,
        description: (
          <Trans
            i18nKey="events.issue.content"
            values={{
              shares: formatNumber(e.shares.total),
            }}
          />
        ),
        panelContent: (
          <ShareAllocationEventList
            currentCompany={currentCompany}
            entitiesMap={entitiesMap}
            parentEventId={e.id}
          />
        ),
      };
    },
    ShareSplit: () => {
      const e = event as ShareSplitEvent;
      const diff = e.ratio.x - e.ratio.y;
      const isReverse = diff > 0;

      return {
        title: i18n.t(
          isReverse ? "events.reverseSplit.title" : "events.split.title"
        ),
        icon: isReverse ? <CrossIcon /> : <SplitIcon />,
        description: (
          <Trans
            i18nKey={
              isReverse ? "events.reverseSplit.content" : "events.split.content"
            }
            values={{
              x: e.ratio.x,
              y: e.ratio.y,
            }}
          />
        ),
        panelContent: (
          <Trans
            i18nKey={
              isReverse
                ? "events.reverseSplit.content.detailed"
                : "events.split.content.detailed"
            }
            values={{
              x: e.ratio.x,
              y: e.ratio.y,
              shares: formatNumber(e.shares.total),
            }}
          />
        ),
      };
    },
    ReverseShareSplit: () => {
      const e = event as ShareSplitEvent;

      return {
        title: i18n.t("events.reverseSplit.title"),
        icon: <CrossIcon />,
        description: (
          <Trans
            i18nKey="events.reverseSplit.content"
            values={{
              x: e.ratio.x,
              y: e.ratio.y,
            }}
          />
        ),
        panelContent: (
          <Trans
            i18nKey="events.reverseSplit.content.detailed"
            values={{
              x: e.ratio.x,
              y: e.ratio.y,
              shares: formatNumber(e.shares.total),
            }}
          />
        ),
      };
    },
    ShareTransfer: () => {
      const e = event as ShareTransferEvent;
      const { shares } = e;

      if (shares.length > 1) {
        const sharesCount = shares.reduce(
          (prev, curr) => prev + (curr.range.end - curr.range.start + 1),
          0
        );

        return {
          title: i18n.t("events.transfer.title"),
          icon: <TransferIcon />,
          description: (
            <Trans
              i18nKey="events.transfer.content.basic"
              values={{ shares: formatNumber(sharesCount) }}
            />
          ),
          panelContent: (
            <List className="tw-gap-y-2">
              {shares.map(({ range, sender, recipient }) => (
                <ListItem
                  key={range.start}
                  className="tw-p-0 tw-text-sm tw-text-secondary"
                >
                  <Trans
                    i18nKey="events.transfer.content.detailed"
                    values={{
                      start: formatNumber(range.start),
                      end: formatNumber(range.end),
                      shares: formatNumber(calcSumWithinRange(range)),
                      sender: getEntityWithFallback(entitiesMap, sender).name,
                      recipient: getEntityWithFallback(entitiesMap, recipient)
                        .name,
                      type: range.type,
                    }}
                  />
                </ListItem>
              ))}
            </List>
          ),
        };
      }

      const [{ sender, recipient, range }] = shares;

      return {
        title: i18n.t("events.transfer.title"),
        icon: <TransferIcon />,
        description: (
          <Trans
            i18nKey="events.transfer.content"
            values={{
              start: formatNumber(range.start),
              end: formatNumber(range.end),
              shares: formatNumber(calcSumWithinRange(range)),
              sender: getEntityWithFallback(entitiesMap, sender).name,
              recipient: getEntityWithFallback(entitiesMap, recipient).name,
              type: range.type,
            }}
          />
        ),
        panelContent: (
          <Trans
            i18nKey="events.transfer.content.detailed"
            values={{
              start: formatNumber(range.start),
              end: formatNumber(range.end),
              shares: formatNumber(calcSumWithinRange(range)),
              sender: getEntityWithFallback(entitiesMap, sender).name,
              recipient: getEntityWithFallback(entitiesMap, recipient).name,
              type: range.type,
            }}
          />
        ),
      };
    },
    DecreaseCapital: () => {
      const e = event as DecreaseCapitalEvent;

      return {
        title: i18n.t("events.reduceCapital.title"),
        icon: <ReduceCapitalIcon />,
        description: (
          <Trans
            i18nKey="events.reduceCapital.content"
            values={{
              capital: formatCurrency(e.shares.capital),
            }}
          />
        ),
        panelContent: (
          <Trans
            i18nKey="events.reduceCapital.content.detailed"
            values={{
              capital: formatCurrency(e.shares.capital),
              quotaBefore: formatCurrency(e.shares.quotaValue.before),
              quotaAfter: formatCurrency(e.shares.quotaValue.after),
            }}
          />
        ),
      };
    },
    DecreaseCapitalCancelShares: () => {
      const e = event as DecreaseCapitalCancelSharesEvent;

      return {
        title: i18n.t("events.reduceCapital.title"),
        icon: <ReduceCapitalIcon />,
        description: (
          <Trans
            i18nKey="events.reduceCapital.content"
            values={{
              capital: formatCurrency(e.shares.capital),
            }}
          />
        ),
        panelContent: (
          <Trans
            i18nKey="events.reduceCapital.cancel.content"
            values={{
              capital: formatCurrency(e.shares.capital),
              shares: formatNumber(e.shares.total),
            }}
          />
        ),
      };
    },
    IncreaseCapital: () => {
      const e = event as IncreaseCapitalEvent;

      return {
        title: i18n.t("events.increaseCapital.title"),
        icon: <IncreaseCapitalIcon />,
        description: (
          <Trans
            i18nKey="events.increaseCapital.content"
            values={{
              capital: formatCurrency(e.shares.capital),
            }}
          />
        ),
        panelContent: (
          <Trans
            i18nKey="events.increaseCapital.content.detailed"
            values={{
              capital: formatCurrency(e.shares.capital),
              quotaBefore: formatCurrency(e.shares.quotaValue.before),
              quotaAfter: formatCurrency(e.shares.quotaValue.after),
            }}
          />
        ),
      };
    },
    IncreaseCapitalBonusIssue: () => {
      const e = event as IncreaseCapitalBonusIssueEvent;

      return {
        title: i18n.t("events.increaseCapital.title"),
        icon: <IncreaseCapitalIcon />,
        description: (
          <Trans
            i18nKey="events.increaseCapital.content"
            values={{
              capital: formatCurrency(e.shares.capital),
              shares: formatNumber(e.shares.total),
            }}
          />
        ),
        panelContent: (
          <ShareAllocationEventList
            currentCompany={currentCompany}
            entitiesMap={entitiesMap}
            parentEventId={e.id}
          />
        ),
      };
    },
    ReclassifySharesByRange: () => {
      const e = event as ShareReclassificationByRangeEvent;
      const sharesCount = e.shares.ranges.reduce(
        (prev, curr) => prev + calcSumWithinRange(curr),
        0
      );

      return {
        title: i18n.t("events.reclassification.title"),
        icon: <ReclassificationIcon />,
        description: (
          <Trans
            i18nKey="events.reclassification.range.content"
            values={{
              shares: formatNumber(sharesCount),
              toClass: e.shares.toClass,
            }}
          />
        ),
        panelContent: (
          <List className="tw-gap-y-2">
            {e.shares.ranges.map((range) => (
              <ListItem
                key={range.start}
                className="tw-p-0 tw-text-sm tw-text-secondary"
              >
                <Trans
                  i18nKey="events.reclassification.range.child.content"
                  values={{
                    shares: formatNumber(calcSumWithinRange(range)),
                    start: formatNumber(range.start),
                    end: formatNumber(range.end),
                    fromClass: range.type,
                    toClass: e.shares.toClass,
                  }}
                />
              </ListItem>
            ))}
          </List>
        ),
      };
    },
    ReclassifySharesByClass: () => {
      const e = event as ShareReclassificationByClassEvent;

      return {
        title: i18n.t("events.reclassification.title"),
        icon: <ReclassificationIcon />,
        description: (
          <Trans
            i18nKey="events.reclassification.class.content"
            values={{
              shares: formatNumber(e.shares.total),
              toClass: e.shares.toClass,
            }}
          />
        ),
        panelContent: (
          <Trans
            i18nKey="events.reclassification.class.content.detailed"
            values={{
              shares: formatNumber(e.shares.total),
              fromClass: e.shares.fromClass,
              toClass: e.shares.toClass,
            }}
          />
        ),
      };
    },
    ShareClassUpdate: () => {
      const e = event as ShareClassUpdateEvent;
      const { shareClass } = e;
      const conditionOptions = useRestrictiveConditionOptions();
      const enabledConditions = conditionOptions.filter(
        (option) => !!shareClass.condition[option.value]
      );

      return {
        title: i18n.t("events.shareTypeUpdate.title"),
        icon: <ShareClassUpdateIcon />,
        description: (
          <Trans
            i18nKey="events.shareTypeUpdate.content"
            values={{
              name: shareClass.name,
            }}
          />
        ),
        panelContent: (
          <Trans
            i18nKey="events.shareTypeUpdate.content.detailed"
            values={{
              name: shareClass.name,
              count: enabledConditions.length,
              conditions: enabledConditions
                .map((condition) => condition.label)
                .join(", "),
            }}
          />
        ),
      };
    },
    ShareCertificateUpdate: () => {
      const e = event as ShareCertificatesUpdateEvent;
      const {
        ranges: { before, after },
      } = e;
      const removed = before.filter(
        (x) =>
          !after.find((range) => x.start === range.start && x.end === range.end)
      );
      const added = after.filter(
        (x) =>
          !before.find(
            (range) => x.start === range.start && x.end === range.end
          )
      );

      return {
        title: i18n.t("events.shareCertificateUpdate.title"),
        icon: <CertificateIcon />,
        description: (
          <Trans
            i18nKey="events.shareCertificateUpdate.content"
            values={{ count: after.length }}
          />
        ),
        panelContent: (
          <List className="tw-gap-y-2">
            {added.map(({ start, end, type }) => (
              <ListItem
                key={start}
                className="tw-p-0 tw-text-sm tw-text-secondary"
              >
                {i18n.t("events.shareCertificateUpdate.content.added", {
                  start,
                  end,
                  type,
                  count:
                    start && end && isRangeValid({ start, end })
                      ? end - start + 1
                      : 0,
                })}
              </ListItem>
            ))}
            {removed.map(({ start, end, type }) => (
              <ListItem
                key={start}
                className="tw-p-0 tw-text-sm tw-text-secondary"
              >
                {i18n.t("events.shareCertificateUpdate.content.removed", {
                  start,
                  end,
                  type,
                  count:
                    start && end && isRangeValid({ start, end })
                      ? end - start + 1
                      : 0,
                })}
              </ListItem>
            ))}
          </List>
        ),
      };
    },
    SharePledgedUpdate: () => {
      const e = event as PledgedSharesUpdateEvent;
      const {
        ranges: { before, after },
      } = e;
      const removed = before.filter(
        (x) =>
          !after.find((range) => x.start === range.start && x.end === range.end)
      );
      const added = after.filter(
        (x) =>
          !before.find(
            (range) => x.start === range.start && x.end === range.end
          )
      );

      return {
        title: i18n.t("events.pledgedSharesUpdate.title"),
        icon: <PledgedIcon />,
        description: (
          <Trans
            i18nKey="events.pledgedSharesUpdate.content"
            values={{ count: after.length }}
          />
        ),
        panelContent: (
          <List className="tw-gap-y-2">
            {added.map(({ start, end, type, creditor }) => (
              <ListItem
                key={start}
                className="tw-p-0 tw-text-sm tw-text-secondary"
              >
                {i18n.t("events.pledgedSharesUpdate.content.added", {
                  start,
                  end,
                  type,
                  count:
                    start && end && isRangeValid({ start, end })
                      ? end - start + 1
                      : 0,
                  creditor: getEntityWithFallback(entitiesMap, creditor).name,
                })}
              </ListItem>
            ))}
            {removed.map(({ start, end, type }) => (
              <ListItem
                key={start}
                className="tw-p-0 tw-text-sm tw-text-secondary"
              >
                {i18n.t("events.pledgedSharesUpdate.content.removed", {
                  start,
                  end,
                  type,
                  count:
                    start && end && isRangeValid({ start, end })
                      ? end - start + 1
                      : 0,
                })}
              </ListItem>
            ))}
          </List>
        ),
      };
    },
    LedgerRollbackPending: () => {
      const {
        user: { id: userId },
      } = event as LedgerApprovalInitializedEvent;
      const initiator: string = entitiesMap[userId]?.name ?? "";

      return {
        title: i18n.t("events.ledgerRollbackPending.title"),
        icon: <RestoreIcon />,
        description: initiator ? (
          <Trans
            i18nKey="events.initializeLedgerApproval.content"
            values={{ initiator }}
          />
        ) : (
          ""
        ),
        panelContent: undefined,
      };
    },
    LedgerApprovalInitialized: undefined,
    LedgerApproval: undefined,
    LedgerRollback: undefined,
    LedgerPolicyApproval: undefined,
    LedgerRollbackRejected: undefined,
    LedgerApprovalRejected: undefined,
  };

  const matchingEventCallable = eventsData[event.type];
  if (matchingEventCallable) {
    return matchingEventCallable();
  }
  return undefined;
};

const ParentEvent = ({
  event,
  label,
  currentCompany,
  entitiesMap,
  border,
  open = false,
  onOpenChange = () => {
    console.error("onOpenChange is not defined");
  },
}: {
  event: TParentEvent;
  label: string;
  currentCompany: CompanyInvolvement | CompanyInformation;
  entitiesMap: EntitiesMap;
  border: boolean;
  open?: boolean;
  onOpenChange?: (value: boolean) => void;
}) => {
  const details = useEventDetails(event, currentCompany, entitiesMap);

  if (details) {
    return (
      <EventLine
        title={details.title}
        icon={details.icon}
        description={details.description}
        panelContent={details.panelContent}
        border={border}
        event={event}
        label={label}
        open={open}
        onOpenChange={onOpenChange}
      />
    );
  }

  monitoring.captureException(
    new Error(`Unexpected event: ${JSON.stringify(event, null, 2)}`)
  );
  return <UnhandledEvent event={event} />;
};

const EventsTable = ({
  events,
  currentCompany,
  entitiesMap,
  expandedEvents,
  setExpandedEvents,
}: {
  events: (TParentEvent & { label: string })[];
  currentCompany: CompanyInvolvement | CompanyInformation;
  entitiesMap: EntitiesMap;
  expandedEvents: { [key: string]: boolean };
  setExpandedEvents: (value: { [key: string]: boolean }) => void;
}) => (
  <>
    <table className="tw-w-full tw-table-fixed max-sm:tw-hidden">
      <thead>
        <tr>
          <th className="tw-w-1/4" aria-labelledby="Date" />
          <th className="tw-w-1/2" aria-labelledby="Event" />
          <th className="tw-w-1/4" aria-labelledby="Controls" />
        </tr>
      </thead>
      <tbody>
        {events.map((event, index) => (
          <ParentEvent
            key={event.id}
            currentCompany={currentCompany}
            entitiesMap={entitiesMap}
            border={events.length !== index + 1}
            event={event}
            label={event.label}
            open={expandedEvents[event.id]}
            onOpenChange={(value: boolean) => {
              setExpandedEvents({
                ...expandedEvents,
                [event.id]: value,
              });
            }}
          />
        ))}
      </tbody>
    </table>
    <div className="tw-block md:tw-hidden">
      {events.map((event, index) => (
        <ParentEvent
          key={event.id}
          currentCompany={currentCompany}
          entitiesMap={entitiesMap}
          border={events.length !== index + 1}
          event={event}
          label={event.label}
          open={expandedEvents[event.id]}
          onOpenChange={(value: boolean) => {
            setExpandedEvents({
              ...expandedEvents,
              [event.id]: value,
            });
          }}
        />
      ))}
    </div>
  </>
);

export { EventsTable };
