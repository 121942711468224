import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useUsersQuery } from "../../../api/blockchain/users";
import { useUpdateEntityMutation } from "../../../api/rest/entities";
import type { CompanyInformation } from "../../../types/models/administration";
import type { CompanyInvolvement } from "../../../types/models/company";
import type { Entity } from "../../../types/models/entities";
import { Button } from "../../design-system/Button";
import { PencilIcon } from "../../design-system/icons";
import { EditEntityDialog } from "../EditEntityDialog";

type EditEntityProps = {
  value: Entity;
  currentCompany: CompanyInvolvement | CompanyInformation;
  onSuccess?: () => void;
};

const EditEntity = ({ value, currentCompany, onSuccess }: EditEntityProps) => {
  const [isOpen, setOpen] = useState(false);
  const usersQuery = useUsersQuery(currentCompany.orgNumber);
  const matchingUser =
    usersQuery.data &&
    usersQuery.data.find((x) => x.user.refId === value.refId);
  const isBoardMember = matchingUser && matchingUser.role === "BoardMember";
  const updateEntityMutation = useUpdateEntityMutation(
    currentCompany.orgNumber,
    value.id,
    {
      onSuccess: () => {
        setOpen(false);
        if (onSuccess) {
          onSuccess();
        }
      },
    }
  );
  const i18n = useTranslation();

  return (
    <>
      <Button variant="clean" size="sm" onClick={() => setOpen(true)}>
        <PencilIcon className="tw-h-6 tw-w-6" />
      </Button>
      {isOpen && (
        <EditEntityDialog
          formId="edit-entity-form"
          title={i18n.t("label.editEntity")}
          onClose={() => {
            setOpen(false);
            updateEntityMutation.reset();
          }}
          isLoading={updateEntityMutation.isLoading}
          error={updateEntityMutation.error}
          onSave={(newEntity) => updateEntityMutation.mutate(newEntity)}
          defaultValues={value}
          enableCountryChange={!isBoardMember}
        />
      )}
    </>
  );
};

export { EditEntity };
