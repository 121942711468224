import type { Entity } from "../../../../../../types/models/entities";
import type { UserRole } from "../../../../../../types/models/users";

const getApproversIds = (approvers: string[]): string[] => {
  return approvers
    .filter((value) => value && value.length > 0)
    .flatMap((value) => value);
};

const getViewerApproverIds = (
  usersList: UserRole[],
  approverIds: string[]
): string[] => {
  return usersList
    .filter(
      (user) => approverIds.includes(user.user.id) && user.role === "Viewer"
    )
    .map((user) => user.user.id);
};

const checkManualApproverHasViewer = (
  usersList: UserRole[],
  approverIds: string[]
): boolean => {
  return usersList
    .filter((user) => approverIds.includes(user.user.id))
    .some((user) => user.role === "Viewer");
};

const getViewerApprovers = (
  approversOptions: Entity[],
  viewerApproverIds: string[]
): UserRole[] => {
  return approversOptions
    .filter((option) => viewerApproverIds.includes(option.id))
    .map((option) => ({
      role: "Viewer",
      user: {
        countryCode: option.countryCode,
        id: option.id,
        refId: option.refId,
        name: option.name,
      },
    }));
};

export {
  checkManualApproverHasViewer,
  getApproversIds,
  getViewerApproverIds,
  getViewerApprovers,
};
