/* eslint-disable no-param-reassign */
import type { ColInfo as SheetjsColInfo } from "sheetjs-style";
import XLSX from "sheetjs-style";

import type { NonEmptyArray } from "../types";
import type { CompanyInformation } from "../types/models/administration";
import type { CompanyInvolvement } from "../types/models/company";

type ColInfo = SheetjsColInfo & { wch: number };

type ExcelData = Record<string, Date | number | string | undefined>[];

type ExcelRows = {
  data: NonEmptyArray<ExcelData[number]>;
  colInfo: ColInfo[];
  styles: object[];
};

const LABEL_ROW_INDEX = 2;
const labelStyle = { font: { bold: true } };

const COMPANY_NAME_STYLE = { font: { sz: 14, bold: true } };

const addStyles = (ws: XLSX.WorkSheet, { styles }: ExcelRows) => {
  const ref = ws["!ref"];
  const range = XLSX.utils.decode_range(ref?.toString() || "");
  for (let C = range.s.c; C <= range.e.c; ++C) {
    const labelCell = ws[XLSX.utils.encode_cell({ c: C, r: 1 })];
    if (labelCell) {
      labelCell.s = labelStyle;
    }
  }
  for (
    let rowIndex = range.s.r + LABEL_ROW_INDEX;
    rowIndex <= range.e.r;
    rowIndex++
  ) {
    for (let colIndex = range.s.c; colIndex <= range.e.c; colIndex++) {
      const nextCell: unknown =
        ws[XLSX.utils.encode_cell({ r: rowIndex, c: colIndex })];
      const isLastRow = rowIndex === range.e.r;
      const style = styles[colIndex] || {};
      const styleToApply = isLastRow ? { ...style, ...labelStyle } : style;
      if (
        Object.keys(styleToApply).length > 0 &&
        typeof nextCell === "object" &&
        nextCell !== null &&
        "s" in nextCell
      ) {
        nextCell.s = styleToApply;
      }
    }
  }
  ws.A1.s = COMPANY_NAME_STYLE;
  ws["!merges"] = [XLSX.utils.decode_range("A1:H1")];
};

const addLabels = (ws: XLSX.WorkSheet, data: ExcelRows["data"]) => {
  const lastRow = data.at(-1) || data[0];
  XLSX.utils.sheet_add_aoa(ws, [Object.keys(lastRow)], {
    origin: `A${LABEL_ROW_INDEX}`,
  });
};

type Options = {
  currentCompany: CompanyInvolvement | CompanyInformation;
  title: string;
  version: string;
  downloaded: string;
};

const getWorkSheet = (
  rows: ExcelRows,
  { currentCompany, title, version, downloaded }: Options
) => {
  const ws = XLSX.utils.aoa_to_sheet([
    [
      `${currentCompany.name} - ${currentCompany.orgNumber} - ${title} - ${version} - ${downloaded}`,
    ],
  ]);
  addLabels(ws, rows.data);
  XLSX.utils.sheet_add_json(ws, [...rows.data], {
    skipHeader: true,
    origin: `A${LABEL_ROW_INDEX + 1}`,
  });
  ws["!cols"] = rows.colInfo;
  addStyles(ws, rows);

  return ws;
};

const getWorkBook = (rows: ExcelRows, options: Options) => {
  const ws = getWorkSheet(rows, options);

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, options.title);

  return wb;
};

const makeAndDownloadExcel = (
  rows: ExcelRows,
  { currentCompany, title, version, downloaded }: Options
) => {
  const wb = getWorkBook(rows, { currentCompany, title, version, downloaded });
  XLSX.writeFile(wb, `${currentCompany.name} - ${title} - ${version}.xlsx`);
};

export type { ColInfo, ExcelData as TExcelData, ExcelRows as TExcelRows };
export { getWorkBook, getWorkSheet, makeAndDownloadExcel };
