import { useTranslation } from "react-i18next";

import type { ShareTypeWithShares } from "../../../types/models/shares";
import { Badge } from "../../design-system/Badge";
import { Button } from "../../design-system/Button";
import { Description } from "../../design-system/Description";
import { Drawer } from "../../design-system/Drawer";

type ShareTypeDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  shareType: ShareTypeWithShares;
};

const ShareTypeDrawer: React.FC<ShareTypeDrawerProps> = ({
  isOpen,
  onClose,
  shareType,
}) => {
  const i18n = useTranslation();

  return (
    <Drawer isOpen={isOpen} position="bottom" onClose={onClose}>
      <div className="tw-flex tw-flex-col tw-gap-6 tw-p-6">
        <h3>{shareType?.name}</h3>
        <Description
          title={i18n.t("label.numberOfShares")}
          description={`${shareType?.shares} ${i18n.t(
            "myShares.sharesSumUnit"
          )}`}
          theme="grayBlack"
        />
        <Description
          title={i18n.t("label.numberOfVotesPerShare")}
          description={`${shareType?.voteValue}`}
          theme="grayBlack"
        />
        <Description
          title={i18n.t("shares.restrictiveConditions")}
          description={
            <div>
              {Object.values(shareType?.condition).some((value) => value) ? (
                Object.entries(shareType?.condition).map(([condition, value]) =>
                  value ? (
                    <Badge
                      key={condition}
                      color="none"
                      className="tw-mb-2 tw-mr-2"
                    >
                      {condition}
                    </Badge>
                  ) : null
                )
              ) : (
                <p>{i18n.t("shares.noConditions")}</p>
              )}
            </div>
          }
          theme="grayBlack"
        />
        <div className="tw-ml-auto">
          <Button onClick={onClose}>{i18n.t("label.close")}</Button>
        </div>
      </div>
    </Drawer>
  );
};

export { ShareTypeDrawer };
