import type { CaptureContext } from "@sentry/types";

import * as monitoring from "./monitoring";

const assertNever = (x: never): never => {
  throw Error(`Unexpected object: ${JSON.stringify(x)}`);
};

const safeAssertNever: (
  x: never,
  captureContext?: CaptureContext | undefined
) => never = (x, captureContext) => {
  monitoring.captureException(
    Error(`Unexpected object: ${JSON.stringify(x)}`),
    captureContext
  );

  // We want TypeScript to throw a compile-time error if we don't handle all
  // cases, so we return `never`.
  return undefined as never;
};

export { assertNever, safeAssertNever };
