import { useTranslation } from "react-i18next";

import type { EntitiesMap } from "../../../../types/models/entities";
import type { LedgerRollbackEvent } from "../../../../types/models/events";
import { RestoreIcon } from "../../../design-system/icons";
import AuditLine from "./AuditLine";

export const LedgerRollbackAuditLine = (props: {
  event: LedgerRollbackEvent;
  label: string;
  entitiesMap: EntitiesMap;
  open: boolean;
  onOpenChange: (value: boolean) => void;
}) => {
  const i18n = useTranslation();

  return (
    <AuditLine
      title={i18n.t("events.ledgerRollback.title")}
      icon={<RestoreIcon />}
      description={i18n.t("events.ledgerRollback.content")}
      {...props}
    />
  );
};
