import { useState } from "react";

const isPositiveInteger = (num: number): boolean =>
  Number.isInteger(num) && num > 0;

const clamp = (num: number, min: number, max: number): number =>
  Math.min(Math.max(num, min), max);

const useStepper = (steps: number) => {
  if (!isPositiveInteger(steps)) {
    throw TypeError(
      `Expected a positive integer for steps. Got ${JSON.stringify(steps)}`
    );
  }

  const maxStep = Math.max(steps, 1) - 1;
  const minStep = 0;

  const [currentStep, setCurrentStep] = useState(minStep);

  const handleNext = (): void => {
    const nextStep = Math.min(currentStep + 1, maxStep);
    if (currentStep >= nextStep) {
      return;
    }
    setCurrentStep(nextStep);
  };

  const handlePrevious = (): void => {
    const previousStep = Math.max(currentStep - 1, minStep);
    if (previousStep >= currentStep) {
      return;
    }
    setCurrentStep((x) => x - 1);
  };

  const jumpToStep = (step: number): void => {
    const clamped = clamp(step, minStep, maxStep);
    if (clamped !== step) {
      throw RangeError(
        `Expected step to be between ${minStep} and ${maxStep}. Got ${step}`
      );
    }

    if (clamped === currentStep) {
      return;
    }
    setCurrentStep(clamped);
  };

  return { currentStep, handleNext, handlePrevious, jumpToStep };
};

export { useStepper };
