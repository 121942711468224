import "./polyfills";
import "./i18n";
import "./index.css";

// ^ The Buffer polyfill needs to be imported before anything else
import { createRoot } from "react-dom/client";

import App from "./app";
import { Hotjar } from "./utils/hotjar";
import * as monitoring from "./utils/monitoring";

if (
  import.meta.env.MODE === "development" &&
  import.meta.env.VITE_MOCK_API === "true"
) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  require("./utils/api-mocking").start();
}

monitoring.init();
Hotjar.init();
const rootEl = document.querySelector("#root");
if (rootEl === null) {
  throw Error("Could not find root element");
}

const root = createRoot(rootEl);
root.render(<App />);
