import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useUpdateShareClassesMutation } from "../../../api/rest/draft";
import { Actions } from "../../../components/Actions";
import { Button } from "../../../components/design-system/Button";
import {
  FormError,
  FormErrorList,
} from "../../../components/design-system/FormGroup";
import { DistributionProgress } from "../../../components/DistributionProgress";
import { ShareTypesList } from "../../../components/ShareTypes/List";
import type {
  CompanyDetails,
  CompanyInformation,
} from "../../../types/models/administration";
import type { CompanyInvolvement } from "../../../types/models/company";
import type { TDraftShareType } from "../../../types/models/draft";
import { sumByKey } from "../../../utils/math";
import { ShareClassesProvider } from "../IssueShares/ShareClassesProvider";
import { draftName } from "./constants";
import type { FormChangeEvent, FormState } from "./types";

type ShareClassesProps = {
  companyDetails?: CompanyDetails;
  currentCompany: CompanyInvolvement | CompanyInformation;
  initialData: FormState;
  onFormChange: FormChangeEvent;
  onSuccess: () => void;
  onCancel: () => void;
};

const formId = "onboarding-shareclasses-form";

const ShareClasses = ({
  companyDetails,
  currentCompany,
  onCancel,
  onSuccess,
  initialData,
  onFormChange,
}: ShareClassesProps) => {
  const i18n = useTranslation();
  const { control, handleSubmit, watch, setValue, setError } = useForm<{
    value: FormState["shareClasses"];
  }>({ defaultValues: { value: initialData.shareClasses }, mode: "onChange" });

  const updateShareClassesMutation = useUpdateShareClassesMutation(
    currentCompany.orgNumber,
    draftName,
    {
      onSuccess: (_, formData) => {
        onFormChange({ shareClasses: formData });
        onSuccess();
      },
    }
  );

  const { value: formValues } = watch();
  const diff =
    sumByKey(formValues, "numberOfShares") -
    (initialData.shareCapital.totalShares || 0);

  const isValid = diff === 0;
  const onSubmit = (data: { value: FormState["shareClasses"] }) => {
    if (!isValid) {
      setError("value", {
        type: "manual",
        message: i18n.t("error.verification.shares.issue"),
      });
      return;
    }
    updateShareClassesMutation.mutate(data.value);
  };

  const importShareClasses = () => {
    const shareClasses = companyDetails?.sharesInformation?.shareClasses ?? [];
    if (shareClasses?.length > 0) {
      const mappedShareClasses: TDraftShareType[] = shareClasses.map(
        ({ name, votes = "0" }) => ({
          name,
          numberOfShares: 0,
          voteValue: Number.parseInt(votes, 10),
          condition: {
            consent: false,
            preemption: false,
            offerOfFirstRefusal: false,
            conversion: false,
            redemption: false,
          },
        })
      );
      setValue("value", mappedShareClasses);
      onFormChange({
        shareClasses: mappedShareClasses,
      });
    }
  };

  return (
    <ShareClassesProvider
      value={{ formValues, totalShares: initialData.shareCapital.totalShares }}
    >
      <form
        id={formId}
        onSubmit={handleSubmit(onSubmit)}
        className="tw-space-y-6"
      >
        <Controller
          control={control}
          name="value"
          render={({ field: { onChange, value }, fieldState }) => (
            <>
              <ShareTypesList
                value={value}
                onChange={onChange}
                importShareClasses={importShareClasses}
              />
              <FormError>{fieldState.error?.message}</FormError>
            </>
          )}
          rules={{ required: i18n.t("error.validation.required") }}
        />
        <DistributionProgress diff={diff} />
        {updateShareClassesMutation.error && (
          <FormErrorList error={updateShareClassesMutation.error} />
        )}
        <Actions>
          <Button onClick={onCancel}>{i18n.t("label.back")}</Button>
          <Button
            isLoading={updateShareClassesMutation.isLoading}
            variant="solid"
            color="primary"
            type="submit"
            form={formId}
          >
            {i18n.t("label.continue")}
          </Button>
        </Actions>
      </form>
    </ShareClassesProvider>
  );
};

export default ShareClasses;
