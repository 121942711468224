import { Trans, useTranslation } from "react-i18next";

import type { EntitiesMap } from "../../../../types/models/entities";
import type { ShareTransferEvent } from "../../../../types/models/events";
import { formatNumber } from "../../../../utils/format";
import { calcSumWithinRange } from "../../../../utils/shares";
import { Description } from "../../../design-system/Description";
import { TransferIcon } from "../../../design-system/icons";
import { ListItem } from "../../../design-system/List";
import { getEntityWithFallback } from "../../EventsTable/EventsTable.utils";
import AuditLine from "./AuditLine";

export const ShareTransferAuditLine = (props: {
  event: ShareTransferEvent;
  label: string;
  entitiesMap: EntitiesMap;
  open: boolean;
  onOpenChange: (value: boolean) => void;
}) => {
  const i18n = useTranslation();
  const createdBy = props.entitiesMap[props.event.user.id];
  const { shares } = props.event;
  if (shares.length > 1) {
    const sharesCount = shares.reduce(
      (prev, curr) => prev + (curr.range.end - curr.range.start + 1),
      0
    );

    return (
      <AuditLine
        title={i18n.t("events.transfer.title")}
        icon={<TransferIcon />}
        description={
          <Trans
            i18nKey="events.transfer.content.basic"
            values={{ shares: formatNumber(sharesCount) }}
          />
        }
        panelContent={
          <div className="tw-flex tw-w-full tw-items-center tw-gap-6 tw-px-6 md:tw-flex md:tw-w-full">
            <div className="tw-flex tw-items-center">
              <Description
                title={i18n.t("auditLog.createdBy")}
                description={createdBy!.name}
                theme="grayBlack"
              />
            </div>
            <div className="tw-flex tw-items-center">
              <Description
                title={i18n.t("auditLog.events.transfer")}
                description={shares.map(({ range, sender, recipient }) => (
                  <ListItem key={range.start} className="tw-text-sm">
                    <Trans
                      i18nKey="events.transfer.content"
                      values={{
                        start: formatNumber(range.start),
                        end: formatNumber(range.end),
                        shares: formatNumber(calcSumWithinRange(range)),
                        sender: getEntityWithFallback(props.entitiesMap, sender)
                          .name,
                        recipient: getEntityWithFallback(
                          props.entitiesMap,
                          recipient
                        ).name,
                        type: range.type,
                      }}
                    />
                  </ListItem>
                ))}
                theme="grayBlack"
              />
            </div>
          </div>
        }
        {...props}
      />
    );
  }

  return (
    <AuditLine
      title={i18n.t("events.transfer.title")}
      icon={<TransferIcon />}
      description={
        <Trans
          i18nKey="events.transfer.content"
          values={{
            start: formatNumber(shares[0].range.start),
            end: formatNumber(shares[0].range.end),
            shares: formatNumber(calcSumWithinRange(shares[0].range)),
            sender: getEntityWithFallback(props.entitiesMap, shares[0].sender)
              .name,
            recipient: getEntityWithFallback(
              props.entitiesMap,
              shares[0].recipient
            ).name,
            type: shares[0].range.type,
          }}
        />
      }
      panelContent={
        <div className="tw-flex tw-w-full tw-items-center tw-gap-6 tw-px-6 md:tw-flex md:tw-w-full">
          <div className="tw-flex tw-items-center">
            <Description
              title={i18n.t("auditLog.createdBy")}
              description={createdBy!.name}
              theme="grayBlack"
            />
          </div>
          <div className="tw-flex tw-items-center">
            <Description
              title={i18n.t("auditLog.events.transfer")}
              description={shares.map(({ range, sender, recipient }) => (
                <ListItem key={range.start} className="tw-text-sm">
                  <Trans
                    i18nKey="events.transfer.content"
                    values={{
                      start: formatNumber(range.start),
                      end: formatNumber(range.end),
                      shares: formatNumber(calcSumWithinRange(range)),
                      sender: getEntityWithFallback(props.entitiesMap, sender)
                        .name,
                      recipient: getEntityWithFallback(
                        props.entitiesMap,
                        recipient
                      ).name,
                      type: range.type,
                    }}
                  />
                </ListItem>
              ))}
              theme="grayBlack"
            />
          </div>
        </div>
      }
      {...props}
    />
  );
};
