const BankID = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    width="31"
    height="31"
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.28 16.403l1.254-8.33h-1.339c-.627 0-1.434-.37-1.671-1.05-.076-.23-.257-1.022.778-1.793.37-.27.608-.57.656-.8a.643.643 0 00-.171-.611c-.228-.23-.675-.36-1.244-.36-.96 0-1.634.58-1.7 1-.048.31.18.561.38.721.598.47.74 1.152.37 1.792-.38.661-1.206 1.091-2.09 1.101H4.137c-.114.811-1.975 13.245-2.118 14.226h7.417c.067-.44.409-2.793.874-5.896h-.028zM20.215.785h-7.55l-1.006 6.738h1.282c.702 0 1.367-.34 1.652-.831.095-.16.133-.3.133-.43 0-.28-.18-.491-.36-.631-.495-.39-.6-.801-.6-1.091 0-.06 0-.11.01-.16.105-.711 1.016-1.482 2.223-1.482.721 0 1.272.18 1.604.51.295.29.409.701.323 1.132-.104.51-.588.93-.864 1.14-.731.541-.636 1.012-.589 1.152.152.42.732.69 1.178.69h1.947v.01c2.659.02 4.083 1.312 3.637 4.316-.418 2.793-2.45 3.994-4.872 4.014l-.959 6.447h1.415c5.973 0 10.855-4.044 11.814-10.431C31.819 3.948 27.052.785 20.214.785zM1.62 25.172h3.029c1.292 0 1.605.691 1.51 1.322-.076.51-.408.89-.978 1.141.722.29 1.006.74.902 1.452-.133.89-.864 1.551-1.823 1.551H.812l.808-5.466zm2.003 2.263c.59 0 .865-.33.922-.72.057-.421-.124-.712-.713-.712H3.31l-.209 1.432h.522zm-.322 2.363c.607 0 .959-.26 1.044-.791.067-.46-.18-.731-.77-.731h-.597l-.228 1.532.55-.01zm7.027.88c-.788.06-1.168-.03-1.358-.39-.418.27-.883.41-1.377.41-.893 0-1.206-.49-1.12-1.03.037-.26.18-.511.408-.721.494-.45 1.71-.511 2.184-.851.038-.38-.104-.521-.55-.521-.523 0-.96.18-1.71.72l.18-1.24c.646-.491 1.273-.721 1.995-.721.92 0 1.737.4 1.585 1.461l-.18 1.202c-.066.42-.047.55.399.56l-.456 1.122zm-1.367-1.882c-.418.28-1.197.23-1.283.811-.038.27.124.47.38.47.247 0 .551-.11.798-.29-.019-.1-.01-.2.02-.39l.085-.6zm2.839-2.392h1.576l-.085.55c.503-.45.883-.62 1.377-.62.883 0 1.292.57 1.15 1.501l-.41 2.793h-1.576l.342-2.312c.067-.42-.057-.62-.36-.62-.248 0-.476.14-.694.45l-.36 2.472h-1.577l.617-4.214zm5.242-1.232h1.577l-.4 2.683 1.51-1.451h1.948l-1.938 1.802 1.558 2.422h-1.985l-1.197-1.952h-.019l-.285 1.952h-1.576l.807-5.456z"
      fill="currentColor"
    />
  </svg>
);

export default BankID;
