import { ArrowRight } from "@phosphor-icons/react";
import { Link } from "react-router-dom";

import { Description } from "../design-system/Description";
import { ListItem } from "../design-system/List";

type LinkListRowProps = {
  url: string;
  title: string | JSX.Element;
  description?: string;
};

const LinkListRow = ({ url, title, description }: LinkListRowProps) => (
  <Link to={url}>
    <ListItem className="tw-list-none tw-py-3 hover:tw-bg-gray-100" interactive>
      <div className="tw-flex tw-items-center tw-justify-between">
        <Description
          title={<h6>{title}</h6>}
          description={description ? <h6>{description}</h6> : undefined}
        />
        <div>
          <div className="tw-flex tw-items-center">
            <ArrowRight className="tw-text-neutral-900" />
          </div>
        </div>
      </div>
    </ListItem>
  </Link>
);

export default LinkListRow;
