import type { EntitiesMap } from "../../types/models/entities";
import type { Shareblock } from "../../types/models/shares";
import type { MultiValueProps as SelectMultiValueProps } from "../design-system/Select";
import { components } from "../design-system/Select";

type MultiValueProps = SelectMultiValueProps<Shareblock, true> & {
  entitiesMap: EntitiesMap;
};

const MultiValue: React.FunctionComponent<MultiValueProps> = (props) => {
  const {
    data: { start, end, holder, type },
    entitiesMap,
  } = props;
  const range = `${start} - ${end}`;
  const name = entitiesMap[holder.id]?.name;

  return (
    <components.MultiValue {...props}>
      <div className="tw-flex tw-gap-1">
        <div className="tw-flex tw-gap-2">
          <span>{range}</span>
          <span>{type}</span>
          <span>{name}</span>
        </div>
      </div>
    </components.MultiValue>
  );
};

export { MultiValue };
