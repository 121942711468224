import { useState } from "react";
import { useTranslation } from "react-i18next";

import type { useEntitiesQuery } from "../../../api/rest/entities";
import type { CompanyInformation } from "../../../types/models/administration";
import type { CompanyInvolvement } from "../../../types/models/company";
import type {
  NewShareBlock,
  TDraftShareType,
} from "../../../types/models/draft";
import { Button } from "../../design-system/Button";
import { EditShareBlockDialog } from "../Dialog";

type AddShareBlockProps = {
  onSuccess: (data: NewShareBlock) => void;
  entitiesQuery: ReturnType<typeof useEntitiesQuery>;
  currentCompany: CompanyInvolvement | CompanyInformation;
  shareTypes: TDraftShareType[];
  disableNewEntity?: boolean;
};

const AddShareBlock = ({
  onSuccess,
  entitiesQuery,
  currentCompany,
  shareTypes,
  disableNewEntity,
}: AddShareBlockProps) => {
  const [isOpen, setOpen] = useState(false);
  const i18n = useTranslation();

  return (
    <>
      <Button variant="solid" color="primary" onClick={() => setOpen(true)}>
        {i18n.t("onboarding.action.addShareBlock")}
      </Button>
      {isOpen && (
        <EditShareBlockDialog
          title={i18n.t("onboarding.action.addShareBlock")}
          onClose={() => setOpen(false)}
          onSuccess={(data) => {
            setOpen(false);
            onSuccess(data);
          }}
          entitiesQuery={entitiesQuery}
          currentCompany={currentCompany}
          shareTypes={shareTypes}
          disableNewEntity={disableNewEntity}
          defaultValues={{ type: shareTypes[0]?.name || "" }}
        />
      )}
    </>
  );
};

export { AddShareBlock };
