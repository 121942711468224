import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Description } from "../../../components/design-system/Description";
import { FormLabel } from "../../../components/design-system/FormGroup";
import { CrossIcon } from "../../../components/design-system/icons";
import { RadioGroup } from "../../../components/design-system/RadioGroup";
import { PageWrapper } from "../../../components/PageWrapper";
import { APP_ROUTE } from "../../../routes/constants";
import type { CompanyInformation } from "../../../types/models/administration";
import type { CompanyInvolvement } from "../../../types/models/company";
import TransferNumber from "./TransferNumber";
import TransferRange from "./TransferRange";

type TransferSharesProps = {
  currentCompany: CompanyInvolvement | CompanyInformation;
};

const TransferShares = ({ currentCompany }: TransferSharesProps) => {
  const eventsPath = `${APP_ROUTE.COMPANIES}/${currentCompany.orgNumber}/events`;
  const i18n = useTranslation();
  const [type, setType] = useState<"basic" | "range">("basic");

  const typeOptions = [
    {
      value: "basic",
      title: i18n.t("events.transfer.form.type.option1.title"),
    },
    {
      value: "range",
      title: i18n.t("events.transfer.form.type.option2.title"),
    },
  ];

  return (
    <PageWrapper data-testid="transfer-shares">
      <header className="tw-flex tw-justify-between tw-pb-6">
        <div>
          <h4>{i18n.t("events.transfer.title")}</h4>
        </div>
        <Link to={eventsPath} className="tw-text-body">
          <CrossIcon className="tw-h-6 tw-w-6" />
        </Link>
      </header>
      <RadioGroup
        value={type}
        onChange={(newValue: "basic" | "range") => {
          setType(newValue);
        }}
        name="type"
        className="tw-mb-6"
      >
        <RadioGroup.Label>
          <FormLabel htmlFor="type" className="tw-mb-2">
            {i18n.t("events.transfer.form.type.label")}
          </FormLabel>
        </RadioGroup.Label>
        <div className="tw-flex tw-flex-col tw-gap-2 md:tw-flex-row">
          {typeOptions.map((option) => (
            <RadioGroup.Option key={option.value} value={option.value}>
              {({ checked }) => (
                <RadioGroup.OptionContent checked={checked}>
                  <Description title={option.title} />
                </RadioGroup.OptionContent>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
      {type === "basic" ? (
        <TransferNumber currentCompany={currentCompany} />
      ) : (
        <TransferRange currentCompany={currentCompany} />
      )}
    </PageWrapper>
  );
};

export default TransferShares;
