import { Trans, useTranslation } from "react-i18next";

import type { EntitiesMap } from "../../../../types/models/entities";
import type { ShareReclassificationByClassEvent } from "../../../../types/models/events";
import { formatNumber } from "../../../../utils/format";
import { Description } from "../../../design-system/Description";
import { ReclassificationIcon } from "../../../design-system/icons";
import AuditLine from "./AuditLine";

export const ShareReclassificationByClassAuditLine = (props: {
  event: ShareReclassificationByClassEvent;
  label: string;
  entitiesMap: EntitiesMap;
  open: boolean;
  onOpenChange: (value: boolean) => void;
}) => {
  const i18n = useTranslation();
  const createdBy = props.entitiesMap[props.event.user.id];

  return (
    <AuditLine
      title={i18n.t("events.reclassification.title")}
      icon={<ReclassificationIcon />}
      description={
        <Trans
          i18nKey="events.reclassification.class.content"
          values={{
            shares: formatNumber(props.event.shares.total),
            fromClass: props.event.shares.fromClass,
            toClass: props.event.shares.toClass,
          }}
        />
      }
      panelContent={
        <div className="tw-flex tw-w-full tw-items-center tw-gap-6 tw-px-6 md:tw-flex md:tw-w-full">
          <div className="tw-flex tw-items-center">
            <Description
              title={i18n.t("auditLog.createdBy")}
              description={createdBy!.name}
              theme="grayBlack"
            />
          </div>
          <div className="tw-flex tw-items-center">
            <Description
              title={i18n.t("auditLog.events.reclassification")}
              description={
                <Trans
                  i18nKey="events.reclassification.class.content"
                  values={{
                    shares: formatNumber(props.event.shares.total),
                    fromClass: props.event.shares.fromClass,
                    toClass: props.event.shares.toClass,
                  }}
                />
              }
              theme="grayBlack"
            />
          </div>
        </div>
      }
      {...props}
    />
  );
};
