import { BasicShareRangeType, Holding, ShareType } from "@capchapdev/rell-api";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import Breadcrumb from "../../../components/Breadcrumb";
import { Badge } from "../../../components/design-system/Badge";
import { Description } from "../../../components/design-system/Description";
import { Tab } from "../../../components/design-system/Tab";
import { PageWrapper } from "../../../components/PageWrapper";
import { Table } from "../../../components/Table";
import { APP_ROUTE } from "../../../routes/constants";
import { formatNumber } from "../../../utils/format";
import { sumRanges } from "../../../utils/shares";
import { AddContactDetails } from "./AddContactDetails";

type DetailedOwnershipProps = {
  companyName: string;
  backLink: { url: string; name: string };
  holding: Holding;
  entity?: { contact: { email?: string | null; phone?: string | null } };
};

const getTotalSharesByType = (blocks: BasicShareRangeType[]) => {
  const totalSharesByType = new Map<string, number>();
  blocks.reduce((result, block) => {
    const { type } = block;
    const totalBlocksForType = sumRanges([block]) + (result.get(type) || 0);
    result.set(type, totalBlocksForType);

    return result;
  }, totalSharesByType);

  return totalSharesByType;
};

const DetailedOwnership = ({
  companyName,
  backLink,
  holding,
  entity,
}: DetailedOwnershipProps) => {
  const i18n = useTranslation();
  const [sortBy, setSortBy] = useState("range-desc");

  const sortedShareRanges = useMemo(() => {
    const [sortType, order] = sortBy.split("-");
    const sortOrder = order === "desc" ? 1 : -1;
    if (sortType === "range") {
      return holding.ranges.sort((a, b) => {
        if (a.start === b.start) {
          return 0;
        }
        return a.start > b.start ? sortOrder : -1 * sortOrder;
      });
    }
    return holding.ranges.sort((a, b) => {
      if (a.type === b.type) {
        return 0;
      }
      return a.type > b.type ? sortOrder : -1 * sortOrder;
    });
  }, [sortBy]);

  const userTotalShares = holding.shares.total;
  const userSharesPercentage = holding.shares.percentage;
  const userVotes = holding.votes.total;
  const userVotingPowerPercentage = holding.votes.percentage;
  const totalShares = holding.company.shares.total;
  const totalVotes = holding.company.votes.total;
  const shareTypes = holding.company.shareTypes.reduce<{
    [name: string]: ShareType;
  }>((acc, obj) => {
    acc[obj.name] = obj;
    return acc;
  }, {});

  const userTotalSharesByType = getTotalSharesByType(holding.ranges);

  const shareTypesWithAmount: string[] = [];
  for (const [shareType, amount] of userTotalSharesByType.entries()) {
    shareTypesWithAmount.push(`${shareType} (${formatNumber(amount)})`);
  }

  return (
    <PageWrapper className="tw-max-w-3xl" data-testid="ownership">
      <Breadcrumb
        links={[
          { url: `${APP_ROUTE.HOME}#asset`, name: i18n.t("home.name") },
          backLink,
        ]}
        current={companyName}
      />
      <h2 className="tw-mt-6 tw-font-medium">{companyName}</h2>
      <div className="tw-my-6">
        <h6 className="tw-text-secondary">
          {i18n.t("assets.details.sharesTitle")}
        </h6>
        <h2>
          {formatNumber(userTotalShares)}{" "}
          <span className="tw-text-secondary">{`(${userSharesPercentage}%)`}</span>
        </h2>
      </div>
      <hr />

      <div className="tw-my-6">
        <Tab.Group>
          <Tab.List>
            <Tab>{i18n.t("assets.details.overview")}</Tab>
            <Tab>{i18n.t("label.shareBlocks")}</Tab>
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel>
              <div className="tw-space-y-6 tw-pt-6">
                <Description
                  title={i18n.t("assets.details.votesOwned")}
                  description={`${formatNumber(
                    userVotes
                  )} (${userVotingPowerPercentage}%)`}
                  theme="grayBlack"
                />
                <Description
                  title={i18n.t("label.shareClasses")}
                  description={
                    <div className="tw-flex tw-gap-2">
                      {shareTypesWithAmount.map((t) => (
                        <Badge className="tw-text-sm" color="none" key={t}>
                          {t}
                        </Badge>
                      ))}
                    </div>
                  }
                  theme="grayBlack"
                />
                <Description
                  title={i18n.t("assets.details.companyShares")}
                  description={formatNumber(totalShares)}
                  theme="grayBlack"
                />
                <Description
                  title={i18n.t("assets.details.companyVotes")}
                  description={formatNumber(totalVotes)}
                  theme="grayBlack"
                />
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <div className="tw-pt-4">
                <Table
                  fixed
                  columns={[
                    {
                      name: "range",
                      title: i18n.t("label.shareBlock"),
                    },
                    {
                      name: "share_class",
                      title: i18n.t("label.shareClass"),
                    },
                  ]}
                  sortBy={sortBy}
                  setSortBy={(value: string) => setSortBy(value)}
                >
                  {sortedShareRanges.map((r) => (
                    <tr className="tw-border-neutral-200" key={r.start}>
                      <td className="tw-px-6 tw-py-2.5">
                        <Description
                          title={`${formatNumber(r.start)} - ${formatNumber(
                            r.end
                          )}`}
                          description={`${formatNumber(
                            r.end - r.start + 1
                          )} shares`}
                        />
                      </td>
                      <td className="tw-px-6 tw-py-2.5">
                        <Description
                          title={r.type}
                          description={`${shareTypes[r.type]?.voteValue} votes`}
                        />
                      </td>
                    </tr>
                  ))}
                </Table>
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
      {entity && !(entity?.contact?.email || entity?.contact?.phone) && (
        <AddContactDetails
          orgNumber={holding.company.orgNumber}
          orgName={companyName}
        />
      )}
    </PageWrapper>
  );
};

export default DetailedOwnership;
