import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useCompanyHoldings } from "../../../api/blockchain/companyHolding";
import { usePrivateHoldings } from "../../../api/blockchain/privateHolding";
import { useRepresentativeHolding } from "../../../api/blockchain/representativeHolding";
import {
  useMyEntityQuery,
  useRepresentativeEntityQuery,
} from "../../../api/rest/entities";
import { Loading } from "../../../components/design-system/Loading";
import { NoData } from "../../../components/NoData";
import { APP_ROUTE } from "../../../routes/constants";
import DetailedOwnership from "./DetailedOwnership";

export const PersonalOwnershipDetailed = () => {
  const i18n = useTranslation();
  const { companyId } = useParams();
  const entity = useMyEntityQuery(companyId).data;
  const privateHoldings = usePrivateHoldings("approved");
  const holding = privateHoldings.data?.holdings?.find(
    (h) => h.company.orgNumber === companyId
  );

  if (privateHoldings.isLoading) {
    return <Loading />;
  }

  if (!holding) {
    return (
      <NoData
        type="error"
        title={i18n.t("error.fetch")}
        description={i18n.t("error.verification.unauthorized")}
      />
    );
  }

  return (
    <DetailedOwnership
      companyName={holding.company.name}
      backLink={{
        url: `${APP_ROUTE.OWNERSHIP}/private`,
        name: i18n.t("assets.details.portfolio.personal"),
      }}
      holding={holding}
      entity={entity}
    />
  );
};

export const CompanyOwnershipDetailed = () => {
  const i18n = useTranslation();
  const { companyId, holdingCompanyNr } = useParams();
  const companyHoldings = useCompanyHoldings("approved");
  const company = companyHoldings.data?.find(
    (holding) => holding.company.orgNumber === holdingCompanyNr
  )?.company;
  const holding = companyHoldings.data
    ?.find((h) => h.company.orgNumber === holdingCompanyNr)
    ?.holdings.find((h) => h.company.orgNumber === companyId);

  if (companyHoldings.isLoading) {
    return <Loading />;
  }

  if (!holding || !company) {
    return (
      <NoData
        type="error"
        title={i18n.t("error.fetch")}
        description={i18n.t("error.verification.unauthorized")}
      />
    );
  }

  return (
    <DetailedOwnership
      companyName={holding.company.name}
      backLink={{
        url: `${APP_ROUTE.OWNERSHIP}/company/${company.orgNumber}`,
        name: i18n.t("assets.details.portfolio", { name: company.name }),
      }}
      holding={holding}
    />
  );
};

export const RepresentativeOwnershipDetailed = () => {
  const i18n = useTranslation();
  const { companyId, entityId } = useParams();
  const representativeHoldings = useRepresentativeHolding("approved");
  const representativeHoldingsForEntity = representativeHoldings.data?.filter(
    (holding) =>
      holding.holder.refId === entityId || holding.holder.id === entityId
  );
  const holding = representativeHoldingsForEntity
    ?.map((x) => x.holding)
    .flat()
    .find((h) => h.company.orgNumber === companyId);
  const orgNumber = holding && holding.company.orgNumber;
  const holderId =
    representativeHoldingsForEntity &&
    representativeHoldingsForEntity[0] &&
    representativeHoldingsForEntity[0].holder.id;
  const entityQuery = useRepresentativeEntityQuery(orgNumber, holderId);
  const entity = entityQuery.data;

  if (representativeHoldings.isLoading || entityQuery.isLoading) {
    return <Loading />;
  }

  if (!holding || !entity) {
    return (
      <NoData
        type="error"
        title={i18n.t("error.fetch")}
        description={i18n.t("error.verification.unauthorized")}
      />
    );
  }

  return (
    <DetailedOwnership
      companyName={holding.company.name}
      backLink={{
        url: `${APP_ROUTE.OWNERSHIP}/representative/${entity.id}`,
        name: i18n.t("assets.details.portfolio", { name: entity.name }),
      }}
      holding={holding}
    />
  );
};
