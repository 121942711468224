import { clsxm } from "../../../utils/tailwind";

type Color = "error" | "neutral" | "success" | "warning" | "blue" | "none";

type BadgeProps = React.HTMLAttributes<HTMLSpanElement> & {
  color?: Color;
};

const Badge = ({ color = "neutral", className, ...rest }: BadgeProps) => (
  <div
    className={clsxm(
      "tw-inline-flex tw-whitespace-nowrap tw-rounded-xl tw-px-2 tw-py-1 tw-text-xs",
      className,
      {
        "tw-bg-gray-500 tw-text-white": color === "neutral",
        "tw-bg-error tw-text-white": color === "error",
        "tw-bg-warning tw-text-white": color === "warning",
        "tw-green-900 tw-bg-green-100": color === "success",
        "tw-blue-900 tw-bg-blue-100": color === "blue",
        "tw-rounded-sm tw-border tw-border-neutral-200": color === "none",
      }
    )}
    {...rest}
  />
);

export { Badge };
export type { BadgeProps };
