import type { MenuPlacement } from "react-select";

import type { Entity } from "../../types/models/entities";
import type { Shareholder } from "../../types/models/shares";
import { formatRefId } from "../../utils/format";
import type {
  MenuPosition,
  OptionProps,
  SingleValueProps,
} from "../design-system/Select";
import {
  components,
  Option as SelectOption,
  Select,
} from "../design-system/Select";
import { EntityItem } from "../EntityItem";

type IOptionProps = OptionProps<Entity, false> & {
  shareBlocks?: Shareholder[];
};

const Option = (props: IOptionProps) => {
  const { data, shareBlocks } = props;

  return (
    <SelectOption className="tw-py-2" {...props}>
      <EntityItem value={data} shareBlocks={shareBlocks} hasFlag={false} />
    </SelectOption>
  );
};

type EntitySingleValueProps = SingleValueProps<Entity, false> & {
  shareBlocks?: Shareholder[];
};

const SingleValue = (props: EntitySingleValueProps) => {
  const { data, shareBlocks } = props;

  const given = shareBlocks?.find((item) => item.id === data.id);
  const sharesByClass = given?.blocks.reduce(
    (acc: Record<string, number>, block) => {
      if (!acc[block.type]) {
        acc[block.type] = 0;
      }
      acc[block.type] += block.end - block.start + 1;
      return acc;
    },
    {}
  );

  const renderBlocks = Object.entries(sharesByClass || {}).map(
    ([type, shares]) => (
      <div key={type} className="tw-flex-shrink-0">
        <span className="tw-rounded tw-border tw-px-2 tw-py-[2px] tw-text-sm">
          {`${type} (${shares})`}
        </span>
      </div>
    )
  );

  return (
    <components.SingleValue
      className="tw-flex tw-items-center tw-gap-4"
      {...props}
    >
      <div className="tw-mr-2 tw-flex tw-gap-1 ">
        <div>{data.name}</div>
        <div className="tw-text-secondary">{formatRefId(data)}</div>
      </div>
      <div className="tw-flex tw-flex-grow tw-flex-wrap tw-items-center tw-gap-2 max-sm:tw-hidden">
        <div className="tw-flex tw-flex-wrap tw-gap-2">{renderBlocks}</div>
      </div>
    </components.SingleValue>
  );
};

const SelectEntity = ({
  value,
  options,
  onChange,
  suffix,
  menuPosition,
  disabled,
  isClearable,
  menuPlacement,
  shareBlocks,
  isMulti,
  id,
}: {
  value?: string[] | string | null;
  options?: Entity[];
  onChange: (newValue: string[] | string | null) => void;
  suffix?: React.ReactNode;
  menuPosition?: MenuPosition;
  disabled?: boolean;
  isClearable?: boolean;
  menuPlacement?: MenuPlacement;
  shareBlocks?: Shareholder[];
  isMulti?: boolean;
  id?: string;
}) => {
  const selectedOption = options?.find((option) => option.id === value);

  return (
    <div
      className="tw-relative tw-flex tw-flex-col tw-items-center tw-space-y-1"
      id={id}
      data-testid={id}
    >
      <Select
        isMulti={isMulti}
        className="tw-w-full tw-flex-1"
        components={{
          Control: (props) => (
            <components.Control {...props} className="tw-py-1" />
          ),
          Menu: (props) => <components.Menu {...props} className="tw-py-1" />,
          Option: (props) => <Option {...props} shareBlocks={shareBlocks} />,
          SingleValue: (props) => (
            <SingleValue {...props} shareBlocks={shareBlocks} />
          ),
        }}
        getOptionLabel={(option) =>
          `${option.name} ${formatRefId({
            refId: option.refId,
            countryCode: option.countryCode,
            birthDate: option.birthDate,
            type: option.type,
          })}`
        }
        getOptionValue={(option) => option.id}
        isClearable={isClearable}
        isDisabled={disabled}
        menuPlacement={menuPlacement}
        menuPosition={menuPosition}
        options={options}
        value={selectedOption}
        onChange={(option) => {
          if (option === null && !isClearable) {
            return;
          }

          onChange(
            option
              ? Array.isArray(option)
                ? option.map((x) => x.id)
                : option.id
              : null
          );
        }}
      />
      {suffix}
    </div>
  );
};

export { SelectEntity };
