import { Trans, useTranslation } from "react-i18next";

import type { EntitiesMap } from "../../../../types/models/entities";
import type { ShareClassUpdateEvent } from "../../../../types/models/events";
import { Description } from "../../../design-system/Description";
import { ShareClassUpdateIcon } from "../../../design-system/icons";
import { useRestrictiveConditionOptions } from "../../../ShareTypes/SelectRestrictiveConditions";
import AuditLine from "./AuditLine";

export const ShareClassUpdateAuditLine = (props: {
  event: ShareClassUpdateEvent;
  label: string;
  entitiesMap: EntitiesMap;
  open: boolean;
  onOpenChange: (value: boolean) => void;
}) => {
  const i18n = useTranslation();
  const { shareClass } = props.event;
  const createdBy = props.entitiesMap[props.event.user.id];
  const conditionOptions = useRestrictiveConditionOptions();
  const enabledConditions = conditionOptions.filter(
    (option) => !!shareClass.condition[option.value]
  );

  return (
    <AuditLine
      title={i18n.t("events.shareTypeUpdate.title")}
      icon={<ShareClassUpdateIcon />}
      description={
        <Trans
          i18nKey="events.shareTypeUpdate.content"
          values={{
            name: shareClass.name,
            count: enabledConditions.length,
            conditions: enabledConditions
              .map((condition) => condition.label)
              .join(", "),
          }}
        />
      }
      panelContent={
        <div className="tw-flex tw-w-full tw-items-center tw-gap-6 tw-px-6 md:tw-flex md:tw-w-full">
          <div className="tw-flex tw-items-center">
            <Description
              title={i18n.t("auditLog.createdBy")}
              description={createdBy!.name}
              theme="grayBlack"
            />
          </div>
          <div className="tw-flex tw-items-center">
            <Description
              title={i18n.t("auditLog.events.conditions")}
              description={enabledConditions
                .map((condition) => condition.label)
                .join(", ")}
              theme="grayBlack"
            />
          </div>
        </div>
      }
      {...props}
    />
  );
};
