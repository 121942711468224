import { useTranslation } from "react-i18next";

import { Button } from "../../../components/design-system/Button";
import { PageWrapper } from "../../../components/PageWrapper";

const CompanyEmptyStateForViewers = () => {
  const i18n = useTranslation();
  return (
    <PageWrapper className="tw-max-w-xl" data-testid="ownership-empty">
      <div className="tw-flex tw-flex-col tw-items-center tw-gap-4 tw-text-center">
        <h3>{i18n.t("ledger.emptyState.viewer.title")}</h3>
        <p className="tw-text-sm">
          {i18n.t("ledger.emptyState.viewer.firstParagraph")}
        </p>
        <p className="tw-text-sm">
          {i18n.t("ledger.emptyState.viewer.secondParagraph")}
        </p>
        <a
          href="https://kvanta.com/contact"
          rel="noreferrer noopener"
          target="_blank"
        >
          <Button variant="solid" className="tw-bg-neutral-900">
            {i18n.t("signUp.contactUs")}
          </Button>
        </a>
      </div>
    </PageWrapper>
  );
};

export { CompanyEmptyStateForViewers };
