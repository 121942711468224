import { featureToggles } from "../config";

export type AllowedToggle = keyof typeof featureToggles;
export type Toggles = Partial<Record<AllowedToggle, boolean>>;

const getTogglesInStorage = (): Toggles => {
  const values = localStorage.getItem("featureToggles") ?? "[]";
  return JSON.parse(values);
};

function pruneToggles(toggles: Toggles): Toggles {
  const prunedToggles: Toggles = {};

  Object.entries(toggles).forEach(([featureName, isTurnedOn]) => {
    const toggleIsAvailable = !!featureToggles[featureName];
    if (toggleIsAvailable && isTurnedOn) {
      prunedToggles[featureName] = true;
    }
  });
  return prunedToggles;
}

const setTogglesInStorage = (togglesToStore: Toggles): void => {
  // We need to get the current toggles from storage, because `toggle` from
  // the module scope may be stale.
  const togglesInStorage: Toggles = getTogglesInStorage();
  const updatedToggles = { ...togglesInStorage, ...togglesToStore };
  const prunedToggles = pruneToggles(updatedToggles);
  localStorage.setItem("featureToggles", JSON.stringify(prunedToggles));
};

function updateFeatureToggle(featureName: AllowedToggle, isTurnedOn: boolean) {
  const activeToggles = {
    ...getTogglesInStorage(),
    [featureName]: isTurnedOn,
  };
  setTogglesInStorage(activeToggles);
}

function getEnabledFeatureToggles() {
  return {
    ...getTogglesInStorage(),
  };
}

export { getEnabledFeatureToggles, updateFeatureToggle };
