import crypto from "crypto";
import elliptic from "elliptic";

import { getKeyPair } from "../context/session";

type AuthData = [string, string, number];

const EC = elliptic.ec;
const ec = new EC("secp256k1");

/**
 * Pad a buffer to at least 32 bytes
 */
const padTo32 = (buffer: Buffer) => {
  const safePaddingSize = Math.max(32 - buffer.length, 0);
  // The padding will be 0-32 bytes long, depending on the length of the buffer
  const padding = Buffer.alloc(safePaddingSize);

  return Buffer.concat([padding, buffer]);
};

const getAuthHeader = (): AuthData | undefined => {
  const keyPair = getKeyPair();
  if (!keyPair) {
    return undefined;
  }

  const timestamp = Date.now();
  const ecKeyPair = ec.keyFromPrivate(keyPair.privateKey);
  const hash = crypto.createHash("sha256").update(`${timestamp}`).digest();
  const signature = ecKeyPair.sign(hash);

  const r = padTo32(signature.r.toBuffer());
  const s = padTo32(signature.s.toBuffer());

  return [
    keyPair.publicKey.toString("hex"),
    Buffer.concat([r, s]).toString("hex"),
    timestamp,
  ];
};

export { getAuthHeader };
