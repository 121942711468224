import type React from "react";

import { PublicPageLayout } from "../../components/PublicPageLayout";
import SignInForm from "./Form";

const SignInPage: React.FunctionComponent = () => (
  <PublicPageLayout>
    <SignInForm />
  </PublicPageLayout>
);

export { SignInPage };
