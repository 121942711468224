import { useTranslation } from "react-i18next";

import type { Condition, ShareTypeClause } from "../../../types/models/shares";
import type { MenuPosition, OptionProps } from "../../design-system/Select";
import { Option as SelectOption, Select } from "../../design-system/Select";

type Option = { value: ShareTypeClause; label: string };

const ClausesOption = (props: OptionProps<Option, true>) => {
  const { data } = props;

  return <SelectOption {...props}>{data.label}</SelectOption>;
};

const useRestrictiveConditionOptions = () => {
  const i18n = useTranslation();
  const options: { value: ShareTypeClause; label: string }[] = [
    { value: "consent", label: i18n.t("shares.restrictiveConditions.consent") },
    {
      value: "preemption",
      label: i18n.t("shares.restrictiveConditions.preemption"),
    },
    {
      value: "conversion",
      label: i18n.t("shares.restrictiveConditions.conversion"),
    },
    {
      value: "offerOfFirstRefusal",
      label: i18n.t("shares.restrictiveConditions.offerOfFirstRefusal"),
    },
    {
      value: "redemption",
      label: i18n.t("shares.restrictiveConditions.redemption"),
    },
  ];

  return options;
};

const SelectClauses = ({
  value,
  onChange,
  menuPosition,
  isDisabled,
}: {
  value: Condition;
  onChange: (newValue?: Condition) => void;
  menuPosition?: MenuPosition;
  isDisabled?: boolean;
}) => {
  const options = useRestrictiveConditionOptions();

  return (
    <Select
      value={options.filter((option) => value[option.value])}
      options={options}
      components={{ Option: ClausesOption }}
      onChange={(newValue = []) => {
        const valueList = new Set(newValue.map((v) => v.value));
        const valuePairs = options.map((option) => [
          option.value,
          valueList.has(option.value),
        ]);
        onChange(Object.fromEntries(valuePairs));
      }}
      menuPosition={menuPosition}
      isMulti
      isDisabled={isDisabled}
    />
  );
};

export { SelectClauses, useRestrictiveConditionOptions };
