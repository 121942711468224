import { isEqual } from "lodash";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import { useShareTypesQuery } from "../../../api/blockchain/company";
import { useShareTypeUpdate } from "../../../api/rest/events";
import {
  FormError,
  FormErrorList,
  FormGroup,
  FormLabel,
} from "../../../components/design-system/FormGroup";
import { CrossIcon } from "../../../components/design-system/icons";
import { Input } from "../../../components/design-system/Input";
import { notify } from "../../../components/design-system/Notifications";
import { Select } from "../../../components/design-system/Select";
import { PageWrapper } from "../../../components/PageWrapper";
import { SelectClauses } from "../../../components/ShareTypes/SelectRestrictiveConditions";
import useLatestVersion from "../../../hooks/useLatestVersion";
import { APP_ROUTE } from "../../../routes/constants";
import type { CompanyInformation } from "../../../types/models/administration";
import type { CompanyInvolvement } from "../../../types/models/company";
import type { Condition } from "../../../types/models/shares";
import { dateToIsoString } from "../../../utils/date";
import FormActions from "../FormActions";

type ShareTypeUpdateProps = {
  currentCompany: CompanyInvolvement | CompanyInformation;
};

type FormProps = {
  date: string;
  type: string;
  condition: Condition;
};

const defaultCondition = {
  consent: false,
  conversion: false,
  preemption: false,
  offerOfFirstRefusal: false,
  redemption: false,
};

const shareTypeUpdateFormId = "share-type-update-form";

const ShareTypeUpdate = ({ currentCompany }: ShareTypeUpdateProps) => {
  const eventsPath = `${APP_ROUTE.COMPANIES}/${currentCompany.orgNumber}/events`;
  const i18n = useTranslation();
  const navigate = useNavigate();
  const form = useForm<FormProps>({
    mode: "onChange",
    defaultValues: { condition: defaultCondition },
  });
  const shareType = form.watch("type");
  const shareTypesQuery = useShareTypesQuery(currentCompany.orgNumber, "");
  const shareTypes = shareTypesQuery.data || [];
  const lastEventDate = useLatestVersion();
  const handleSuccess = () => {
    notify(
      i18n.t("events.success", {
        name: i18n.t("events.shareTypeUpdate.title"),
      }),
      { type: "success" }
    );
    navigate(eventsPath);
  };

  const mutation = useShareTypeUpdate(currentCompany.orgNumber, shareType, {
    onSuccess: handleSuccess,
  });

  const handleSubmit = (data: FormProps) => {
    if (
      isEqual(
        data.condition,
        shareTypes.find((x) => x.name === shareType)?.condition
      )
    ) {
      form.setError("condition", {
        type: "manual",
        message: i18n.t("error.verification.noChanges"),
      });
      return;
    }
    mutation.mutate({
      date: data.date,
      condition: data.condition,
    });
  };

  return (
    <PageWrapper data-testid="share-type-update">
      <header className="tw-flex tw-justify-between tw-pb-6">
        <div>
          <h4>{i18n.t("events.shareTypeUpdate.title")}</h4>
        </div>
        <Link to={eventsPath} className="tw-text-body">
          <CrossIcon className="tw-h-6 tw-w-6" />
        </Link>
      </header>
      <form
        id={shareTypeUpdateFormId}
        className="tw-h-96 tw-space-y-6"
        onSubmit={form.handleSubmit(handleSubmit)}
      >
        <FormGroup>
          <FormLabel htmlFor="date">{i18n.t("label.date")}</FormLabel>
          <Controller
            control={form.control}
            render={({ field: { ref, name, onChange, value }, fieldState }) => (
              <>
                <Input
                  id="date"
                  value={value}
                  ref={ref}
                  name={name}
                  onChange={onChange}
                  type="date"
                  className="tw-w-full"
                  max={dateToIsoString(new Date())}
                  min={lastEventDate && dateToIsoString(lastEventDate.date)}
                />
                <FormError>{fieldState.error?.message}</FormError>
              </>
            )}
            name="date"
            rules={{ required: i18n.t("error.validation.required") }}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel htmlFor="shareClass">
            {i18n.t("label.shareClass")}
          </FormLabel>
          <Controller
            control={form.control}
            render={({ field: { onChange, value }, fieldState }) => (
              <>
                <Select
                  options={shareTypes}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.name}
                  value={shareTypes.find(({ name }) => name === value)}
                  onChange={(newValue) => {
                    onChange(newValue?.name);
                    form.setValue(
                      "condition",
                      newValue?.condition || defaultCondition
                    );
                  }}
                />
                <FormError>{fieldState.error?.message}</FormError>
              </>
            )}
            name="type"
            rules={{ required: i18n.t("error.validation.required") }}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel htmlFor="condition" isOptional>
            {i18n.t("shares.restrictiveConditions")}
          </FormLabel>
          <Controller
            name="condition"
            control={form.control}
            render={({ field: { onChange, value }, fieldState }) => (
              <>
                <SelectClauses value={value} onChange={onChange} />
                <FormError>{fieldState.error?.message}</FormError>
              </>
            )}
            rules={{ required: i18n.t("error.validation.required") }}
          />
        </FormGroup>
        {mutation.error && <FormErrorList error={mutation.error} />}
        <FormActions
          formId={shareTypeUpdateFormId}
          isLoading={mutation.isLoading}
          orgNumber={currentCompany.orgNumber}
          selectedShareTypes={[shareType]}
          hasError={Object.keys(form.formState.errors).length > 0}
        />
      </form>
    </PageWrapper>
  );
};

export default ShareTypeUpdate;
