import { useState } from "react";
import { useTranslation } from "react-i18next";

import type { EntitiesMap, Entity } from "../../../types/models/entities";
import type {
  EventType,
  Ledger,
  Shareblock,
  ShareBlockHistory,
  ShareSplitDetails,
  ShareTypesMap,
} from "../../../types/models/shares";
import {
  getFormattedDate,
  getFormattedLedgerVersion,
} from "../../../utils/date";
import { formatAddress, formatNumber } from "../../../utils/format";
import { calcSumWithinRange } from "../../../utils/shares";
import { clsxm } from "../../../utils/tailwind";
import { Description } from "../../design-system/Description";
import { Disclosure } from "../../design-system/Disclosure";
import {
  CertificateIcon,
  PledgedIcon,
  TrusteeIcon,
} from "../../design-system/icons";
import { ListItem } from "../../design-system/List";
import { Tooltip } from "../../design-system/Tooltip";
import { EntityItem } from "../../EntityItem";
import { getEntityWithFallback } from "../../EventList/EventsTable/EventsTable.utils";
import { VerifiedEmail } from "../../VerifiedEmail";
import { HistorySection } from "../HistorySection";
import { ShareBlockDetails } from "../ShareBlockDetails";

const defaultDisplay = {
  address: true,
  email: false,
  phone: false,
  since: false,
  entry: true,
  keyFigures: true,
  certificates: true,
  creditor: true,
  restrictiveConditions: true,
  history: true,
  trustees: true,
};

type DisplayProperties = Record<keyof typeof defaultDisplay, boolean>;

// TODO, extend useLedgerQuery with votes.total, it is used everywhere
type LedgerWithVotes = Ledger & { votes: { total: number } };

type DetailedBlockRowProps = {
  block: Shareblock;
  history?: ShareBlockHistory;
  entitiesMap: EntitiesMap;
  shareTypesMap: ShareTypesMap;
  ledgerData?: LedgerWithVotes;
  open?: boolean;
  onOpenChange: (value: boolean) => void;
  display: DisplayProperties;
  showHeader?: boolean;
};

type EntityRowProps = {
  entity: Entity;
  display: DisplayProperties;
};

type SectionProps = {
  header?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
};

const EntityDetails = ({ entity, display }: EntityRowProps) => {
  const address =
    display.address && entity.contact.address
      ? formatAddress(entity.contact.address)
      : "";
  const displayPhone = display.phone && entity.contact.phone;
  const displayEmail = display.email && entity.contact.email;
  const contact =
    displayPhone || displayEmail ? (
      <div className="tw-gap- tw-flex tw-items-center tw-gap-2">
        {displayEmail && (
          <span>
            {entity.contact.email}
            <VerifiedEmail emailVerification={entity.emailVerification} />
          </span>
        )}
        {displayPhone && <span>{entity.contact.phone}</span>}
      </div>
    ) : (
      ""
    );

  return address && contact ? (
    <Description title={address} description={contact} />
  ) : (
    <span>{address || contact}</span>
  );
};

const EntityRow = ({ entity, display }: EntityRowProps) => {
  return (
    <div className="tw-grid tw-gap-2 print:tw-grid-cols-3 md:tw-grid-cols-1">
      <div className="tw-col-span-1">
        <EntityItem value={entity} hasFlag={false} />
      </div>
      <div className="tw-col-span-1 tw-text-sm">
        <EntityDetails entity={entity} display={display} />
      </div>
    </div>
  );
};

const Section = ({ header = "", children, className }: SectionProps) => (
  <div
    className={clsxm(
      "tw-grid tw-gap-1 tw-py-2 tw-text-sm lg:tw-grid-cols-3",
      className
    )}
  >
    {header === "" ? null : <div className="tw-font-medium">{header}</div>}
    <div className="lg:tw-col-span-3">{children}</div>
  </div>
);

const Entry = ({
  history,
  getEventTypeFormatted,
}: {
  history: ShareBlockHistory;
  getEventTypeFormatted: (
    eventType: EventType,
    eventDetails?: ShareSplitDetails
  ) => string;
}) => {
  const i18n = useTranslation();

  return (
    <Section className="tw-py-0">
      <Description
        title={i18n.t("shares.history.end")}
        description={
          <div className="tw-flex tw-items-center tw-gap-2">
            <span className="tw-font-medium tw-text-neutral-800">
              {getFormattedDate(history.date)}
            </span>
            <span className="tw-text-secondary">
              {getEventTypeFormatted(history.eventType, history.eventDetails)}
            </span>
          </div>
        }
        theme="grayBlack"
      />
    </Section>
  );
};

const DetailedBlockRow = ({
  block,
  history,
  entitiesMap,
  shareTypesMap,
  open,
  onOpenChange,
  display,
}: DetailedBlockRowProps) => {
  const i18n = useTranslation();
  const holder = entitiesMap[block.holder.id];
  const creditor = entitiesMap[block.creditor?.id || ""];
  const trustees = (holder?.type === "Private" && holder?.children) || [];

  const shares = calcSumWithinRange(block);
  const shareType = shareTypesMap[block.type];
  const blockVotes = shares * (shareType?.voteValue || 1);

  const [openBlockRow, setOpenBlockRow] = useState(false);

  const toggleMobileHistory = () => {
    setOpenBlockRow(!openBlockRow);
  };

  const entityItem = getEntityWithFallback(entitiesMap, block.holder);

  if (!("type" in entityItem)) {
    console.error("No entity fallback found");

    return null;
  }

  const entityRowToDisplay = holder || entityItem;

  const getEventTypeFormatted = (
    eventType: EventType,
    eventDetails?: ShareSplitDetails
  ) => {
    switch (eventType) {
      case "CompanyFoundation":
        return i18n.t("events.companyFoundation.title");
      case "ShareIssue":
        return i18n.t("events.issue.title");
      case "ShareSplit":
        return eventDetails
          ? eventDetails.originShareRanges &&
            eventDetails.originShareRanges.length > 0
            ? i18n.t("events.split.title.detailed", {
                ratioX: eventDetails.ratioX,
                ratioY: eventDetails.ratioY,
                shareBlocks: eventDetails.originShareRanges
                  .map(({ start, end }) => `${start}-${end}`)
                  .join(", "),
              })
            : i18n.t("events.split.title.ratio", {
                ratioX: eventDetails.ratioX,
                ratioY: eventDetails.ratioY,
              })
          : i18n.t("events.split.title");
      case "ReverseShareSplit":
        return i18n.t("events.reverseSplit.title");
      case "ShareTransfer":
        return i18n.t("events.transfer.title");
      case "IncreaseCapitalBonusIssue":
        return i18n.t("events.bonusIssue.title");
      case "DecreaseCapitalCancelShares":
        return i18n.t("events.decreaseCapitalCancelShares.title");
      case "ReclassifySharesByClass":
        return i18n.t("events.reclassification.title");
      case "ReclassifySharesByRange":
        return i18n.t("events.reclassification.title");
      default:
        return "";
    }
  };

  return (
    <>
      <button
        type="button"
        id="mobile"
        className="tw-relative tw-my-1 tw-w-full tw-rounded tw-p-4 tw-text-left sm:tw-hidden"
        style={{
          boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.08)",
        }}
        onClick={toggleMobileHistory}
      >
        <div className="tw-flex tw-justify-between">
          <Description
            title={`${formatNumber(block.start)} - ${formatNumber(block.end)}`}
            description={`${formatNumber(calcSumWithinRange(block))} ${
              block.type
            }`}
            className="tw-text-left"
          />
          <EntityItem
            value={entityItem}
            displayIcon={false}
            flagPosition="left"
            className="tw-text-right"
          />
        </div>
        <div
          className={clsxm(
            "tw-flex tw-flex-col tw-gap-4 tw-overflow-hidden tw-transition-all tw-duration-500 tw-ease-in-out",
            {
              "tw-max-h-[1000px] tw-opacity-100": openBlockRow,
              "tw-max-h-0 tw-opacity-0": !openBlockRow,
            }
          )}
        >
          <div className="tw-flex tw-flex-col tw-gap-6 tw-py-4">
            <ShareBlockDetails
              displayOptions={display}
              shareTypeCondition={shareType?.condition}
              entity={entityRowToDisplay}
              certificateIssueDate={block.hasCertificateSince}
              blockVotes={blockVotes}
              blockCancelled={block.cancelled}
              creditor={creditor}
              trustees={trustees}
              block={block}
            />
            <button
              type="button"
              className="tw-text-left"
              onClick={(e) => e.stopPropagation()}
            >
              {display.entry && history && (
                <Entry
                  history={history}
                  getEventTypeFormatted={getEventTypeFormatted}
                />
              )}
              {display.history && history && (
                <HistorySection
                  title={i18n.t("shares.history")}
                  history={history}
                  entitiesMap={entitiesMap}
                  getEventTypeFormatted={getEventTypeFormatted}
                />
              )}
            </button>
          </div>
        </div>
      </button>

      <ListItem className="tw-relative tw-block tw-break-inside-avoid tw-p-0 max-sm:tw-hidden">
        <Disclosure>
          <div>
            <div
              className={clsxm(
                " tw-grid tw-grid-cols-3 tw-items-center tw-justify-between tw-gap-2 tw-px-4 tw-py-4 lg:tw-grid-cols-4",
                {
                  "print:tw-grid-cols-3": !open,
                }
              )}
            >
              <div
                className="tw-flex tw-flex-wrap tw-items-center tw-gap-4 print:tw-col-span-1"
                data-testid="share-block"
              >
                <div
                  className={clsxm(
                    "tw-flex tw-flex-wrap tw-items-center tw-gap-2",
                    {
                      "tw-line-through tw-decoration-secondary":
                        block.cancelled,
                    }
                  )}
                >
                  <Description
                    title={`${formatNumber(block.start)} - ${formatNumber(
                      block.end
                    )}`}
                    description={`${formatNumber(calcSumWithinRange(block))} ${
                      block.type
                    }`}
                  />
                </div>
              </div>
              {!!block.cancelled && (
                <div className="tw-leading-5 tw-text-secondary print:tw-col-span-1">{`(${i18n.t(
                  "label.cancelled"
                )} ${getFormattedDate(
                  getFormattedLedgerVersion(block.lastModified).date
                )})`}</div>
              )}
              {!block.cancelled && entityRowToDisplay ? (
                <div
                  className={clsxm("print:tw-col-span-2")}
                  data-testid="cancelled-share-block"
                >
                  <EntityItem value={entityItem} displayIcon={false} />
                </div>
              ) : (
                <div className="md:tw-hidden" />
              )}
              <div className="tw-flex tw-items-center tw-justify-between tw-gap-2 print:tw-hidden lg:tw-col-span-2">
                <div className={clsxm("tw-flex tw-flex-1 tw-gap-2")}>
                  {creditor && (
                    <Tooltip
                      content={
                        <span className="tw-whitespace-nowrap">
                          {i18n.t("label.creditor")}
                        </span>
                      }
                    >
                      <PledgedIcon />
                    </Tooltip>
                  )}
                  {block.hasCertificateSince && (
                    <Tooltip
                      content={
                        <span className="tw-whitespace-nowrap">
                          {i18n.t("shares.certificates")}
                        </span>
                      }
                    >
                      <CertificateIcon />
                    </Tooltip>
                  )}
                  {trustees.length > 0 && (
                    <Tooltip
                      content={
                        <span className="tw-whitespace-nowrap">
                          {i18n.t("label.trustees")}
                        </span>
                      }
                    >
                      <TrusteeIcon />
                    </Tooltip>
                  )}
                </div>
                <div>
                  <Disclosure.Button
                    open={open}
                    onClick={() => onOpenChange(!open)}
                  />
                </div>
              </div>
            </div>
          </div>
          <Disclosure.Panel static>
            {open && (
              <div className="tw-flex tw-flex-col tw-gap-6 tw-px-4 tw-pb-4">
                <ShareBlockDetails
                  displayOptions={display}
                  shareTypeCondition={shareType?.condition}
                  entity={entityRowToDisplay}
                  certificateIssueDate={block.hasCertificateSince}
                  blockVotes={blockVotes}
                  blockCancelled={block.cancelled}
                  creditor={creditor}
                  trustees={trustees}
                  block={block}
                />
                {display.entry && history && (
                  <Entry
                    history={history}
                    getEventTypeFormatted={getEventTypeFormatted}
                  />
                )}
                {display.history &&
                  history &&
                  history.previousHolders &&
                  history.previousHolders.length > 0 && (
                    <HistorySection
                      title={i18n.t("shares.history")}
                      history={history}
                      entitiesMap={entitiesMap}
                      getEventTypeFormatted={getEventTypeFormatted}
                    />
                  )}
              </div>
            )}
          </Disclosure.Panel>
        </Disclosure>
      </ListItem>
    </>
  );
};

export { defaultDisplay, DetailedBlockRow, EntityDetails, EntityRow, Section };
export type { DisplayProperties as TDisplayProperties };
