import { useMemo } from "react";

type UseFilterProps<T> = {
  list: T[];
  keyword?: string;
  fields: (keyof T)[];
};

function filterList<T>({ list, keyword, fields }: UseFilterProps<T>) {
  const hasKeyword = keyword && keyword.trim().length > 1;
  if (!hasKeyword) {
    return list;
  }
  const formatedKeyword = keyword.toLowerCase();
  return list.filter((item) => {
    return fields.some((field) => {
      return item[field]?.toString().toLowerCase().includes(formatedKeyword);
    });
  });
}

function useFilteredList<T>({ list, keyword, fields }: UseFilterProps<T>) {
  const originalList = useMemo(() => list ?? [], [list]);
  const filteredList = useMemo(() => {
    return filterList({ list: originalList, keyword, fields });
  }, [keyword, originalList, fields]);

  return filteredList;
}

type UseSortedListProps<T> = {
  list: T[];
  sortFn: (a: T, b: T) => number;
};

function useSortedList<T>({ list, sortFn }: UseSortedListProps<T>) {
  const originalList = useMemo(() => list ?? [], [list]);

  const sortedList = useMemo(() => {
    const newSortedList = [...originalList];
    return newSortedList.sort(sortFn);
  }, [originalList, sortFn]);

  return sortedList;
}

export { filterList, useFilteredList, useSortedList };
export type { UseFilterProps, UseSortedListProps };
