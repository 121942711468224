import { Trans, useTranslation } from "react-i18next";

import type { EntitiesMap } from "../../../../types/models/entities";
import type { ShareReclassificationByRangeEvent } from "../../../../types/models/events";
import { formatNumber } from "../../../../utils/format";
import { calcSumWithinRange } from "../../../../utils/shares";
import { Description } from "../../../design-system/Description";
import { ReclassificationIcon } from "../../../design-system/icons";
import { ListItem } from "../../../design-system/List";
import AuditLine from "./AuditLine";

export const ShareReclassificationByRangeAuditLine = (props: {
  event: ShareReclassificationByRangeEvent;
  label: string;
  entitiesMap: EntitiesMap;
  open: boolean;
  onOpenChange: (value: boolean) => void;
}) => {
  const i18n = useTranslation();
  const sharesCount = props.event.shares.ranges.reduce(
    (prev, curr) => prev + calcSumWithinRange(curr),
    0
  );
  const createdBy = props.entitiesMap[props.event.user.id];

  return (
    <AuditLine
      title={i18n.t("events.reclassification.title")}
      icon={<ReclassificationIcon />}
      description={
        <Trans
          i18nKey="events.reclassification.range.content"
          values={{
            shares: formatNumber(sharesCount),
            toClass: props.event.shares.toClass,
          }}
        />
      }
      panelContent={
        <div className="tw-flex tw-w-full tw-items-center tw-gap-6 tw-px-6 md:tw-flex md:tw-w-full">
          <div className="tw-flex tw-items-center">
            <Description
              title={i18n.t("auditLog.createdBy")}
              description={createdBy!.name}
              theme="grayBlack"
            />
          </div>
          <div className="tw-flex tw-items-center">
            <Description
              title={i18n.t("auditLog.events.reclassification")}
              description={props.event.shares.ranges.map((range) => (
                <ListItem key={range.start} className="tw-text-sm">
                  <Trans
                    i18nKey="events.reclassification.range.child.content"
                    values={{
                      shares: formatNumber(calcSumWithinRange(range)),
                      start: formatNumber(range.start),
                      end: formatNumber(range.end),
                      fromClass: range.type,
                      toClass: props.event.shares.toClass,
                    }}
                  />
                </ListItem>
              ))}
              theme="grayBlack"
            />
          </div>
        </div>
      }
      {...props}
    />
  );
};
