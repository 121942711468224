import { useTranslation } from "react-i18next";
import type { NoticeProps } from "react-select";
import { components } from "react-select";

const NoOptionsMessage = (props: NoticeProps) => {
  const { t } = useTranslation();

  return (
    <components.NoOptionsMessage {...props}>
      {t("label.noOptions")}
    </components.NoOptionsMessage>
  );
};

export { NoOptionsMessage };
