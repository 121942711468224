import { clsxm } from "../../../utils/tailwind";

type ToggleProps = React.HTMLAttributes<HTMLSpanElement> & {
  label: string;
  className?: string;
  isActive?: boolean;
  onClick?: () => void;
};

const Toggle = ({
  label,
  className,
  isActive = false,
  onClick = () => {},
}: ToggleProps) => (
  <button
    onClick={onClick}
    type="button"
    className={clsxm("tw-flex tw-items-center tw-gap-2", className)}
  >
    <div
      className={clsxm(
        "tw-flex tw-h-6 tw-w-10 tw-min-w-[2.5rem] tw-cursor-pointer tw-items-center tw-rounded-full tw-p-0.5",
        {
          "tw-bg-green-500": isActive,
          "tw-bg-gray-300": !isActive,
        }
      )}
    >
      <input
        type="checkbox"
        className="tw-peer tw-sr-only"
        checked={isActive}
        readOnly
      />
      <div
        className={clsxm(
          "tw-h-5 tw-w-5 tw-transform tw-rounded-full tw-bg-white tw-shadow-md tw-transition-transform tw-duration-200",
          {
            "tw-translate-x-4": isActive,
          }
        )}
      />
    </div>
    <span className="tw-align-baseline tw-text-sm">{label}</span>
  </button>
);

export { Toggle };
export type { ToggleProps };
