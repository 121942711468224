const getIsIOS = () => {
  const { userAgent } = window.navigator;
  const isIOSDevice = /iPad|iPhone|iPod/.test(userAgent);
  const isTouchDevice =
    "ontouchstart" in window || navigator.maxTouchPoints > 0;

  return isIOSDevice || (isTouchDevice && userAgent.includes("Mac"));
};

export default getIsIOS;
