import { QuestionIcon } from "../design-system/icons";
import { Tooltip } from "../design-system/Tooltip";

type InfoTextProps = { content: string; children?: React.ReactNode };

const InfoText = ({ content, children }: InfoTextProps) => (
  <div className="tw-flex tw-items-center tw-gap-1">
    {children}
    <Tooltip content={<span className="tw-whitespace-nowrap">{content}</span>}>
      <QuestionIcon className="tw-h-4 tw-w-4" />
    </Tooltip>
  </div>
);

export type { InfoTextProps };
export { InfoText };
export default InfoText;
