import i18n from "i18next";
import ICU from "i18next-icu";
import { initReactI18next } from "react-i18next";

import en from "./dictionary/en.json";
import sv from "./dictionary/sv.json";
import type { Locale } from "./locale";
import {
  DEFAULT_LOCALE,
  getLocale as getLocaleFromStorage,
  setLocale as setLocaleInStorage,
} from "./locale";

const defaultNS = "translation";

const resources = {
  "en-US": { [defaultNS]: en },
  "sv-SE": { [defaultNS]: sv },
} as const;

const enableDebugging = import.meta.env.MODE === "development";

i18n.use(ICU).use(initReactI18next).init({
  resources,
  lng: getLocaleFromStorage(),
  fallbackLng: DEFAULT_LOCALE,
  debug: enableDebugging,
});

const getLocale = (): Locale => i18n.language as Locale;

const setLocale = (locale: Locale) => {
  setLocaleInStorage(locale);
  i18n.changeLanguage(locale);
};

type Ti18nKey =
  keyof (typeof resources)[typeof DEFAULT_LOCALE][typeof defaultNS];

export { type Locale, locales } from "./locale";
export { default as i18n } from "i18next";
export type { Ti18nKey };
export { defaultNS, getLocale, resources, setLocale };
