import { FilePdf } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";

import { getLocale } from "../../../i18n";
import type { LedgerVersionDetails } from "../../../pages/CompanyShares/useLedgerVersions";
import type { CompanyInformation } from "../../../types/models/administration";
import type { CompanyInvolvement } from "../../../types/models/company";
import { EntitiesMap } from "../../../types/models/entities";
import {
  Shareblock,
  ShareBlockHistory,
  ShareTypesMap,
} from "../../../types/models/shares";
import { makeAndDownloadExcel } from "../../../utils/excel";
import { generateShareledgerData } from "../../../utils/excel-utils";
import { formatRefId } from "../../../utils/format";
import { ActionButtonGroup } from "../../ActionButtonGroup";
import { Description } from "../../design-system/Description";
import { ExcelIcon } from "../../design-system/icons";
import { Menu } from "../../design-system/Menu/Menu";
import { PageWrapper } from "../../PageWrapper";
import { SelectVersion } from "../../SelectVersion";
import { useRestrictiveConditionOptions } from "../../ShareTypes/SelectRestrictiveConditions";

type ShareLedgerHeaderProps = {
  currentCompany: CompanyInvolvement | CompanyInformation;
  ledgerVersions: LedgerVersionDetails[];
  selectedVersion?: LedgerVersionDetails;
  setSelectedVersion: (version?: LedgerVersionDetails) => void;
  sortedBlocks: Shareblock[];
  entitiesMap: EntitiesMap;
  shareTypesMap: ShareTypesMap;
  shareBlockHistory: ShareBlockHistory[];
  isDownloadLoading: boolean;
  downloadLedger: () => void;
};

const ShareLedgerHeader: React.FC<ShareLedgerHeaderProps> = ({
  currentCompany,
  ledgerVersions,
  selectedVersion,
  setSelectedVersion,
  sortedBlocks,
  entitiesMap,
  shareTypesMap,
  shareBlockHistory,
  isDownloadLoading,
  downloadLedger,
}) => {
  const i18n = useTranslation();
  const conditionOptions = useRestrictiveConditionOptions();

  return (
    <PageWrapper className="tw-flex tw-items-center tw-justify-between tw-p-0">
      <div className="max-md:tw-hidden">
        <Description
          title={
            <h3 className="tw-font-medium">
              {currentCompany.name} {i18n.t("label.shareLedger")}
            </h3>
          }
          description={
            <p className="tw-text-sm tw-text-gray-500">
              {formatRefId({
                refId: currentCompany.orgNumber,
                countryCode: currentCompany.countryCode,
                type: "Company",
              })}
            </p>
          }
        />
      </div>
      <ActionButtonGroup className="tw-flex-row">
        <SelectVersion
          availableVersions={ledgerVersions}
          selectedVersion={selectedVersion}
          onChange={setSelectedVersion}
        />
        <div className="tw-ml-4 tw-flex tw-gap-2">
          <Menu>
            <Menu.Button
              type="button"
              className="tw-p-5s tw-flex tw-items-center tw-rounded-md max-sm:tw-w-full"
              isDropdown={false}
              isLoading={isDownloadLoading}
              disabled={!selectedVersion}
              onClick={() => downloadLedger()}
            >
              <FilePdf className="tw-h-6 tw-w-6" />
            </Menu.Button>
          </Menu>
          <Menu>
            <Menu.Button
              type="button"
              className="tw-p-5s tw-flex tw-items-center tw-rounded-md max-sm:tw-w-full"
              isDropdown={false}
              disabled={!selectedVersion}
              onClick={() => {
                const data = generateShareledgerData(
                  sortedBlocks,
                  entitiesMap,
                  shareTypesMap,
                  shareBlockHistory,
                  conditionOptions,
                  i18n
                );

                makeAndDownloadExcel(data, {
                  currentCompany,
                  title: i18n.t("label.shareLedger"),
                  version: selectedVersion!.version,
                  downloaded: i18n.t("label.downloaded", {
                    date: new Date().toLocaleDateString(getLocale()),
                  }),
                });
              }}
            >
              <ExcelIcon className="tw-h-6 tw-w-6" />
            </Menu.Button>
          </Menu>
        </div>
      </ActionButtonGroup>
    </PageWrapper>
  );
};

export { ShareLedgerHeader };
