import type { CompanyOverview } from "@capchapdev/rell-api";
import { z } from "zod";

import type { CompanyInformation } from "../types/models/administration";
import type { LoggedInUser } from "../types/models/auth";
import type { RoleSchema } from "../types/models/users";

/** The permissions are ordered from least to most privileged */
const PermissionSchema = z.enum([
  "Viewer",
  "Editor",
  "Administrator",
  "BoardMember",
]);
type Permission = z.infer<typeof PermissionSchema>;

/**
 * Creates a new schema that requires the role to have a minimum permission
 * level to pass.
 *
 * @param minPermission The minimum permission required to pass the schema
 * @returns A new schema that requires the role to have a minimum permission
 */
const createPermissionSchema = (minPermission: Permission) =>
  PermissionSchema.refine(
    (value: Permission) =>
      PermissionSchema.options.indexOf(value) >=
      PermissionSchema.options.indexOf(minPermission),
    {
      message: `User must have ${minPermission} or higher privileges`,
    }
  );

const hasRequiredPermission = (
  levelRequired: Permission,
  company: CompanyInformation | CompanyOverview,
  user?: LoggedInUser
): boolean => {
  const isAdmin = user?.role === "Administrator";
  const userRole = (company as CompanyOverview).access as z.infer<
    typeof RoleSchema
  >;

  const supportRole = isAdmin
    ? (company as CompanyInformation).supportRole
    : null;

  const PermissionSchemaByLevel = createPermissionSchema(levelRequired);
  const hasPermission =
    PermissionSchemaByLevel.safeParse(userRole).success ||
    (supportRole && PermissionSchemaByLevel.safeParse(supportRole).success);

  return !!hasPermission;
};

export { hasRequiredPermission, PermissionSchema };
export type { Permission };
