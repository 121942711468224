import { useCallback, useEffect, useState } from "react";

import { useEntitiesQuery } from "../../../api/rest/entities";
import { useSession } from "../../../context/session";
import type { LoggedInUser } from "../../../types/models/auth";
import type { Entity } from "../../../types/models/entities";
import { userActions } from "./userActions";
import type {
  FillContactInfoUserAction,
  RequiredUserActions,
  VerifyEmailAddressUserAction,
} from "./userActions/RequiredUserActions";

const isEntityMissingEmail = (entity: Entity) => {
  if (!entity.contact.email || entity.contact.email === "") {
    return true;
  }
  return false;
};

const isEntityMissingPhone = (entity: Entity) => {
  if (!entity.contact.phone || entity.contact.phone === "") {
    return true;
  }
  return false;
};

const isEntityMissingAddress = (entity: Entity) => {
  const { line1, postalCode, city } = entity.contact?.address ?? {};
  const missingStreet = !line1 || line1 === "";
  const missingPostalCode = !postalCode || postalCode === "";
  const missingCity = !city || city === "";

  if (missingCity || missingPostalCode || missingStreet) {
    return true;
  }
  return false;
};

const getEntityForUser = (user?: LoggedInUser, entities?: Entity[]) => {
  if (!user || !entities) {
    return null;
  }
  return entities.find(
    (entity) => entity.id === user.id || entity.refId === user.refId
  );
};

function getRequiredUserActionForMissingInfo(
  entity: Entity,
  orgNumber: string
): FillContactInfoUserAction | null {
  const skippedContactDetails = JSON.parse(
    window.sessionStorage.getItem("skippedContactDetails") ?? "{}"
  );
  if (skippedContactDetails[orgNumber]) {
    return null;
  }

  const missingEmail = isEntityMissingEmail(entity);
  const missingAddress = isEntityMissingAddress(entity);
  const missingPhone = isEntityMissingPhone(entity);
  const missingInfo: FillContactInfoUserAction["missingInfo"] = {};

  if (missingAddress || missingEmail) {
    if (missingEmail) {
      missingInfo.email = true;
    }
    if (missingAddress) {
      missingInfo.address = true;
    }
    if (missingPhone) {
      missingInfo.phone = true;
    }
  }

  if (Object.values(missingInfo).filter(Boolean).length === 0) {
    return null;
  }

  return {
    type: userActions.FILL_CONTACT_INFO,
    payload: entity,
    missingInfo,
  };
}

function getRequiredUserActionMailVerification(
  entity: Entity,
  orgNumber: string
): VerifyEmailAddressUserAction | null {
  const skippedMailVerifications = JSON.parse(
    window.sessionStorage.getItem("skippedMailVerification") ?? "{}"
  );
  if (skippedMailVerifications[orgNumber]) {
    return null;
  }

  if (
    (entity.contact.email?.length ?? 0) > 0 &&
    entity.emailVerification?.status === "NOT_VERIFIED"
  ) {
    return { type: userActions.VERIFY_EMAIL };
  }
  return null;
}

const useUserActionRequired = (
  orgNumber?: string
): [RequiredUserActions | undefined, () => void] => {
  const entitiesQuery = useEntitiesQuery(orgNumber);
  const { user } = useSession();
  const [requiredUserAction, setRequiredUserAction] =
    useState<RequiredUserActions>();
  const refresh = useCallback(() => {
    const userEntity = getEntityForUser(user, entitiesQuery.data);
    if (!userEntity || !orgNumber) {
      setRequiredUserAction(undefined);
      return;
    }

    const requiredUserActionForMissingInfo =
      getRequiredUserActionForMissingInfo(userEntity, orgNumber);
    const requiredUserActionForMailValidation =
      getRequiredUserActionMailVerification(userEntity, orgNumber);

    if (requiredUserActionForMissingInfo) {
      setRequiredUserAction(requiredUserActionForMissingInfo);
    } else if (requiredUserActionForMailValidation) {
      setRequiredUserAction(requiredUserActionForMailValidation);
    } else {
      setRequiredUserAction(undefined);
    }
  }, [entitiesQuery.data, orgNumber, user]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  if (entitiesQuery.isError) {
    console.error("Couldn't load entities", entitiesQuery.error);
    // return null;
  }

  return [requiredUserAction, refresh];
};

const unitTests = {
  isEntityMissingEmail,
  getEntityForUser,
};

export { unitTests, useUserActionRequired };
