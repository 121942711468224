import { Menu as TailwindMenu, Transition } from "@headlessui/react";
import type React from "react";
import { Fragment } from "react";

import type { ExtractProps } from "../../../types";
import { clsxm } from "../../../utils/tailwind";
import { Button } from "../Button";
import { ChevronDownIcon } from "../icons";

const Menu = (props: ExtractProps<typeof TailwindMenu>) => (
  <TailwindMenu
    as="div"
    className="tw-relative tw-inline-block tw-text-left"
    {...props}
  />
);

type MenuButtonProps = ExtractProps<typeof Button> & {
  isDropdown?: boolean;
  icon?: React.ReactElement;
};

Menu.Button = ({
  children,
  isDropdown = true,
  icon,
  ...props
}: MenuButtonProps) => {
  const suffixProps = isDropdown
    ? { suffix: icon || <ChevronDownIcon className="tw-ml-2" /> }
    : {};

  return (
    <TailwindMenu.Button as={Button} {...suffixProps} {...props}>
      {children}
    </TailwindMenu.Button>
  );
};

Menu.ThreeDotsButton = ({
  children,
  ...props
}: ExtractProps<typeof Button>) => (
  <TailwindMenu.Button as={Button} {...props}>
    {children}
  </TailwindMenu.Button>
);

type Align = "bottomLeft" | "bottomRight" | "topLeft" | "topRight";

Menu.Items = ({
  className,
  align,
  ...props
}: {
  className?: string;
  align?: Align;
  children: React.ReactNode;
  static?: boolean;
}) => (
  <Transition
    as={Fragment}
    enter="tw-transition tw-ease-out tw-duration-100"
    enterFrom="tw-transform tw-opacity-0 tw-scale-95"
    enterTo="tw-transform tw-opacity-100 tw-scale-100"
    leave="tw-transition tw-ease-in tw-duration-75"
    leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
    leaveTo="tw-transform tw-opacity-0 tw-scale-95"
  >
    <TailwindMenu.Items
      className={clsxm(
        "tw-absolute tw-z-10 tw-w-72 tw-rounded-md tw-border tw-border-gray-200 tw-bg-white tw-shadow-lg tw-outline-none",
        {
          "tw-mt-2": !align,
          "tw-left-0 tw-mt-2 tw-origin-top-left": align === "bottomLeft",
          "tw-right-0 tw-mt-2 tw-origin-top-right": align === "bottomRight",
          "tw-bottom-full tw-left-0 tw-mb-2 tw-origin-bottom-left":
            align === "topLeft",
          "tw-bottom-full tw-right-0 tw-mb-2 tw-origin-bottom-right":
            align === "topRight",
        },
        className
      )}
      {...props}
    />
  </Transition>
);

Menu.Item = ({
  className,
  onClick,
  closeOnClick = true,
  ...props
}: ExtractProps<typeof TailwindMenu.Item>) => (
  <TailwindMenu.Item
    as="button"
    onClick={(e: React.MouseEvent) => {
      if (onClick) {
        onClick(e);
      }
      if (!closeOnClick) {
        e.preventDefault();
        e.stopPropagation();
      }
    }}
    className={clsxm(
      "tw-flex tw-w-full tw-cursor-pointer tw-px-4 tw-py-2 tw-text-left tw-text-body ui-active:tw-bg-gray-100",
      className
    )}
    {...props}
  />
);

export { Menu };
export type { Align };
