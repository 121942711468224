import { ArrowRight } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { InfoIcon } from "../components/design-system/icons";
import { Tab } from "../components/design-system/Tab";
import { PageWrapper } from "../components/PageWrapper";
import { useSession } from "../context/session";
import { Ledgers } from "./ledgers";
import { Ownership } from "./ownership";

type HomePageCardProps = {
  title: string;
  description: string;
  footer: JSX.Element;
};

const HomePageCard = ({ title, description, footer }: HomePageCardProps) => (
  <div className="tw-rounded-md tw-border tw-p-4 tw-text-left hover:tw-bg-neutral-50">
    <h5 className="tw-text-base tw-font-medium">{title}</h5>
    <h5 className="tw-text-sm tw-text-secondary">{description}</h5>
    <div className="tw-mt-6 tw-flex tw-justify-between">
      {footer}
      <ArrowRight />
    </div>
  </div>
);

const ContactBanner = ({
  i18nKey,
}: {
  i18nKey: "home.ledgerCard.help" | "home.assetsCard.help";
}) => {
  return (
    <div className="tw-mt-4 tw-flex tw-gap-4 tw-rounded-md tw-border tw-border-neutral-200 tw-p-6 tw-text-sm tw-text-secondary">
      <InfoIcon width="60" />
      <p>
        <Trans
          components={{
            contactLink: (
              <Link target="_blank" to="https://kvanta.com/contact" />
            ),
          }}
          i18nKey={i18nKey}
        />
      </p>
    </div>
  );
};

const HomePage = () => {
  const { user } = useSession();
  const i18n = useTranslation();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  useEffect(() => {
    setSelectedTabIndex(window.location.hash === "#asset" ? 1 : 0);
  }, [window.location.hash]);

  return (
    <PageWrapper className="tw-max-w-3xl" data-testid="home">
      <div className="tw-space-y-6">
        <h2 className="tw-font-medium">
          {i18n.t("home.title", { name: user?.name.split(" ")[0] })}
        </h2>
        <Tab.Group selectedIndex={selectedTabIndex}>
          <Tab.List>
            <Tab
              onClick={() => {
                window.location.hash = "#ledger";
                setSelectedTabIndex(0);
              }}
            >
              {i18n.t("home.ledgerCard.title")}
            </Tab>
            <Tab
              onClick={() => {
                window.location.hash = "#asset";
                setSelectedTabIndex(1);
              }}
            >
              {i18n.t("home.assetsCard.title")}
            </Tab>
          </Tab.List>
          <Tab.Panels className="tw-mt-4">
            <Tab.Panel>
              <Ledgers />
              <ContactBanner i18nKey="home.ledgerCard.help" />
            </Tab.Panel>
            <Tab.Panel>
              <Ownership />
              <ContactBanner i18nKey="home.assetsCard.help" />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </PageWrapper>
  );
};

export { HomePage, HomePageCard };
