import { useTranslation } from "react-i18next";

import type { IRequestError } from "../../../api";
import type { NewEntity } from "../../../types/models/entities";
import { Button } from "../../design-system/Button";
import { Dialog } from "../../design-system/Dialog";
import {
  CreateEntityForm,
  EntityTypeOptions,
} from "../CreateEntityForm/CreateEntityForm";

type EditEntityDialogProps = {
  title: string;
  onClose: () => void;
  onSave: (newEntity: NewEntity) => void;
  defaultValues: NewEntity;
  formId: string;
  error?: IRequestError | null;
  isLoading?: boolean;
  enabledEntityTypes?: EntityTypeOptions[];
  enableCountryChange?: boolean;
};

const EditEntityDialog: React.FunctionComponent<EditEntityDialogProps> = ({
  title,
  formId,
  onClose,
  defaultValues,
  onSave,
  error,
  isLoading,
  enabledEntityTypes = ["Company", "PrivateWithSSN", "PrivateWithPassport"],
  enableCountryChange = true,
}) => {
  const i18n = useTranslation();

  return (
    <Dialog
      isOpen
      actions={
        <>
          <Button disabled={isLoading} onClick={onClose}>
            {i18n.t("label.cancel")}
          </Button>
          <Button
            color="primary"
            form={formId}
            isLoading={isLoading}
            type="submit"
            variant="solid"
          >
            {i18n.t("label.save")}
          </Button>
        </>
      }
      isLoading={isLoading}
      size="lg"
      title={title}
      onClose={onClose}
    >
      <CreateEntityForm
        error={error}
        onSave={onSave}
        defaultValues={defaultValues}
        formId={formId}
        enableCountryChange={enableCountryChange}
        enabledEntityTypes={enabledEntityTypes}
      />
    </Dialog>
  );
};

export { EditEntityDialog };
