import type { ParentEventType } from "../../types/models/events";
import type { LedgerVersion } from "../../types/models/shares";
import { isGreaterLedgerVersion } from "../../utils/date";

const eventsToIgnore = [
  "LedgerApproval",
  "LedgerApprovalInitialized",
  "LedgerRollback",
  "LedgerRollbackRejected",
] as const;
const cleanEvents = (e: { type: ParentEventType }) =>
  !eventsToIgnore.some((type) => type === e.type);

type Event = { type: ParentEventType; date: LedgerVersion };

const getApprovalEvents = <T extends Event>(events: T[]) =>
  events.filter((e) => e.type === "LedgerApproval");

const getInitializedApprovalEvents = <T extends Event>(events: T[]) =>
  events.filter((e) => e.type === "LedgerApprovalInitialized");

const getLatestEventFromEvents = <T extends Event>(events: T[]): T | null =>
  events.reduce<T | null>((latestEvent, currentEvent) => {
    if (!latestEvent) {
      return currentEvent;
    }
    if (!currentEvent.date) {
      return latestEvent;
    }

    if (isGreaterLedgerVersion(currentEvent.date, latestEvent.date)) {
      return currentEvent;
    }

    return latestEvent;
  }, null);

const splitEvents = <T extends Event>(
  events: T[]
): {
  approvedEvents: T[];
  pendingEvents: T[];
  draftEvents: T[];
} => {
  const approvalEvents = getApprovalEvents(events);
  const latestApprovalEvent = getLatestEventFromEvents(approvalEvents);

  const initializedApprovalEvents = getInitializedApprovalEvents(events);
  const latestVersionWithInitialization = getLatestEventFromEvents(
    initializedApprovalEvents
  );

  if (approvalEvents.length === 0 && initializedApprovalEvents.length === 0) {
    // No approval events and no pending events
    return {
      approvedEvents: [],
      pendingEvents: [],
      draftEvents: events.filter(cleanEvents),
    };
  }

  if (approvalEvents.length === 0 && initializedApprovalEvents.length > 0) {
    const [pendingEvents, draftEvents] = events.reduce<[T[], T[]]>(
      ([pending, drafts], event) => {
        if (
          isGreaterLedgerVersion(
            event.date,
            latestVersionWithInitialization?.date
          )
        ) {
          return [pending, [...drafts, event]];
        }

        return [[...pending, event], drafts];
      },
      [[], []]
    );

    // No approval events, but there are initialisation events
    return {
      approvedEvents: [],
      pendingEvents: pendingEvents.filter(cleanEvents),
      draftEvents: draftEvents.filter(cleanEvents),
    };
  }

  if (latestApprovalEvent?.date === latestVersionWithInitialization?.date) {
    // No pending events
    const [approvedEvents, draftEvents] = events.reduce<[T[], T[]]>(
      ([approved, drafts], event) => {
        if (
          isGreaterLedgerVersion(
            event.date,
            latestVersionWithInitialization?.date
          )
        ) {
          return [approved, [...drafts, event]];
        }

        return [[...approved, event], drafts];
      },
      [[], []]
    );

    return {
      approvedEvents: approvedEvents.filter(cleanEvents),
      pendingEvents: [],
      draftEvents: draftEvents.filter(cleanEvents),
    };
  }

  // There are approval events and initialization events
  // but the latest approval event is not the latest initialization event
  const lastApprovedVersion = latestApprovalEvent?.date;

  if (lastApprovedVersion === undefined) {
    return { approvedEvents: [], pendingEvents: [], draftEvents: [] };
  }

  const lastInitializationVersion = latestVersionWithInitialization?.date;

  const [approvedEvents, draftEvents, pendingEvents] = events.reduce<
    [T[], T[], T[]]
  >(
    ([approved, drafts, pending], event) => {
      const eventIsApproved =
        lastApprovedVersion === event.date ||
        isGreaterLedgerVersion(lastApprovedVersion, event.date);

      const eventIsDraft =
        isGreaterLedgerVersion(event.date, lastApprovedVersion) &&
        isGreaterLedgerVersion(event.date, lastInitializationVersion);

      if (eventIsApproved) {
        return [[...approved, event], drafts, pending];
      }

      if (eventIsDraft) {
        return [approved, [...drafts, event], pending];
      }

      return [approved, drafts, [...pending, event]];
    },
    [[], [], []]
  );

  return {
    approvedEvents: approvedEvents.filter(cleanEvents),
    pendingEvents: pendingEvents.filter(cleanEvents),
    draftEvents: draftEvents.filter(cleanEvents),
  };
};

export { splitEvents };
