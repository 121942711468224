import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import type { useRegisterSwedishCompanyMutation } from "../../../api/rest/administration";
import { Button } from "../../../components/design-system/Button";
import { Dialog } from "../../../components/design-system/Dialog";
import {
  FormError,
  FormErrorList,
  FormGroup,
  FormLabel,
} from "../../../components/design-system/FormGroup";
import { Input } from "../../../components/design-system/Input";
import type { NewCompany } from "../../../types/models/administration";

const formId = "edit-company-form";

type EditCompanyDialogProps = {
  title: string;
  onClose: () => void;
  mutation: ReturnType<typeof useRegisterSwedishCompanyMutation>;
  defaultValues: NewCompany;
};

const EditCompanyDialog = ({
  title,
  onClose,
  mutation,
  defaultValues,
}: EditCompanyDialogProps) => {
  const i18n = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<NewCompany>({ mode: "onChange", defaultValues });
  const { isLoading } = mutation;

  return (
    <Dialog
      isOpen
      title={title}
      onClose={() => {
        mutation.reset();
        onClose();
      }}
      isLoading={isLoading}
      actions={
        <>
          <Button
            onClick={() => {
              mutation.reset();
              onClose();
            }}
            disabled={isLoading}
          >
            {i18n.t("label.cancel")}
          </Button>
          <Button
            type="submit"
            variant="solid"
            color="primary"
            isLoading={isLoading}
            form={formId}
          >
            {i18n.t("label.save")}
          </Button>
        </>
      }
    >
      <form
        className="tw-space-y-4"
        onSubmit={(event) => {
          event.stopPropagation();

          return handleSubmit((data) =>
            mutation.mutate({ orgNumber: data.orgNumber.trim() })
          )(event);
        }}
        id={formId}
      >
        <FormGroup>
          <FormLabel htmlFor="orgNumber">
            {i18n.t("label.organizationId")}
          </FormLabel>
          <Input
            id="orgNumber"
            {...register("orgNumber", {
              validate: (value) => {
                if (!value.trim()) {
                  return i18n.t("error.validation.required");
                }

                return true;
              },
            })}
          />
          <FormError>{errors.orgNumber?.message}</FormError>
        </FormGroup>
        {mutation.error && <FormErrorList error={mutation.error} />}
      </form>
    </Dialog>
  );
};

export default EditCompanyDialog;
