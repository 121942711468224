import type {
  RawTxResponse as OpenApiRawTxResponse,
  SaveRoleResponse as OpenApiSaveRoleResponse,
} from "@capchapdev/admin-api";
import type { UseMutationOptions } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import { z } from "zod";

import type { NewMultiUsersRole, NewUserRole } from "../../types/models/users";
import * as monitoring from "../../utils/monitoring";
import type { IRequestError } from "..";
import { sendTransaction } from "../blockchain/transaction";
import useClient, { URL } from "./client";

const RawTxResponseSchema = z.object({
  rawTx: z.string(),
});

type RawTxResponse = z.infer<typeof RawTxResponseSchema>;

const UserDtoSchema = z.object({
  id: z.string().uuid(),
  refId: z.string(),
  countryCode: z.string(),
});

const SavedRoleDtoSchema = z.object({
  name: z.string(),
  user: UserDtoSchema,
});

const SaveRoleResponseSchema = z.object({
  rawTx: z.string(),
  role: SavedRoleDtoSchema,
});

type SaveRoleResponse = z.infer<typeof SaveRoleResponseSchema>;

const useAddUserMutation = (
  orgNumber: string,
  options?: UseMutationOptions<RawTxResponse, IRequestError, NewUserRole>
) => {
  const client = useClient({ hasAuth: true });

  return useMutation<SaveRoleResponse, IRequestError, NewUserRole>(
    async (data) => {
      const response = await client<OpenApiSaveRoleResponse>(
        `${URL.ADMIN}/Transaction/Role/${orgNumber}`,
        { body: data }
      );

      const result = SaveRoleResponseSchema.safeParse(response);

      if (!result.success) {
        monitoring.captureException(result.error, {
          contexts: { response, result },
        });

        return sendTransaction(response.rawTx);
      }

      return sendTransaction(result.data.rawTx);
    },
    options
  );
};

const useUpdateUsersMutation = (
  orgNumber: string,
  options?: UseMutationOptions<RawTxResponse, IRequestError, NewMultiUsersRole>
) => {
  const client = useClient({ hasAuth: true });

  return useMutation<RawTxResponse, IRequestError, NewMultiUsersRole>(
    async (data) => {
      const response = await client<OpenApiRawTxResponse>(
        `${URL.ADMIN}/Transaction/Roles/${orgNumber}`,
        { body: data, method: "PUT" }
      );

      const result = RawTxResponseSchema.safeParse(response);

      if (!result.success) {
        monitoring.captureException(result.error, {
          contexts: { response, result },
        });
        return sendTransaction(response.rawTx);
      }

      return sendTransaction(result.data.rawTx);
    },
    options
  );
};

const useDeleteUserMutation = (
  orgNumber: string,
  id: string,
  options?: UseMutationOptions<RawTxResponse, IRequestError>
) => {
  const client = useClient({ hasAuth: true });

  return useMutation<RawTxResponse, IRequestError>(async () => {
    const response = await client<OpenApiRawTxResponse>(
      `${URL.ADMIN}/Transaction/Role/${orgNumber}/${id}`,
      { method: "DELETE" }
    );

    const result = RawTxResponseSchema.safeParse(response);

    if (!result.success) {
      monitoring.captureException(result.error, {
        contexts: { response, result },
      });

      return sendTransaction(response.rawTx);
    }

    return sendTransaction(result.data.rawTx);
  }, options);
};

export type { RawTxResponse, SaveRoleResponse };
export {
  RawTxResponseSchema,
  SaveRoleResponseSchema,
  useAddUserMutation,
  useDeleteUserMutation,
  useUpdateUsersMutation,
};
