import { ReactElement, ReactNode } from "react";
import { useMediaQuery } from "react-responsive";

import { TParentEvent } from "../../../../types/models/events";
import { LedgerVersion } from "../../../../types/models/shares";
import {
  getFormattedDate,
  getFormattedLedgerVersion,
} from "../../../../utils/date";
import { clsxm } from "../../../../utils/tailwind";
import { Disclosure } from "../../../design-system/Disclosure";
import { UList } from "../../../design-system/List";
import { IconDescription } from "../../../IconDescription";

const EventWrapper = ({
  children,
  toggleOpen,
}: {
  children: React.ReactNode;
  toggleOpen: () => void;
}) => {
  const isMobileDevice = useMediaQuery({
    query: "(max-width: 640px)",
  });
  const isDesktopOrTabletDevice = useMediaQuery({
    query: "(min-width: 641px)",
  });

  return (
    <div>
      {isMobileDevice && (
        <button
          type="button"
          id="mobile"
          className="tw-relative tw-my-1 tw-w-full tw-rounded tw-text-left "
          style={
            isMobileDevice
              ? {
                  boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.08)",
                }
              : {}
          }
          onClick={toggleOpen}
        >
          {children}
        </button>
      )}
      {isDesktopOrTabletDevice && <>{children}</>}
    </div>
  );
};

const DisclosurePanelWrapper = ({
  children,
  open,
}: {
  children: React.ReactNode;
  open: boolean;
}) => {
  return (
    <div
      className={clsxm(
        "tw-flex tw-flex-col tw-gap-4 tw-overflow-hidden tw-px-4 tw-ease-in-out max-sm:tw-transition-all max-sm:tw-duration-500",
        {
          "tw-max-h-[1000px] tw-opacity-100": open,
          "tw-max-h-0 tw-opacity-0": !open,
        }
      )}
    >
      <div className="tw-flex tw-flex-col tw-gap-4 tw-pb-4">
        <UList>{children}</UList>
      </div>
    </div>
  );
};

const Wrapper = ({ children }: { children: React.ReactNode }) => (
  <div className="tw-flex tw-gap-6 tw-px-4 tw-py-2 max-sm:tw-flex-row-reverse max-sm:tw-py-4 md:tw-flex-row md:tw-items-center md:tw-gap-0">
    {children}
  </div>
);

const Version = ({ ledgerVersion }: { ledgerVersion?: LedgerVersion }) => {
  const formatedLedgerVersion = getFormattedLedgerVersion(ledgerVersion);
  const date = getFormattedDate(formatedLedgerVersion.date);

  return (
    <div className="tw-flex tw-flex-wrap tw-items-center tw-gap-1 tw-text-sm tw-text-secondary hover:tw-text-primary hover:tw-no-underline md:tw-block md:tw-min-w-[10rem] md:tw-gap-0">
      {date}
    </div>
  );
};

const AuditLine = ({
  title,
  icon,
  description,
  panelContent,
  event,
  open,
  onOpenChange,
}: {
  title: string;
  icon: ReactNode;
  description: ReactElement | string;
  panelContent?: ReactElement | ReactElement[];
  event: TParentEvent;
  open: boolean;
  onOpenChange: (value: boolean) => void;
}) => {
  return (
    <Disclosure>
      <EventWrapper toggleOpen={() => onOpenChange(!open)}>
        <Wrapper>
          <Version ledgerVersion={event.date} />
          <div className="tw-flex tw-flex-1 tw-items-center tw-justify-between">
            <IconDescription
              className="max-sm:tw-gap-2"
              description={
                <div className="max-sm:tw-hidden">{description}</div>
              }
              icon={icon}
              title={title}
              titleWeight="medium"
            />
            {panelContent && (
              <div className="tw-flex tw-justify-end max-sm:tw-hidden">
                <div className="tw-flex tw-items-center">
                  <Disclosure className="max-sm:tw-hidden">
                    <Disclosure.Button
                      open={open}
                      onClick={() => {
                        onOpenChange(!open);
                      }}
                    />
                  </Disclosure>
                </div>
              </div>
            )}
          </div>
        </Wrapper>
        {panelContent && (
          <Disclosure.Panel static>
            <DisclosurePanelWrapper open={open}>
              {panelContent}
            </DisclosurePanelWrapper>
          </Disclosure.Panel>
        )}
      </EventWrapper>
    </Disclosure>
  );
};

export default AuditLine;
