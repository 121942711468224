import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Badge } from "../../../../components/design-system/Badge";
import { isSSN } from "../../../../components/Entities/CreateEntityForm/CreateEntityForm";
import { EditEntity } from "../../../../components/Entities/Edit";
import { EntityItem } from "../../../../components/EntityItem";
import { LabelValue } from "../../../../components/LabelValue";
import { NoData } from "../../../../components/NoData";
import { VerifiedEmail } from "../../../../components/VerifiedEmail";
import { useSession } from "../../../../context/session";
import type { CompanyInformation } from "../../../../types/models/administration";
import type { CompanyInvolvement } from "../../../../types/models/company";
import type { Entity } from "../../../../types/models/entities";
import { formatAddress } from "../../../../utils/format";
import { hasRequiredPermission } from "../../../../utils/permissions";
import { sortAlphabeticallyByValue } from "../../../../utils/sort";

type EntitiesListProps = {
  currentCompany: CompanyInvolvement | CompanyInformation;
  value: Entity[];
  onChange: () => void;
};

export const Roles: { [key: string]: string } = {
  BoardMember: "entity.represenatatives.role.boardMember",
  Ceo: "entity.represenatatives.role.ceo",
};

const EntitiesList = ({
  currentCompany,
  value = [],
  onChange,
}: EntitiesListProps) => {
  const i18n = useTranslation();
  const { user } = useSession();
  const sortedEntities = useMemo(
    () => sortAlphabeticallyByValue(value, (entity) => entity.name),
    [value]
  );

  if (sortedEntities.length === 0) {
    return <NoData />;
  }

  return (
    <>
      <div className="max-md:tw-hidden">
        <div className="tw-px-6 tw-pb-4">
          <table className="tw-w-full tw-table-fixed">
            {sortedEntities.map((entity) => {
              const {
                contact: { address, email },
                emailVerification,
                role,
              } = entity;
              const missingPassport =
                entity.type === "Private" &&
                !isSSN(entity.refId) &&
                !entity.passportNumber;

              return (
                <tr key={entity.id}>
                  <td className="tw-p-4">
                    <EntityItem
                      value={entity}
                      hasFlag={false}
                      customDescription={role ? i18n.t(Roles[role]) : undefined}
                    />
                  </td>
                  <td className="tw-p-4">
                    <LabelValue
                      label={i18n.t("label.address")}
                      value={address ? formatAddress(address) : ""}
                    />
                  </td>
                  <td className="tw-p-4">
                    <LabelValue
                      label={i18n.t("label.email")}
                      value={
                        email ? (
                          <a
                            href={`mailto:${email}`}
                            rel="noreferrer noopener"
                            target="_blank"
                          >
                            {email}
                            <VerifiedEmail
                              emailVerification={emailVerification}
                            />
                          </a>
                        ) : (
                          "-"
                        )
                      }
                    />
                  </td>
                  <td className="tw-p-4">
                    <div className="tw-flex tw-items-center tw-gap-4">
                      {hasRequiredPermission(
                        "Editor",
                        currentCompany,
                        user
                      ) && (
                        <EditEntity
                          currentCompany={currentCompany}
                          value={entity}
                          onSuccess={onChange}
                        />
                      )}
                      {missingPassport && (
                        <Badge color="error">
                          {i18n.t("label.passportNumber.missing")}
                        </Badge>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          </table>
        </div>
      </div>
      <div className="md:tw-hidden">
        {sortedEntities.map((entity) => {
          const {
            contact: { address, email },
            emailVerification,
            role,
          } = entity;
          const missingPassport =
            entity.type === "Private" &&
            !isSSN(entity.refId) &&
            !entity.passportNumber;

          return (
            <div key={entity.id} className="tw-m-2">
              <div
                className="tw-relative tw-flex tw-flex-col tw-gap-4 tw-rounded tw-p-4 tw-text-left"
                style={{
                  boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.08)",
                }}
              >
                <div className="tw-flex tw-items-center tw-justify-between">
                  <EntityItem
                    value={entity}
                    displayIcon={false}
                    customDescription={role ? i18n.t(Roles[role]) : undefined}
                  />
                  {hasRequiredPermission("Editor", currentCompany, user) && (
                    <EditEntity
                      currentCompany={currentCompany}
                      value={entity}
                      onSuccess={onChange}
                    />
                  )}
                </div>

                <LabelValue
                  label={i18n.t("label.address")}
                  value={address ? formatAddress(address) : ""}
                />
                <LabelValue
                  label={i18n.t("label.email")}
                  value={
                    email ? (
                      <a
                        href={`mailto:${email}`}
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                        {email}
                        <VerifiedEmail emailVerification={emailVerification} />
                      </a>
                    ) : (
                      "-"
                    )
                  }
                />
                {missingPassport && (
                  <div>
                    <Badge color="error">
                      {i18n.t("label.passportNumber.missing")}
                    </Badge>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default EntitiesList;
