import type React from "react";

import { clsxm } from "../../utils/tailwind";
import type { DescriptionProps } from "../design-system/Description";
import { Description } from "../design-system/Description";

type IconDescriptionProps = Omit<DescriptionProps, "title" | "description"> & {
  title: React.ReactNode;
  description?: React.ReactNode;
  icon: React.ReactNode;
  iconFirst?: boolean;
  className?: string;
};

const IconDescription: React.FC<IconDescriptionProps> = ({
  title,
  description,
  icon,
  iconFirst = true,
  className,
  theme,
  size,
  titleSize,
  descriptionSize,
  titleWeight,
  descriptionWeight,
  align,
}) => (
  <div className={clsxm("tw-flex tw-items-center tw-gap-4", className)}>
    {iconFirst ? (
      <>
        <div>{icon}</div>
        <Description
          title={title}
          description={description}
          theme={theme}
          size={size}
          titleSize={titleSize}
          descriptionSize={descriptionSize}
          titleWeight={titleWeight}
          descriptionWeight={descriptionWeight}
          align={align}
        />{" "}
      </>
    ) : (
      <>
        <Description
          title={title}
          description={description}
          theme={theme}
          size={size}
          titleSize={titleSize}
          descriptionSize={descriptionSize}
          titleWeight={titleWeight}
          descriptionWeight={descriptionWeight}
          align={align}
        />
        <div>{icon}</div>
      </>
    )}
  </div>
);

export { IconDescription };
