import { NavLink } from "react-router-dom";

import { clsxm } from "../../utils/tailwind";

const TabLinkItem = ({
  to,
  children,
  ...props
}: {
  to: string;
  children: React.ReactNode;
}) => (
  <li>
    <NavLink
      end
      className={({ isActive }) =>
        clsxm(
          "tw-block tw-rounded tw-border-transparent tw-font-medium tw-transition-colors tw-duration-300 hover:tw-text-primary hover:tw-no-underline",
          {
            "tw-border-b-primary tw-text-primary": isActive,
            "tw-text-gray-700": !isActive,
          }
        )
      }
      to={to}
      {...props}
    >
      {children}
    </NavLink>
  </li>
);

const TabLayout = ({
  links,
  children,
}: {
  className?: string;
  links: React.ReactNode;
  children?: React.ReactNode;
}) => (
  <div>
    <aside className="tw-border-b tw-border-solid tw-border-gray-200 print:tw-hidden">
      <ul className="tw-flex tw-flex-wrap tw-gap-4 tw-pb-3 tw-pt-4 md:tw-gap-8">
        {links}
      </ul>
    </aside>
    <div className="tw-pt-4">{children}</div>
  </div>
);

export { TabLayout, TabLinkItem };
