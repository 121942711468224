import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useUpdateUsersMutation } from "../../../api/rest/users";
import type { CompanyInformation } from "../../../types/models/administration";
import type { CompanyInvolvement } from "../../../types/models/company";
import type { NewUserRole, UserRole } from "../../../types/models/users";
import { Button } from "../../design-system/Button";
import { PencilIcon } from "../../design-system/icons";
import { EditUserDialog } from "../Dialog";

type EditUserProps = {
  value: UserRole;
  currentCompany: CompanyInvolvement | CompanyInformation;
  onSuccess: () => void;
  isApprover?: boolean;
};

const EditUser = ({
  value,
  currentCompany,
  onSuccess,
  isApprover,
}: EditUserProps) => {
  const [isOpen, setOpen] = useState(false);
  const i18n = useTranslation();

  const editUpdateMutation = useUpdateUsersMutation(currentCompany.orgNumber, {
    onSuccess: () => {
      setOpen(false);
      onSuccess();
    },
  });

  const handleUpdateSubmit = (data: NewUserRole) => {
    editUpdateMutation.mutate({
      Role: data.role,
      UserIds: [data.entityId],
    });
  };

  return (
    <>
      <Button variant="clean" size="sm" onClick={() => setOpen(true)}>
        <PencilIcon className="tw-h-6 tw-w-6" />
      </Button>
      {isOpen && (
        <EditUserDialog
          currentCompany={currentCompany}
          title={i18n.t("label.editUser")}
          onClose={() => setOpen(false)}
          handleOnSubmit={handleUpdateSubmit}
          mutation={editUpdateMutation}
          defaultValues={{ entityId: value.user.id, role: value.role }}
          isApprover={isApprover}
        />
      )}
    </>
  );
};

export { EditUser };
