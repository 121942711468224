import type {
  useAddUserMutation,
  useDeleteUserMutation,
  useUpdateUsersMutation,
} from "../api/rest/users";
import type { NewMultiUsersRole, NewUserRole } from "../types/models/users";

const NO_ACCESS_ROLE = "No access";

type Props = {
  addUserMutation: ReturnType<typeof useAddUserMutation>;
  deleteUserMutation: ReturnType<typeof useDeleteUserMutation>;
  updateUsersMutation: ReturnType<typeof useUpdateUsersMutation>;
};

const useSupportAccessLogic = ({
  addUserMutation,
  deleteUserMutation,
  updateUsersMutation,
}: Props): {
  handleDelete: () => void;
  handleAddOrUpdate: (userData: NewUserRole, currentRole: string) => void;
  NO_ACCESS_ROLE: string;
} => {
  const handleDelete = () => {
    deleteUserMutation.mutate(undefined, {
      onSuccess: () => {},
    });
  };

  const handleAddOrUpdate = (userData: NewUserRole, currentRole: string) => {
    const multiUserData: NewMultiUsersRole = {
      Role: userData.role as string,
      UserIds: [userData.entityId],
    };

    if (currentRole === NO_ACCESS_ROLE) {
      addUserMutation.mutate(userData);
      return;
    }

    updateUsersMutation.mutate(multiUserData);
  };

  return {
    handleDelete,
    handleAddOrUpdate,
    NO_ACCESS_ROLE,
  };
};

export { useSupportAccessLogic };
