import { PageWrapper } from "../../../../components/PageWrapper";
import { useCurrentCompany } from "../../../../context/account";
import { SettingsRoutes } from "./routes";

const CompanySettingsPage = () => {
  const currentCompany = useCurrentCompany();

  if (!currentCompany) {
    return null;
  }

  return (
    <PageWrapper
      className="tw-flex tw-flex-col max-md:tw-px-4 max-md:tw-py-6 md:tw-px-0"
      data-testid="company-settings"
    >
      <div className="tw-mx-auto tw-w-full tw-py-6 md:tw-py-8">
        <SettingsRoutes currentCompany={currentCompany} />
      </div>
    </PageWrapper>
  );
};

export { CompanySettingsPage };
