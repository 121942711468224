import { Disclosure as TailwindDisclosure } from "@headlessui/react";

import type { ExtractProps } from "../../../types";
import { clsxm } from "../../../utils/tailwind";
import { Button } from "../Button";
import { ChevronDownIcon, ChevronUpIcon } from "../icons";

const Disclosure = (props: ExtractProps<typeof TailwindDisclosure>) => (
  <TailwindDisclosure as="div" {...props} />
);

type ButtonProps = ExtractProps<typeof Button> & {
  open?: boolean;
  isReverse?: boolean;
};

Disclosure.Button = ({
  open,
  isReverse,
  children,
  className,
  ...props
}: ButtonProps) => {
  const Icon = (isReverse ? !open : open) ? ChevronUpIcon : ChevronDownIcon;

  return (
    <TailwindDisclosure.Button
      as={Button}
      className={clsxm("tw-w-full tw-justify-between tw-gap-2", className)}
      variant="clean"
      {...props}
    >
      {children}
      <Icon className={clsxm({ "tw-ml-2": children })} />
    </TailwindDisclosure.Button>
  );
};

Disclosure.Panel = (
  props: ExtractProps<(typeof TailwindDisclosure)["Panel"]>
) => <TailwindDisclosure.Panel as="div" {...props} />;

export { Disclosure };
