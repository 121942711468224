import type React from "react";
import { forwardRef } from "react";

import { clsxm } from "../../../utils/tailwind";
import type { Approver } from "../../ActionBanner";
import { ApprovalBadge } from "../../ApprovalBadge";
import { ApprovalStatus } from "../../ApprovalStatus";

const status = ["approved", "pending", "draft", "pendingRollback"] as const;
type Status = (typeof status)[number];
const isStatus = (
  approvedStatus: string | undefined
): approvedStatus is Status => status.some((s) => s === approvedStatus);

type ListHeaderProps = {
  title: string | React.ReactNode;
  description?: string;
  actions?: React.ReactNode;
  actionsPosition?: "right" | "below";
  className?: string;
} & (OtherListHeaderProps | PendingListHeaderProps);

type OtherListHeaderProps = {
  approvedStatus?: Exclude<Status, "pending">;
};

type PendingListHeaderProps = {
  approvedStatus: "pending";
  approved: number;
  approvers: Approver[];
  total: number;
  isPendingYourApproval: boolean;
  isUserApprover: boolean;
};

const backgroundClasses: Record<Status, string> = {
  approved: "tw-border-solid  tw-border-2 tw-border-green-300 tw-bg-green-100",
  pending: "tw-border-solid tw-border-2 tw-border-orange-300 tw-bg-orange-100",
  draft: "tw-border-solid tw-border-2 tw-border-neutral-300 tw-bg-neutral-100",
  pendingRollback:
    "tw-border-solid tw-border-2 tw-border-orange-300 tw-bg-orange-100",
};

const ListHeader: React.FunctionComponent<ListHeaderProps> = ({
  title,
  description,
  actions,
  actionsPosition = "right",
  className,
  ...props
}) => (
  <div
    className={clsxm(
      "tw-flex tw-items-center tw-justify-between tw-gap-4 tw-bg-neutral-50 tw-p-6",
      className,
      { "max-md:tw-flex-col": actionsPosition === "below" }
    )}
  >
    <div>
      <div className="tw-flex tw-items-center tw-gap-2 tw-text-sm sm:tw-gap-4">
        <div className="tw-flex tw-items-center tw-gap-2 sm:tw-gap-4">
          {isStatus(props.approvedStatus) ? (
            <div
              className={clsxm(
                "tw-h-3 tw-w-3 tw-rounded-sm",
                backgroundClasses[props.approvedStatus]
              )}
              data-testid="list-header-status-indicator"
            />
          ) : null}
          <h4 className="tw-text-semibold tw-text-sm tw-leading-tight">
            {title}
          </h4>
        </div>
        {props.approvedStatus === "pending" && (
          <>
            <ApprovalBadge
              approved={props.approved}
              approvers={props.approvers}
              total={props.total}
            />
            <div className="max-sm:tw-hidden">
              {props.isUserApprover && (
                <ApprovalStatus
                  isPendingYourApproval={props.isPendingYourApproval}
                />
              )}
            </div>
          </>
        )}
      </div>
      {description !== undefined && description !== "" && (
        <div className="tw-text-sm tw-text-secondary">{description}</div>
      )}
    </div>
    <div
      className={clsxm({
        "tw-w-full md:tw-mt-0 md:tw-w-auto": actionsPosition === "below",
      })}
    >
      {actions}
    </div>
  </div>
);

type ListItemProps = React.HTMLAttributes<HTMLLIElement> & {
  interactive?: boolean;
  className?: string;
};

const ListItem = forwardRef(
  (
    { interactive, className, ...props }: ListItemProps,
    ref: React.Ref<HTMLLIElement>
  ) => (
    <li
      ref={ref}
      className={clsxm("tw-space-y-2 tw-px-6 tw-py-2", className, {
        "hover:tw-bg-gray-100": interactive,
      })}
      data-testid="list-item"
      {...props}
    />
  )
);

const UList = forwardRef(
  (
    { children, ...props }: React.HTMLAttributes<HTMLUListElement>,
    ref: React.Ref<HTMLUListElement>
  ) => (
    <ul className="tw-flex tw-flex-col" ref={ref} {...props}>
      {children}
    </ul>
  )
);

type ListProps = {
  header?: React.ReactNode;
  footer?: React.ReactNode;
  className?: string;
  "data-testid"?: string;
};

const List: React.FunctionComponent<React.PropsWithChildren<ListProps>> = ({
  header,
  footer,
  children,
  className,
  ...props
}) => (
  <div {...props}>
    {header}
    <UList className={className}>{children}</UList>
    {footer}
  </div>
);

export { List, ListHeader, ListItem, UList };
