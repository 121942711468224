import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useShareTypesQuery } from "../../../api/blockchain/options";
import { useEntitiesQuery } from "../../../api/rest/entities";
import {
  useCreateOptionsProgram,
  useUpdateOptionsProgram,
} from "../../../api/rest/options";
import { Button } from "../../../components/design-system/Button";
import { ProgressDialog } from "../../../components/design-system/ProgressDialog";
import type { CompanyInformation } from "../../../types/models/administration";
import type { CompanyInvolvement } from "../../../types/models/company";
import {
  OptionsProgram,
  OptionsProgramParticipant,
} from "../../../types/models/options";
import {
  deleteStateFromLocalStorage as deleteDetailsDraftState,
  Details,
} from "./Details";
import { Done } from "./Done";
import {
  deleteStateFromLocalStorage as deleteParticipantsDraftState,
  Participants,
} from "./Participants";

type OptionsFormProps = {
  currentCompany: CompanyInvolvement | CompanyInformation;
  selectedProgram?: OptionsProgram;
  close: () => void;
};

type Step = "DETAILS_STEP" | "PARTICIPANTS_STEP" | "DONE_STEP";

const steps: Readonly<Record<Step, number>> = Object.freeze({
  DETAILS_STEP: 0,
  PARTICIPANTS_STEP: 1,
  DONE_STEP: 2,
});

const OptionsForm: React.FunctionComponent<OptionsFormProps> = ({
  currentCompany,
  selectedProgram,
  close,
}) => {
  const i18n = useTranslation();
  const [currentStep, setCurrentStep] = useState(steps.DETAILS_STEP);
  const [details, setDetails] = useState<OptionsProgram>();

  const shareTypesQuery = useShareTypesQuery(currentCompany.orgNumber);
  const entitiesQuery = useEntitiesQuery(currentCompany.orgNumber);
  const createProgramMutation = useCreateOptionsProgram(
    currentCompany.orgNumber,
    {
      onSuccess: () => {
        deleteDetailsDraftState();
        deleteParticipantsDraftState();
        setCurrentStep(steps.DONE_STEP);
      },
    }
  );
  const updateProgramMutation = useUpdateOptionsProgram(
    currentCompany.orgNumber,
    selectedProgram,
    {
      onSuccess: () => setCurrentStep(steps.DONE_STEP),
    }
  );
  const error = createProgramMutation.error || updateProgramMutation.error;

  const submitParticipants = (data: OptionsProgramParticipant[]) => {
    if (selectedProgram) {
      updateProgramMutation.mutate({ ...details!, participants: data });
    } else {
      createProgramMutation.mutate({ ...details!, participants: data });
    }
  };

  const { stepComponent, actions } = useMemo(() => {
    switch (currentStep) {
      case steps.DETAILS_STEP:
        return {
          stepComponent: (
            <Details
              orgNumber={currentCompany.orgNumber}
              shareTypes={shareTypesQuery.data || []}
              form="program-details"
              selectedProgram={selectedProgram}
              nextStep={() => setCurrentStep(steps.PARTICIPANTS_STEP)}
              setDetails={setDetails}
            />
          ),
          actions: (
            <Button
              type="submit"
              className="tw-w-full md:tw-w-auto"
              color="primary"
              variant="solid"
              form="program-details"
              disabled={!shareTypesQuery.isSuccess}
              isLoading={shareTypesQuery.isLoading}
            >
              {i18n.t("label.next")}
            </Button>
          ),
        };
      case steps.PARTICIPANTS_STEP:
        return {
          stepComponent: (
            <Participants
              form="program-participants"
              programType={details.type}
              entitiesQuery={entitiesQuery}
              currentCompany={currentCompany}
              selectedProgram={selectedProgram}
              maxTotal={details.totalAmountOfOptions}
              onSubmit={(data: OptionsProgramParticipant[]) =>
                submitParticipants(data)
              }
              error={error}
            />
          ),
          actions: [
            <Button
              className="tw-w-full md:tw-w-auto"
              key="previous"
              onClick={(e) => {
                e.preventDefault();
                setCurrentStep(steps.DETAILS_STEP);
              }}
            >
              {i18n.t("label.back")}
            </Button>,
            <Button
              key="next"
              type="submit"
              className="tw-w-full md:tw-w-auto"
              color="primary"
              variant="solid"
              form="program-participants"
              disabled={!entitiesQuery.isSuccess}
              isLoading={
                entitiesQuery.isLoading ||
                createProgramMutation.isLoading ||
                updateProgramMutation.isLoading
              }
            >
              {i18n.t(
                selectedProgram
                  ? "options.participants.update"
                  : "options.participants.submit"
              )}
            </Button>,
          ],
        };
      case steps.DONE_STEP:
        return {
          stepComponent: (
            <Done name={details.title} updated={!!selectedProgram} />
          ),
          actions: (
            <Button
              type="submit"
              className="tw-w-full md:tw-w-auto"
              color="primary"
              variant="solid"
              onClick={() => close()}
            >
              {i18n.t("label.done")}
            </Button>
          ),
        };
      default:
        return { stepComponent: undefined, actions: undefined };
    }
  }, [currentStep, setCurrentStep, shareTypesQuery, entitiesQuery]);

  return (
    <ProgressDialog
      onClose={close}
      totalSteps={2}
      currentStep={currentStep}
      actions={actions}
      isLoading={shareTypesQuery.isLoading || entitiesQuery.isLoading}
    >
      {stepComponent}
    </ProgressDialog>
  );
};

export { OptionsForm };
