const LabelValue = ({
  label,
  value,
}: {
  label: React.ReactNode;
  value: React.ReactNode;
}) => (
  <div className="tw-text-sm">
    <div className="tw-text-secondary">{label}</div>
    <div>{value}</div>
  </div>
);

export { LabelValue };
