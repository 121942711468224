import { format } from "date-fns";
import { useTranslation } from "react-i18next";

import { getDateLocale } from "../../../../../utils/date";

const LedgerConfirmedAsOfficial = ({
  lastUpdated,
}: {
  lastUpdated?: number;
}) => {
  const i18n = useTranslation();
  const dateLocale = getDateLocale();

  return (
    <div className="tw-flex tw-flex-col tw-gap-4 tw-border-t tw-pt-8">
      <h3 className="tw-font-body tw-text-lg tw-leading-tight md:tw-text-2xl">
        {i18n.t("ledgerConfirmedAsOfficial.title")}
      </h3>
      <div className="tw-place-items-center tw-rounded tw-bg-kvanta-background tw-p-5">
        {i18n.t("ledgerConfirmedAsOfficial.description")}
        {lastUpdated && (
          <div className=" tw-text-kvanta-secondary">
            {i18n.t("ledgerConfirmedAsOfficial.details", {
              date: format(new Date(lastUpdated), "PPP", {
                locale: dateLocale,
              }),
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export { LedgerConfirmedAsOfficial };
