import { useTranslation } from "react-i18next";

import type { ErrorBoundaryProps } from "../../utils/monitoring";
import { ErrorBoundary as SentryErrorBoundary } from "../../utils/monitoring";
import { NoData } from "../NoData";

const ErrorBoundary: React.FunctionComponent<ErrorBoundaryProps> = ({
  children,
  ...props
}) => {
  const i18n = useTranslation();

  return (
    <SentryErrorBoundary
      fallback={
        <NoData
          type="error"
          title={i18n.t("error.page.general")}
          description={i18n.t("error.general")}
        />
      }
      {...props}
    >
      {children}
    </SentryErrorBoundary>
  );
};

export { ErrorBoundary };
