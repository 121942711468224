import type { PrivateHoldings } from "@capchapdev/rell-api";
import type { UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";

import { useSession } from "../../context/session";
import type { IRequestError } from "..";
import { getAuthHeader } from "../auth";
import { blockchainClient } from "./client";
import { sendQuery } from "./transaction";

const usePrivateHoldings = (
  version: "approved" | "latest" = "latest"
): UseQueryResult<PrivateHoldings, IRequestError> => {
  const session = useSession();
  const refId = session.user?.refId ?? "";

  return useQuery<PrivateHoldings, IRequestError, PrivateHoldings, string[]>(
    ["privateHoldings", version, refId],
    () =>
      sendQuery(
        blockchainClient
          .query<PrivateHoldings>()
          .name("ledger.private_holdings")
          .addParameter("auth", getAuthHeader())
          .addParameter("version", version)
      ),
    { enabled: refId.length > 0 }
  );
};

export { usePrivateHoldings };
