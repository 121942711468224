import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useAddUserMutation } from "../../../api/rest/users";
import type { CompanyInformation } from "../../../types/models/administration";
import type { CompanyInvolvement } from "../../../types/models/company";
import type { NewUserRole } from "../../../types/models/users";
import { Button } from "../../design-system/Button";
import { EditUserDialog } from "../Dialog";

type AddUserProps = {
  currentCompany: CompanyInvolvement | CompanyInformation;
  onSuccess: () => void;
};

const AddUser = ({ currentCompany, onSuccess }: AddUserProps) => {
  const [isOpen, setOpen] = useState(false);
  const i18n = useTranslation();
  const addUserMutation = useAddUserMutation(currentCompany.orgNumber, {
    onSuccess: () => {
      setOpen(false);
      onSuccess();
    },
  });

  const handleAddSubmit = (data: NewUserRole) => {
    addUserMutation.mutate({
      role: data.role,
      entityId: data.entityId,
    });
  };

  return (
    <>
      <Button color="primary" variant="solid" onClick={() => setOpen(true)}>
        {i18n.t("label.addUser")}
      </Button>
      {isOpen && (
        <EditUserDialog
          currentCompany={currentCompany}
          title={i18n.t("label.addUser")}
          onClose={() => setOpen(false)}
          handleOnSubmit={handleAddSubmit}
          mutation={addUserMutation}
        />
      )}
    </>
  );
};

export { AddUser };
