import { useTranslation } from "react-i18next";

import type { EntitiesMap } from "../../../../types/models/entities";
import type { LedgerRollbackPending } from "../../../../types/models/events";
import { Description } from "../../../design-system/Description";
import { CheckmarkIcon } from "../../../design-system/icons";
import AuditLine from "./AuditLine";

export const LedgerRollbackPendingAuditLine = (props: {
  event: LedgerRollbackPending;
  label: string;
  entitiesMap: EntitiesMap;
  open: boolean;
  onOpenChange: (value: boolean) => void;
}) => {
  const i18n = useTranslation();
  const createdBy = props.entitiesMap[props.event.user.id];

  return (
    <AuditLine
      title={i18n.t("events.ledgerRollbackPending.title")}
      icon={<CheckmarkIcon />}
      description={i18n.t("events.ledgerRollbackPending.title")}
      panelContent={
        <div className="tw-flex tw-w-full tw-items-center tw-gap-6 tw-px-6 md:tw-flex md:tw-w-full">
          <div className="tw-flex tw-items-center">
            <Description
              title={i18n.t("auditLog.createdBy")}
              description={createdBy!.name}
              theme="grayBlack"
            />
          </div>
          <div className="tw-flex tw-items-center">
            <Description
              title={i18n.t("auditLog.events.rollback.date")}
              description={props.event.rollback_date.split(".")[0]}
              theme="grayBlack"
            />
          </div>
        </div>
      }
      {...props}
    />
  );
};
