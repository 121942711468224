import { useTranslation } from "react-i18next";

import {
  useAddUserMutation,
  useDeleteUserMutation,
  useUpdateUsersMutation,
} from "../../../api/rest/users";
import type { CompanyInformation } from "../../../types/models/administration";
import type { CompanyInvolvement } from "../../../types/models/company";
import type { SupportAccess } from "../../../types/models/users";
import { supportUserId } from "../../../utils/user";
import { Description } from "../../design-system/Description";
import { notify } from "../../design-system/Notifications";
import { SelectSupportAccess } from "./SelectSupportAccess";

type ManageSupportAccessProps = {
  enabled: boolean;
  currentCompany: CompanyInvolvement | CompanyInformation;
  onSuccess: () => void;
  currentSupportRole: SupportAccess;
};
const ManageSupportAccess: React.FunctionComponent<
  ManageSupportAccessProps
> = ({ enabled, currentCompany, onSuccess, currentSupportRole }) => {
  const i18n = useTranslation();

  const addUserMutation = useAddUserMutation(currentCompany.orgNumber, {
    onSuccess: () => {
      notify(<Description title={i18n.t("support.supportAccess.updated")} />, {
        type: "success",
      });
      onSuccess();
    },
  });

  const updateUsersMutation = useUpdateUsersMutation(currentCompany.orgNumber, {
    onSuccess: () => {
      notify(<Description title={i18n.t("support.supportAccess.updated")} />, {
        type: "success",
      });
      onSuccess();
    },
  });

  const deleteUserMutation = useDeleteUserMutation(
    currentCompany.orgNumber,
    supportUserId,
    {
      onSuccess: () => {
        notify(
          <Description title={i18n.t("support.supportAccess.updated")} />,
          { type: "success" }
        );
        onSuccess();
      },
    }
  );

  return (
    <div>
      <SelectSupportAccess
        currentSupportRole={currentSupportRole}
        updateUsersMutation={updateUsersMutation}
        enabled={enabled}
        title={i18n.t("support.supportAccess.enable")}
        addUserMutation={addUserMutation}
        deleteUserMutation={deleteUserMutation}
        defaultValues={{
          role: currentSupportRole,
          entityId: supportUserId,
        }}
      />
    </div>
  );
};

export { ManageSupportAccess };
