import { Trans, useTranslation } from "react-i18next";

import type { EntitiesMap } from "../../../../types/models/entities";
import type { ShareCertificatesUpdateEvent } from "../../../../types/models/events";
import { isRangeValid } from "../../../../utils/shares";
import { Description } from "../../../design-system/Description";
import { CertificateIcon } from "../../../design-system/icons";
import { ListItem } from "../../../design-system/List";
import AuditLine from "./AuditLine";

export const ShareCertificatesUpdateAuditLine = (props: {
  event: ShareCertificatesUpdateEvent;
  label: string;
  entitiesMap: EntitiesMap;
  open: boolean;
  onOpenChange: (value: boolean) => void;
}) => {
  const i18n = useTranslation();
  const createdBy = props.entitiesMap[props.event.user.id];
  const {
    ranges: { before, after },
  } = props.event;
  const removed = before.filter(
    (x) =>
      !after.find((range) => x.start === range.start && x.end === range.end)
  );
  const added = after.filter(
    (x) =>
      !before.find((range) => x.start === range.start && x.end === range.end)
  );

  return (
    <AuditLine
      title={i18n.t("events.shareCertificateUpdate.title")}
      icon={<CertificateIcon />}
      description={
        <Trans
          i18nKey="events.shareCertificateUpdate.content"
          values={{ count: after.length }}
        />
      }
      panelContent={
        <div className="tw-flex tw-w-full tw-items-center tw-gap-6 tw-px-6 md:tw-flex md:tw-w-full">
          <div className="tw-flex tw-items-center">
            <Description
              title={i18n.t("auditLog.createdBy")}
              description={createdBy!.name}
              theme="grayBlack"
            />
          </div>
          <div className="tw-flex tw-items-center">
            <Description
              title={i18n.t("auditLog.events.shares.added")}
              description={added.map(({ start, end, type }) => (
                <ListItem key={start} className="tw-text-sm">
                  {i18n.t("events.shareCertificateUpdate.content.added", {
                    start,
                    end,
                    type,
                    count:
                      start && end && isRangeValid({ start, end })
                        ? end - start + 1
                        : 0,
                  })}
                </ListItem>
              ))}
              theme="grayBlack"
            />
          </div>
          <div className="tw-flex tw-items-center">
            <Description
              title={i18n.t("auditLog.events.shares.removed")}
              description={
                removed.length > 0
                  ? removed.map(({ start, end, type }) => (
                      <ListItem key={start} className="tw-text-sm">
                        {i18n.t(
                          "events.shareCertificateUpdate.content.removed",
                          {
                            start,
                            end,
                            type,
                            count:
                              start && end && isRangeValid({ start, end })
                                ? end - start + 1
                                : 0,
                          }
                        )}
                      </ListItem>
                    ))
                  : "None"
              }
              theme="grayBlack"
            />
          </div>
        </div>
      }
      {...props}
    />
  );
};
