import { useTranslation } from "react-i18next";
import { Link, Route, Routes, useParams } from "react-router-dom";

import { useAdminCompaniesQuery } from "../../../api/blockchain/administration";
import { PageWrapper } from "../../../components/PageWrapper";
import { TabLayout, TabLinkItem } from "../../../components/TabLayout";
import { environment } from "../../../config";
import * as monitoring from "../../../utils/monitoring";
import { UpdateCompanyStatusPage } from "../../companies/[companyId]/settings/update-status";

const SentryRoutes = monitoring.withSentryReactRouterV6Routing(Routes);

const CompanyAdministrationPage = () => {
  const { companyId } = useParams();
  const companiesQuery = useAdminCompaniesQuery();
  const currentCompany = (companiesQuery.data || []).find(
    (company) => companyId === company.orgNumber
  );
  const i18n = useTranslation();
  if (!currentCompany) {
    return null;
  }
  const showUpdateStatusTab = ["staging", "dev", "local"].includes(environment);

  return (
    <PageWrapper data-testid="administration-details">
      <header className="tw-flex tw-justify-between tw-pb-3">
        <h4>
          <Link to="..">{i18n.t("label.administration")}</Link>
          {" / "}
          {currentCompany.name}
        </h4>
      </header>
      <TabLayout
        links={
          <>
            {showUpdateStatusTab && (
              <TabLinkItem to="update-status">
                {i18n.t("label.updateStatus")}
              </TabLinkItem>
            )}
          </>
        }
      >
        <SentryRoutes>
          <Route path="update-status" element={<UpdateCompanyStatusPage />} />
        </SentryRoutes>
      </TabLayout>
    </PageWrapper>
  );
};

export { CompanyAdministrationPage };
