import { z } from "zod";

import { LedgerVersionSchema } from "./shares";
import { RoleSchema } from "./users";

const StatusSchema = z.enum([
  "Onboarding",
  "Onboarded",
  "UnderLiquidation",
  "Liquidated",
]);

const SettingsSchema = z.object({ currency: z.string() });

const LedgerConfirmedOfficialStatusCompletedSchema = z.object({
  lastUpdate: z.number().min(0),
  status: z.literal("Completed"),
});
const LedgerConfirmedOfficialStatusPartialSchema = z.object({
  lastUpdate: z.number().min(0),
  status: z.literal("Partial"),
});
const LedgerConfirmedOfficialStatusNoneSchema = z.object({
  lastUpdate: z.null().nullish(),
  status: z.literal("None"),
});

const LedgerConfirmedOfficialSchema = z.discriminatedUnion("status", [
  LedgerConfirmedOfficialStatusCompletedSchema,
  LedgerConfirmedOfficialStatusPartialSchema,
  LedgerConfirmedOfficialStatusNoneSchema,
]);

const CompanyInvolvementSchema = z.object({
  orgNumber: z.string(),
  name: z.string(),
  status: StatusSchema,
  ledgerApproved: LedgerVersionSchema.nullable(),
  access: RoleSchema,
  settings: SettingsSchema.nullable(),
  countryCode: z.string(),
  confirmedAsOfficial: LedgerConfirmedOfficialSchema,
});

type CompanyInvolvement = z.infer<typeof CompanyInvolvementSchema>;

type CompanyStatusType = z.infer<typeof StatusSchema>;

const CompanyInvolvementsSchema = z.array(CompanyInvolvementSchema).refine(
  (arr) => {
    const orgNumbers = arr.map(({ orgNumber }) => orgNumber);

    return orgNumbers.length === new Set(orgNumbers).size;
  },
  { message: "All 'orgNumber' values in the array must be unique" }
);

type CompanyLegalStatus = "underLiquidation" | "liquidated";

const BaseCompanyEntitySchema = z.object({
  name: z.string(),
  refId: z.string(),
  countryCode: z.string(),
});

type BaseCompanyEntity = z.infer<typeof BaseCompanyEntitySchema>;

export type {
  BaseCompanyEntity,
  CompanyInvolvement,
  CompanyLegalStatus,
  CompanyStatusType,
};
export { CompanyInvolvementsSchema, LedgerConfirmedOfficialSchema };
