import { useSession } from "../../context/session";

type AdministratorActionProps = {
  children: React.ReactNode;
};

const AdministratorAction = (props: AdministratorActionProps) => {
  const { user } = useSession();
  if (!user || user?.role !== "Administrator") {
    return null;
  }

  return <>{props.children}</>;
};

export { AdministratorAction };
