import { Control, Controller, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useShareholdersQuery } from "../../api/blockchain/company";
import type { useEntitiesQuery } from "../../api/rest/entities";
import type { CompanyInformation } from "../../types/models/administration";
import type { CompanyInvolvement } from "../../types/models/company";
import type {
  CreditorRow,
  Shareblock,
  Shareholder,
} from "../../types/models/shares";
import { Button } from "../design-system/Button";
import { FormError, FormGroup, FormLabel } from "../design-system/FormGroup";
import { TrashIcon } from "../design-system/icons";
import { Input } from "../design-system/Input";
import { List, ListHeader, ListItem } from "../design-system/List";
import { AddEntity } from "../Entities/AddEntity";
import { NoData } from "../NoData";
import { SelectEntity } from "../SelectEntity";
import { SelectShareCertificates } from "../SelectShareCertificates";

type ShareRangeErrors = {
  [index: number]: ShareRangeError;
};
type ShareRangeError = {
  start?: { message?: string };
  end?: { message?: string };
  creditorId?: { message?: string };
};

const Row = ({
  value,
  register,
  control,
  index,
  error,
  onChange,
  onDelete,
  entitiesQuery,
  currentCompany,
}: {
  value: CreditorRow;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  index: number;
  error: ShareRangeError | undefined;
  onChange: (newValue: CreditorRow) => void;
  onDelete: () => void;
  currentCompany: CompanyInvolvement | CompanyInformation;
  entitiesQuery: ReturnType<typeof useEntitiesQuery>;
  shareBlock?: Shareholder[];
}) => {
  const i18n = useTranslation();
  const entitiesData = entitiesQuery.data || [];

  return (
    <div className="tw-flex tw-items-center tw-gap-4 tw-pb-1">
      <div className="tw-grid tw-flex-1 tw-auto-cols-fr tw-gap-4 md:tw-grid-cols-2">
        <FormGroup>
          <FormLabel htmlFor={`shareRanges.${index}.start`}>
            {i18n.t("label.from")}
          </FormLabel>
          <Input
            id={`shareRanges.${index}.start`}
            {...register(`shareRanges.${index}.start`, {
              required: i18n.t("error.validation.required"),
              valueAsNumber: true,
              min: {
                value: 1,
                message: i18n.t("error.validation.range.min", {
                  min: 1,
                }),
              },
              max: {
                value: value?.end || Number.MAX_SAFE_INTEGER,
                message: i18n.t("error.validation.shareRange.from"),
              },
            })}
            value={value.start ?? ""}
            onChange={(e) => {
              const { start: _, ...rest } = value;
              if (e.target.value === "") {
                onChange({ ...rest, start: null });
              } else if (Number.isNaN(e.target.valueAsNumber)) {
                onChange(value);
              } else {
                onChange({ ...rest, start: e.target.valueAsNumber });
              }
            }}
            type="number"
            step={1}
            min={1}
          />
          <FormError>{error && error?.start?.message}</FormError>
        </FormGroup>
        <FormGroup>
          <FormLabel htmlFor={`shareRanges.${index}.end`}>
            {i18n.t("label.to")}
          </FormLabel>
          <Input
            id={`shareRanges.${index}.end`}
            {...register(`shareRanges.${index}.end`, {
              required: i18n.t("error.validation.required"),
              min: {
                value: value?.start || 1,
                message: value.start
                  ? i18n.t("error.validation.shareRange.to")
                  : i18n.t("error.validation.range.min", {
                      min: 1,
                    }),
              },
            })}
            value={value.end ?? ""}
            onChange={(e) => {
              const { end: _, ...rest } = value;
              if (e.target.value === "") {
                onChange({ ...rest, end: null });
              } else if (Number.isNaN(e.target.valueAsNumber)) {
                onChange(value);
              } else {
                onChange({ ...rest, end: e.target.valueAsNumber });
              }
            }}
            type="number"
            step={1}
            min={1}
          />
          <FormError>{error && error?.end?.message}</FormError>
        </FormGroup>
        <div className="tw-col-span-2">
          <FormGroup>
            <div className="tw-flex tw-items-end tw-justify-between">
              <FormLabel htmlFor="creditor">
                {i18n.t("label.creditor")}
              </FormLabel>
              <AddEntity
                currentCompany={currentCompany}
                onSuccess={(newEntity) => {
                  entitiesQuery.refetch();
                  onChange({ ...value, creditorId: newEntity.id });
                }}
              />
            </div>
            <Controller
              control={control}
              name={`shareRanges.${index}.creditorId`}
              render={({ field, fieldState }) => (
                <>
                  <SelectEntity
                    options={entitiesData}
                    value={field.value}
                    onChange={(creditorId) => field.onChange(creditorId)}
                  />
                  <FormError>{fieldState.error?.message}</FormError>
                </>
              )}
              rules={{ required: i18n.t("error.validation.required") }}
            />
          </FormGroup>
        </div>
      </div>
      <div className="tw-flex tw-items-center md:tw-items-end">
        <Button variant="clean" size="md" onClick={onDelete}>
          <TrashIcon className="tw-h-6 tw-w-6" />
        </Button>
      </div>
    </div>
  );
};

const SelectCreditors = ({
  value,
  register,
  control,
  errors,
  onChange,
  entitiesQuery,
  currentCompany,
  shareBlockOptions,
  selectedPledgeBlocks,
}: {
  value: CreditorRow[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  errors: ShareRangeErrors;
  onChange: (newValue: CreditorRow[] | (Shareblock[] | undefined)) => void;
  entitiesQuery: ReturnType<typeof useEntitiesQuery>;
  currentCompany: CompanyInvolvement | CompanyInformation;
  shareBlockOptions: Shareblock[];
  selectedPledgeBlocks?: Shareblock[];
}) => {
  const i18n = useTranslation();

  const handleAdd = () => {
    onChange([...value, { type: new Date().toISOString() }]);
  };

  const orgNumber = currentCompany.orgNumber || "";
  const shareholdersQuery = useShareholdersQuery(orgNumber);
  const shareholders = shareholdersQuery.data || [];
  const entitiesData = entitiesQuery.data || [];
  const entitiesMap = Object.fromEntries(entitiesData.map((e) => [e.id, e]));

  return (
    <List
      header={
        <ListHeader
          title={i18n.t("events.pledgedSharesUpdate.form.ranges.label")}
          description={i18n.t(
            "events.pledgedSharesUpdate.form.ranges.description"
          )}
        />
      }
      footer={
        <div className="tw-p-4">
          <Button onClick={handleAdd}>{i18n.t("label.addRange")}</Button>
        </div>
      }
    >
      <FormGroup className="tw-space-y-2 tw-px-4 tw-py-2">
        <FormLabel htmlFor="pledgeSelect">
          {i18n.t("label.pledgeSelect")}
        </FormLabel>
        <SelectShareCertificates
          value={selectedPledgeBlocks}
          options={shareBlockOptions}
          onChange={onChange}
          entitiesMap={entitiesMap}
        />
      </FormGroup>

      {value.map((range, index) => (
        <ListItem key={range.type}>
          <Row
            value={range}
            register={register}
            control={control}
            error={errors[index]}
            index={index}
            onChange={(newValue) => {
              onChange(value.map((v, i) => (i === index ? newValue : v)));
            }}
            onDelete={() => {
              onChange(value.filter((_, i) => i !== index));
            }}
            entitiesQuery={entitiesQuery}
            currentCompany={currentCompany}
            shareBlock={shareholders}
          />
        </ListItem>
      ))}
      {value.length === 0 && <NoData />}
    </List>
  );
};

export { SelectCreditors };
export type { ShareRangeErrors };
