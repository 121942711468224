import type React from "react";
import type { FlagIconCode } from "react-flag-kit";
import { FlagIcon } from "react-flag-kit";

import { getCountries } from "../../utils/country";
import type { OptionProps, SingleValueProps } from "../design-system/Select";
import {
  components,
  Option as SelectOption,
  Select,
} from "../design-system/Select";

type Option = { value: string; label: string };

const CountryItem = ({
  code,
  children,
}: {
  code: string;
  children: React.ReactNode;
}) => (
  <div className="tw-flex tw-items-center tw-gap-1">
    <span>
      <FlagIcon code={code as FlagIconCode} size={16} />
    </span>
    <span>{children}</span>
  </div>
);

const CountryOption = (props: OptionProps<Option, false>) => {
  const { data } = props;

  return (
    <SelectOption {...props}>
      <CountryItem code={data.value}>{data.label}</CountryItem>
    </SelectOption>
  );
};

const SingleValue = (props: SingleValueProps<Option, false>) => {
  const { data } = props;

  return (
    <components.SingleValue {...props}>
      <CountryItem code={data.value}>{data.label}</CountryItem>
    </components.SingleValue>
  );
};

const SelectCountry = ({
  name,
  value,
  onChange,
  disabled,
}: {
  name: string;
  value: string;
  onChange: (value?: string) => void;
  disabled?: boolean;
}) => {
  const countryOptions = Object.entries(getCountries()).map(
    ([code, country]) => ({ value: code, label: country.name })
  );

  return (
    <Select
      name={name}
      value={countryOptions.find((c) => c.value === value)}
      options={countryOptions}
      onChange={(newValue) => onChange(newValue?.value)}
      components={{ Option: CountryOption, SingleValue }}
      menuPosition="fixed"
      isDisabled={disabled}
    />
  );
};

export { SelectCountry };
