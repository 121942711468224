import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { OptionsProgram, ShareClass } from "../../types/models/options";
import { IRequestError } from "..";
import { getAuthHeader } from "../auth";
import { blockchainClient } from "./client";
import { sendQuery } from "./transaction";

const getOptionsPrograms = async (orgNumber: string) => {
  return await sendQuery(
    blockchainClient
      .query<OptionsProgram[]>()
      .name("options.get_option_programs_with_participants_for_org")
      .addParameter("auth", getAuthHeader())
      .addParameter("org_no", orgNumber)
  );
};

const useOptionsProgramQuery = (
  orgNumber?: string,
  options?: UseQueryOptions<unknown, IRequestError, OptionsProgram[], string[]>
) =>
  useQuery(
    ["options", orgNumber || ""],
    () => getOptionsPrograms(orgNumber || ""),
    { enabled: !!orgNumber, ...options }
  );

const getShareTypes = async (orgNumber: string) => {
  return await sendQuery(
    blockchainClient
      .query<ShareClass[]>()
      .name("options.get_share_types")
      .addParameter("auth", getAuthHeader())
      .addParameter("org_no", orgNumber)
  );
};

const useShareTypesQuery = (
  orgNumber?: string,
  options?: UseQueryOptions<unknown, IRequestError, ShareClass[], string[]>
) =>
  useQuery(
    ["optionsShareTypes", orgNumber || ""],
    () => getShareTypes(orgNumber || ""),
    { enabled: !!orgNumber, ...options }
  );

export { useOptionsProgramQuery, useShareTypesQuery };
