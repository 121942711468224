import { useTranslation } from "react-i18next";

import Truid from "../../../../../../api/rest/auth/Truid";
import { Alert } from "../../../../../../components/design-system/Alert";
import { Button } from "../../../../../../components/design-system/Button";
import { Loading } from "../../../../../../components/design-system/Loading";
import { useCurrentCompany } from "../../../../../../context/account";
import type { NewSignatureRequest } from "../../../../../../types/models/auth";

type SignWithTruidProps = {
  request?: NewSignatureRequest;
  onRedirectUrlFetched: (redirectUrl: string) => void;
  truidSignData?: {
    code: string;
    state: string;
  };
  onFetchingSignature: (signature: string) => void;
  onFetchingSignatureError: () => void;
  onRetry: () => void;
};

const SignWithTruid = ({
  request,
  truidSignData,
  onFetchingSignature,
  onRedirectUrlFetched,
  onFetchingSignatureError,
  onRetry,
}: SignWithTruidProps) => {
  const i18n = useTranslation();
  const initSignMutation = Truid.useInitSignMutation();
  const collectSignatureMutation = Truid.useCollectSignatureMutation();
  const currentCompany = useCurrentCompany();

  if (
    request &&
    !truidSignData &&
    initSignMutation.isIdle &&
    !initSignMutation.data &&
    !initSignMutation.error
  ) {
    initSignMutation
      .mutateAsync(request)
      .then(({ redirectUrl }) => {
        onRedirectUrlFetched(redirectUrl);
        return redirectUrl;
      })
      .catch(() => {});
  }

  if (
    !request &&
    currentCompany?.orgNumber &&
    truidSignData?.code &&
    truidSignData?.state &&
    collectSignatureMutation.isIdle &&
    !collectSignatureMutation.data &&
    !collectSignatureMutation.isError
  ) {
    collectSignatureMutation
      .mutateAsync({
        code: truidSignData.code,
        state: truidSignData.state,
        orgNumber: currentCompany?.orgNumber,
      })
      .then(({ signature }) => {
        onFetchingSignature(signature);
        return signature;
      })
      .catch((error) => {
        onFetchingSignatureError();
        throw error;
      });
  }

  const isLoading =
    initSignMutation.isLoading || collectSignatureMutation.isLoading;
  const isError = initSignMutation.isError || collectSignatureMutation.isError;
  const error =
    isError && (initSignMutation.error ?? collectSignatureMutation.error);
  if (error) {
    return (
      <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
        <Alert className="tw-my-2" type="error">
          {i18n.t(error.errors[0].message.code)}
        </Alert>
        <Button className="tw-my-2" onClick={() => onRetry()}>
          {i18n.t("label.retry")}
        </Button>
      </div>
    );
  }
  return (
    <>
      <h3 className="tw-text-center">
        {i18n.t("approval-policy.signWithTruid.title")}
      </h3>
      <p className="tw-my-2 tw-text-center">
        {i18n.t("approval-policy.signWithTruid.text")}
      </p>
      {isLoading && <Loading />}
    </>
  );
};

export { SignWithTruid };
