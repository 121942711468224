import { parse } from "date-fns";

import type { LedgerVersion } from "../types/models/shares";

export const sortVersions = (versions: LedgerVersion[]) => {
  const sortedVersions = versions.sort((a, b) => {
    const [dateStringA, versionA] = a.split(".");
    const [dateStringB, versionB] = b.split(".");
    const dateA = parse(dateStringA as string, "yyyy-MM-dd", new Date());
    const dateB = parse(dateStringB as string, "yyyy-MM-dd", new Date());

    if (dateStringA === dateStringB) {
      return (
        parseInt(versionA as string, 10) - parseInt(versionB as string, 10)
      );
    }
    return dateA.getTime() - dateB.getTime();
  });
  return sortedVersions;
};
