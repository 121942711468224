import { useTranslation } from "react-i18next";

import type { EntitiesMap } from "../../../../types/models/entities";
import type { LedgerPolicyApproval } from "../../../../types/models/events";
import { Description } from "../../../design-system/Description";
import { PencilIcon } from "../../../design-system/icons";
import AuditLine from "./AuditLine";

export const LedgerPolicyApprovalAuditLine = (props: {
  event: LedgerPolicyApproval;
  label: string;
  entitiesMap: EntitiesMap;
  open: boolean;
  onOpenChange: (value: boolean) => void;
}) => {
  const i18n = useTranslation();
  const approvedBy = props.event.approvedBy.map(
    (u) => props.entitiesMap[u.id]?.name
  );

  let rule = "";
  if (props.event.specificUsers) {
    rule = i18n.t("initiatePolicy.manualTitle");
  } else if (props.event.percentageRule === 1000) {
    rule = i18n.t("initiatePolicy.allTitle");
  } else if (props.event.percentageRule === 0) {
    rule = i18n.t("initiatePolicy.anyTitle");
  } else {
    rule = i18n.t("initiatePolicy.percentageTitle", {
      percentage: props.event.percentageRule! / 10,
    });
  }

  return (
    <AuditLine
      title={i18n.t("events.ledgerPolicyApproval.title")}
      icon={<PencilIcon />}
      description={i18n.t("events.ledgerPolicyApproval.title")}
      panelContent={
        <div className="tw-flex tw-w-full tw-items-center tw-gap-6 tw-px-6 md:tw-flex md:tw-w-full">
          <div className="tw-flex tw-items-center">
            <Description
              title={i18n.t("auditLog.approvedBy")}
              description={approvedBy.join(", ")}
              theme="grayBlack"
            />
          </div>
          <div className="tw-flex tw-items-center">
            <Description
              title={i18n.t("auditLog.events.policy.change")}
              description={rule}
              theme="grayBlack"
            />
          </div>
          {props.event.specificUsers && (
            <div className="tw-flex tw-items-center">
              <Description
                title={i18n.t("auditLog.events.policy.delegated")}
                description={props.event.specificUsers
                  .map((u) => props.entitiesMap[u]?.name)
                  .join(", ")}
                theme="grayBlack"
              />
            </div>
          )}
        </div>
      }
      {...props}
    />
  );
};
