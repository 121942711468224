import { Holding } from "@capchapdev/rell-api";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";

import { useCompanyHoldings } from "../../../api/blockchain/companyHolding";
import { usePrivateHoldings } from "../../../api/blockchain/privateHolding";
import { useRepresentativeHolding } from "../../../api/blockchain/representativeHolding";
import { useRepresentativeEntityQuery } from "../../../api/rest/entities";
import Breadcrumb from "../../../components/Breadcrumb";
import { Loading } from "../../../components/design-system/Loading";
import LinkList from "../../../components/LinkList";
import LinkListRow from "../../../components/LinkListRow";
import { PageWrapper } from "../../../components/PageWrapper";
import { APP_ROUTE } from "../../../routes/constants";

type OwnershipListProps = {
  title: string;
  holdings: Holding[];
};

const OwnershipList = ({ title, holdings }: OwnershipListProps) => {
  const i18n = useTranslation();
  const location = useLocation();

  return (
    <PageWrapper className="tw-max-w-3xl" data-testid="ownership">
      <Breadcrumb
        links={[{ url: `${APP_ROUTE.HOME}#asset`, name: i18n.t("home.name") }]}
        current={title}
      />
      <h2 className="tw-mt-4">{title}</h2>
      <div className="tw-mt-4">
        <LinkList>
          {holdings.map((h) => (
            <LinkListRow
              key={h.company.name}
              url={`${location.pathname}/${h.company.orgNumber}`}
              title={h.company.name}
              description={`${h.shares.total} shares (${h.shares.percentage}%)`}
            />
          ))}
        </LinkList>
      </div>
    </PageWrapper>
  );
};

export const PersonalOwnershipList = () => {
  const i18n = useTranslation();
  const privateHoldings = usePrivateHoldings("approved");

  if (privateHoldings.isLoading) {
    return <Loading />;
  }

  return (
    <OwnershipList
      title={i18n.t("assets.details.portfolio.personal")}
      holdings={privateHoldings.data?.holdings || []}
    />
  );
};

export const CompanyOwnershipList = () => {
  const i18n = useTranslation();
  const companyHoldings = useCompanyHoldings("approved");
  const { companyId } = useParams();
  const matchingCompany = companyHoldings.data?.find(
    (i) => i.company.orgNumber === companyId
  );

  if (companyHoldings.isLoading || !matchingCompany) {
    return <Loading />;
  }

  return (
    <OwnershipList
      title={i18n.t("assets.details.portfolio", {
        name: matchingCompany.company.name,
      })}
      holdings={matchingCompany.holdings || []}
    />
  );
};

export const RepresentativeOwnershipList = () => {
  const i18n = useTranslation();
  const representativeHoldings = useRepresentativeHolding("approved");
  const { holderId } = useParams();
  const matchingHoldings =
    representativeHoldings.data &&
    representativeHoldings.data.filter((h) => h.holder.id === holderId);
  const firstHolding = matchingHoldings && matchingHoldings[0];
  const orgNumber = firstHolding && firstHolding.holding.company.orgNumber;
  const entity = useRepresentativeEntityQuery(orgNumber, holderId).data;

  if (representativeHoldings.isLoading || !entity) {
    return <Loading />;
  }

  return (
    <OwnershipList
      title={i18n.t("assets.details.portfolio", { name: entity.name })}
      holdings={matchingHoldings?.map((h) => h.holding) || []}
    />
  );
};
