import { NewNavigation } from "../NewNavigation";

type PageLayoutProps = { children: React.ReactNode };

const PageLayout: React.FunctionComponent<PageLayoutProps> = ({ children }) => {
  return (
    <div>
      <NewNavigation />
      <main className="tw-bg-white">{children}</main>
    </div>
  );
};

export { PageLayout };
