import type { BasicShareRangeType } from "@capchapdev/rell-api";

import type { EntitiesMap } from "../../types/models/entities";
import type { Shareblock, ShareType } from "../../types/models/shares";
import { sumRanges } from "../../utils/shares";

type SortBy = "blockNumber-asc" | "blockNumber-desc" | "name-asc" | "name-desc";

const getTotalSharesByType = (blocks: BasicShareRangeType[]) => {
  const totalSharesByTypeMap = new Map<string, number>();
  blocks.reduce((result, block) => {
    const { type } = block;
    const totalBlocksForType = sumRanges([block]) + (result.get(type) || 0);
    result.set(type, totalBlocksForType);

    return result;
  }, totalSharesByTypeMap);

  const totalSharesByType: Record<string, number> =
    Object.fromEntries(totalSharesByTypeMap);

  return totalSharesByType;
};

const addSharesToSharesTypesData = (
  shareTypes: ShareType[],
  totalSharesByType: Record<string, number>
): (ShareType & { shares: number })[] => {
  return shareTypes.map((type) => ({
    ...type,
    shares: totalSharesByType[type.name] || 0,
  }));
};

const sortBlocks = (
  blocks: Shareblock[],
  key: SortBy,
  entitiesMap: EntitiesMap
): Shareblock[] => {
  const [sortBy, sortOrder] = key.split("-");
  const isAscending = sortOrder === "asc";
  if (sortBy === "blockNumber") {
    return blocks.sort((a, b) =>
      isAscending ? a.start - b.start : b.start - a.start
    );
  }

  return blocks.sort(
    (a, b) =>
      (isAscending ? 1 : -1) *
      // @ts-expect-error TODO: Prepare the data before rendering and use a fallback value (e.g. 'N/A')
      entitiesMap[a.holder.id].name.localeCompare(entitiesMap[b.holder.id].name)
  );
};

export { addSharesToSharesTypesData, getTotalSharesByType, sortBlocks };
export type { SortBy };
