import { CaretRight } from "@phosphor-icons/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Description } from "../../../components/design-system/Description";
import { CompanyInformation } from "../../../types/models/administration";
import { CompanyInvolvement } from "../../../types/models/company";
import { OptionsProgram } from "../../../types/models/options";
import { formatNumber } from "../../../utils/format";
import { clsxm } from "../../../utils/tailwind";

type OptionsListItemProps = {
  program: OptionsProgram;
  hasBorder: boolean;
  currentCompany: CompanyInformation | CompanyInvolvement;
  handleRowClick: (id: string) => void;
};

const OptionsListItem: React.FC<OptionsListItemProps> = ({
  program,
  hasBorder,
  handleRowClick,
}) => {
  const i18n = useTranslation();
  const programTypes = {
    Warrants: i18n.t("options.type.to.short"),
    EmployeeStockOptions: i18n.t("options.type.kpo.short"),
  };
  const {
    title,
    participants,
    totalAmountOfOptions,
    startDate,
    strikeEndDate,
    type,
    id,
  } = program;

  return (
    <>
      <tr
        key={id}
        className={clsxm("tw-cursor-pointer max-md:tw-hidden", {
          "tw-border-b": hasBorder,
        })}
        onClick={() => handleRowClick(id)}
      >
        <td className="tw-py-4 tw-pr-6">
          <Description
            title={title}
            description={`${participants?.length || 0} ${i18n.t(
              "options.participants.list"
            )} `}
          />
        </td>
        <td className="tw-px-6 tw-py-4">
          {formatNumber(totalAmountOfOptions || 0)}
        </td>
        <td className="tw-px-6 tw-py-4">{startDate}</td>
        <td className="tw-px-6 tw-py-4">{strikeEndDate}</td>
        <td className="tw-px-6 tw-py-4">{programTypes[type]}</td>
        <td className="tw-py-4">
          <CaretRight />
        </td>
      </tr>
      <button
        type="button"
        className="tw-relative tw-my-2 tw-flex tw-w-full tw-items-center tw-justify-between tw-rounded tw-p-4 tw-text-left md:tw-hidden"
        style={{
          boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.08)",
        }}
        onClick={() => handleRowClick(id)}
      >
        <Description
          title={title}
          titleWeight="medium"
          description={`${participants?.length || 0} ${i18n.t(
            "options.participants.list"
          )} `}
        />
        <CaretRight />
      </button>
    </>
  );
};

export default OptionsListItem;
