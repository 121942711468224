import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useUsersQuery } from "../../api/blockchain/users";
import { useAddBoardMemberMutation } from "../../api/rest/administration";
import type { NewBoardMember } from "../../types/models/users";
import { Button } from "../design-system/Button";
import { Dialog } from "../design-system/Dialog";
import {
  FormError,
  FormErrorList,
  FormGroup,
  FormLabel,
} from "../design-system/FormGroup";
import { Input } from "../design-system/Input";
import { SelectCountry } from "../SelectCountry";

const formId = "add-boardMember-form";

type AddBoardMemberDialogProps = {
  title: string;
  onClose: () => void;
  orgNumber: string;
  defaultValues: NewBoardMember;
};

const AddBoardMemberDialog = ({
  title,
  onClose,
  defaultValues,
  orgNumber,
}: AddBoardMemberDialogProps) => {
  const i18n = useTranslation();
  const usersQuery = useUsersQuery(orgNumber);

  const mutationOptions = {
    onSuccess: () => {
      onClose();
      usersQuery.refetch();
    },
  };

  const addBoardMemberMutation = useAddBoardMemberMutation(
    orgNumber,
    mutationOptions
  );

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onChange", defaultValues });

  const formValues = watch();

  useEffect(() => {
    if (formValues.countryCode === "SE") {
      if (formValues.passportNumber) {
        setValue("passportNumber", undefined);
      }
      if (formValues.birthDate) {
        setValue("birthDate", undefined);
      }
    } else if (formValues.refId) {
      setValue("refId", undefined);
    }
  }, [
    formValues.countryCode,
    setValue,
    formValues.passportNumber,
    formValues.birthDate,
    formValues.refId,
  ]);

  return (
    <Dialog
      isOpen
      title={title}
      onClose={onClose}
      isLoading={addBoardMemberMutation.isLoading}
      actions={
        <>
          <Button onClick={onClose} disabled={addBoardMemberMutation.isLoading}>
            {i18n.t("label.cancel")}
          </Button>
          <Button
            type="submit"
            variant="solid"
            color="primary"
            isLoading={addBoardMemberMutation.isLoading}
            form={formId}
          >
            {i18n.t("label.save")}
          </Button>
        </>
      }
    >
      <form
        className="tw-space-y-4"
        onSubmit={(event) =>
          handleSubmit((data) => addBoardMemberMutation.mutate(data))(event)
        }
        id={formId}
      >
        <FormGroup>
          <FormLabel htmlFor="countryCode">{i18n.t("label.country")}</FormLabel>
          <Controller
            control={control}
            name="countryCode"
            render={({ field: { onChange, value, name }, fieldState }) => (
              <>
                <SelectCountry name={name} value={value} onChange={onChange} />
                <FormError>{fieldState.error?.message}</FormError>
              </>
            )}
            rules={{ required: i18n.t("error.validation.required") }}
          />
        </FormGroup>
        {formValues.countryCode === "SE" && (
          <FormGroup>
            <FormLabel htmlFor="refId">{i18n.t("label.personId")}</FormLabel>
            <Input
              id="refId"
              {...register("refId", {
                validate: (value) => {
                  if (!value?.trim()) {
                    return i18n.t("error.validation.required");
                  }

                  return true;
                },
              })}
            />
            <FormError>{errors.refId?.message}</FormError>
          </FormGroup>
        )}
        {formValues.countryCode !== "SE" && (
          <>
            <FormGroup>
              <FormLabel htmlFor="passportNumber">
                {i18n.t("label.passportNumber")}
              </FormLabel>
              <Input
                id="passportNumber"
                {...register("passportNumber", {
                  validate: (value) => {
                    if (!value?.trim()) {
                      return i18n.t("error.validation.required");
                    }
                    return true;
                  },
                })}
              />
              <FormError>{errors.passportNumber?.message}</FormError>
            </FormGroup>
            <FormGroup>
              <FormLabel htmlFor="birthDate">
                {i18n.t("label.birthDate")}
              </FormLabel>
              <Input
                id="birthDate"
                placeholder="yyyyMMdd"
                {...register("birthDate", {
                  validate: (value) => {
                    const regex = /^\d{4}\d{2}\d{2}$/;
                    if (!value?.trim()) {
                      return i18n.t("error.validation.required");
                    }
                    if (!regex.test(value)) {
                      return "Invalid date format, expected yyyyMMdd";
                    }
                    return true;
                  },
                })}
              />
              <FormError>{errors.birthDate?.message}</FormError>
            </FormGroup>
          </>
        )}
        {addBoardMemberMutation.error && (
          <FormErrorList error={addBoardMemberMutation.error} />
        )}
      </form>
    </Dialog>
  );
};

export default AddBoardMemberDialog;
