import type {
  CollectAuthUser,
  GenerateQrCodeResponse,
} from "@capchapdev/auth-api";
import type {
  UseMutationOptions,
  UseQueryOptions,
} from "@tanstack/react-query";
import { useMutation, useQuery } from "@tanstack/react-query";

import { useSession } from "../../../context/session";
import type {
  BankIdAuthDetails,
  CollectAuth,
  CollectSign,
  NewSignatureRequest,
  QRCodeResponse,
} from "../../../types/models/auth";
import {
  BankIdAuthDetailsSchema,
  CollectAuthSchema,
  CollectSignSchema,
  QRCodeResponseSchema,
} from "../../../types/models/auth";
import getIsIOS from "../../../utils/getIsIOS";
import * as monitoring from "../../../utils/monitoring";
import type { IRequestError } from "../..";
import useClient, { NoContentSchema, URL } from "../client";

type CollectAuthResponse = {
  status: string;
  hintCode?: string | null;
  user?: CollectAuthUser;
  signature?: string | null;
};

const BANK_ID_RETURN_HASH = "bankidReturn";

const useInitAuthMutation = (
  options?: UseMutationOptions<BankIdAuthDetails, IRequestError, string>
) => {
  const client = useClient();
  return useMutation(async (pubKey: string): Promise<BankIdAuthDetails> => {
    const response = await client<BankIdAuthDetails>(`${URL.API}/Auth/Init`, {
      body: { pubKey },
    });

    const result = BankIdAuthDetailsSchema.safeParse(response);

    if (!result.success) {
      monitoring.captureException(result.error, {
        contexts: { response, result },
      });

      return response;
    }

    return result.data;
  }, options);
};

const useQRCodeQuery = (
  startToken: string,
  options?: UseQueryOptions<QRCodeResponse, IRequestError>
) => {
  const client = useClient();
  return useQuery<QRCodeResponse, IRequestError>(
    ["qrCode"],
    async (): Promise<QRCodeResponse> => {
      const response = await client<GenerateQrCodeResponse>(
        `${URL.API}/Auth/GenerateQrCode`,
        { body: { startToken } }
      );

      const result = QRCodeResponseSchema.safeParse(response);

      if (!result.success) {
        monitoring.captureException(result.error, {
          contexts: { response, result },
        });

        return response as QRCodeResponse;
      }

      return result.data;
    },
    options
  );
};

const useCancelAuthMutation = () => {
  const client = useClient();
  return useMutation(async (orderRef: string) => {
    const response = await client<undefined>(`${URL.API}/Auth/Cancel`, {
      body: { orderRef },
    });

    const result = NoContentSchema.safeParse(response);

    if (!result.success) {
      monitoring.captureException(result.error, {
        contexts: { response, result },
      });

      return response;
    }

    return result.data;
  });
};

const useCollectAuthMutation = (
  options?: UseMutationOptions<CollectAuth, IRequestError, string>
) => {
  const client = useClient();
  return useMutation(async (orderRef: string): Promise<CollectAuth> => {
    const response = await client<CollectAuthResponse>(
      `${URL.API}/Auth/Collect`,
      { body: { orderRef } }
    );

    const result = CollectAuthSchema.safeParse(response);

    if (!result.success) {
      monitoring.captureException(result.error, {
        contexts: { response, result },
      });

      return response as unknown as CollectAuth;
    }

    return result.data;
  }, options);
};

const useCollectSignQuery = (
  orderRef: string,
  options?: UseQueryOptions<CollectSign, IRequestError>
) => {
  const client = useClient({ hasAuth: true });
  return useQuery<CollectSign, IRequestError>(
    ["collectSign", orderRef],
    async (): Promise<CollectSign> => {
      const response = await client<CollectSign>(
        `${URL.API}/Auth/Sign/Collect`,
        { body: { orderRef }, method: "POST" }
      );

      const result = CollectSignSchema.safeParse(response);

      if (!result.success) {
        monitoring.captureException(result.error, {
          contexts: { response, result },
        });

        return response;
      }

      return result.data;
    },
    options
  );
};

const useInitSignMutation = (
  options?: UseMutationOptions<
    BankIdAuthDetails,
    IRequestError,
    NewSignatureRequest
  >
) => {
  const client = useClient({ hasAuth: true });
  const { user } = useSession();

  return useMutation(
    ["initSign", "BankID", user?.refId],
    async (body: NewSignatureRequest): Promise<BankIdAuthDetails> => {
      const response = await client<BankIdAuthDetails>(
        `${URL.API}/Auth/InitSignNewApprovalProposal`,
        {
          body,
        }
      );

      const result = BankIdAuthDetailsSchema.safeParse(response);

      if (!result.success) {
        monitoring.captureException(result.error, {
          contexts: { response, result },
        });

        return response;
      }

      return result.data;
    },
    options
  );
};

const getRedirectUrl = () => {
  const isIOS = getIsIOS();
  if (!isIOS) {
    return null;
  }

  const isChrome = window.navigator.userAgent.includes("CriOS");
  if (isChrome) {
    return encodeURIComponent("googlechrome://");
  }

  const isFirefox = window.navigator.userAgent.includes("FxiOS");
  if (isFirefox) {
    return encodeURIComponent("firefox://");
  }

  const isOpera = window.navigator.userAgent.includes("OPT");
  if (isOpera) {
    return encodeURIComponent(
      `${window.location.origin.replace("http", "touch-http")}${
        window.location.pathname
      }`
    );
  }
  return encodeURIComponent(
    `${window.location.origin}${window.location.pathname}#${BANK_ID_RETURN_HASH}`
  );
};

const getOpenBankIdUri = (autoStartToken: string) => {
  const redirectUrl = getRedirectUrl();
  return `bankid:///?autostarttoken=${autoStartToken}&redirect=${redirectUrl}`;
};

export default {
  useCollectSignQuery,
  useCancelAuthMutation,
  useCollectAuthMutation,
  useInitAuthMutation,
  useQRCodeQuery,
  useInitSignMutation,
  getOpenBankIdUri,
  BANK_ID_RETURN_HASH,
};

export type { CollectAuthResponse };
