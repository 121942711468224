import { z } from "zod";

const StatusSchema = z.enum(["approved", "pending"]);
type Status = z.infer<typeof StatusSchema>;

const KeySchema = z.string().or(z.number());
const ProgressItemSchema = z.object({
  key: KeySchema,
  name: z.string(),
  status: StatusSchema,
  isCurrentUser: z.boolean(),
});
type ProgressItem = z.infer<typeof ProgressItemSchema>;

export type { ProgressItem, Status };
export { ProgressItemSchema };
