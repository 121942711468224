export const downloadBlob = (blob: Blob, filename: string) => {
  const url = URL.createObjectURL(blob);

  // Create a temporary anchor element and trigger the download
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();

  // Clean up
  a.remove();
  URL.revokeObjectURL(url);
};
