import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import { AddEventDrawer } from "../../pages/CompanyShares/AddEvent";
import { CompanyInformation } from "../../types/models/administration";
import { LoggedInUser } from "../../types/models/auth";
import { CompanyInvolvement } from "../../types/models/company";
import { LedgerRollbackPending } from "../../types/models/events";
import { LedgerVersion } from "../../types/models/shares";
import { ValueLabelPair } from "../../utils/events-utils";
import { hasRequiredPermission } from "../../utils/permissions";
import { clsxm } from "../../utils/tailwind";
import { Button } from "../design-system/Button/Button";
import { PrinterIcon } from "../design-system/icons";
import { Menu } from "../design-system/Menu/Menu";
import { TooltipV2 } from "../design-system/Tooltip/TooltipV2";
import { StartApproval } from "../History/Actions";

type ApprovalAction = "approve" | "initiate" | "reset" | "restore";

type EventActionsBarProps = {
  shouldShowInitiateApprovalButton?: boolean;
  shouldShowApproveButton?: boolean;
  shouldShowRejectButton?: boolean;
  shouldDisableInitiateApprovalButton: boolean;
  shouldDisableApproveButton: boolean;
  versionToApprove: ValueLabelPair | null;
  setStartApprovalLoading: (value: boolean) => void;
  isApprovalPolicyPending: boolean;
  rejectLedgerApprovalMutation: {
    isLoading: boolean;
    mutate: () => void;
  };
  rejectAction?: () => void;
  rollbackPending: LedgerRollbackPending;
  setCurrentAction: React.Dispatch<
    React.SetStateAction<ApprovalAction | undefined>
  >;
  currentCompany: CompanyInvolvement | CompanyInformation;
  currentVersion: LedgerVersion;
  user?: LoggedInUser;
};

const EventActionsBar: React.FC<EventActionsBarProps> = ({
  shouldShowInitiateApprovalButton,
  shouldShowApproveButton,
  shouldShowRejectButton,
  shouldDisableInitiateApprovalButton,
  shouldDisableApproveButton,
  versionToApprove,
  setStartApprovalLoading,
  isApprovalPolicyPending,
  rejectLedgerApprovalMutation,
  rollbackPending,
  setCurrentAction,
  currentCompany,
  currentVersion,
  user,
}) => {
  const i18n = useTranslation();
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 768px)",
  });

  return (
    <div
      id="mobile"
      className="tw-z-45 tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-flex tw-gap-2 tw-border-t-[1px] tw-bg-white tw-p-4 sm:tw-hidden"
    >
      {hasRequiredPermission("Editor", currentCompany, user) && (
        <div className="tw-flex tw-w-full tw-items-center tw-justify-stretch tw-gap-2 max-md:tw-grid">
          <div
            className={clsxm("tw-space-y-2", {
              "tw-flex tw-items-center tw-gap-2":
                shouldShowInitiateApprovalButton !== shouldShowApproveButton &&
                !shouldShowRejectButton,
            })}
          >
            <div
              className={clsxm("tw-flex tw-w-full tw-flex-col tw-gap-2", {
                "tw-flex-grow":
                  shouldShowInitiateApprovalButton !== shouldShowApproveButton,
              })}
            >
              {shouldShowInitiateApprovalButton && (
                <StartApproval
                  orgNumber={currentCompany.orgNumber}
                  currentVersion={currentVersion}
                  versionToApprove={versionToApprove}
                  setIsLoading={setStartApprovalLoading}
                  disabled={
                    shouldDisableInitiateApprovalButton ||
                    isApprovalPolicyPending
                  }
                  placement="top"
                  showPanel={isTabletOrMobileDevice}
                  setCurrentAction={setCurrentAction}
                  className="tw-ml-10"
                />
              )}
              {shouldShowApproveButton && (
                <TooltipV2
                  content={
                    shouldDisableApproveButton
                      ? i18n.t("approvalPolicy.youHaveAlreadyApproved")
                      : null
                  }
                >
                  <Button
                    className="tw-w-full"
                    color="primary"
                    disabled={shouldDisableApproveButton}
                    variant="solid"
                    onClick={() => {
                      setCurrentAction("approve");
                    }}
                  >
                    {i18n.t(
                      rollbackPending
                        ? "label.approveRollback"
                        : "label.approvePending"
                    )}
                  </Button>
                </TooltipV2>
              )}
              {shouldShowRejectButton && (
                <Button
                  className="tw-mb-2 tw-w-full"
                  color="primary"
                  variant="outline"
                  isLoading={rejectLedgerApprovalMutation.isLoading}
                  onClick={() => {
                    rejectLedgerApprovalMutation.mutate();
                  }}
                >
                  {i18n.t(
                    rollbackPending
                      ? "label.rejectRollback"
                      : "label.rejectPending"
                  )}
                </Button>
              )}
            </div>
            <div
              className="tw-flex tw-items-center tw-gap-2"
              style={{ marginTop: "0px" }}
            >
              <div className="tw-flex-shrink-0">
                <Menu>
                  <Menu.Button
                    type="button"
                    className="tw-rounded tw-px-4 tw-py-2"
                    isDropdown={false}
                    onClick={() => window.print()}
                  >
                    <PrinterIcon className="tw-h-6 tw-w-6" />
                  </Menu.Button>
                </Menu>
              </div>
              <div
                className={clsxm({
                  "tw-flex-grow":
                    (!shouldShowInitiateApprovalButton &&
                      !shouldShowApproveButton) ||
                    (shouldShowInitiateApprovalButton &&
                      shouldShowApproveButton) ||
                    shouldShowRejectButton,
                })}
              >
                <AddEventDrawer
                  disabled={!!rollbackPending}
                  isDropdown={
                    (shouldShowInitiateApprovalButton !==
                      shouldShowApproveButton && !shouldShowRejectButton) ===
                    false
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export { EventActionsBar };
