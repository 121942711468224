import { useEffect, useRef } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { useRenewSessionQuery } from "../api/blockchain/auth";
import { AccountProvider } from "../context/account";
import { getOrderRefFromStorage, useSession } from "../context/session";
import { APP_ROUTE } from "./constants";

const usePrevious: <T>(value: T) => T = (value) => {
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const Private = () => {
  const { keyPair, onSignout } = useSession();
  const renewSessionQuery = useRenewSessionQuery({
    enabled: false,
    onSuccess: (data) => {
      const orderRefInStorage = getOrderRefFromStorage();
      if (!data && !orderRefInStorage) {
        onSignout();
      }
    },
  });
  const location = useLocation();
  const previousLocation = usePrevious(location);
  const isLoggedIn = !!keyPair;

  useEffect(() => {
    const orderRefInStorage = getOrderRefFromStorage();
    if (!renewSessionQuery.isFetching && !orderRefInStorage && isLoggedIn) {
      renewSessionQuery.refetch();
    }
  }, [
    location,
    isLoggedIn,
    renewSessionQuery.refetch,
    renewSessionQuery.isFetched,
    previousLocation?.pathname,
  ]);

  const signedOut = previousLocation.key !== "default";

  return isLoggedIn ? (
    <AccountProvider>
      <Outlet />
    </AccountProvider>
  ) : (
    <Navigate
      to={
        signedOut || location.pathname === "/"
          ? APP_ROUTE.SIGN_IN
          : `${APP_ROUTE.SIGN_IN}?redirect_url=${location.pathname}`
      }
    />
  );
};

export default Private;
