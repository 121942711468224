import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useUpdateShareBlocksMutation } from "../../../api/rest/draft";
import { useEntitiesQuery } from "../../../api/rest/entities";
import { Actions } from "../../../components/Actions";
import { Button } from "../../../components/design-system/Button";
import { Dialog } from "../../../components/design-system/Dialog";
import {
  FormError,
  FormErrorList,
} from "../../../components/design-system/FormGroup";
import { DistributionProgress } from "../../../components/DistributionProgress";
import { ShareBlocksList } from "../../../components/ShareBlocks/List";
import type { CompanyInformation } from "../../../types/models/administration";
import type { CompanyInvolvement } from "../../../types/models/company";
import { calcSumWithinRange } from "../../../utils/shares";
import { draftName } from "./constants";
import type { FormChangeEvent, FormState } from "./types";

type ShareBlocksProps = {
  currentCompany: CompanyInvolvement | CompanyInformation;
  initialData: FormState;
  onFormChange: FormChangeEvent;
  onSuccess: () => void;
  onCancel: () => void;
  isLoading?: boolean;
};

const formId = "onboarding-shareblocks-form";

const ShareBlocks = ({
  currentCompany,
  onSuccess,
  onCancel,
  initialData,
  onFormChange,
  isLoading,
}: ShareBlocksProps) => {
  const i18n = useTranslation();
  const [showShareRangeWarning, setShowShareRangeWarning] = useState(false);
  const { control, handleSubmit, watch, setError } = useForm<{
    value: FormState["shareBlocks"];
  }>({ defaultValues: { value: initialData.shareBlocks }, mode: "onChange" });
  const entitiesQuery = useEntitiesQuery(currentCompany.orgNumber);
  const updateShareBlocksMutation = useUpdateShareBlocksMutation(
    currentCompany.orgNumber,
    draftName,
    {
      onSuccess: (_, formData) => {
        onFormChange({ shareBlocks: formData });
        onSuccess();
      },
    }
  );

  const { value: formValues } = watch();

  const shareClasses = initialData.shareClasses.filter(
    (type) => type.numberOfShares > 0
  );

  const diffList = shareClasses.map((shareType) => {
    const blocksForType = formValues.filter(
      (block) => block.type === shareType.name
    );
    const totalSharesInType = blocksForType.reduce(
      (prev, curr) => prev + calcSumWithinRange(curr),
      0
    );

    return {
      name: shareType.name,
      value: totalSharesInType - shareType.numberOfShares,
    };
  });

  const isValid = diffList.every((diff) => diff.value === 0);
  const onSubmit = (data: { value: FormState["shareBlocks"] }) => {
    if (!isValid) {
      setError("value", {
        type: "manual",
        message: i18n.t("error.verification.shares.issue"),
      });
      return;
    }
    updateShareBlocksMutation.mutate(data.value);
  };

  const isSaving = updateShareBlocksMutation.isLoading || isLoading;

  return (
    <form
      id={formId}
      onSubmit={handleSubmit(onSubmit)}
      className="tw-space-y-6"
    >
      <Controller
        control={control}
        name="value"
        render={({ field: { onChange, value }, fieldState }) => (
          <>
            <ShareBlocksList
              value={value}
              onChange={onChange}
              entitiesQuery={entitiesQuery}
              currentCompany={currentCompany}
              shareTypes={shareClasses}
            />
            <FormError>{fieldState.error?.message}</FormError>
          </>
        )}
        rules={{ required: i18n.t("error.validation.required") }}
      />
      <div className="tw-space-y-1">
        {diffList.map((diff) => (
          <DistributionProgress
            key={diff.name}
            diff={diff.value}
            title={diff.name}
          />
        ))}
      </div>
      {updateShareBlocksMutation.error && (
        <FormErrorList error={updateShareBlocksMutation.error} />
      )}
      <Actions>
        <Button onClick={onCancel}>{i18n.t("label.back")}</Button>
        {formValues.length > 1 ? (
          <Button
            variant="solid"
            color="primary"
            type="button"
            onClick={() => setShowShareRangeWarning(true)}
          >
            {i18n.t("label.save")}
          </Button>
        ) : (
          <Button
            isLoading={isSaving}
            variant="solid"
            color="primary"
            type="submit"
            form={formId}
          >
            {i18n.t("label.save")}
          </Button>
        )}
      </Actions>
      {showShareRangeWarning && (
        <Dialog
          isOpen
          title={i18n.t("events.issue.ranges.warning.title")}
          onClose={() => setShowShareRangeWarning(false)}
          isLoading={isLoading}
          actions={
            <>
              <Button
                onClick={() => setShowShareRangeWarning(false)}
                disabled={isLoading}
              >
                {i18n.t("label.cancel")}
              </Button>
              <Button
                type="submit"
                variant="solid"
                color="primary"
                isLoading={isLoading}
                form={formId}
              >
                {i18n.t("label.continue")}
              </Button>
            </>
          }
        >
          {i18n.t("events.issue.ranges.warning.description")}
        </Dialog>
      )}
    </form>
  );
};

export default ShareBlocks;
