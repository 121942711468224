import { useTranslation } from "react-i18next";

import { useUserConfirmedLedgerAsOfficial } from "../../../../../api/blockchain/officialLedger";
import {
  useApprovalRuleProposalQuery,
  useUsersQuery,
} from "../../../../../api/blockchain/users";
import { useEntitiesQuery } from "../../../../../api/rest/entities";
import { Loading } from "../../../../../components/design-system/Loading";
import { useSession } from "../../../../../context/session";
import type { CompanyInformation } from "../../../../../types/models/administration";
import type { CompanyInvolvement } from "../../../../../types/models/company";
import { captureException } from "../../../../../utils/monitoring";
import type { ApprovalPolicyProgressProps } from "./ApprovalPolicy";
import { ApprovalPolicy } from "./ApprovalPolicy";
import { getApprovalPolicyProgressProps } from "./ApprovalPolicyPage.utils";
import { LedgerConfirmedAsOfficial } from "./LedgerConfirmedAsOfficial";

type ApprovalPolicyContainerProps = {
  currentCompany: CompanyInvolvement | CompanyInformation;
};

const ApprovalPolicyPage: React.FunctionComponent<
  ApprovalPolicyContainerProps
> = ({ currentCompany }) => {
  const i18n = useTranslation();
  const entitiesQuery = useEntitiesQuery(currentCompany.orgNumber);
  const usersQuery = useUsersQuery(currentCompany.orgNumber);
  const userApprovedLedgerAsOfficialQuery = useUserConfirmedLedgerAsOfficial(
    currentCompany.orgNumber
  );

  const approvalRuleProposal = useApprovalRuleProposalQuery(
    currentCompany.orgNumber
  );
  const { user } = useSession();

  if (
    approvalRuleProposal.isLoading ||
    entitiesQuery.isLoading ||
    usersQuery.isLoading ||
    userApprovedLedgerAsOfficialQuery.isLoading ||
    !user
  ) {
    return <Loading />;
  }

  if (
    approvalRuleProposal.isError ||
    entitiesQuery.isError ||
    userApprovedLedgerAsOfficialQuery.isError ||
    usersQuery.isError
  ) {
    return <div>{i18n.t("error.general")}</div>;
  }

  let approvalPolicyProgressProps: ApprovalPolicyProgressProps | undefined;
  try {
    approvalPolicyProgressProps = getApprovalPolicyProgressProps(
      approvalRuleProposal.data,
      entitiesQuery.data,
      usersQuery.data,
      user.id
    );
  } catch (err) {
    captureException(err, {
      contexts: {
        arguments: {
          approvalRuleProposal: approvalRuleProposal.data,
          entitiesQuery: entitiesQuery.data,
          user: user.id,
        },
      },
    });
  }

  if (approvalPolicyProgressProps === undefined) {
    return <div>{i18n.t("error.general")}</div>;
  }

  return (
    <div className="tw-mx-auto tw-max-w-xl">
      <ApprovalPolicy
        currentCompany={currentCompany}
        boardShouldApproveLedgerAsOfficial={
          currentCompany.confirmedAsOfficial.status !== "Completed"
        }
        {...approvalPolicyProgressProps}
      />
      {currentCompany.confirmedAsOfficial.status === "Completed" && (
        <LedgerConfirmedAsOfficial
          lastUpdated={currentCompany.confirmedAsOfficial.lastUpdate}
        />
      )}
    </div>
  );
};

export { ApprovalPolicyPage };
