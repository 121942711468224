import type {
  GETDraftingShareClassesOrgNumberDraftNameResponse,
  GETDraftingShareRangesOrgNumberDraftNameResponse,
  ShareCapitalDto,
} from "@capchapdev/admin-api";
import type { UseMutationOptions } from "@tanstack/react-query";
import { useMutation, useQuery } from "@tanstack/react-query";

import type {
  DraftShareTypes,
  NewShareCapital,
  ShareCapital,
  TDraftShareBlock,
  TDraftShareType,
} from "../../types/models/draft";
import {
  DraftShareBlocksSchema,
  DraftShareTypesSchema,
  ShareCapitalSchema,
} from "../../types/models/draft";
import * as monitoring from "../../utils/monitoring";
import type { IRequestError } from "..";
import type { UseQueryOptions } from "../types";
import useClient, { NoContentSchema, URL } from "./client";

const getCompanyCapital = async (
  orgNumber: string,
  draftName: string
): Promise<ShareCapital> => {
  const client = useClient({ hasAuth: true });

  const response = await client<ShareCapitalDto>(
    `${URL.ADMIN}/Drafting/ShareCapital/${orgNumber}/${draftName}`
  );

  const result = ShareCapitalSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { response, result },
    });

    return response as ShareCapital;
  }

  return result.data;
};

const postCompanyCapital = async (
  orgNumber: string,
  draftName: string,
  data: NewShareCapital
) => {
  const client = useClient({ hasAuth: true });

  const response = await client<undefined>(
    `${URL.ADMIN}/Drafting/ShareCapital/${orgNumber}/${draftName}`,
    { body: data }
  );

  const result = NoContentSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { response, result },
    });

    return response;
  }

  return result.data;
};

const getCompanyClasses = async (
  orgNumber: string,
  draftName: string
): Promise<TDraftShareType[]> => {
  const client = useClient({ hasAuth: true });

  const response =
    await client<GETDraftingShareClassesOrgNumberDraftNameResponse>(
      `${URL.ADMIN}/Drafting/ShareClasses/${orgNumber}/${draftName}`
    );

  const result = DraftShareTypesSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { data: { response }, result },
    });

    return response as DraftShareTypes;
  }

  return result.data;
};

const postCompanyClasses = async (
  orgNumber: string,
  draftName: string,
  shareClasses: TDraftShareType[]
) => {
  const client = useClient({ hasAuth: true });

  const response = await client<undefined>(
    `${URL.ADMIN}/Drafting/ShareClasses/${orgNumber}/${draftName}`,
    { body: { shareClasses } }
  );

  const result = NoContentSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { response, result },
    });

    return response;
  }

  return result.data;
};

const getShareBlocks = async (
  orgNumber: string,
  draftName: string
): Promise<TDraftShareBlock[]> => {
  const client = useClient({ hasAuth: true });

  const response =
    await client<GETDraftingShareRangesOrgNumberDraftNameResponse>(
      `${URL.ADMIN}/Drafting/ShareRanges/${orgNumber}/${draftName}`
    );

  const result = DraftShareBlocksSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { data: { response }, result },
    });

    return response as TDraftShareBlock[];
  }

  return result.data;
};

const fetchDraftOnboarding = (
  orgNumber: string,
  draftName: string
): Promise<[ShareCapital, TDraftShareType[], TDraftShareBlock[]]> =>
  Promise.all([
    getCompanyCapital(orgNumber, draftName),
    getCompanyClasses(orgNumber, draftName),
    getShareBlocks(orgNumber, draftName),
  ]);

const useDraftOnboardingQuery = (
  orgNumber: string,
  draftName: string,
  options?: UseQueryOptions<
    unknown,
    IRequestError,
    [ShareCapital, TDraftShareType[], TDraftShareBlock[]],
    string[]
  >
) =>
  useQuery(
    [draftName, orgNumber],
    () => fetchDraftOnboarding(orgNumber, draftName),
    {
      enabled:
        options?.enabled !== undefined
          ? options.enabled
          : !!orgNumber && !!draftName,
      ...options,
    }
  );

const postCompanyShareBlocks = async (
  orgNumber: string,
  draftName: string,
  shareRanges: TDraftShareBlock[]
) => {
  const client = useClient({ hasAuth: true });

  const response = await client<undefined>(
    `${URL.ADMIN}/Drafting/ShareRanges/${orgNumber}/${draftName}`,
    { body: { shareRanges } }
  );

  const result = NoContentSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { response, result },
    });

    return response;
  }

  return result.data;
};

const useUpdateCapitalMutation = (
  orgNumber: string,
  draftName: string,
  options?: UseMutationOptions<unknown, IRequestError, NewShareCapital>
) =>
  useMutation<unknown, IRequestError, NewShareCapital>(
    (data) => postCompanyCapital(orgNumber, draftName, data),
    options
  );

const useUpdateShareClassesMutation = (
  orgNumber: string,
  draftName: string,
  options?: UseMutationOptions<unknown, IRequestError, TDraftShareType[]>
) =>
  useMutation<unknown, IRequestError, TDraftShareType[]>(
    (data) =>
      postCompanyClasses(
        orgNumber,
        draftName,
        data.filter((type) => type.numberOfShares > 0) // TODO: Api may change here
      ),
    options
  );

const useUpdateShareBlocksMutation = (
  orgNumber: string,
  draftName: string,
  options?: UseMutationOptions<unknown, IRequestError, TDraftShareBlock[]>
) =>
  useMutation<unknown, IRequestError, TDraftShareBlock[]>(
    (data) => postCompanyShareBlocks(orgNumber, draftName, data),
    options
  );

export {
  useDraftOnboardingQuery,
  useUpdateCapitalMutation,
  useUpdateShareBlocksMutation,
  useUpdateShareClassesMutation,
};
