import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import { useShareblocksQuery } from "../../../api/blockchain/company";
import { useEntitiesQuery } from "../../../api/rest/entities";
import { useShareCertificatesUpdateMutation } from "../../../api/rest/events";
import {
  FormError,
  FormErrorList,
  FormGroup,
  FormLabel,
} from "../../../components/design-system/FormGroup";
import { CrossIcon } from "../../../components/design-system/icons";
import { Input } from "../../../components/design-system/Input";
import { notify } from "../../../components/design-system/Notifications";
import { PageWrapper } from "../../../components/PageWrapper";
import { SelectShareCertificates } from "../../../components/SelectShareCertificates";
import useLatestVersion from "../../../hooks/useLatestVersion";
import { APP_ROUTE } from "../../../routes/constants";
import type { CompanyInformation } from "../../../types/models/administration";
import type { CompanyInvolvement } from "../../../types/models/company";
import type { Shareblock } from "../../../types/models/shares";
import { dateToIsoString } from "../../../utils/date";
import FormActions from "../FormActions";

type Props = { currentCompany: CompanyInvolvement | CompanyInformation };

type FormProps = { date: string; shareRanges: Shareblock[] };

const shareCertificatesUpdateFormId = "share-certificates-update-form";

const ShareCertificateUpdate = ({ currentCompany }: Props) => {
  const eventsPath = `${APP_ROUTE.COMPANIES}/${currentCompany.orgNumber}/events`;
  const i18n = useTranslation();
  const navigate = useNavigate();
  const form = useForm<FormProps>({
    mode: "onChange",
    defaultValues: {
      shareRanges: [],
    },
  });
  const shareBlocksQuery = useShareblocksQuery(currentCompany.orgNumber, "", {
    onSuccess: (data) => {
      form.setValue(
        "shareRanges",
        data.filter((block) => !!block.hasCertificateSince)
      );
    },
  });
  const shareBlockOptions = (shareBlocksQuery.data || []).filter(
    (block) => !block.cancelled
  );
  const lastEventDate = useLatestVersion();
  const entitiesQuery = useEntitiesQuery(currentCompany.orgNumber);
  const entitiesData = entitiesQuery.data || [];
  const entitiesMap = Object.fromEntries(entitiesData.map((e) => [e.id, e]));
  const ledgerHasCert = shareBlockOptions.some(
    (block) => !!block.hasCertificateSince
  );

  const certifiedShareBlocks = shareBlockOptions.filter(
    (blocks) => blocks.hasCertificateSince !== null
  );
  const selectedShareBlocks = form.watch("shareRanges");

  const areSelectedSharesCertified =
    selectedShareBlocks.length === certifiedShareBlocks.length &&
    selectedShareBlocks.every((selectedShare) =>
      certifiedShareBlocks.some(
        (certifiedShare) =>
          certifiedShare.start === selectedShare.start &&
          certifiedShare.end === selectedShare.end
      )
    );

  const handleSuccess = () => {
    notify(
      i18n.t("events.success", {
        name: i18n.t("events.shareCertificateUpdate.title"),
      }),
      { type: "success" }
    );
    navigate(eventsPath);
  };

  const mutation = useShareCertificatesUpdateMutation(
    currentCompany.orgNumber,
    { onSuccess: handleSuccess }
  );

  const handleSubmit = (data: FormProps) => {
    if (!ledgerHasCert && selectedShareBlocks.length === 0) {
      form.setError("shareRanges", {
        type: "manual",
        message: i18n.t("error.verification.certificate.none"),
      });
      return;
    }
    if (areSelectedSharesCertified) {
      form.setError("shareRanges", {
        type: "manual",
        message: i18n.t("error.verification.certificate.alreadyIssued"),
      });
      return;
    }
    mutation.mutate({
      date: data.date,
      shareRanges: data.shareRanges.map((range) => ({
        start: range.start,
        end: range.end,
      })),
    });
  };

  const selectedShareTypes = useMemo(() => {
    return selectedShareBlocks.map((block) => block.type);
  }, [selectedShareBlocks]);

  return (
    <PageWrapper data-testid="share-certificate-update">
      <header className="tw-flex tw-justify-between tw-pb-6">
        <div>
          <h4>{i18n.t("events.shareCertificateUpdate.title")}</h4>
        </div>
        <Link to={eventsPath} className="tw-text-body">
          <CrossIcon className="tw-h-6 tw-w-6" />
        </Link>
      </header>
      <form
        id={shareCertificatesUpdateFormId}
        className="tw-h-96 tw-space-y-6"
        onSubmit={form.handleSubmit(handleSubmit)}
      >
        <FormGroup>
          <FormLabel htmlFor="date">{i18n.t("label.date")}</FormLabel>
          <Controller
            control={form.control}
            render={({ field: { ref, name, onChange, value }, fieldState }) => (
              <>
                <Input
                  id="date"
                  value={value}
                  ref={ref}
                  name={name}
                  onChange={onChange}
                  type="date"
                  className="tw-w-full"
                  max={dateToIsoString(new Date())}
                  min={lastEventDate && dateToIsoString(lastEventDate.date)}
                />
                <FormError>{fieldState.error?.message}</FormError>
              </>
            )}
            name="date"
            rules={{ required: i18n.t("error.validation.required") }}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel htmlFor="shareRanges">
            {i18n.t("shares.certificates")}
          </FormLabel>
          <Controller
            name="shareRanges"
            control={form.control}
            render={({ field: { onChange, value }, fieldState }) => (
              <>
                <SelectShareCertificates
                  options={shareBlockOptions}
                  value={value}
                  onChange={onChange}
                  entitiesMap={entitiesMap}
                />
                <FormError>{fieldState.error?.message}</FormError>
              </>
            )}
          />
        </FormGroup>
        {mutation.error && <FormErrorList error={mutation.error} />}
        <FormActions
          formId={shareCertificatesUpdateFormId}
          isLoading={mutation.isLoading}
          orgNumber={currentCompany.orgNumber}
          selectedShareTypes={selectedShareTypes}
          hasError={Object.keys(form.formState.errors).length > 0}
        />
      </form>
    </PageWrapper>
  );
};

export default ShareCertificateUpdate;
