import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useUpdateCapitalMutation } from "../../../api/rest/draft";
import { Actions } from "../../../components/Actions";
import { Button } from "../../../components/design-system/Button";
import {
  FormError,
  FormErrorList,
  FormGroup,
  FormLabel,
} from "../../../components/design-system/FormGroup";
import { Input } from "../../../components/design-system/Input";
import useLatestVersion from "../../../hooks/useLatestVersion";
import type { CompanyInformation } from "../../../types/models/administration";
import type { CompanyInvolvement } from "../../../types/models/company";
import { dateToIsoString } from "../../../utils/date";
import { draftName } from "./constants";
import type { FormChangeEvent, FormState } from "./types";

type InvestmentAmountProps = {
  currentCompany: CompanyInvolvement | CompanyInformation;
  initialData: FormState;
  onFormChange: FormChangeEvent;
  onSuccess: () => void;
};

const InvestmentAmount = ({
  currentCompany,
  initialData,
  onFormChange,
  onSuccess,
}: InvestmentAmountProps) => {
  const { register, handleSubmit, formState, control } = useForm({
    mode: "onChange",
    defaultValues: initialData.shareCapital,
  });
  const { t } = useTranslation();
  const lastEventDate = useLatestVersion();
  const updateCapitalMutation = useUpdateCapitalMutation(
    currentCompany.orgNumber,
    draftName,
    {
      onSuccess: (_, formData) => {
        onFormChange({ shareCapital: formData });
        onSuccess();
      },
    }
  );
  const onSubmit = (data: FormState["shareCapital"]) => {
    updateCapitalMutation.mutate(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="tw-space-y-6">
      <FormGroup>
        <FormLabel htmlFor="formationDate">{t("label.date")}</FormLabel>
        <Controller
          control={control}
          render={({ field: { ref, name, onChange, value }, fieldState }) => (
            <>
              <Input
                id="formationDate"
                value={value}
                ref={ref}
                name={name}
                onChange={onChange}
                type="date"
                className="tw-w-full"
                max={dateToIsoString(new Date())}
                min={lastEventDate && dateToIsoString(lastEventDate.date)}
              />
              <FormError>{fieldState.error?.message}</FormError>
            </>
          )}
          name="formationDate"
          rules={{ required: t("error.validation.required") }}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel htmlFor="capitalAmount" isOptional>
          {t("label.investmentAmount")}
        </FormLabel>
        <div className="tw-flex tw-space-x-1">
          <Input
            id="capitalAmount"
            {...register("capitalAmount", {
              valueAsNumber: true,
              min: {
                value: 0.000001,
                message: t("error.validation.range.min", { min: 0.000001 }),
              },
            })}
            className="tw-flex-1"
            type="number"
            step={0.000001}
            prefix={currentCompany.settings?.currency}
          />
        </div>
        <FormError>{formState.errors.capitalAmount?.message}</FormError>
      </FormGroup>
      <FormGroup>
        <FormLabel htmlFor="totalShares">
          {t("label.totalNrOfShares")}
        </FormLabel>
        <Input
          id="totalShares"
          {...register("totalShares", {
            required: t("error.validation.required"),
            valueAsNumber: true,
            min: {
              value: 1,
              message: t("error.validation.range.min", { min: 1 }),
            },
          })}
          type="number"
          step={1}
        />
        <FormError>{formState.errors.totalShares?.message}</FormError>
      </FormGroup>
      {updateCapitalMutation.error && (
        <FormErrorList error={updateCapitalMutation.error} />
      )}
      <Actions>
        <Button
          isLoading={updateCapitalMutation.isLoading}
          variant="solid"
          color="primary"
          type="submit"
        >
          {t("label.continue")}
        </Button>
      </Actions>
    </form>
  );
};

export default InvestmentAmount;
