import type React from "react";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";

import type { IRequestError } from "../../../api";
import { clsxm } from "../../../utils/tailwind";
import type { AlertProps } from "../Alert";
import { Alert } from "../Alert";

type FormLabelProps = React.HTMLProps<HTMLLabelElement> & {
  isOptional?: boolean;
  secondaryLabel?: string;
};

const FormLabel = forwardRef(
  (
    {
      children,
      isOptional,
      secondaryLabel,
      className,
      ...props
    }: FormLabelProps,
    ref: React.Ref<HTMLLabelElement>
  ) => {
    const i18n = useTranslation();

    let label = secondaryLabel;
    if (isOptional) {
      label = i18n.t("label.optional");
    }

    return (
      <label
        ref={ref}
        className={clsxm(
          "tw-block tw-text-sm tw-font-medium tw-text-gray-700",
          className
        )}
        {...props}
      >
        {children}
        {label && <span className="tw-ml-2 tw-text-secondary">{label}</span>}
      </label>
    );
  }
);

const FormError = (props: AlertProps) =>
  props.children ? (
    <Alert
      type="error"
      size="sm"
      contentClassname="tw-text-error"
      data-testid="form-error"
      {...props}
    />
  ) : null;

const FormHint = ({ children }: { children: React.ReactNode }) => (
  <div className="tw-text-xs tw-text-secondary">{children}</div>
);

const FormSection = ({
  title,
  children,
}: {
  title: React.ReactNode;
  children: React.ReactNode;
}) => (
  <div className="tw-space-y-3">
    <h5 className="tw-font-semibold tw-text-body">{title}</h5>
    {children}
  </div>
);

const FormErrorList = ({
  error,
  className,
}: {
  error: IRequestError;
  className?: string;
}) => {
  const i18n = useTranslation();
  const { errors } = error;

  return (
    <div
      className={clsxm(
        "tw-space-y-1 tw-rounded tw-border tw-border-error tw-p-2",
        className
      )}
    >
      {errors ? (
        errors.length > 1 ? (
          <Alert type="error" className="tw-gap-2">
            <ul className="tw-list-disc tw-pl-4">
              {errors.map((e, i) => (
                // eslint-disable-next-line
                <li key={i}>{i18n.t(e.message.code)}</li>
              ))}
            </ul>
          </Alert>
        ) : (
          <Alert type="error">{i18n.t(errors[0].message.code)}</Alert>
        )
      ) : (
        <Alert type="error">{i18n.t("error.general")}</Alert>
      )}
    </div>
  );
};

const FormGroup = forwardRef(
  (
    { className, ...props }: React.HTMLAttributes<HTMLDivElement>,
    ref: React.Ref<HTMLDivElement>
  ) => (
    <div
      ref={ref}
      className={clsxm("tw-space-y-2", className)}
      role="group"
      {...props}
    />
  )
);

export type { FormLabelProps };
export {
  FormError,
  FormErrorList,
  FormGroup,
  FormHint,
  FormLabel,
  FormSection,
};
