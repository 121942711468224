import { useState } from "react";
import { useTranslation } from "react-i18next";

import type { TDraftShareType } from "../../../types/models/draft";
import type { Color, Variant } from "../../design-system/Button";
import { Button } from "../../design-system/Button";
import { EditShareTypeDialog } from "../Dialog";

type AddShareTypeProps = {
  list: TDraftShareType[];
  onSuccess: (data: TDraftShareType) => void;
  buttonVariant?: Variant;
  buttonColor?: Color;
};

const AddShareType = ({
  list,
  onSuccess,
  buttonVariant = "solid",
  buttonColor = "primary",
}: AddShareTypeProps) => {
  const [isOpen, setOpen] = useState(false);
  const i18n = useTranslation();

  return (
    <>
      <Button
        variant={buttonVariant}
        color={buttonColor}
        onClick={() => setOpen(true)}
      >
        {i18n.t("onboarding.action.addShareClass")}
      </Button>
      {isOpen && (
        <EditShareTypeDialog
          title={i18n.t("onboarding.action.addShareClass")}
          list={list}
          onClose={() => setOpen(false)}
          onSuccess={(data) => {
            setOpen(false);
            onSuccess(data);
          }}
          defaultValues={{
            name: "",
            condition: {
              consent: false,
              conversion: false,
              preemption: false,
              offerOfFirstRefusal: false,
              redemption: false,
            },
          }}
        />
      )}
    </>
  );
};

export { AddShareType };
