import type { FormEventHandler } from "react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { z } from "zod";

import {
  useEntitiesQuery,
  useUpdateEntityMutation,
} from "../../../../api/rest/entities";
import { Button } from "../../../../components/design-system/Button";
import { Dialog } from "../../../../components/design-system/Dialog";
import {
  FormError,
  FormGroup,
  FormLabel,
  FormSection,
} from "../../../../components/design-system/FormGroup";
import { Input } from "../../../../components/design-system/Input";
import {
  isPossiblePhoneNumber,
  PhoneInput,
} from "../../../../components/design-system/PhoneInput";
import { shouldShowRegion } from "../../../../components/Entities/CreateEntityForm/CreateEntityForm.utils";
import { SelectCountry } from "../../../../components/SelectCountry";
import { useCurrentCompany } from "../../../../context/account";
import type { FillContactInfoUserAction } from "./RequiredUserActions";
import { VerificationEmailSent } from "./VerificationEmailSent";

type AddContactDetailsProps = {
  orgNumber: string;
  requiredAction: FillContactInfoUserAction;
  onClose: () => void;
};

type FormValues = {
  email: string;
  phone: string;
  address: {
    line1: string;
    line2?: string | null;
    postalCode: string;
    city: string;
    region?: string | null;
    countryCode: string;
  };
};

const formId = "add-email-and-telephone-form";
const defaultValues: FormValues = Object.freeze({
  email: "",
  phone: "",
  address: {
    line1: "",
    postalCode: "",
    city: "",
    countryCode: "",
  },
});

function getSkipsFromLocalStorage() {
  return JSON.parse(
    window.sessionStorage.getItem("skippedContactDetails") ?? "{}"
  );
}

const setSkipContactDetailsInLocalStorage = (orgNumber: string) => {
  const currentSkips = getSkipsFromLocalStorage();
  const newSkips = { ...currentSkips, [orgNumber]: true };
  window.sessionStorage.setItem(
    "skippedContactDetails",
    JSON.stringify(newSkips)
  );
};

const AddContactDetails = ({
  orgNumber,
  requiredAction,
  onClose,
}: AddContactDetailsProps) => {
  const company = useCurrentCompany(orgNumber);
  const entity = requiredAction.payload;
  const updateEntityMutation = useUpdateEntityMutation(orgNumber, entity.id);
  const entitiesQuery = useEntitiesQuery(orgNumber);
  const i18n = useTranslation();
  const [showEmailVerificationModal, setShowEmailVerificationModal] =
    useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormValues>({
    mode: "onSubmit",
    defaultValues: {
      email: entity.contact.email ?? defaultValues.email,
      phone: entity.contact.phone ?? defaultValues.phone,
      address: {
        ...defaultValues.address,
        ...entity.contact.address,
      },
    },
  });

  const onCloseClick = () => {
    setSkipContactDetailsInLocalStorage(orgNumber);
    onClose();
  };

  if (!company) {
    return null;
  }

  const updateContactDetails: FormEventHandler<HTMLFormElement> = (event) => {
    event.stopPropagation();

    return handleSubmit((data) => {
      updateEntityMutation.mutate(
        {
          ...entity,
          contact: {
            email: data.email,
            phone: data.phone,
            address: {
              line1: data.address.line1,
              line2: data.address.line2,
              postalCode: data.address.postalCode,
              city: data.address.city,
              region: data.address.region,
              countryCode: data.address.countryCode,
            },
          },
        },
        {
          onSuccess: () => {
            if (data.email !== entity.contact.email) {
              setShowEmailVerificationModal(true);
              return;
            }
            entitiesQuery.refetch();
          },
        }
      );
    })(event);
  };

  if (showEmailVerificationModal) {
    return <VerificationEmailSent orgNumber={orgNumber} />;
  }

  return (
    <Dialog
      canClose
      onClose={onCloseClick}
      isOpen
      actions={
        <>
          <Button
            color="primary"
            form={formId}
            isLoading={updateEntityMutation.isLoading}
            type="submit"
            variant="solid"
          >
            {i18n.t("label.continue")}
          </Button>
        </>
      }
      isLoading={updateEntityMutation.isLoading}
      title={i18n.t("userActionRequired.AddContactDetails.title")}
    >
      <p>{i18n.t("userActionRequired.AddContactDetails.subText")}</p>
      <form
        className="tw-mt-4 tw-space-y-4"
        id={formId}
        onSubmit={updateContactDetails}
      >
        {requiredAction.missingInfo.email && (
          <FormGroup>
            <FormLabel htmlFor="email">{i18n.t("label.email")}</FormLabel>
            <Input
              id="email"
              {...register("email", {
                required: i18n.t("error.validation.required"),
                validate: (value) => {
                  if (!value) {
                    return false;
                  }
                  const check = z.string().email().safeParse(value);

                  return check.success
                    ? true
                    : i18n.t("error.validation.format");
                },
              })}
            />
            <FormError>{errors.email?.message}</FormError>
          </FormGroup>
        )}

        {requiredAction.missingInfo.phone && (
          <FormGroup>
            <FormLabel htmlFor="phone">{i18n.t("label.phone")}</FormLabel>
            <Controller
              control={control}
              name="phone"
              render={({ field: { onChange, value, name }, fieldState }) => (
                <>
                  <PhoneInput
                    id="phone"
                    name={name}
                    value={value}
                    onChange={onChange}
                  />
                  <FormError>{fieldState.error?.message}</FormError>
                </>
              )}
              rules={{
                required: i18n.t("error.validation.required"),
                validate: (value) => {
                  if (!value) {
                    return true;
                  }

                  return isPossiblePhoneNumber(value)
                    ? true
                    : i18n.t("error.validation.format.phone");
                },
              }}
            />
          </FormGroup>
        )}

        {requiredAction.missingInfo.address && (
          <FormSection title={i18n.t("label.address")}>
            <div className="tw-grid tw-gap-4 md:tw-grid-cols-2">
              <FormGroup>
                <FormLabel htmlFor="address.line1">
                  {i18n.t("entity.contact.address.line1")}
                </FormLabel>
                <Input
                  id="address.line1"
                  {...register("address.line1", {
                    validate: (value) => {
                      if (!value.trim()) {
                        return i18n.t("error.validation.required");
                      }

                      return true;
                    },
                  })}
                />
                <FormError>{errors.address?.line1?.message}</FormError>
              </FormGroup>
              <FormGroup>
                <FormLabel isOptional htmlFor="address.line2">
                  {i18n.t("entity.contact.address.line2")}
                </FormLabel>
                <Input id="address.line2" {...register("address.line2")} />
                <FormError>{errors.address?.line2?.message}</FormError>
              </FormGroup>
            </div>
            <div className="tw-grid tw-gap-4 md:tw-grid-cols-2">
              <FormGroup>
                <FormLabel htmlFor="address.city">
                  {i18n.t("entity.contact.address.city")}
                </FormLabel>
                <Input
                  id="address.city"
                  {...register("address.city", {
                    validate: (value) => {
                      if (!value.trim()) {
                        return i18n.t("error.validation.required");
                      }

                      return true;
                    },
                  })}
                />
                <FormError>{errors.address?.city?.message}</FormError>
              </FormGroup>
              <FormGroup>
                <FormLabel htmlFor="address.postalCode">
                  {i18n.t("entity.contact.address.postalCode")}
                </FormLabel>
                <Input
                  id="address.postalCode"
                  {...register("address.postalCode", {
                    validate: (value) => {
                      if (!value.trim()) {
                        return i18n.t("error.validation.required");
                      }

                      const postalCodeRegex: Record<string, RegExp> = {
                        SE: /^\d{3}\s?\d{2}$/,
                      };

                      const regex =
                        entity.contact.address &&
                        postalCodeRegex[entity.contact.address.countryCode];
                      if (!regex) {
                        return true;
                      }

                      return (
                        regex.test(value) || i18n.t("error.validation.format")
                      );
                    },
                  })}
                />
                <FormError>{errors.address?.postalCode?.message}</FormError>
              </FormGroup>
            </div>
            {entity.contact.address &&
              shouldShowRegion(entity.contact.address.countryCode) && (
                <FormGroup>
                  <FormLabel htmlFor="address.region" isOptional>
                    {i18n.t("entity.contact.address.region")}
                  </FormLabel>
                  <Input id="address.region" {...register("address.region")} />
                  <FormError>{errors.address?.region?.message}</FormError>
                </FormGroup>
              )}
            <FormGroup>
              <FormLabel htmlFor="address.countryCode">
                {i18n.t("label.country")}
              </FormLabel>
              <Controller
                control={control}
                name="address.countryCode"
                render={({ field: { onChange, value, name }, fieldState }) => (
                  <>
                    <SelectCountry
                      name={name}
                      value={value}
                      onChange={onChange}
                    />
                    <FormError>{fieldState.error?.message}</FormError>
                  </>
                )}
                rules={{ required: i18n.t("error.validation.required") }}
              />
            </FormGroup>
          </FormSection>
        )}

        <p>
          <Trans
            components={{
              termsLink: <Link target="_blank" to="https://kvanta.com/terms" />,
              privacyPolicyLink: (
                <Link target="_blank" to="https://kvanta.com/privacy-policy" />
              ),
            }}
            i18nKey="userActionRequired.AddContactDetails.agree"
          />
        </p>
      </form>
    </Dialog>
  );
};

export { AddContactDetails };
