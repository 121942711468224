import { clsxm } from "../../../utils/tailwind";

type BadgeProps = React.HTMLAttributes<HTMLSpanElement> & {
  isActive?: boolean;
  onClick?: () => void;
};

const Chip = ({
  isActive = false,
  onClick = () => {},
  className,
  children,
}: BadgeProps) => (
  <button onClick={onClick} type="button">
    <div
      className={clsxm(
        "tw-items-center tw-rounded tw-border tw-px-2 tw-py-1 tw-text-sm",
        className,
        {
          "tw-bg-neutral-800 tw-text-white": isActive,
          "tw-text-neutral-500": !isActive,
        }
      )}
    >
      {children}
    </div>
  </button>
);

export { Chip };
export type { BadgeProps };
