import { IconContext } from "@phosphor-icons/react";
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { BrowserRouter } from "react-router-dom";

import { NotificationsContainer } from "./components/design-system/Notifications";
import { SessionProvider } from "./context/session";
import Routes from "./routes";

const queryClient = new QueryClient({
  /**
   * The reason we use a custom `QueryCache` instead of defining `onError` on
   * `defaultOptions`, is that `onError` on `defaultOptions` can be overridden
   * by `useQuery` and `useMutation`.
   *
   * @see https://tkdodo.eu/blog/react-query-error-handling
   * @see https://tanstack.com/query/v3/docs/react/reference/QueryCache#global-callbacks
   */
  queryCache: new QueryCache({
    onError: (error) => {
      /**
       * `console.error` will be picked up by Sentry, so we can see the error
       * without triggering duplicate error reports, which would happen if we
       * used Sentry's `captureException`.
       */
      console.error("query-error", error);
    },
  }),
  defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } },
});

const App = () => (
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <IconContext.Provider value={{ weight: "regular", size: "1.5rem" }}>
        <SessionProvider>
          <Routes />
          <NotificationsContainer />
        </SessionProvider>
      </IconContext.Provider>
    </BrowserRouter>
  </QueryClientProvider>
);

export default App;
