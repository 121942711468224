import { CheckSquare } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";

import type { EmailVerification } from "../../types/models/entities";
import { TooltipV2 } from "../design-system/Tooltip/TooltipV2";

type VerifiedEmailProps = {
  emailVerification?: EmailVerification | null;
};

const VerifiedEmail = ({ emailVerification }: VerifiedEmailProps) => {
  const i18n = useTranslation();
  if (emailVerification?.status !== "VERIFIED") {
    return null;
  }
  return (
    <TooltipV2 content={i18n.t("label.verifiedEmail")}>
      <CheckSquare className="tw-inline-block" />
    </TooltipV2>
  );
};

export { VerifiedEmail };
