import { z } from "zod";

import type { LedgerConfirmedOfficialSchema } from "./company";
import { ContactSchema } from "./entities";
import type { RoleSchema } from "./users";

type NewCompany = { orgNumber: string };

type NewTestCompany = { name: string; countryCode: string };

type CompanySettings = {
  currency: string;
};

type CompanyInformation = {
  orgNumber: string;
  name: string;
  created: number;
  countryCode: string;
  status: "Onboarding" | "Onboarded" | "UnderLiquidation" | "Liquidated";
  ledgerApproved: `${string}.${string}`;
  settings: CompanySettings;
  supportRole: z.infer<typeof RoleSchema>;
  confirmedAsOfficial: z.infer<typeof LedgerConfirmedOfficialSchema>;
};

const AuthorizedSignatorySchema = z.object({
  clearText: z.string(),
});

type AuthorizedSignatory = z.infer<typeof AuthorizedSignatorySchema>;

const ShareCapitalSchema = z.object({
  amount: z.number(),
  currency: z.string(),
});

const CapitalLimitsSchema = z.object({
  min: z.number(),
  max: z.number(),
});

const ShareLimitsSchema = z.object({
  min: z.number().int(),
  max: z.number().int(),
});

const ShareLimitSchema = z.object({
  currency: z.string(),
  capital: CapitalLimitsSchema,
  shares: ShareLimitsSchema,
});

const ShareClassesSchema = z.object({
  name: z.string(),
  amount: z.number(),
  shareLimits: ShareLimitsSchema,
  votes: z.string(),
});

const SharesInformationSchema = z.object({
  numberOfShares: z.number(),
  description: z.string().optional(),
  shareCapital: ShareCapitalSchema,
  shareLimits: ShareLimitSchema,
  shareClasses: z.array(ShareClassesSchema),
});

const CompanyDetailsSchema = z.object({
  orgNumber: z.string(),
  name: z.string().optional(),
  countryCode: z.string().optional(),
  description: z.string().optional(),
  contact: ContactSchema.optional(),
  authorizedSignatory: AuthorizedSignatorySchema.optional(),
  sharesInformation: SharesInformationSchema.optional(),
  lastUpdated: z.union([z.string(), z.date()]),
  registrationDate: z.string().optional(),
  formationDate: z.string().optional(),
});

type CompanyDetails = z.infer<typeof CompanyDetailsSchema>;

export type {
  AuthorizedSignatory,
  CompanyDetails,
  CompanyInformation,
  NewCompany,
  NewTestCompany,
};
export { AuthorizedSignatorySchema, CompanyDetailsSchema };
