import { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  useRegisterSwedishCompanyMutation,
  useRegisterTestCompanyMutation,
} from "../../../api/rest/administration";
import { Description } from "../../../components/design-system/Description";
import { Menu } from "../../../components/design-system/Menu";
import AddSwedishCompanyDialog from "./SwedishCompanyDialog";
import AddTestCompanyDialog from "./TestCompanyDialog";

type AddCompanyProps = { onSuccess: () => void };
const AddCompany = ({ onSuccess }: AddCompanyProps) => {
  const [companyType, setCompanyType] = useState<"swedish" | "test">();
  const i18n = useTranslation();
  const handleClose = () => {
    setCompanyType(undefined);
  };
  const mutationOptions = {
    onSuccess: () => {
      handleClose();
      onSuccess();
    },
  };
  const addSwedishCompanyEntityMutation =
    useRegisterSwedishCompanyMutation(mutationOptions);

  const addTestCompanyEntityMutation =
    useRegisterTestCompanyMutation(mutationOptions);

  return (
    <>
      <Menu>
        <Menu.Button className="tw-w-full" color="primary" variant="solid">
          {i18n.t("label.addCompany")}
        </Menu.Button>
        <Menu.Items align="bottomLeft">
          <Menu.Item as="button" onClick={() => setCompanyType("swedish")}>
            <Description
              title={i18n.t("admin.addCompany.swedish.title")}
              description={i18n.t("admin.addCompany.swedish.description")}
            />
          </Menu.Item>
          <Menu.Item as="button" onClick={() => setCompanyType("test")}>
            <Description
              title={i18n.t("admin.addCompany.test.title")}
              description={i18n.t("admin.addCompany.test.description")}
            />
          </Menu.Item>
        </Menu.Items>
      </Menu>
      {companyType &&
        {
          swedish: (
            <AddSwedishCompanyDialog
              title={i18n.t("label.addCompany")}
              onClose={handleClose}
              mutation={addSwedishCompanyEntityMutation}
              defaultValues={{ orgNumber: "" }}
            />
          ),
          test: (
            <AddTestCompanyDialog
              title={i18n.t("label.addCompany")}
              onClose={handleClose}
              mutation={addTestCompanyEntityMutation}
              defaultValues={{ name: "", countryCode: "SE" }}
            />
          ),
        }[companyType]}
    </>
  );
};

export default AddCompany;
