import { useMemo } from "react";

import { userActions } from ".";
import { AddContactDetails } from "./AddContactDetails";
import type { RequiredUserActions } from "./RequiredUserActions";
import { VerifyEmail } from "./VerifyEmail";

type UserActionModalProps = {
  requiredAction: RequiredUserActions;
  orgNumber: string;
  refresh: () => void;
};

const UserActionModal = ({
  requiredAction,
  orgNumber,
  refresh,
}: UserActionModalProps) => {
  const actionContent = useMemo(() => {
    switch (requiredAction.type) {
      case userActions.FILL_CONTACT_INFO:
        return (
          <AddContactDetails
            requiredAction={requiredAction}
            orgNumber={orgNumber}
            onClose={refresh}
          />
        );
      case userActions.VERIFY_EMAIL:
        return <VerifyEmail orgNumber={orgNumber} onClose={refresh} />;
      default:
        return null;
    }
  }, [requiredAction, orgNumber, refresh]);

  return (
    <div className="tw-fixed tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-bg-neutral-400 tw-bg-opacity-50">
      {actionContent}
    </div>
  );
};

export { UserActionModal };
