import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Button } from "../../../../../components/design-system/Button";
import { Dialog } from "../../../../../components/design-system/Dialog";
import {
  FormError,
  FormGroup,
  FormLabel,
} from "../../../../../components/design-system/FormGroup";
import { Input } from "../../../../../components/design-system/Input";

type Form = { numberOfShares?: number };

type EditShareCancelDialogProps = {
  onClose: () => void;
  onSuccess: (values: Form) => void;
  defaultValues: Form;
  blockSize: number;
};

const formId = "edit-cancelshares-form";

const EditShareCancelDialog = ({
  onSuccess,
  onClose,
  defaultValues,
  blockSize,
}: EditShareCancelDialogProps) => {
  const i18n = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Form>({ mode: "onChange", defaultValues });

  return (
    <Dialog
      isOpen
      title={i18n.t("events.reduceCapital.edit.title")}
      onClose={onClose}
      actions={
        <>
          <Button onClick={onClose}>{i18n.t("label.cancel")}</Button>
          <Button type="submit" variant="solid" color="primary" form={formId}>
            {i18n.t("label.save")}
          </Button>
        </>
      }
    >
      <form
        className="tw-space-y-4"
        onSubmit={(event) => {
          event.stopPropagation();

          return handleSubmit((data) => onSuccess(data))(event);
        }}
        id={formId}
      >
        <FormGroup>
          <FormLabel htmlFor="numberOfShares">
            {i18n.t("label.numberOfShares")}
          </FormLabel>
          <Input
            id="numberOfShares"
            {...register("numberOfShares", {
              valueAsNumber: true,
              max: {
                value: blockSize,
                message: i18n.t("error.validation.range.max", {
                  max: blockSize,
                }),
              },
            })}
            type="number"
            step={1}
          />
          <FormError>{errors.numberOfShares?.message}</FormError>
        </FormGroup>
      </form>
    </Dialog>
  );
};

export default EditShareCancelDialog;
export type { Form };
