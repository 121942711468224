import { UsersThree } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useApprovalRuleProposalQuery } from "../../../../../api/blockchain/users";
import { Button } from "../../../../../components/design-system/Button";
import { APP_ROUTE } from "../../../../../routes/constants";
import type { ProgressItem } from "../../../../../types/models/approval";
import type { Entity } from "../../../../../types/models/entities";
import { dateToIsoString } from "../../../../../utils/date";
import type { Policy } from "../../../../../utils/policy";
import PolicyDetails from "./PolicyDetails";

type PendingApprovalProposalProps = {
  policy: Policy;
  entities: Entity[];
  progress: [ProgressItem, ...ProgressItem[]];
  orgNumber: string;
  boardShouldApproveLedgerAsOfficial: boolean;
  className?: string;
};

const PendingApprovalProposal = ({
  entities,
  orgNumber,
  progress,
  policy,
  boardShouldApproveLedgerAsOfficial,
  className = "",
}: PendingApprovalProposalProps) => {
  const i18n = useTranslation();
  const approvalRuleProposal = useApprovalRuleProposalQuery(orgNumber);

  const initiatedBy = entities.find(
    (e) => e.id === approvalRuleProposal.data?.initiatedBy?.id
  );

  const initiatedAt = approvalRuleProposal.data?.initiatedAt
    ? dateToIsoString(new Date(approvalRuleProposal.data.initiatedAt))
    : null;

  const userHasApproved = !!progress.find(
    (p) => p.isCurrentUser && p.status === "approved"
  );
  const userNeedToApprove = !!progress.find(
    (p) => p.isCurrentUser && p.status === "pending"
  );
  const totalApproved = progress.filter((p) => p.status === "approved").length;

  return (
    <div
      className={`tw-flex tw-flex-col tw-gap-6 tw-rounded tw-border tw-p-6 ${className}`}
    >
      <div className="tw-flex tw-flex-col tw-gap-2">
        <h2 className="tw-text-left tw-font-body tw-text-2xl tw-leading-none">
          {i18n.t("approvalPolicy.pendingChangeOfPolicy")}
        </h2>
        {initiatedBy && initiatedAt && (
          <p className=" tw-text-kvanta-secondary">
            {i18n.t("approvalPolicy.pendingChangeOfPolicy.details", {
              name: initiatedBy.name,
              date: initiatedAt,
            })}
          </p>
        )}
      </div>
      <div className="tw-flex tw-flex-col tw-gap-2">
        {boardShouldApproveLedgerAsOfficial && (
          <div
            className="tw-flex tw-place-items-center tw-rounded tw-bg-kvanta-background tw-p-5 tw-text-sm tw-text-kvanta-primary"
            data-testid="board-approve-ledger-as-official"
          >
            {i18n.t(
              "approvalPolicy.pendingChangeOfPolicy.boardApprovedLedgerAsOfficial"
            )}
          </div>
        )}
        <div
          className="tw-flex tw-place-items-center tw-rounded tw-bg-kvanta-background tw-p-5"
          data-testid="approval-policy-progress-new-policy"
        >
          <PolicyDetails
            rule={policy}
            entities={entities}
            percentage={
              approvalRuleProposal.data?.rule === "BoardPercentage"
                ? approvalRuleProposal.data.percentage / 10 // Since percentage is stored as 1000th of a percent
                : undefined
            }
            approverIds={
              approvalRuleProposal.data?.rule === "SpecificUsers"
                ? approvalRuleProposal.data.users?.map((u) => u.id)
                : undefined
            }
          />
        </div>
      </div>

      <div className="tw-grid tw-grid-cols-4">
        <div className="tw-col-span-4 tw-flex tw-items-center tw-gap-2 md:tw-col-span-3">
          <UsersThree />
          {progress.length > 0 &&
            Array(progress.length)
              .fill(0)
              .map((_, index) => (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  className={`tw-h-6 tw-w-6 tw-rounded-sm tw-border ${
                    index < totalApproved
                      ? "tw-bg-green-400"
                      : "tw-bg-transparent"
                  }`}
                />
              ))}
          {userHasApproved && (
            <span className="tw-text-secondary">
              {i18n.t("settings.shareLedger.youHasApproved")}
            </span>
          )}
        </div>
        <div className="tw-col-span-4 tw-flex tw-flex-col tw-items-end tw-pt-6 md:tw-col-span-1 md:tw-pt-0">
          <Link
            to={`${APP_ROUTE.COMPANIES}/${orgNumber}/settings/policy/preview-proposal`}
          >
            {userNeedToApprove ? (
              <Button color="primary" variant="solid">
                {i18n.t("label.previewAndApprove")}
              </Button>
            ) : (
              <Button>{i18n.t("label.preview")}</Button>
            )}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PendingApprovalProposal;
