import { z } from "zod";

import type { Condition } from "./shares";
import { ConditionSchema } from "./shares";

const ShareCapitalSchema = z.object({
  capitalAmount: z.number().int().optional(),
  totalShares: z.number().int().optional(),
  formationDate: z.string().optional(),
  currency: z.string().optional(),
});

type ShareCapital = z.infer<typeof ShareCapitalSchema>;

type NewShareCapital = {
  capitalAmount?: number;
  totalShares?: number;
  formationDate?: string;
  currency?: string;
};

const DraftShareTypeSchema = z.object({
  name: z.string(),
  numberOfShares: z.number(),
  voteValue: z.number(),
  condition: ConditionSchema,
});

type DraftShareType = z.infer<typeof DraftShareTypeSchema>;

type TDraftShareType = DraftShareType;

const DraftShareTypesSchema = z.array(DraftShareTypeSchema);

type DraftShareTypes = z.infer<typeof DraftShareTypesSchema>;

type NewShareType = {
  name: string;
  numberOfShares?: number;
  voteValue?: number;
  condition: Condition;
};

const DraftShareBlockSchema = z.object({
  start: z.number(),
  end: z.number(),
  holderId: z.string(),
  type: z.string(),
});

type DraftShareBlock = z.infer<typeof DraftShareBlockSchema>;

const DraftShareBlocksSchema = z.array(DraftShareBlockSchema);

type DraftShareBlocks = z.infer<typeof DraftShareBlocksSchema>;

type TDraftShareBlock = {
  start: number;
  end: number;
  holderId: string;
  type: string;
};

type NewShareBlock = {
  holderId?: string;
  numberOfShares?: number;
  type?: string;
};

export type {
  DraftShareBlock,
  DraftShareBlocks,
  DraftShareType,
  DraftShareTypes,
  NewShareBlock,
  NewShareCapital,
  NewShareType,
  ShareCapital,
  TDraftShareBlock,
  TDraftShareType,
};
export {
  DraftShareBlockSchema,
  DraftShareBlocksSchema,
  DraftShareTypeSchema,
  DraftShareTypesSchema,
  ShareCapitalSchema,
};
