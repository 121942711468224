import { CheckSquare, ThumbsUp } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";

import { useApprovalRuleProposalQuery } from "../../../../../../api/blockchain/users";
import { Loading } from "../../../../../../components/design-system/Loading";

type DoneProps = {
  orgNumber: string;
  onlyInitiation?: boolean;
};

const Done = ({ orgNumber, onlyInitiation }: DoneProps) => {
  const i18n = useTranslation();
  const policyProposalInfoQuery = useApprovalRuleProposalQuery(orgNumber, {
    enabled: !onlyInitiation,
  });

  if (
    policyProposalInfoQuery.isLoading ||
    policyProposalInfoQuery.isFetching ||
    (!onlyInitiation && !policyProposalInfoQuery.isFetched)
  ) {
    return <Loading />;
  }
  if (policyProposalInfoQuery.isError) {
    return <>{i18n.t("error.approvalPolicy.fetchError")}</>;
  }
  let icon = <CheckSquare className="tw-h-20 tw-w-20" />;
  let title = i18n.t("settings.shareLedger.doneStep.policyApplied.title");
  let description: React.ReactElement | null = (
    <p className="tw-mt-6 tw-text-center">
      {i18n.t("settings.shareLedger.doneStep.policyApplied.description")}
    </p>
  );

  if (onlyInitiation) {
    icon = <ThumbsUp className="tw-h-20 tw-w-20" />;
    title = i18n.t("label.done");
    description = (
      <>
        <p className="tw-mt-6 tw-text-center">
          {i18n.t(
            "settings.shareLedger.newPolicy.doneStep.approved.description1"
          )}
        </p>
        <p className="tw-mt-6 tw-text-center">
          {i18n.t(
            "settings.shareLedger.newPolicy.doneStep.approved.description2"
          )}
        </p>
      </>
    );
  } else if (
    (policyProposalInfoQuery.data?.rule === "BoardPercentage" ||
      policyProposalInfoQuery.data?.rule === "SpecificUsers") &&
    policyProposalInfoQuery.data?.pendingApprovalBy.length > 0
  ) {
    icon = <ThumbsUp className="tw-h-20 tw-w-20" />;
    title = i18n.t("settings.shareLedger.newPolicy.doneStep.approved.title");
    description = (
      <>
        <p className="tw-mt-6 tw-text-center">
          {i18n.t(
            "settings.shareLedger.newPolicy.doneStep.approved.description1"
          )}
        </p>
        <p className="tw-mt-6 tw-text-center">
          {i18n.t(
            "settings.shareLedger.newPolicy.doneStep.approved.description2"
          )}
        </p>
      </>
    );
  }

  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-align-middle">
      {icon}
      <h3 className="tw-mt-2">{title}</h3>
      {description}
    </div>
  );
};

export default Done;
