import { useTranslation } from "react-i18next";

import { useUsersQuery } from "../../../../../api/blockchain/users";
import { List, ListItem } from "../../../../../components/design-system/List";
import { Loading } from "../../../../../components/design-system/Loading";
import { NoData } from "../../../../../components/NoData";
import { ManageSupportAccess } from "../../../../../components/Support/ManageSupportAccess";
import type { CompanyInvolvement } from "../../../../../types/models/company";
import { supportUserId } from "../../../../../utils/user";
import { getSupportUserRole } from "./SupportPage.utils";

type Props = { currentCompany: CompanyInvolvement };

const SupportPage = ({ currentCompany }: Props) => {
  const i18n = useTranslation();
  const usersQuery = useUsersQuery(currentCompany.orgNumber);

  const errorCode = usersQuery.error?.errors[0]?.message.code;
  const supportUser = usersQuery.data?.find(
    (user) => user.user.id === supportUserId
  );
  const supportUserRole = getSupportUserRole(supportUser);

  const canEdit =
    currentCompany.access === "Administrator" ||
    currentCompany.access === "BoardMember";

  const handleUsersChange = () => {
    usersQuery.refetch();
  };

  return (
    <List>
      {usersQuery.isLoading && <Loading />}
      {usersQuery.data && (
        <div className="tw-mx-auto tw-max-w-xl tw-px-1">
          <div className="tw-pb-4">
            <h3>{i18n.t("support.supportAccess.title")}</h3>
            <p className="tw-text-sm">
              {i18n.t("support.supportAccess.description")}
            </p>
          </div>
          <ListItem className="tw-p-0">
            <div>
              <ManageSupportAccess
                enabled={canEdit}
                currentCompany={currentCompany}
                onSuccess={handleUsersChange}
                currentSupportRole={supportUserRole}
              />
            </div>
          </ListItem>
        </div>
      )}
      {errorCode && (
        <NoData
          type="error"
          title={i18n.t("error.fetch")}
          description={i18n.t(errorCode)}
        />
      )}
    </List>
  );
};

export { SupportPage };
