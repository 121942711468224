import type { CompanyInformation } from "../../../types/models/administration";
import type { CompanyInvolvement } from "../../../types/models/company";
import type { EntitiesMap } from "../../../types/models/entities";
import type { TParentEvent } from "../../../types/models/events";
import * as monitoring from "../../../utils/monitoring";
import {
  CompanyFoundationAuditLine,
  DecreaseCapitalAuditLine,
  DecreaseCapitalCancelSharesAuditLine,
  IncreaseCapitalAuditLine,
  IncreaseCapitalBonusIssueAuditLine,
  LedgerApprovalAuditLine,
  LedgerApprovalInitializedAuditLine,
  LedgerApprovalRejectedAuditLine,
  LedgerPolicyApprovalAuditLine,
  LedgerRollbackAuditLine,
  LedgerRollbackPendingAuditLine,
  LedgerRollbackRejectedAuditLine,
  PledgedSharesUpdateAuditLine,
  ShareCertificatesUpdateAuditLine,
  ShareClassUpdateAuditLine,
  ShareIssueAuditLine,
  ShareReclassificationByClassAuditLine,
  ShareReclassificationByRangeAuditLine,
  ShareSplitAuditLine,
  ShareTransferAuditLine,
} from "./Events";

const UnhandledEvent = ({ event }: { event: TParentEvent }) => (
  <div className="tw-flex tw-items-center tw-gap-x-4 tw-bg-error/25 tw-px-4 tw-py-2">
    {`UnhandledEvent: ${event.type}`}
  </div>
);

const AuditEvent = ({
  event,
  label,
  currentCompany,
  entitiesMap,
  open = false,
  onOpenChange = () => {
    console.error("onOpenChange is not defined");
  },
}: {
  event: TParentEvent;
  label: string;
  currentCompany: CompanyInvolvement | CompanyInformation;
  entitiesMap: EntitiesMap;
  open?: boolean;
  onOpenChange?: (value: boolean) => void;
}) => {
  if (event.type === "CompanyFoundation") {
    return (
      <CompanyFoundationAuditLine
        event={event}
        label={label}
        currentCompany={currentCompany}
        entitiesMap={entitiesMap}
        open={open}
        onOpenChange={onOpenChange}
      />
    );
  }
  if (event.type === "ShareIssue") {
    return (
      <ShareIssueAuditLine
        event={event}
        label={label}
        currentCompany={currentCompany}
        entitiesMap={entitiesMap}
        open={open}
        onOpenChange={onOpenChange}
      />
    );
  }
  if (event.type === "ShareSplit") {
    return (
      <ShareSplitAuditLine
        event={event}
        label={label}
        entitiesMap={entitiesMap}
        open={open}
        onOpenChange={onOpenChange}
      />
    );
  }
  if (event.type === "ShareTransfer") {
    return (
      <ShareTransferAuditLine
        event={event}
        label={label}
        entitiesMap={entitiesMap}
        open={open}
        onOpenChange={onOpenChange}
      />
    );
  }
  if (event.type === "DecreaseCapital") {
    return (
      <DecreaseCapitalAuditLine
        event={event}
        label={label}
        entitiesMap={entitiesMap}
        open={open}
        onOpenChange={onOpenChange}
      />
    );
  }
  if (event.type === "DecreaseCapitalCancelShares") {
    return (
      <DecreaseCapitalCancelSharesAuditLine
        event={event}
        label={label}
        entitiesMap={entitiesMap}
        open={open}
        onOpenChange={onOpenChange}
      />
    );
  }
  if (event.type === "IncreaseCapital") {
    return (
      <IncreaseCapitalAuditLine
        event={event}
        label={label}
        entitiesMap={entitiesMap}
        open={open}
        onOpenChange={onOpenChange}
      />
    );
  }
  if (event.type === "IncreaseCapitalBonusIssue") {
    return (
      <IncreaseCapitalBonusIssueAuditLine
        event={event}
        label={label}
        currentCompany={currentCompany}
        entitiesMap={entitiesMap}
        open={open}
        onOpenChange={onOpenChange}
      />
    );
  }
  if (event.type === "ReclassifySharesByRange") {
    return (
      <ShareReclassificationByRangeAuditLine
        event={event}
        label={label}
        entitiesMap={entitiesMap}
        open={open}
        onOpenChange={onOpenChange}
      />
    );
  }
  if (event.type === "ReclassifySharesByClass") {
    return (
      <ShareReclassificationByClassAuditLine
        event={event}
        label={label}
        entitiesMap={entitiesMap}
        open={open}
        onOpenChange={onOpenChange}
      />
    );
  }
  if (event.type === "ShareClassUpdate") {
    return (
      <ShareClassUpdateAuditLine
        event={event}
        label={label}
        entitiesMap={entitiesMap}
        open={open}
        onOpenChange={onOpenChange}
      />
    );
  }
  if (event.type === "ShareCertificateUpdate") {
    return (
      <ShareCertificatesUpdateAuditLine
        event={event}
        label={label}
        entitiesMap={entitiesMap}
        open={open}
        onOpenChange={onOpenChange}
      />
    );
  }
  if (event.type === "SharePledgedUpdate") {
    return (
      <PledgedSharesUpdateAuditLine
        event={event}
        label={label}
        entitiesMap={entitiesMap}
        open={open}
        onOpenChange={onOpenChange}
      />
    );
  }
  if (event.type === "LedgerApprovalInitialized") {
    return (
      <LedgerApprovalInitializedAuditLine
        event={event}
        label={label}
        entitiesMap={entitiesMap}
        open={open}
        onOpenChange={onOpenChange}
      />
    );
  }
  if (event.type === "LedgerRollbackPending") {
    return (
      <LedgerRollbackPendingAuditLine
        event={event}
        label={label}
        entitiesMap={entitiesMap}
        open={open}
        onOpenChange={onOpenChange}
      />
    );
  }
  if (event.type === "LedgerApprovalRejected") {
    return (
      <LedgerApprovalRejectedAuditLine
        event={event}
        label={label}
        entitiesMap={entitiesMap}
        open={open}
        onOpenChange={onOpenChange}
      />
    );
  }
  if (event.type === "LedgerRollbackRejected") {
    return (
      <LedgerRollbackRejectedAuditLine
        event={event}
        label={label}
        entitiesMap={entitiesMap}
        open={open}
        onOpenChange={onOpenChange}
      />
    );
  }
  if (event.type === "LedgerRollback") {
    return (
      <LedgerRollbackAuditLine
        event={event}
        label={label}
        entitiesMap={entitiesMap}
        open={open}
        onOpenChange={onOpenChange}
      />
    );
  }
  if (event.type === "LedgerApproval") {
    return (
      <LedgerApprovalAuditLine
        event={event}
        label={label}
        entitiesMap={entitiesMap}
        open={open}
        onOpenChange={onOpenChange}
      />
    );
  }
  if (event.type === "LedgerPolicyApproval") {
    return (
      <LedgerPolicyApprovalAuditLine
        event={event}
        label={label}
        entitiesMap={entitiesMap}
        open={open}
        onOpenChange={onOpenChange}
      />
    );
  }

  try {
    return assertNever(event);
  } catch (err) {
    monitoring.captureException(err);
    return <UnhandledEvent event={event} />;
  }
};

function assertNever(x: never): never {
  throw Error(`Unexpected event: ${JSON.stringify(x, null, 2)}`);
}

export { AuditEvent };
