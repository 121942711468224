import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import {
  useLedgerQuery,
  useShareblocksQuery,
} from "../../../api/blockchain/company";
import { useEntitiesQuery } from "../../../api/rest/entities";
import { usePledgedSharesMutation } from "../../../api/rest/events";
import {
  FormError,
  FormErrorList,
  FormGroup,
  FormLabel,
} from "../../../components/design-system/FormGroup";
import { CrossIcon } from "../../../components/design-system/icons";
import { Input } from "../../../components/design-system/Input";
import { notify } from "../../../components/design-system/Notifications";
import { PageWrapper } from "../../../components/PageWrapper";
import {
  SelectCreditors,
  ShareRangeErrors,
} from "../../../components/SelectCreditors";
import useLatestVersion from "../../../hooks/useLatestVersion";
import { APP_ROUTE } from "../../../routes/constants";
import type { CompanyInformation } from "../../../types/models/administration";
import type { CompanyInvolvement } from "../../../types/models/company";
import type { CreditorRow } from "../../../types/models/shares";
import { dateToIsoString } from "../../../utils/date";
import {
  hasOverlappingRanges,
  isRangeValid,
  sumRanges,
} from "../../../utils/shares";
import FormActions from "../FormActions";

type Props = { currentCompany: CompanyInvolvement | CompanyInformation };

type FormProps = {
  date: string;
  shareRanges: CreditorRow[];
};

const isValidRow = (value: CreditorRow) =>
  isRangeValid(value) && value.creditorId;
const validRows = (data: CreditorRow[]) => data.filter(isValidRow);

const pledgedSharesUpdateFormId = "pledged-shares-update-form";

const PledgedSharesUpdate = ({ currentCompany }: Props) => {
  const eventsPath = `${APP_ROUTE.COMPANIES}/${currentCompany.orgNumber}/events`;
  const i18n = useTranslation();
  const navigate = useNavigate();
  const form = useForm<FormProps>({
    mode: "onChange",
    defaultValues: { shareRanges: [] },
  });
  const shareBlocksQuery = useShareblocksQuery(currentCompany.orgNumber, "", {
    onSuccess: (data) => {
      form.setValue(
        "shareRanges",
        data
          .filter((block) => !!block.creditor && !block.cancelled)
          .map((block) => ({
            type: new Date().toISOString(),
            start: block.start,
            end: block.end,
            creditorId: block.creditor?.id,
          }))
      );
    },
  });
  const shareBlockOptions = (shareBlocksQuery.data || []).filter(
    (block) => !block.cancelled
  );
  const ledgerQuery = useLedgerQuery(currentCompany.orgNumber, "");
  const lastEventDate = useLatestVersion();
  const entitiesQuery = useEntitiesQuery(currentCompany.orgNumber);

  const handleSuccess = () => {
    notify(
      i18n.t("events.success", {
        name: i18n.t("events.pledgedSharesUpdate.title"),
      }),
      { type: "success" }
    );
    navigate(eventsPath);
  };

  const mutation = usePledgedSharesMutation(currentCompany.orgNumber, {
    onSuccess: handleSuccess,
  });

  const handleSubmit = (data: FormProps) => {
    if (!ledgerHasCreditor && selectedShareBlocks.length === 0) {
      form.setError("shareRanges", {
        type: "manual",
        message: i18n.t("error.verification.certificate.none"),
      });
      return;
    }
    if (areSelectedSharesPledged) {
      form.setError("shareRanges", {
        type: "manual",
        message: i18n.t("error.verification.pledge.alreadyIssued"),
      });
      return;
    }
    mutation.mutate({
      date: data.date,
      shareRanges: validRows(data.shareRanges).map(
        ({ start, end, creditorId }) => ({ start, end, creditorId })
      ),
    });
  };

  const ledgerHasCreditor = shareBlockOptions.some((block) => !!block.creditor);
  const pledgedShareBlocks = shareBlockOptions.filter(
    (blocks) => blocks.creditor !== null
  );
  const selectedShareBlocks = form.watch("shareRanges");

  const normalizePledgedShareBlocks = pledgedShareBlocks.map((block) => ({
    start: block.start,
    end: block.end,
    creditorId: block.creditor?.id,
  }));

  const areSelectedSharesPledged =
    selectedShareBlocks.length === normalizePledgedShareBlocks.length &&
    selectedShareBlocks.every((selectedShare) =>
      normalizePledgedShareBlocks.some(
        (pledgedShare) =>
          pledgedShare.start &&
          pledgedShare.end &&
          selectedShare.start &&
          selectedShare.end &&
          selectedShare.start <= selectedShare.end &&
          pledgedShare.start >= selectedShare.start &&
          pledgedShare.end <= selectedShare.end &&
          pledgedShare.creditorId === selectedShare.creditorId
      )
    );

  const shareRanges = form.watch("shareRanges");

  const selectedPledgeBlocks = useMemo(() => {
    return shareBlockOptions.filter((block) =>
      shareRanges.some(
        (range) =>
          range.start &&
          range.end &&
          range.start <= range.end &&
          range.end <= block.end &&
          range.start >= block.start
      )
    );
  }, [shareBlockOptions, shareRanges]);

  const selectedShareTypes = useMemo(() => {
    const shareTypes = selectedPledgeBlocks.map((block) => block.type);
    return shareTypes;
  }, [selectedPledgeBlocks]);

  return (
    <PageWrapper data-testid="pledged-shares-update">
      <header className="tw-flex tw-justify-between tw-pb-6">
        <div>
          <h4>{i18n.t("events.pledgedSharesUpdate.title")}</h4>
        </div>
        <Link to={eventsPath} className="tw-text-body">
          <CrossIcon className="tw-h-6 tw-w-6" />
        </Link>
      </header>
      <form
        id={pledgedSharesUpdateFormId}
        className="tw-h-96 tw-space-y-6"
        onSubmit={form.handleSubmit(handleSubmit)}
      >
        <FormGroup>
          <FormLabel htmlFor="date">{i18n.t("label.date")}</FormLabel>
          <Controller
            control={form.control}
            render={({ field: { ref, name, onChange, value }, fieldState }) => (
              <>
                <Input
                  id="date"
                  value={value}
                  ref={ref}
                  name={name}
                  onChange={onChange}
                  type="date"
                  className="tw-w-full"
                  max={dateToIsoString(new Date())}
                  min={lastEventDate && dateToIsoString(lastEventDate.date)}
                />
                <FormError>{fieldState.error?.message}</FormError>
              </>
            )}
            name="date"
            rules={{ required: i18n.t("error.validation.required") }}
          />
        </FormGroup>

        <Controller
          control={form.control}
          render={({ field: { onChange, value }, fieldState }) => (
            <FormGroup>
              <SelectCreditors
                value={value}
                register={form.register}
                control={form.control}
                errors={
                  (form.formState.errors.shareRanges as ShareRangeErrors) || {}
                }
                onChange={onChange}
                entitiesQuery={entitiesQuery}
                currentCompany={currentCompany}
                shareBlockOptions={shareBlockOptions}
                selectedPledgeBlocks={selectedPledgeBlocks}
              />
              <FormError>{fieldState.error?.message}</FormError>
            </FormGroup>
          )}
          name="shareRanges"
          rules={{
            validate: (value) => {
              const totalShares = ledgerQuery.data?.shares.total || 1;
              const ranges = validRows(value);
              const rangeTotal = sumRanges(ranges);
              if (rangeTotal > totalShares) {
                return i18n.t("error.validation.range.max", {
                  max: totalShares,
                });
              }
              if (hasOverlappingRanges(ranges)) {
                return i18n.t("error.validation.overlaps");
              }

              return true;
            },
          }}
        />
        {mutation.error && <FormErrorList error={mutation.error} />}
        <FormActions
          formId={pledgedSharesUpdateFormId}
          isLoading={mutation.isLoading}
          orgNumber={currentCompany.orgNumber}
          selectedShareTypes={selectedShareTypes}
          hasError={Object.keys(form.formState.errors).length > 0}
        />
      </form>
    </PageWrapper>
  );
};

export default PledgedSharesUpdate;
