import type { CompanyOverview } from "@capchapdev/rell-api";
import { useEffect, useMemo, useState } from "react";
import { Navigate, Route, Routes, useSearchParams } from "react-router-dom";

import { useCurrentCompany } from "../../../../context/account";
import { useSession } from "../../../../context/session";
import type { CompanyInformation } from "../../../../types/models/administration";
import * as monitoring from "../../../../utils/monitoring";
import IncreaseCapital from "../../../CompanyShares/IncreaseCapital";
import { IssueShares } from "../../../CompanyShares/IssueShares";
import { CompanyOnboarding } from "../../../CompanyShares/Onboarding";
import PledgedSharesUpdate from "../../../CompanyShares/PledgedSharesUpdate";
import Reclassification from "../../../CompanyShares/Reclassification";
import ReduceCapital from "../../../CompanyShares/ReduceCapital";
import ReverseSplitShares from "../../../CompanyShares/ReverseSplitShares";
import Shareblocks from "../../../CompanyShares/ShareBlocks";
import ShareCertificatesUpdate from "../../../CompanyShares/ShareCertificatesUpdate";
import Shareholders from "../../../CompanyShares/ShareHolders";
import ShareTypeUpdate from "../../../CompanyShares/ShareTypeUpdate";
import SplitShares from "../../../CompanyShares/SplitShares";
import TransferShares from "../../../CompanyShares/TransferShares";
import type { LedgerVersionDetails } from "../../../CompanyShares/useLedgerVersions";
import { useLedgerVersions } from "../../../CompanyShares/useLedgerVersions";

const SentryRoutes = monitoring.withSentryReactRouterV6Routing(Routes);

const getInitialVersion = (
  ledgerVersions: LedgerVersionDetails[],
  versionInUrl?: string | null
) => {
  const latestVersion = ledgerVersions[0];
  const latestApprovedVersion = ledgerVersions?.find(
    (version) => version.isApproved
  );

  let result = latestVersion;

  if (versionInUrl) {
    const ledgerFromQuery = ledgerVersions.find(
      (version) => version.formatedValue === versionInUrl
    );

    if (ledgerFromQuery) {
      result = ledgerFromQuery;
    }
  } else if (latestApprovedVersion) {
    result = latestApprovedVersion;
  }

  return result;
};

const CompanyShares = () => {
  const currentCompany = useCurrentCompany();
  const { user } = useSession();

  const ledgerVersionsToShow = useMemo(() => {
    const userRole = (currentCompany as CompanyOverview)?.access;
    const supportRole =
      user?.role === "Administrator"
        ? (currentCompany as CompanyInformation)?.supportRole
        : null;

    if (userRole && userRole === "Viewer") {
      return "approved";
    }

    if (!userRole && supportRole === "Viewer") {
      return "approved";
    }

    return "all";
  }, [currentCompany, user]);

  const ledgerVersions = useLedgerVersions(
    currentCompany?.orgNumber,
    ledgerVersionsToShow
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const versionStringInQuery = searchParams.get("version");

  const initialLedgerVersion = getInitialVersion(
    ledgerVersions,
    versionStringInQuery
  );

  const [selectedVersion, setSelectedVersion] = useState<
    LedgerVersionDetails | undefined
  >(initialLedgerVersion);

  useEffect(() => {
    if (currentCompany?.status === "Onboarding") {
      setSelectedVersion(undefined);
      return;
    }

    if (!selectedVersion && initialLedgerVersion) {
      setSelectedVersion(initialLedgerVersion);
      return;
    }

    const selectedVersionMissing =
      !selectedVersion ||
      !ledgerVersions.find(
        (ledgerVersion) =>
          ledgerVersion.formatedValue === selectedVersion.formatedValue
      );

    if (selectedVersionMissing) {
      setSelectedVersion(ledgerVersions[0]);
    }
  }, [ledgerVersions, selectedVersion, initialLedgerVersion]);

  if (!currentCompany) {
    return null;
  }
  const onVersionChange = (newVersion?: LedgerVersionDetails) => {
    if (newVersion) {
      setSearchParams({
        ...searchParams,
        version: newVersion.formatedValue ?? "",
      });
    }
    setSelectedVersion(newVersion);
  };

  return (
    <SentryRoutes>
      {currentCompany.status === "Onboarding" && (
        <Route
          path="setup"
          element={<CompanyOnboarding currentCompany={currentCompany} />}
        />
      )}
      <Route
        path="blocks"
        element={
          <Shareblocks
            currentCompany={currentCompany}
            ledgerVersions={ledgerVersions}
            selectedVersion={selectedVersion}
            setSelectedVersion={onVersionChange}
          />
        }
      />
      <Route
        path="holders"
        element={
          <Shareholders
            currentCompany={currentCompany}
            ledgerVersions={ledgerVersions}
            selectedVersion={selectedVersion}
            setSelectedVersion={onVersionChange}
          />
        }
      />
      <Route
        path="transfer"
        element={<TransferShares currentCompany={currentCompany} />}
      />
      <Route
        path="issueshares"
        element={<IssueShares currentCompany={currentCompany} />}
      />
      <Route
        path="reducecapital"
        element={<ReduceCapital currentCompany={currentCompany} />}
      />
      <Route
        path="increasecapital"
        element={<IncreaseCapital currentCompany={currentCompany} />}
      />
      <Route
        path="reclassification"
        element={<Reclassification currentCompany={currentCompany} />}
      />
      <Route
        path="split"
        element={<SplitShares currentCompany={currentCompany} />}
      />
      <Route
        path="reversesplit"
        element={<ReverseSplitShares currentCompany={currentCompany} />}
      />
      <Route
        path="sharetypeupdate"
        element={<ShareTypeUpdate currentCompany={currentCompany} />}
      />
      <Route
        path="sharecertificateupdate"
        element={<ShareCertificatesUpdate currentCompany={currentCompany} />}
      />
      <Route
        path="pledgedsharesupdate"
        element={<PledgedSharesUpdate currentCompany={currentCompany} />}
      />
      <Route path="*" element={<Navigate to="holders" />} />
    </SentryRoutes>
  );
};

export { CompanyShares };
