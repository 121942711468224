import { z } from "zod";

import type { User } from "./shares";
import { UserSchema } from "./shares";

type ApprovalPolicyAny = {
  type: "Any";
};

type ApprovalPolicyAll = {
  type: "All";
  boardMembers: User[];
};

type ApprovalPolicyPercentage = {
  type: "Percentage";
  percentageRequired: number;
};

type ApprovalPolicyManual = {
  type: "Manual";
  approvers: User[];
};

type ApprovalPolicyNumber = {
  type: "Number";
  numberOfApprovalsRequired: number;
};

type ApprovalPolicyNone = {
  type: "None";
};

type ApprovalPolicy =
  | ApprovalPolicyAll
  | ApprovalPolicyAny
  | ApprovalPolicyManual
  | ApprovalPolicyNone
  | ApprovalPolicyNumber
  | ApprovalPolicyPercentage;

type Status = "Approved" | "Pending";

type ApprovalStatus = {
  approvedBy: User[];
  pendingApprovalBy: User[];
  requiredNumber: number;
  currentNumber: number;
  status: Status;
};

type PendingApprovalStatus = {
  currentApprovalPolicy: ApprovalPolicy;
  pendingApprovalPolicy: Exclude<ApprovalPolicy, { type: "None" }> | null;
  approvalStatus: ApprovalStatus;
};

const ApprovalRuleProposalBoardPercentageSchema = z.object({
  rule: z.literal("BoardPercentage"),
  approvedBy: z.array(UserSchema),
  pendingApprovalBy: z.array(UserSchema).nonempty(),
  percentage: z.number().int().min(0).max(1000),
  // TODO: initiatedBy should not be optional once BC responds with this information
  initiatedBy: UserSchema.optional(),
  // TODO: initiatedAt should not be optional once BC responds with this information
  initiatedAt: z.number().int().min(0).optional(),
});

const ApprovalRuleProposalSpecificUsersSchema = z.object({
  rule: z.literal("SpecificUsers"),
  approvedBy: z.array(UserSchema),
  pendingApprovalBy: z.array(UserSchema).nonempty(),
  users: z.array(UserSchema),
  // TODO: initiatedBy should not be optional once BC responds with this information
  initiatedBy: UserSchema.optional(),
  // TODO: initiatedAt should not be optional once BC responds with this information
  initiatedAt: z.number().int().min(0).optional(),
});

const ApprovalRuleProposalSchema = z.union([
  ApprovalRuleProposalBoardPercentageSchema,
  ApprovalRuleProposalSpecificUsersSchema,
]);

const ApprovalRuleProposalResponseSchema =
  ApprovalRuleProposalSchema.nullable();

type ApprovalRuleProposal = z.infer<typeof ApprovalRuleProposalSchema>;
type ApprovalRuleProposalResponse = z.infer<
  typeof ApprovalRuleProposalResponseSchema
>;

type ApprovalRuleProposalSpecificUsers = z.infer<
  typeof ApprovalRuleProposalSpecificUsersSchema
>;

export { ApprovalRuleProposalResponseSchema };
export type {
  ApprovalPolicy,
  ApprovalRuleProposal,
  ApprovalRuleProposalResponse,
  ApprovalRuleProposalSpecificUsers,
  ApprovalStatus,
  PendingApprovalStatus,
};
