import { type FunctionComponent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import { useApprovalInfoQuery } from "../../api/blockchain/company";
import { useParentEventsQuery } from "../../api/blockchain/events";
import { useApprovalRuleProposalQuery } from "../../api/blockchain/users";
import { useEntitiesQuery } from "../../api/rest/entities";
import { useSession } from "../../context/session";
import { useCompanyEvents } from "../../hooks/useCompanyEvents";
import { APP_ROUTE } from "../../routes/constants";
import { CompanyInformation } from "../../types/models/administration";
import type { ProgressItem } from "../../types/models/approval";
import { CompanyInvolvement } from "../../types/models/company";
import {
  useShouldApprovePolicy,
  useShouldSetPolicy,
} from "../../utils/approvalUtils";
import { hasRequiredPermission } from "../../utils/permissions";
import { ArrowRightIcon, CrossIcon } from "../design-system/icons";
import { isSSN } from "../Entities/CreateEntityForm/CreateEntityForm";
import { getVersionToApprove } from "../History/History.utils";
import { IconDescription } from "../IconDescription";

type Approver = ProgressItem;

type Notification = {
  link: string;
  message: string;
  linkText: string;
};

const Banner = ({ notification }: { notification: Notification }) => {
  const i18n = useTranslation();
  const [isVisible, setIsVisible] = useState(true);
  const handleClose = () => setIsVisible(false);
  const { link, message, linkText } = notification;

  if (!isVisible) {
    return null;
  }

  return (
    <div className="tw-bg-kvanta-purple tw-py-5 print:tw-hidden">
      <div className="tw-mx-auto tw-flex tw-max-w-7xl tw-items-center tw-gap-x-3 tw-px-3 tw-text-sm tw-text-kvanta-purple-dark md:tw-px-6">
        <div
          className="tw-flex tw-w-full tw-items-center tw-justify-between"
          data-testid="pending-events-count"
        >
          <div>
            <Link to={link} className="tw-text-kvanta-purple-dark">
              {message}
            </Link>
          </div>
          <div className="tw-flex tw-items-center">
            <button onClick={handleClose} type="button">
              <IconDescription
                title={i18n.t("pendingEventsNotification.close")}
                icon={<CrossIcon />}
                iconFirst={false}
                theme="purple"
                className="tw-cursor-pointer tw-gap-2 tw-px-4 max-md:tw-hidden"
              />
            </button>
            <Link to={link} className="tw-text-kvanta-purple-dark">
              <IconDescription
                title={linkText}
                icon={<ArrowRightIcon />}
                iconFirst={false}
                theme="purple"
                className="tw-gap-2 tw-px-4"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

type ActionBannerProps = {
  company: CompanyInvolvement | CompanyInformation;
};

const ActionBanner: FunctionComponent<ActionBannerProps> = ({ company }) => {
  const location = useLocation();
  const i18n = useTranslation();
  const { user } = useSession();

  const { pendingEvents, pendingRollbackEvents } = useCompanyEvents(
    company.orgNumber
  );
  const eventsQuery = useParentEventsQuery({
    orgNumber: company.orgNumber,
    offset: 0,
    limit: Number.MAX_SAFE_INTEGER,
  });
  const events = useMemo(
    () => (eventsQuery.isSuccess ? eventsQuery.data.data : []),
    [eventsQuery]
  );

  const versionToApprove = useMemo(() => getVersionToApprove(events), [events]);
  const versionToApproveApprovalInfo = useApprovalInfoQuery(
    company.orgNumber,
    versionToApprove?.value
  );
  const isLedgerPendingApprovalByUser =
    user !== undefined &&
    versionToApproveApprovalInfo.isSuccess &&
    versionToApproveApprovalInfo.data !== null &&
    versionToApproveApprovalInfo.data.rule !== "None" &&
    versionToApproveApprovalInfo.data.status === "Pending" &&
    versionToApproveApprovalInfo.data.pendingApprovalBy.some(
      ({ id }) => id === user.id
    );
  const pendingApprovalRuleProposalQuery = useApprovalRuleProposalQuery(
    company.orgNumber
  );
  const pendingApprovalData = pendingApprovalRuleProposalQuery.data;
  const shouldSetPolicy = useShouldSetPolicy(
    company.orgNumber,
    pendingApprovalData,
    user
  );
  const shouldApprovePolicy = useShouldApprovePolicy(pendingApprovalData, user);

  const entitiesQuery = useEntitiesQuery(company.orgNumber);
  const entitiesMissingPassport = (entitiesQuery.data || []).filter(
    (entity) =>
      entity.type === "Private" &&
      !isSSN(entity.refId) &&
      !entity.passportNumber
  );
  const isEditor = hasRequiredPermission("Editor", company, user);

  const notifications = [
    {
      check: shouldSetPolicy || shouldApprovePolicy,
      message: i18n.t(
        shouldApprovePolicy
          ? "approvalNotification.boardMember"
          : "approvalNotification.administrator"
      ),
      link: `${APP_ROUTE.COMPANIES}/${company.orgNumber}/settings/policy`,
      linkText: i18n.t("approvalNotification.link"),
    },
    {
      check:
        isLedgerPendingApprovalByUser &&
        (pendingEvents.length > 0 || pendingRollbackEvents.length > 0),
      message: i18n.t(
        pendingEvents.length > 0
          ? "pendingEventsNotification.count"
          : "pendingRollbackEventsNotification.count",
        {
          count: pendingEvents.length + pendingRollbackEvents.length,
        }
      ),
      link: `${APP_ROUTE.COMPANIES}/${company.orgNumber}/events`,
      linkText: i18n.t("eventsNotification.approve"),
    },
    {
      check: isEditor && entitiesMissingPassport.length > 0,
      message: i18n.t("passportRequiredNotification.count", {
        count: entitiesMissingPassport.length,
      }),
      link: `${APP_ROUTE.COMPANIES}/${company.orgNumber}/settings/entities`,
      linkText: i18n.t("label.continue"),
    },
  ];

  const activeNotifications = notifications.filter(
    (n) => n.check && location.pathname !== n.link
  );

  return (
    <>
      {activeNotifications.map((n) => (
        <Banner key={n.link} notification={n} />
      ))}
    </>
  );
};

export { ActionBanner };
export type { ActionBannerProps, Approver };
