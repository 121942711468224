import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useDeleteUserMutation } from "../../../api/rest/users";
import type { CompanyInformation } from "../../../types/models/administration";
import type { CompanyInvolvement } from "../../../types/models/company";
import type { User } from "../../../types/models/users";
import { Button } from "../../design-system/Button";
import { Dialog } from "../../design-system/Dialog";
import { TrashIcon } from "../../design-system/icons";

type DeleteUserProps = {
  currentCompany: CompanyInvolvement | CompanyInformation;
  user: User;
  onSuccess: () => void;
  disabled?: boolean;
};

const DeleteUser: React.FunctionComponent<DeleteUserProps> = ({
  currentCompany,
  user,
  onSuccess,
  disabled = false,
}) => {
  const i18n = useTranslation();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const deleteUserMutation = useDeleteUserMutation(
    currentCompany.orgNumber,
    user.id,
    { onSuccess }
  );

  return (
    <>
      <Button
        variant="clean"
        size="sm"
        onClick={() => {
          if (disabled) {
            return;
          }

          setShowConfirmation(true);
        }}
        isLoading={deleteUserMutation.isLoading}
        disabled={disabled}
      >
        <TrashIcon className="tw-h-6 tw-w-6" />
      </Button>
      {showConfirmation && (
        <Dialog
          isOpen
          title={i18n.t("users.delete.title")}
          onClose={() => setShowConfirmation(false)}
          isLoading={deleteUserMutation.isLoading}
          actions={
            <>
              <Button
                onClick={() => setShowConfirmation(false)}
                disabled={deleteUserMutation.isLoading}
              >
                {i18n.t("label.cancel")}
              </Button>
              <Button
                type="button"
                variant="solid"
                color="primary"
                isLoading={deleteUserMutation.isLoading}
                onClick={() => deleteUserMutation.mutate()}
              >
                {i18n.t("label.continue")}
              </Button>
            </>
          }
        >
          {i18n.t("users.delete.description")}
        </Dialog>
      )}
    </>
  );
};

export { DeleteUser };
