import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import {
  useLedgerQuery,
  useShareholdersQuery,
} from "../../../api/blockchain/company";
import { useReverseShareSplit } from "../../../api/rest/events";
import { Actions } from "../../../components/Actions";
import { Alert } from "../../../components/design-system/Alert";
import { Button } from "../../../components/design-system/Button";
import {
  FormError,
  FormErrorList,
  FormGroup,
  FormLabel,
} from "../../../components/design-system/FormGroup";
import {
  ArrowDownIcon,
  CrossIcon,
} from "../../../components/design-system/icons";
import { Input } from "../../../components/design-system/Input";
import { notify } from "../../../components/design-system/Notifications";
import type { TEventSummaryMetric } from "../../../components/EventSummary";
import {
  EventFormWrapper,
  EventSummarySectionList,
} from "../../../components/EventSummary";
import { PageWrapper } from "../../../components/PageWrapper";
import useLatestVersion from "../../../hooks/useLatestVersion";
import { APP_ROUTE } from "../../../routes/constants";
import type { CompanyInformation } from "../../../types/models/administration";
import type { CompanyInvolvement } from "../../../types/models/company";
import { dateToIsoString } from "../../../utils/date";
import { getSplitMetrics } from "../../../utils/shares";

type ReverseSplitSharesProps = {
  currentCompany: CompanyInvolvement | CompanyInformation;
};

type FormProps = { ratioX: number; ratioY: number; date: string };

const formId = "reverse-split-shares-form";

const ReverseSplitShares = ({ currentCompany }: ReverseSplitSharesProps) => {
  const eventsPath = `${APP_ROUTE.COMPANIES}/${currentCompany.orgNumber}/events`;
  const i18n = useTranslation();
  const navigate = useNavigate();
  const form = useForm<FormProps>({ mode: "onChange" });
  const ledgerQuery = useLedgerQuery(currentCompany.orgNumber, "");
  const lastEventDate = useLatestVersion();

  const handleSuccess = () => {
    notify(
      i18n.t("events.success", { name: i18n.t("events.reverseSplit.title") }),
      { type: "success" }
    );
    navigate(eventsPath);
  };

  const mutation = useReverseShareSplit(currentCompany.orgNumber, {
    onSuccess: handleSuccess,
  });

  const LCDRatio = (num1Input: number, num2Input: number) => {
    let num1 = num1Input;
    let num2 = num2Input;
    for (let num = num2; num > 1; num--) {
      if (num1 % num === 0 && num2 % num === 0) {
        num1 = num1 / num;
        num2 = num2 / num;
      }
    }

    return { num1, num2 };
  };

  const handleSubmit = (data: FormProps) => {
    if (!isModifierValid) {
      form.setError("ratioX", {
        type: "manual",
        message: i18n.t("error.validation.split.decrease"),
      });
      form.setError("ratioY", {
        type: "manual",
        message: i18n.t("error.validation.split.decrease"),
      });
      return;
    }
    if (!validRanges) {
      form.setError("ratioX", {
        type: "manual",
        message: i18n.t("error.validation.split.ratio"),
      });
      form.setError("ratioY", {
        type: "manual",
        message: i18n.t("error.validation.split.ratio"),
      });
      return;
    }

    mutation.mutate({
      date: data.date,
      ratio: { x: data.ratioX, y: data.ratioY },
    });
  };

  const [ratioX, ratioY] = form.watch(["ratioX", "ratioY"]);
  const modifier = ratioY / ratioX;
  const isModifierValid = modifier < 1;
  const metrics = getSplitMetrics(modifier || 1, ledgerQuery.data);
  const metricsWithLabel: Record<string, TEventSummaryMetric> = {
    shareCapital: {
      label: `${i18n.t("label.shareCapital")} (${
        currentCompany.settings?.currency
      })`,
      format: "number",
      ...metrics.shareCapital,
    },
    quotaValue: {
      label: `${i18n.t("label.quotaValue")} (${
        currentCompany.settings?.currency
      })`,
      format: "number",
      ...metrics.quotaValue,
    },
    numberOfShares: {
      label: i18n.t("label.shares"),
      format: "number",
      ...metrics.numberOfShares,
    },
  };
  const { dirtyFields, errors } = form.formState;
  const { isLoading } = mutation;

  const shareHoldersQuery = useShareholdersQuery(currentCompany.orgNumber);
  const rangeTotals = (shareHoldersQuery.data || [])
    .map((x) => x.blocks.map((b) => b.end - b.start + 1))
    .flat();
  const validRanges = rangeTotals
    .map((x) => Math.round(x * modifier) === x * modifier)
    .every((x) => x);

  return (
    <PageWrapper data-testid="reverse-split-shares">
      <header className="tw-flex tw-justify-between tw-pb-6">
        <div>
          <h4>{i18n.t("events.reverseSplit.title")}</h4>
        </div>
        <Link to={eventsPath} className="tw-text-body">
          <CrossIcon className="tw-h-6 tw-w-6" />
        </Link>
      </header>
      <EventFormWrapper
        summary={
          <EventSummarySectionList metrics={Object.values(metricsWithLabel)} />
        }
      >
        <form
          className="tw-h-96 tw-space-y-6"
          onSubmit={form.handleSubmit(handleSubmit)}
          id={formId}
        >
          <FormGroup>
            <FormLabel htmlFor="date">{i18n.t("label.date")}</FormLabel>
            <Controller
              control={form.control}
              render={({
                field: { ref, name, onChange, value },
                fieldState,
              }) => (
                <>
                  <Input
                    id="date"
                    value={value}
                    ref={ref}
                    name={name}
                    onChange={onChange}
                    type="date"
                    className="tw-w-full"
                    max={dateToIsoString(new Date())}
                    min={lastEventDate && dateToIsoString(lastEventDate.date)}
                  />
                  <FormError>{fieldState.error?.message}</FormError>
                </>
              )}
              name="date"
              rules={{ required: i18n.t("error.validation.required") }}
            />
          </FormGroup>
          <div className="tw-space-y-2">
            <FormGroup>
              <FormLabel htmlFor="ratioX">
                {i18n.t("events.split.ratioX")}
              </FormLabel>
              <Input
                id="ratioX"
                {...form.register("ratioX", {
                  required: i18n.t("error.validation.required"),
                  valueAsNumber: true,
                  validate: (val) => {
                    if (Number.isNaN(val)) {
                      return i18n.t("error.validation.required");
                    }
                    if (!(val > 0)) {
                      return i18n.t("error.validation.range.min.exclusive", {
                        min: 0,
                      });
                    }

                    return true;
                  },
                })}
                type="number"
              />
              <FormError>{form.formState.errors.ratioX?.message}</FormError>
            </FormGroup>
            <ArrowDownIcon className="tw-h-7 tw-w-7 tw-text-secondary" />
            <FormGroup>
              <FormLabel htmlFor="ratioY">
                {i18n.t("events.split.ratioY")}
              </FormLabel>
              <Input
                id="ratioY"
                {...form.register("ratioY", {
                  required: i18n.t("error.validation.required"),
                  valueAsNumber: true,
                  validate: (val) => {
                    if (Number.isNaN(val)) {
                      return i18n.t("error.validation.required");
                    }
                    if (!(val > 0)) {
                      return i18n.t("error.validation.range.min.exclusive", {
                        min: 0,
                      });
                    }

                    return true;
                  },
                })}
                type="number"
              />
              <FormError>{form.formState.errors.ratioY?.message}</FormError>
            </FormGroup>
            {isModifierValid ? (
              <div className="tw-pt-2">
                <Alert type="neutral">
                  {i18n.t("events.split.form.description", {
                    x: LCDRatio(ratioX, ratioY).num1,
                    y: LCDRatio(ratioX, ratioY).num2,
                  })}
                </Alert>
              </div>
            ) : (
              dirtyFields.ratioX &&
              dirtyFields.ratioY &&
              !errors.ratioX &&
              !errors.ratioY && (
                <div className="tw-pt-2">
                  <Alert type="error">
                    {i18n.t("error.validation.split.decrease")}
                  </Alert>
                </div>
              )
            )}
          </div>
          {mutation.error && <FormErrorList error={mutation.error} />}
          <Actions>
            <Link to="../">
              <Button>{i18n.t("label.cancel")}</Button>
            </Link>
            <Button
              type="submit"
              variant="solid"
              color="primary"
              form={formId}
              isLoading={isLoading}
            >
              {i18n.t("label.save")}
            </Button>
          </Actions>
        </form>
      </EventFormWrapper>
    </PageWrapper>
  );
};

export default ReverseSplitShares;
