import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useCompanyUtils } from "../../context/account";
import { useCompanyEvents } from "../../hooks/useCompanyEvents";
import useLatestVersion from "../../hooks/useLatestVersion";
import type { LedgerVersionDetails } from "../../pages/CompanyShares/useLedgerVersions";
import type { CompanyInformation } from "../../types/models/administration";
import type { CompanyInvolvement } from "../../types/models/company";
import type { DateVersion, Ledger } from "../../types/models/shares";
import {
  getFormattedDate,
  getFormattedLedgerVersion,
  getFormattedTime,
} from "../../utils/date";
import { formatNumber, formatRefId } from "../../utils/format";
import { LogoIcon, PrintCoverIcon } from "../design-system/icons";
import { LabelValue } from "../LabelValue";

type MetricsProps = {
  foundedDateVersion: DateVersion;
  numberOfShareholders: number;
  totalShares: number;
  shareCapital: number;
  quotaValue: number;
};

const Metrics = ({
  foundedDateVersion,
  numberOfShareholders,
  totalShares,
  shareCapital,
  quotaValue,
}: MetricsProps) => {
  const i18n = useTranslation();
  const { formatCurrency } = useCompanyUtils();

  const metrics = [
    {
      label: i18n.t("label.totalNrOfShares"),
      value: formatNumber(totalShares),
    },
    {
      label: i18n.t("label.shareCapital"),
      value: formatCurrency(shareCapital),
    },
    { label: i18n.t("label.quotaValue"), value: formatCurrency(quotaValue) },
  ];

  return (
    <div className="tw-space-y-4 tw-py-4">
      <div className="tw-grid tw-grid-cols-3">
        <LabelValue
          label={i18n.t("events.companyFoundation.title")}
          value={getFormattedDate(foundedDateVersion.date)}
        />
        <LabelValue
          label={i18n.t("label.numberOfShareholders")}
          value={numberOfShareholders}
        />
      </div>
      <div className="tw-grid tw-grid-cols-3">
        {metrics.map((metric) => (
          <LabelValue
            key={metric.label}
            label={metric.label}
            value={metric.value}
          />
        ))}
      </div>
    </div>
  );
};

type Props = {
  currentCompany: CompanyInvolvement | CompanyInformation;
  numberOfShareholders: number;
  ledgerData?: Ledger;
  currentVersion?: DateVersion;
  approvedLedgerVersions: LedgerVersionDetails[];
};
const SharesPrint = ({
  currentCompany,
  numberOfShareholders,
  ledgerData,
  currentVersion,
  approvedLedgerVersions,
}: Props) => {
  const i18n = useTranslation();

  const shareCapital = ledgerData?.capital || 1;
  const totalShares = ledgerData?.shares.total || 1;
  const quotaValue = shareCapital / totalShares;

  // TODO: Unify logic below and get it via context / hooks? Already in parent, SharesLayout.tsx
  const companyEvents = useCompanyEvents(currentCompany.orgNumber);

  const latestVersion = useLatestVersion();
  const currentVersionString =
    currentVersion?.formatedValue ?? latestVersion.formatedValue;
  const currentLedgerVersion = getFormattedLedgerVersion(currentVersionString);
  const isLatestVersion = currentVersionString === latestVersion.formatedValue;
  const currentVersionLabel = `${getFormattedDate(
    currentLedgerVersion.date
  )}, ${i18n.t("label.version")} ${currentLedgerVersion.version}`;
  const latestVersionLabel = ` ${getFormattedDate(
    latestVersion.date
  )}, ${i18n.t("label.version")} ${latestVersion.version}`;
  const foundedDateVersion = useMemo(() => {
    const allEvents = [
      ...companyEvents.approvedEvents,
      ...companyEvents.pendingEvents,
      ...companyEvents.draftEvents,
    ];

    const companyFoundationEvent = allEvents.find(
      (event) => event.type === "CompanyFoundation"
    );

    return getFormattedLedgerVersion(companyFoundationEvent?.date);
  }, [companyEvents]);
  const ledgerIsApproved = currentVersion?.isApproved;
  const latestApprovedVersion = approvedLedgerVersions[0];
  const isLatestApprovedVersion =
    latestApprovedVersion?.formatedValue === currentVersion?.formatedValue;

  return (
    <div className="tw-relative tw-hidden print:tw-block">
      <div className="tw-relative tw-flex tw-min-h-screen tw-w-screen tw-break-after-page tw-flex-col tw-justify-between tw-py-4">
        <div className="tw-divide-y">
          <div className="tw-pb-4">
            <div className="tw-mb-2 tw-font-bold tw-uppercase">
              {ledgerIsApproved
                ? i18n.t("label.shareLedger")
                : i18n.t("label.draftOfShareLedger")}
            </div>
            <h1 className="tw-mb-4 tw-w-full tw-text-6xl">
              {currentCompany.name}
            </h1>
            <div className="tw-mb-4 tw-w-full tw-text-xl tw-text-secondary">
              {formatRefId({
                refId: currentCompany.orgNumber,
                countryCode: currentCompany.countryCode,
                type: "Company",
              })}
            </div>
            <div className="tw-mb-1 tw-flex tw-items-center tw-gap-2 tw-text-sm">
              <span>{currentVersionLabel}</span>
              <span className="tw-inline-flex tw-rounded-xl tw-border tw-border-body tw-px-2 tw-py-1 tw-text-xs">
                {ledgerIsApproved ? "" : "⚠️ "}
                {ledgerIsApproved && isLatestApprovedVersion
                  ? i18n.t("label.approved")
                  : ledgerIsApproved && !isLatestApprovedVersion
                  ? i18n.t("label.notLatestApproved")
                  : i18n.t("label.unapproved")}
              </span>
            </div>
            <div className="tw-text-xs tw-text-secondary">{`${i18n.t(
              "label.latestVersion"
            )} ${isLatestVersion ? "" : latestVersionLabel}`}</div>
          </div>
          <Metrics
            foundedDateVersion={foundedDateVersion}
            numberOfShareholders={numberOfShareholders}
            quotaValue={quotaValue}
            totalShares={totalShares}
            shareCapital={shareCapital}
          />
        </div>
        <div className="tw-z-10 tw-space-y-4 tw-pb-8">
          <div className="tw-text-sm">
            <div>
              {i18n.t("print.export.date", { date: getFormattedTime() })}
            </div>
            <div>{i18n.t("print.export.ad")}</div>
          </div>
          <LogoIcon />
        </div>
        <div className="tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-flex tw-w-full tw-items-end">
          <PrintCoverIcon className="tw-h-[500px]" />
        </div>
        {!ledgerIsApproved && (
          <div className="tw-full tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-top-0 tw-hidden -tw-rotate-12 tw-items-center tw-justify-center tw-text-5xl tw-font-semibold tw-opacity-20 print:tw-flex">
            {i18n.t("label.unapproved")}
          </div>
        )}
      </div>
    </div>
  );
};

export { SharesPrint };
