import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useAddEntityMutation } from "../../../api/rest/entities";
import type { Ti18nKey } from "../../../i18n";
import type { CompanyInformation } from "../../../types/models/administration";
import type { CompanyInvolvement } from "../../../types/models/company";
import type { Entity } from "../../../types/models/entities";
import type { ButtonProps } from "../../design-system/Button";
import { Button } from "../../design-system/Button";
import { PlusIcon } from "../../design-system/icons";
import { EntityTypeOptions } from "../CreateEntityForm/CreateEntityForm";
import { EditEntityDialog } from "../EditEntityDialog";

type AddEntityProps = {
  currentCompany: CompanyInvolvement | CompanyInformation;
  onSuccess: (newEntity: Entity) => void;
  title?: Ti18nKey;
  customButtonProps?: ButtonProps;
  enabledEntityTypes?: EntityTypeOptions[];
};

const AddEntity = ({
  currentCompany,
  onSuccess,
  title = "label.addEntity",
  customButtonProps,
  enabledEntityTypes = ["Company", "PrivateWithSSN", "PrivateWithPassport"],
}: AddEntityProps): JSX.Element => {
  const [isOpen, setOpen] = useState(false);
  const addEntityMutation = useAddEntityMutation(currentCompany.orgNumber, {
    onSuccess: (data) => {
      setOpen(false);
      onSuccess(data);
    },
  });
  const i18n = useTranslation();
  // TODO: Pull trigger out of component, this is not good
  const buttonProps: ButtonProps = customButtonProps || {
    size: "sm",
    variant: "clean",
    color: "secondary",
    prefix: <PlusIcon />,
    children: i18n.t("label.addNew"),
    className: "!tw-pl-0",
  };

  return (
    <>
      <Button
        {...buttonProps}
        onClick={() => {
          setOpen(true);
        }}
      />
      {isOpen && (
        <EditEntityDialog
          formId="add-entity-form"
          defaultValues={{
            refId: "",
            name: "",
            type: "Private",
            countryCode: "SE",
            contact: {
              address: {
                line1: "",
                line2: "",
                city: "",
                postalCode: "",
                region: "",
                countryCode: "",
              },
              email: "",
              phone: "",
            },
          }}
          error={addEntityMutation.error}
          isLoading={addEntityMutation.isLoading}
          onSave={(newEntity) => addEntityMutation.mutate(newEntity)}
          title={i18n.t(title)}
          onClose={() => {
            setOpen(false);
            addEntityMutation.reset();
          }}
          enabledEntityTypes={enabledEntityTypes}
        />
      )}
    </>
  );
};

export { AddEntity };
