import { QRCodeSVG } from "qrcode.react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import type { MockTruidAuthRequest } from "../../api/rest/auth/authTest";
import { Alert } from "../../components/design-system/Alert";
import { Button } from "../../components/design-system/Button";
import {
  FormErrorList,
  FormGroup,
  FormLabel,
} from "../../components/design-system/FormGroup";
import { BankIdIcon, TruidIcon } from "../../components/design-system/icons";
import { Input } from "../../components/design-system/Input";
import { Loading } from "../../components/design-system/Loading";
import { SelectCountry } from "../../components/SelectCountry";
import { featureToggles } from "../../config";
import { useSession } from "../../context/session";
import { useToggles } from "../../hooks/useToggles";
import isMobileDevice from "../../utils/isMobileDevice";

const SignInForm = () => {
  const [step, setStep] = useState<
    | "bankId"
    | "Truid"
    | "initial"
    | "bankIdMock"
    | "TruidMock"
    | "bankIdSamedevice"
    | "TruidCollect"
  >("initial");
  const i18n = useTranslation();
  const { isFeatureEnabled } = useToggles();

  const mockAuthEnabled = isFeatureEnabled(featureToggles.ENABLE_MOCK_AUTH);

  const {
    onInitSameDeviceBankIdSignIn,
    onInitQRBankIdSignIn,
    onInitTruidSignIn,
    onCollectTruidAuth,
    onInitMockBankIdSignIn,
    onCancelQRBankIdAuth,
    BankIdQRCodeQuery,
    collectBankIdAuthMutation,
    collectTruidAuthMutation,
    initBankIdQRAuthMutation,
    initTruidAuthMutation,
    onInitMockTruidSignIn,
    initMockTruidAuthMutation,
    onCancelSameDeviceBankId,
    onCancelMockBankId,
    TruidLoginState,
  } = useSession();

  const [TruidMockState, setTruidLoginState] = useState<
    Partial<MockTruidAuthRequest>
  >({
    countryCode: "SE",
  });

  const handleBankIdClick = () => {
    setStep("bankId");
    onInitQRBankIdSignIn();
  };

  const handleTruidClick = () => {
    setStep("Truid");
    onInitTruidSignIn();
  };

  const handleSameDeviceClick = () => {
    setStep("bankIdSamedevice");
    onInitSameDeviceBankIdSignIn();
  };

  const handleMockClick = () => {
    setStep("bankIdMock");
    onInitMockBankIdSignIn();
  };

  const handleStartMockTruidClick = () => setStep("TruidMock");

  const handleMockTruid = () => {
    onInitMockTruidSignIn(TruidMockState as MockTruidAuthRequest);
  };

  const isBankIdError =
    collectBankIdAuthMutation?.data?.status === "failed" ||
    collectBankIdAuthMutation?.isError;

  const isBankIdQrLoading =
    initBankIdQRAuthMutation?.isLoading || BankIdQRCodeQuery?.isLoading;

  const isTruidRequestLoading = initTruidAuthMutation?.isLoading;

  const isTruidRequestError =
    initTruidAuthMutation?.isError || collectTruidAuthMutation?.isError;

  const truiIdError = initTruidAuthMutation?.isError
    ? initTruidAuthMutation.error
    : collectTruidAuthMutation?.isError
    ? collectTruidAuthMutation.error
    : null;

  useEffect(() => {
    const TruidCallback = (event: MessageEvent) => {
      const payload = event.data;
      if (!payload || event.origin !== window.location.origin) {
        return;
      }

      if (payload.type === "TruidResponse") {
        onCollectTruidAuth(payload.code ?? payload.error, payload.state);
        setStep("TruidCollect");
      }
    };

    window.addEventListener("message", TruidCallback);
    return () => {
      window.removeEventListener("message", TruidCallback);
    };
  }, [onCollectTruidAuth]);

  useEffect(() => {
    const url = TruidLoginState?.authorizationUrl;
    const isMobile = isMobileDevice();
    if (isMobile && url && url.length > 0) {
      window.location.href = url;
    }
  }, [TruidLoginState?.authorizationUrl]);

  if (step === "initial" && collectBankIdAuthMutation?.isLoading) {
    // For redirects from BankID app
    return <Loading />;
  }

  return (
    <div>
      {step === "initial" && (
        <div className="tw-text-center">
          <h1>{i18n.t("login.title")}</h1>
          <p className="tw-mb-6 tw-text-secondary">{i18n.t("login.tip")}</p>
          <div className="tw-hidden tw-gap-4 lg:tw-flex">
            <Button
              onClick={handleBankIdClick}
              variant="solid"
              color="primary"
              prefix={<BankIdIcon />}
            >
              {i18n.t("login.mobileBankId")}
            </Button>
            <Button
              variant="outline"
              color="secondary"
              onClick={handleSameDeviceClick}
              prefix={<BankIdIcon />}
            >
              {i18n.t("login.bankIdSameDevice")}
            </Button>
            <Button
              variant="outline"
              color="secondary"
              onClick={handleTruidClick}
              prefix={
                <span>
                  <TruidIcon />
                </span>
              }
            >
              {i18n.t("login.withMobileTruid")}
            </Button>
            {mockAuthEnabled && (
              <Button
                color="danger"
                prefix={<BankIdIcon />}
                variant="outline"
                onClick={handleMockClick}
              >
                Mock
              </Button>
            )}
            {mockAuthEnabled && (
              <Button
                color="danger"
                prefix={<TruidIcon />}
                variant="outline"
                onClick={handleStartMockTruidClick}
              >
                Mock Truid
              </Button>
            )}
          </div>
          <div className="tw-flex tw-flex-col tw-gap-4 lg:tw-hidden">
            <Button
              onClick={handleSameDeviceClick}
              variant="solid"
              color="primary"
              prefix={<BankIdIcon />}
            >
              {i18n.t("login.mobileBankId")}
            </Button>
            <Button
              variant="outline"
              color="secondary"
              onClick={handleBankIdClick}
              prefix={<BankIdIcon />}
            >
              {i18n.t("login.mobileBankId.anotherDevice")}
            </Button>
            <Button
              variant="outline"
              color="secondary"
              onClick={handleTruidClick}
              prefix={
                <span>
                  <TruidIcon />
                </span>
              }
            >
              {i18n.t("login.withMobileTruid")}
            </Button>
            {mockAuthEnabled && (
              <>
                <Button
                  color="danger"
                  prefix={<BankIdIcon />}
                  variant="outline"
                  onClick={handleMockClick}
                >
                  Mock
                </Button>
                <Button
                  color="danger"
                  prefix={<TruidIcon />}
                  variant="outline"
                  onClick={handleStartMockTruidClick}
                >
                  Mock Truid
                </Button>
              </>
            )}
          </div>
        </div>
      )}
      {step === "TruidMock" && (
        <div className=" tw-w-96 tw-px-4">
          <form
            className="tw-flex tw-w-full tw-flex-col tw-gap-4"
            onSubmit={(event) => {
              handleMockTruid();
              event.preventDefault();
              event.stopPropagation();
            }}
          >
            <FormGroup>
              <FormLabel htmlFor="passportNumber">Passport Number</FormLabel>
              <Input
                value={TruidMockState.passportNumber}
                id="passportNumber"
                type="text"
                onChange={(e) =>
                  setTruidLoginState({
                    ...TruidMockState,
                    passportNumber: e.target.value,
                  })
                }
              />
            </FormGroup>
            <FormGroup>
              <FormLabel htmlFor="TruidUserId">Truid UserId</FormLabel>
              <Input
                id="TruidUserId"
                value={TruidMockState.TruidUserId}
                type="text"
                onChange={(e) =>
                  setTruidLoginState({
                    ...TruidMockState,
                    TruidUserId: e.target.value,
                  })
                }
              />
            </FormGroup>
            <FormGroup>
              <FormLabel htmlFor="countryCode">Nationality</FormLabel>
              <SelectCountry
                name="countryCode"
                onChange={(newValue) =>
                  setTruidLoginState({
                    ...TruidMockState,
                    countryCode: newValue,
                  })
                }
                value={TruidMockState.countryCode as string}
              />
            </FormGroup>
            {initMockTruidAuthMutation?.isError && (
              <div className="tw-flex tw-justify-between">
                <pre>
                  {JSON.stringify(initMockTruidAuthMutation?.error, null, 2)}
                </pre>
              </div>
            )}
            <div className="tw-flex tw-justify-between">
              <Button
                type="button"
                onClick={() => setStep("initial")}
                disabled={initMockTruidAuthMutation?.isLoading}
              >
                Back
              </Button>
              <Button
                type="submit"
                disabled={initMockTruidAuthMutation?.isLoading}
              >
                {initMockTruidAuthMutation?.isError
                  ? "Try again"
                  : "Mock Truid"}
              </Button>
            </div>
          </form>
        </div>
      )}
      {step === "bankId" && (
        <div className="tw-space-y-8">
          <h3 className="tw-text-center">{i18n.t("login.withMobileBankId")}</h3>
          {isBankIdError && (
            <div className="tw-space-y-4">
              {collectBankIdAuthMutation.data?.status === "failed" && (
                <Alert
                  type="error"
                  className="tw-max-w-lg tw-rounded tw-border tw-border-error tw-p-2"
                >
                  {i18n.t("auth.bankId.startFailed")}{" "}
                  <a
                    href="https://install.bankid.com"
                    rel="noreferrer"
                    target="_blank"
                  >
                    https://install.bankid.com.
                  </a>
                </Alert>
              )}
              {collectBankIdAuthMutation.isError && (
                <FormErrorList error={collectBankIdAuthMutation.error} />
              )}
              <div className="tw-flex tw-justify-center">
                <Button
                  onClick={handleBankIdClick}
                  variant="solid"
                  color="primary"
                >
                  {i18n.t("label.tryAgain")}
                </Button>
              </div>
            </div>
          )}
          {isBankIdQrLoading && <Loading />}
          {!isBankIdError && BankIdQRCodeQuery?.data && (
            <div className="tw-flex tw-justify-center">
              <QRCodeSVG
                fgColor="currentColor"
                size={256}
                value={BankIdQRCodeQuery.data.qrCode}
                role="img"
                aria-label={i18n.t("label.qrCode")}
              />
            </div>
          )}
          {!isBankIdError && (
            <ol className="tw-list-decimal tw-pl-8 tw-text-gray-700">
              <li>{i18n.t("login.qrCodeStep1")}</li>
              <li>{i18n.t("login.qrCodeStep2")}</li>
              <li>{i18n.t("login.qrCodeStep3")}</li>
            </ol>
          )}
          <div className="tw-flex tw-items-center tw-justify-center">
            <Button
              onClick={() => {
                onCancelQRBankIdAuth();
                setStep("initial");
              }}
              variant="clean"
              color="primary"
            >
              {i18n.t("label.cancel")}
            </Button>
          </div>
        </div>
      )}
      {step === "Truid" && isTruidRequestLoading && <Loading />}
      {step === "Truid" && TruidLoginState && (
        <div className="tw-flex tw-flex-col tw-space-y-8">
          <iframe
            referrerPolicy="no-referrer"
            title="Truid"
            src={TruidLoginState.authorizationUrl}
            height={500}
            width={400}
          />
          <Button
            onClick={() => {
              initTruidAuthMutation?.reset();
              collectTruidAuthMutation?.reset();
              setStep("initial");
            }}
            variant="clean"
            color="primary"
          >
            {i18n.t("label.cancel")}
          </Button>
        </div>
      )}
      {step === "TruidCollect" && collectTruidAuthMutation?.isLoading && (
        <Loading />
      )}
      {isTruidRequestError && (
        <div className="tw-flex tw-flex-col">
          {truiIdError?.status === 403
            ? i18n.t("auth.Truid.loginFailed.useBankId")
            : i18n.t("auth.Truid.loginFailed")}

          <Button
            onClick={() => {
              initTruidAuthMutation?.reset();
              collectTruidAuthMutation?.reset();
              setStep("initial");
            }}
            variant="clean"
            color="primary"
          >
            {i18n.t("label.return")}
          </Button>
        </div>
      )}
      {step === "bankIdSamedevice" && (
        <div className="tw-flex tw-flex-col">
          <h3 className="tw-hidden tw-text-center lg:tw-block">
            {i18n.t("login.tip")} {i18n.t("login.bankIdSameDevice")}
          </h3>
          <h3 className="tw-block tw-text-center lg:tw-hidden">
            {i18n.t("login.withMobileBankId")}
          </h3>
          {collectBankIdAuthMutation?.error ? (
            <div className="tw-py-8">
              <FormErrorList error={collectBankIdAuthMutation.error} />
            </div>
          ) : (
            <Loading />
          )}
          <Button
            onClick={() => {
              onCancelSameDeviceBankId();
              setStep("initial");
            }}
            variant="clean"
            color="primary"
          >
            {i18n.t("label.cancel")}
          </Button>
        </div>
      )}
      {step === "bankIdMock" && (
        <div className="tw-flex tw-flex-col">
          <h3 className="tw-text-center">{i18n.t("login.tip")} mock</h3>
          {collectBankIdAuthMutation?.error ? (
            <div className="tw-py-8">
              <FormErrorList error={collectBankIdAuthMutation.error} />
            </div>
          ) : (
            <Loading />
          )}
          <Button
            color="primary"
            variant="clean"
            onClick={() => {
              onCancelMockBankId();
              setStep("initial");
            }}
          >
            {i18n.t("label.cancel")}
          </Button>
        </div>
      )}
    </div>
  );
};

export default SignInForm;
