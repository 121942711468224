import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import type { SubmitHandler } from "react-hook-form";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import {
  useClearCompanyDataCacheMutation,
  useClearCompanyInvolvementDataCacheMutation,
  useRefreshCompanyDetailsMutation,
} from "../../../api/rest/administration";
import { Alert } from "../../../components/design-system/Alert";
import { Button } from "../../../components/design-system/Button";
import { Checkbox } from "../../../components/design-system/Checkbox";
import { Description } from "../../../components/design-system/Description";
import { Dialog } from "../../../components/design-system/Dialog";
import {
  FormError,
  FormGroup,
  FormLabel,
} from "../../../components/design-system/FormGroup";
import { Input } from "../../../components/design-system/Input";
import { notify } from "../../../components/design-system/Notifications";
import { Select } from "../../../components/design-system/Select/Select";
import { validateSwedishPersonalNumber } from "../../../utils/validation";

const ClearCacheFormSchema = z
  .object({
    type: z.enum(["company-data", "company-involvement"]),
    id: z.string(),
    refreshDataInSystem: z.boolean().optional(),
  })
  .refine(
    (formData) => {
      switch (formData.type) {
        case "company-data":
          return formData.id?.match(/^\d{10}$/);
        case "company-involvement":
          return !validateSwedishPersonalNumber(formData.id);
        default:
          return false;
      }
    },
    (args) => ({
      message:
        args.type === "company-data"
          ? "orgNumber.format.invalid"
          : "swedishPersonalNumber.format.invalid",
      path: ["id"],
    })
  );

type ClearCacheFormType = z.infer<typeof ClearCacheFormSchema>;

const typeOptions = [
  { label: "Company data", value: "company-data" },
  { label: "Company involvement", value: "company-involvement" },
];

const formDefaultValues: ClearCacheFormType = {
  type: "company-data",
  id: "",
  refreshDataInSystem: true,
};

export const ClearCache = () => {
  const i18n = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting },
    setValue,
  } = useForm<ClearCacheFormType>({
    resolver: zodResolver(ClearCacheFormSchema),
    mode: "onBlur",
    defaultValues: formDefaultValues,
  });
  const clearCompanyDataCache = useClearCompanyDataCacheMutation();
  const clearCompanyInvolvementCache =
    useClearCompanyInvolvementDataCacheMutation();
  const refreshCompanyDetails = useRefreshCompanyDetailsMutation();

  const typeValue = watch("type");

  const onSubmit: SubmitHandler<ClearCacheFormType> = async (data) => {
    let cacheCleared;
    switch (data.type) {
      case "company-data":
        cacheCleared = await clearCompanyDataCache.mutateAsync(data.id);
        break;
      case "company-involvement":
        cacheCleared = await clearCompanyInvolvementCache.mutateAsync(data.id);
        break;
      default:
        return;
    }

    if (!cacheCleared) {
      return;
    }

    if (data.refreshDataInSystem && data.type === "company-data") {
      const dataUpdated = await refreshCompanyDetails.mutateAsync(data.id);
      if (!dataUpdated) {
        return;
      }
    }

    const sucessMessage = data.refreshDataInSystem
      ? i18n.t("admin.clearCacheAndRefreshData.response.success")
      : i18n.t("admin.clearCache.response.success");
    notify(<Description title={sucessMessage} />, {
      type: "success",
    });

    setIsModalOpen(false);
  };

  const formId = "clear-cache-form";
  const isLoading =
    isSubmitting ||
    clearCompanyDataCache.isLoading ||
    clearCompanyInvolvementCache.isLoading;

  useEffect(() => {
    if (typeValue === "company-involvement") {
      setValue("refreshDataInSystem", false);
    }
  }, [typeValue, setValue]);
  return (
    <>
      <Button className="tw-w-full" onClick={() => setIsModalOpen(true)}>
        {i18n.t("admin.button.clearCache")}
      </Button>
      {isModalOpen && (
        <Dialog
          canClose
          isOpen
          onClose={() => setIsModalOpen(false)}
          actions={
            <Button
              color="primary"
              form={formId}
              isLoading={isLoading}
              type="submit"
              variant="solid"
            >
              {i18n.t("admin.clearCache.label.clear")}
            </Button>
          }
          isLoading={isLoading}
          title={i18n.t("admin.clearCache.title.clearCacheDialog")}
        >
          <form
            className="tw-mt-4 tw-space-y-4"
            id={formId}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              name="type"
              control={control}
              render={({ field: { value, onChange, onBlur } }) => (
                <FormGroup>
                  <FormLabel htmlFor="type">
                    {i18n.t("admin.clearCache.label.cacheType")}
                  </FormLabel>
                  <Select
                    name="type"
                    required
                    value={typeOptions.find((option) => option.value === value)}
                    onChange={(option) => {
                      if (option) {
                        onChange(option?.value);
                      }
                    }}
                    onBlur={onBlur}
                    options={typeOptions}
                  />
                </FormGroup>
              )}
            />
            <Controller
              name="id"
              control={control}
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error },
              }) => (
                <FormGroup>
                  <FormLabel htmlFor="id">
                    {i18n.t(
                      typeValue === "company-data"
                        ? "label.organizationId"
                        : "label.personId"
                    )}
                  </FormLabel>
                  <Input
                    name="id"
                    required
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                  {error && (
                    <FormError>{i18n.t(error.message as never)}</FormError>
                  )}
                </FormGroup>
              )}
            />
            {typeValue === "company-data" && (
              <Controller
                name="refreshDataInSystem"
                control={control}
                render={({
                  field: { value, onChange, onBlur },
                  fieldState: { error },
                }) => (
                  <FormGroup>
                    <Checkbox
                      name="refreshDataInSystem"
                      checked={value}
                      onChange={onChange}
                      onBlur={onBlur}
                    >
                      {i18n.t("label.refreshDataInSystem")}
                    </Checkbox>
                    <Alert
                      type="neutral"
                      className="tw-text-sm tw-text-gray-600"
                    >
                      {i18n.t("label.refreshDataInSystem.description")}
                    </Alert>
                    {error && (
                      <FormError>{i18n.t(error.message as never)}</FormError>
                    )}
                  </FormGroup>
                )}
              />
            )}

            {clearCompanyDataCache.isError && (
              <FormError>
                {clearCompanyDataCache.error.errors[0].message.text}
              </FormError>
            )}
            {clearCompanyInvolvementCache.isError && (
              <FormError>
                {clearCompanyInvolvementCache.error.errors[0].message.text}
              </FormError>
            )}
            {clearCompanyInvolvementCache.isError && (
              <FormError>
                {clearCompanyInvolvementCache.error.errors[0].message.text}
              </FormError>
            )}
            {refreshCompanyDetails.isError && (
              <FormError>
                {refreshCompanyDetails.error.errors[0].message.text}
              </FormError>
            )}
          </form>
        </Dialog>
      )}
    </>
  );
};
