import type {
  Shareblock,
  Shareholder,
  ShareRange,
} from "../../types/models/shares";

function getSelectedBlocksByTypeFromShareholders(
  shareRanges: Array<ShareRange>,
  shareHolders: Array<Shareholder>
) {
  return shareHolders.reduce((blocksByType, shareHolder) => {
    let newValue = { ...blocksByType };
    const filteredBlocks = shareHolder.blocks.filter((block) =>
      shareRanges.some((range) => {
        if (range.start && range.end && range.start <= range.end) {
          const rangeIsInBlock =
            range?.start >= block.start && range?.end <= block.end;
          const startIsInBlock =
            range?.start >= block.start && range.start <= block.end;
          const endIsInBlock =
            range?.end >= block.start && range.end <= block.end;
          return rangeIsInBlock || startIsInBlock || endIsInBlock;
        }

        return false;
      })
    );

    filteredBlocks.forEach((block) => {
      const knownBlockType = newValue[block.type];
      if (knownBlockType) {
        newValue = {
          ...newValue,
          [block.type]: [...knownBlockType, block],
        };
      } else {
        newValue = { ...newValue, [block.type]: [block] };
      }
    });

    return newValue;
  }, {} as Record<string, Omit<Shareblock, "holder">[]>);
}

export { getSelectedBlocksByTypeFromShareholders };
export type { ShareRange };
