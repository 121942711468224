import { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  useAddEntityMutation,
  useAddEntityRelationshipMutation,
} from "../../../api/rest/entities";
import type { CompanyInformation } from "../../../types/models/administration";
import type { CompanyInvolvement } from "../../../types/models/company";
import type { Entity, NewEntity } from "../../../types/models/entities";
import type { ButtonProps } from "../../design-system/Button";
import { Button } from "../../design-system/Button";
import { Dialog } from "../../design-system/Dialog";
import { PlusIcon } from "../../design-system/icons";
import { CreateEntityForm } from "../CreateEntityForm/CreateEntityForm";

type AddRepresentativesProps = {
  currentCompany: CompanyInvolvement | CompanyInformation;
  onSuccess: () => void;
  entity: Entity;
};

const AddRepresentatives = ({
  currentCompany,
  onSuccess,
  entity,
}: AddRepresentativesProps): JSX.Element => {
  const [isOpen, setOpen] = useState(false);
  const addEntityMutation = useAddEntityMutation(currentCompany.orgNumber, {
    onSuccess: (data) => {
      addEntityRelationshipMutation.mutate([data.id]);
    },
  });
  const addEntityRelationshipMutation = useAddEntityRelationshipMutation(
    currentCompany.orgNumber,
    entity.id,
    {
      onSuccess: () => {
        onClose();
        onSuccess();
      },
    }
  );
  const formId = "add-representative-form";
  const i18n = useTranslation();
  const buttonProps: ButtonProps = {
    variant: "outline",
    prefix: <PlusIcon />,
    children: i18n.t("label.addRepresentatives.new"),
  };
  const onClose = () => {
    setOpen(false);
    addEntityMutation.reset();
    addEntityRelationshipMutation.reset();
  };

  return (
    <>
      <Button
        {...buttonProps}
        onClick={() => {
          setOpen(true);
        }}
      />
      {isOpen && (
        <Dialog
          isOpen
          actions={
            <>
              <Button disabled={addEntityMutation.isLoading} onClick={onClose}>
                {i18n.t("label.cancel")}
              </Button>
              <Button
                color="primary"
                form={formId}
                isLoading={addEntityMutation.isLoading}
                type="submit"
                variant="solid"
              >
                {i18n.t("label.save")}
              </Button>
            </>
          }
          isLoading={addEntityMutation.isLoading}
          size="lg"
          title={i18n.t("label.addRepresentatives.new")}
          onClose={onClose}
        >
          <CreateEntityForm
            error={addEntityMutation.error}
            onSave={(newEntity: NewEntity) => {
              addEntityMutation.mutate(newEntity);
            }}
            defaultValues={{
              refId: "",
              name: "",
              type: "Private",
              countryCode: "SE",
              contact: {
                address: {
                  line1: "",
                  line2: "",
                  city: "",
                  postalCode: "",
                  region: "",
                  countryCode: "",
                },
                email: "",
                phone: "",
              },
            }}
            formId={formId}
            enabledEntityTypes={["PrivateWithPassport", "PrivateWithSSN"]}
          />
        </Dialog>
      )}
    </>
  );
};

export { AddRepresentatives };
