import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { z } from "zod";

import { Button } from "../../../components/design-system/Button";
import { Checkbox } from "../../../components/design-system/Checkbox";
import { Dialog } from "../../../components/design-system/Dialog";
import {
  FormError,
  FormGroup,
  FormLabel,
} from "../../../components/design-system/FormGroup";
import { Input } from "../../../components/design-system/Input";
import {
  isPossiblePhoneNumber,
  PhoneInput,
} from "../../../components/design-system/PhoneInput";

type WelcomeProps = {
  companyName: string;
  isLoading: boolean;
  orgNumber: string;
  onClose: () => void;
  onSubmit: (data: FormValues) => void;
};

const formId = "welcome-form";

const getOnboardingDetailsSchema = (message: string, checkboxMessage: string) =>
  z.object({
    orgNumber: z.string().nonempty(),
    email: z.string().email({ message }),
    phone: z.string().nonempty(message).refine(isPossiblePhoneNumber, message),
    agree: z.boolean().refine((value) => value === true, checkboxMessage),
  });

type FormValues = z.infer<ReturnType<typeof getOnboardingDetailsSchema>>;

const Welcome: React.FunctionComponent<WelcomeProps> = ({
  companyName,
  orgNumber,
  isLoading,
  onClose,
  onSubmit,
}) => {
  const defaultValues: FormValues = {
    orgNumber,
    email: "",
    phone: "",
    agree: false,
  };
  const i18n = useTranslation();

  const OnboardingDetailsSchema = getOnboardingDetailsSchema(
    i18n.t("error.validation.format"),
    i18n.t("error.validation.required")
  );

  const resolver = zodResolver(OnboardingDetailsSchema);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormValues>({
    mode: "onChange",
    defaultValues,
    resolver,
  });

  const title = i18n.t("company.welcome.title", { companyName });

  return (
    <Dialog
      isOpen
      actions={
        <>
          <Button disabled={isLoading} onClick={onClose}>
            {i18n.t("label.cancel")}
          </Button>
          <Button
            color="primary"
            form={formId}
            isLoading={isLoading}
            type="submit"
            variant="solid"
          >
            {i18n.t("company.welcome.submit")}
          </Button>
        </>
      }
      isLoading={isLoading}
      title={title}
      onClose={onClose}
    >
      <p>{i18n.t("company.welcome.copy1")}</p>
      <p>{i18n.t("company.welcome.copy2", { companyName })}</p>
      <p>{i18n.t("company.welcome.form")}</p>
      <form
        className="tw-space-y-4"
        id={formId}
        onSubmit={(event) => {
          event.stopPropagation();

          return handleSubmit((data) => {
            onSubmit(data);
          })(event);
        }}
      >
        <FormGroup>
          <FormLabel htmlFor="email">{i18n.t("label.email")}</FormLabel>
          <Input id="email" {...register("email")} />
          <FormError>{errors.email?.message}</FormError>
        </FormGroup>
        <FormGroup>
          <FormLabel htmlFor="phone">{i18n.t("label.phone")}</FormLabel>
          <Controller
            control={control}
            name="phone"
            render={({ field: { onChange, value, name }, fieldState }) => (
              <>
                <PhoneInput
                  id="phone"
                  name={name}
                  value={value}
                  onChange={onChange}
                />
                <FormError>{fieldState.error?.message}</FormError>
              </>
            )}
          />
        </FormGroup>
        <FormGroup>
          <Controller
            name="agree"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, onBlur, name },
              fieldState,
            }) => (
              <>
                <Checkbox
                  id="agree"
                  name={name}
                  checked={value}
                  onChange={onChange}
                  onBlur={onBlur}
                >
                  <Trans
                    components={{
                      privacyPolicyLink: (
                        <Link
                          target="_blank"
                          to="https://kvanta.com/privacy-policy"
                        />
                      ),
                    }}
                    i18nKey="company.welcome.agree"
                  />
                </Checkbox>
                <FormError>{fieldState.error?.message}</FormError>
              </>
            )}
          />
        </FormGroup>
      </form>
    </Dialog>
  );
};

export { Welcome };
