import { useMemo } from "react";

import { useVersionsQuery } from "../api/blockchain/events";
import { useCurrentCompany } from "../context/account";
import { getFormattedLedgerVersion } from "../utils/date";
import { sortVersions } from "../utils/versions-utils";
import { useCompanyEvents } from "./useCompanyEvents";

const useLatestVersion = () => {
  const currentCompany = useCurrentCompany();
  const events = useCompanyEvents(currentCompany?.orgNumber || "");
  const availableVersionsQuery = useVersionsQuery(
    currentCompany?.orgNumber || ""
  );

  const eventsVersions = useMemo(() => {
    const allEvents = [];
    allEvents.push(...events.approvedEvents.map((e) => e.date));
    allEvents.push(...events.pendingEvents.map((e) => e.date));
    allEvents.push(...events.draftEvents.map((e) => e.date));

    const resultWithUniqItems = [...new Set(allEvents)];
    return resultWithUniqItems;
  }, [events]);

  const lastVersionDate = useMemo(() => {
    if (!availableVersionsQuery.isSuccess) {
      return getFormattedLedgerVersion();
    }
    const filteredVersions =
      availableVersionsQuery.data?.filter((e) => eventsVersions.includes(e)) ??
      [];
    const lastVersion = sortVersions(filteredVersions).pop();
    return getFormattedLedgerVersion(lastVersion);
  }, [
    availableVersionsQuery.data,
    availableVersionsQuery.isSuccess,
    eventsVersions,
  ]);

  return lastVersionDate;
};

export default useLatestVersion;
