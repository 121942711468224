const APP_ROUTE = {
  HOME: "/",
  TRU_ID_COMPLETE_SIGN_IN: "/truid/v1/complete-login",
  TRU_ID_COMPLETE_SIGN: "/truid/v1/complete-sign",
  SIGN_IN: "/sign-in",
  COMPANIES: "/companies",
  OWNERSHIP: "/ownership",
  ADMINISTRATION: "/administration",
  PATH_APPROVAL_POLICY: "approval-policy",
} as const;

export { APP_ROUTE };
