import type React from "react";
import { useTranslation } from "react-i18next";

import { clsxm } from "../../utils/tailwind";
import { BellIcon, CheckmarkIcon } from "../design-system/icons";

type ApprovalStatusProps = {
  isPendingYourApproval: boolean;
};

const ApprovalStatus: React.FunctionComponent<ApprovalStatusProps> = ({
  isPendingYourApproval,
}) => {
  const i18n = useTranslation();
  const [IconComponent, colorClassName, description] = isPendingYourApproval
    ? [
        BellIcon,
        "tw-text-yellow-700",
        i18n.t("listApprovalStatus.waitingForYou"),
      ]
    : [
        CheckmarkIcon,
        "tw-text-green-700",
        i18n.t("listApprovalStatus.youHaveApproved"),
      ];

  return (
    <div
      className={clsxm(
        "tw-flex tw-items-center tw-gap-2 tw-text-sm",
        colorClassName
      )}
    >
      <IconComponent className={clsxm("tw-h-5 tw-w-5", colorClassName)} />
      {description}
    </div>
  );
};

export { ApprovalStatus };
