import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Checkbox } from "../../../../../../components/design-system/Checkbox";
import { FormError } from "../../../../../../components/design-system/FormGroup";
import { InfoIcon } from "../../../../../../components/design-system/icons";

type AcceptLedgerAsOfficialProps = {
  onSubmit: (newValue: boolean) => void;
  formId: string;
  isChecked: boolean;
  companyName: string;
};

const AcceptLedgerAsOfficial = ({
  onSubmit,
  formId,
  isChecked,
  companyName,
}: AcceptLedgerAsOfficialProps) => {
  const { handleSubmit, control } = useForm<{ agree: boolean }>({
    mode: "onSubmit",
    defaultValues: {
      agree: isChecked,
    },
  });

  const i18n = useTranslation();
  return (
    <form
      id={formId}
      onSubmit={(event) => {
        event.stopPropagation();
        return handleSubmit((data) => {
          onSubmit(data.agree);
        })(event);
      }}
    >
      <div>
        <h3>{i18n.t("settings.shareLedger.approveAsOfficialStep.title")}</h3>
        <p className="tw-mt-2">
          {i18n.t("settings.shareLedger.approveAsOfficialStep.description", {
            companyName,
          })}
        </p>
        <div className="tw-mt-8">
          <Controller
            name="agree"
            control={control}
            rules={{ required: i18n.t("error.validation.required") }}
            render={({ field: { value, onChange }, fieldState }) => (
              <>
                <Checkbox
                  checked={value}
                  onChange={(event) => onChange(!!event.target?.checked)}
                >
                  {i18n.t(
                    "settings.shareLedger.approveAsOfficialStep.checkbox"
                  )}
                </Checkbox>
                <FormError>{fieldState.error?.message}</FormError>
              </>
            )}
          />
        </div>

        <p className="tw-mt-6 tw-flex tw-flex-row tw-items-start tw-border tw-p-6 tw-text-gray-500">
          <InfoIcon className="tw-h-6 tw-w-6 tw-shrink-0" />
          <div className="tw-ml-3">
            {i18n.t("settings.shareLedger.approveAsOfficialStep.info")}
          </div>
        </p>
      </div>
    </form>
  );
};

export { AcceptLedgerAsOfficial };
