import { BlockchainClient } from "@capchapdev/kvanta-postchain-client";

import { blockchainRID, blockchainUrls, chainId } from "../../config";
import { randomElementInList } from "../../utils/random";

const randomBlockchainUrl = randomElementInList(blockchainUrls);

const createBlockchainClient = (
  blockchainUrl: string,
  id: number | string
): BlockchainClient => {
  if (typeof id === "string") {
    return BlockchainClient.initializeByBrid(blockchainUrl, id);
  }

  return BlockchainClient.initializeByChainId(blockchainUrl, id);
};

const blockchainClient = createBlockchainClient(
  randomBlockchainUrl,
  blockchainRID ?? chainId
);

export { blockchainClient };
