const PrintCoverIcon = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    width={1200}
    height={751}
    viewBox="0 0 1200 751"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="m-8.068 841.436 225.945-174.1m0 0-97.183 557.194 519.675 2.16M217.877 667.336l88.494-513.249M842.92 22.511l3.128 17.311m-3.128-17.31-505.309 88.726M842.92 22.511l-124.469 739.98m345.429 462.039h150.38V566.718m-150.38 657.812-415.327 2.16m415.327-2.16-20.73-110.47m-79.2-421.84 250.31-242.35v116.848M963.95 692.22l18.077 96.291M963.95 692.22 846.048 39.822m135.979 748.689 232.233-221.793M982.027 788.511l61.123 325.549M846.048 39.822l-80.843 485.834M648.553 1226.69h-8.184m8.184 0 98.748-593.434 17.904-107.6M640.369 1226.69l78.082-464.199m-412.08-608.404 6.644-38.53 24.596-4.319m-31.24 42.849L470.105 1225.98M337.611 111.238l380.84 651.253m46.754-236.835 277.945 588.404"
        stroke="#BBB"
        strokeWidth={2.017}
      />
    </g>
  </svg>
);

export default PrintCoverIcon;
