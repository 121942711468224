import { Progress } from "../../../pages/companies/[companyId]/settings/policy/components/Progress";
import { Dialog2 } from "../Dialog2";

type ProgressDialogProps = {
  children: React.ReactNode;
  totalSteps: number;
  currentStep: number;
  actions: React.ReactNode;
  isLoading: boolean;
  onClose: () => void;
};

const ProgressDialog = ({
  children,
  totalSteps,
  currentStep,
  actions,
  isLoading,
  onClose,
}: ProgressDialogProps) => (
  <Dialog2
    isOpen
    onClose={() => onClose()}
    stackedActions={false}
    headerChildren={
      totalSteps > 0 && (
        <Progress
          className="tw-max-w-md tw-items-center"
          totalSteps={totalSteps}
          finishedSteps={currentStep}
        />
      )
    }
    actions={actions}
    isLoading={isLoading}
    showCancel={currentStep < totalSteps || totalSteps === 0}
  >
    <div>{children}</div>
  </Dialog2>
);

export { ProgressDialog };
