import { useState } from "react";

import { Button } from "../../../../components/design-system/Button";
import { PencilIcon } from "../../../../components/design-system/icons";
import type { Form } from "./Dialog";
import EditShareCancelDialog from "./Dialog";

type EditShareCancelProps = {
  value: Form;
  onSuccess: (value: Form) => void;
  blockSize: number;
};

const EditShareCancel = ({
  value,
  onSuccess,
  blockSize,
}: EditShareCancelProps) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <Button variant="clean" className="!tw-p-0" onClick={() => setOpen(true)}>
        <PencilIcon />
      </Button>
      {isOpen && (
        <EditShareCancelDialog
          defaultValues={value}
          onSuccess={(newValues) => {
            setOpen(false);
            onSuccess(newValues);
          }}
          onClose={() => setOpen(false)}
          blockSize={blockSize}
        />
      )}
    </>
  );
};

export default EditShareCancel;
