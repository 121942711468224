import { useTranslation } from "react-i18next";
import { Outlet, useMatch, useParams } from "react-router-dom";

import { ActionBanner } from "../../../components/ActionBanner";
import { Loading } from "../../../components/design-system/Loading";
import { NoData } from "../../../components/NoData";
import { useCurrentCompany } from "../../../context/account";
import { useSession } from "../../../context/session";
import { useCompanyEvents } from "../../../hooks/useCompanyEvents";
import type { CompanyInformation } from "../../../types/models/administration";
import type { CompanyInvolvement } from "../../../types/models/company";
import { CompanyEmptyStateForViewers } from "./CompanyEmptyStateForViewers";
import { UserActionModal } from "./userActions/UserActionModal";
import { useUserActionRequired } from "./useUserActionRequired";

const CompanyPage = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const i18n = useTranslation();
  const { user } = useSession();
  const isInMailVerificationPage = useMatch(
    "/companies/:companyId/email/verify"
  );

  const company = useCurrentCompany(companyId);
  const userRole = (company as CompanyInvolvement)?.access;
  const supportRole =
    user?.role === "Administrator"
      ? (company as CompanyInformation)?.supportRole
      : null;
  const role = userRole || supportRole;

  // Avoid making queries if user does not have role
  const [requiredUserAction, refresh] = useUserActionRequired(
    role ? companyId : ""
  );
  const companyEvents = useCompanyEvents(companyId);

  if (!companyEvents.isSuccess || !company) {
    return <Loading />;
  }

  if (role === "Viewer" && companyEvents.approvedEvents.length === 0) {
    return <CompanyEmptyStateForViewers />;
  }

  if (!role) {
    return (
      <NoData
        type="error"
        title={i18n.t("error.fetch")}
        description={i18n.t("error.verification.unauthorized")}
      />
    );
  }

  return (
    <>
      {requiredUserAction && companyId && !isInMailVerificationPage && (
        <UserActionModal
          requiredAction={requiredUserAction}
          orgNumber={companyId}
          refresh={refresh}
        />
      )}
      {company && <ActionBanner company={company} />}
      <Outlet />
    </>
  );
};

export { CompanyPage };
