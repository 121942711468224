import { Loading } from "../../../../components/design-system/Loading";
import { PageWrapper } from "../../../../components/PageWrapper";
import { useCurrentCompany } from "../../../../context/account";
import { CompanyInformation } from "./CompanyInformation";

const CompanyInformationPage: React.FunctionComponent = () => {
  const currentCompany = useCurrentCompany();
  if (!currentCompany) {
    return <Loading />;
  }
  return (
    <PageWrapper
      data-testid="company-information-page"
      className="tw-max-w-3xl"
    >
      <CompanyInformation currentCompany={currentCompany} />
    </PageWrapper>
  );
};

export { CompanyInformationPage };
