import type React from "react";
import { forwardRef } from "react";

import { clsxm } from "../../../utils/tailwind";
import { CheckmarkIcon, ErrorIcon, InfoIcon, WarningIcon } from "../icons";

type AlertType = "error" | "neutral" | "success" | "warning" | "info";

type Size = "md" | "sm";

type AlertProps = React.HTMLAttributes<HTMLDivElement> & {
  type?: AlertType;
  size?: Size;
  contentClassname?: string;
  "data-testid"?: string;
};

const Alert = forwardRef(
  (
    {
      children,
      type = "neutral",
      size = "md",
      className,
      contentClassname,
      "data-testid": dataTestId,
    }: AlertProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const Icon = {
      success: CheckmarkIcon,
      error: ErrorIcon,
      warning: WarningIcon,
      info: InfoIcon,
      neutral: InfoIcon,
    }[type];

    return (
      <div
        ref={ref}
        className={clsxm("tw-flex", className, {
          "tw-gap-1 tw-text-sm": size === "sm",
          "tw-gap-2": size === "md",
        })}
        data-testid={dataTestId}
        role="alert"
      >
        <div className="tw-pt-0.5">
          <Icon
            className={clsxm({
              "tw-text-success": type === "success",
              "tw-text-warning": type === "warning",
              "tw-text-error": type === "error",
              "tw-text-blue-500": type === "info",
              "tw-h-5 tw-w-5": size === "md",
              "tw-h-4 tw-w-4": size === "sm",
            })}
          />
        </div>
        <span className={contentClassname}>{children}</span>
      </div>
    );
  }
);

export { Alert };
export type { AlertProps, AlertType };
