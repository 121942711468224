import type { Holding } from "@capchapdev/rell-api";
import type { UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";

import { useSession } from "../../context/session";
import type { IRequestError } from "..";
import { getAuthHeader } from "../auth";
import { blockchainClient } from "./client";
import { sendQuery } from "./transaction";

type RepresentativeHolding = {
  type: string;
  holder: {
    id: string;
    refId: string;
    type: "Private" | "Company";
  };
  holding: Holding;
};

const useRepresentativeHolding = (
  version: "approved" | "latest" = "latest"
): UseQueryResult<RepresentativeHolding[], IRequestError> => {
  const session = useSession();
  const refId = session.user?.refId ?? "";

  return useQuery<
    RepresentativeHolding[],
    IRequestError,
    RepresentativeHolding[],
    string[]
  >(
    ["representativeHolding", version, refId],
    () =>
      sendQuery(
        blockchainClient
          .query<RepresentativeHolding[]>()
          .name("ledger.representative_holdings")
          .addParameter("auth", getAuthHeader())
          .addParameter("version", version)
      ),
    { enabled: refId.length > 0 }
  );
};

export type { RepresentativeHolding };
export { useRepresentativeHolding };
