import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { CompanyInformation } from "../../types/models/administration";
import type { LoggedInUser } from "../../types/models/auth";
import { CompanyInvolvement } from "../../types/models/company";
import { MenuIcon } from "../design-system/icons";
import { Menu } from "../design-system/Menu";
import { NavItem } from "./NewNavigation";

const NavbarMenu = ({
  companyName,
  user,
  currentCompany,
  companyLinks,
}: {
  companyName?: string;
  user?: Pick<LoggedInUser, "name" | "role">;
  currentCompany: CompanyInformation | CompanyInvolvement;
  companyLinks: NavItem[];
}) => {
  const { t } = useTranslation();

  if (!currentCompany) {
    return null;
  }

  return (
    <Menu data-testid="navigation-user-profile">
      <Menu.Button className="tw-p-0" isDropdown={false} variant="clean">
        <div className="tw-flex tw-flex-col tw-items-end">
          <p className="tw-hidden tw-text-sm tw-font-medium sm:tw-block">
            {user?.name}
          </p>
          <p className="tw-hidden tw-opacity-80 sm:tw-block">{companyName}</p>
        </div>
        <MenuIcon size={32} weight="bold" className="tw-ml-4" />
      </Menu.Button>
      <Menu.Items
        align="bottomRight"
        className="tw-mt-6 tw-min-w-[350px] tw-text-body"
      >
        <div className="tw-grid tw-grid-cols-3 tw-gap-2 tw-p-6">
          {companyLinks.map((link) => (
            <Menu.Item
              className="tw-flex tw-flex-col tw-items-center tw-gap-3 tw-rounded tw-p-2"
              key={link.key}
              as={link.to ? Link : "button"}
              to={link.to}
              onClick={link.onClick}
            >
              <div className="tw-relative">
                <div className="tw-rounded tw-bg-kvanta-purple tw-p-2 tw-text-kvanta-purple-icon">
                  {link.icon}
                </div>
                {link.isBeta && (
                  <div className="tw-absolute tw--right-2 tw--top-2 tw-rounded tw-bg-blue-100 tw-px-1 tw-text-xs tw-font-medium tw-text-black">
                    {t("label.beta")}
                  </div>
                )}
              </div>
              <p className="tw-text-center tw-text-sm tw-font-medium tw-leading-[18px]">
                {link.label}
              </p>
            </Menu.Item>
          ))}
        </div>
        {user?.role === "Administrator" && (
          <Menu.Items align="bottomLeft">
            <Menu.Item
              className="tw-text-warning"
              closeOnClick={false}
              as="div"
              disabled
              // eslint-disable-next-line react/jsx-no-literals
            >
              Kvanta Admin
            </Menu.Item>
            <Menu.Item as={Link} to="/administration" className="tw-px-6">
              {t("label.administration")}
            </Menu.Item>
            <Menu.Item
              as={Link}
              to="/administration/features"
              className="tw-px-6"
            >
              {t("label.administration.features")}
            </Menu.Item>
          </Menu.Items>
        )}
      </Menu.Items>
    </Menu>
  );
};

export { NavbarMenu };
