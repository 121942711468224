import type { RawTxResponse } from "@capchapdev/admin-api";
import type { UseMutationOptions } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";

import * as monitoring from "../../utils/monitoring";
import type { IRequestError } from "..";
import { sendTransaction } from "../blockchain/transaction";
import useClient, { URL } from "./client";
import { RawTxResponseSchema } from "./users";

const completeOnboarding = async (orgNumber: string) => {
  const client = useClient({ hasAuth: true });
  const response = await client<RawTxResponse>(
    `${URL.ADMIN}/Transaction/CompleteOnboarding/${orgNumber}`
  );

  const result = RawTxResponseSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { response, result },
    });

    return sendTransaction(response.rawTx);
  }

  return sendTransaction(result.data.rawTx);
};

const useCompleteOnboardingMutation = (
  orgNumber: string,
  options?: UseMutationOptions<unknown, IRequestError>
) =>
  useMutation<unknown, IRequestError>(
    () => completeOnboarding(orgNumber),
    options
  );

export { useCompleteOnboardingMutation };
