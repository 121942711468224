import "./index.css";

import type { CSSObjectWithLabel, OptionProps, Props } from "react-select";
import ReactSelect, { components } from "react-select";

import { clsxm } from "../../../utils/tailwind";
import { CheckIcon } from "../icons";
import { ClearIndicator } from "./ClearIndicator";
import { DropdownIndicator } from "./DropdownIndicator";
import { MultiValueRemove } from "./MultiValueRemove";
import { NoOptionsMessage } from "./NoOptionsMessage";

type HardCodedProps = "classNamePrefix";

type ReactSelectProps<TOptionType, TIsMultiType extends boolean = false> = Omit<
  Props<TOptionType, TIsMultiType>,
  HardCodedProps
>;

const Option = <TOption, TIsMultiType extends boolean = false>({
  children,
  ...props
}: OptionProps<TOption, TIsMultiType>) => (
  <components.Option {...props}>
    <div className="tw-flex tw-items-center tw-justify-between tw-gap-4">
      <div className="tw-w-full tw-overflow-hidden tw-text-ellipsis">
        {children}
      </div>
      <div className={clsxm({ "tw-invisible": !props.isSelected })}>
        <CheckIcon className="tw-text-primary" />
      </div>
    </div>
  </components.Option>
);

const Select = <TOption, TIsMultiType extends boolean = false>({
  className,
  ...props
}: ReactSelectProps<TOption, TIsMultiType>) => {
  const { menuPosition, ...rest } = props;
  const extendedProps =
    menuPosition === "fixed"
      ? {
          ...rest,

          menuPortalTarget: document.body,
          styles: {
            menuPortal: (provided: CSSObjectWithLabel) => ({
              ...provided,
              zIndex: 9999,
            }),
          },
        }
      : props;

  return (
    <ReactSelect
      {...extendedProps}
      className={clsxm("react-select-container", className)}
      classNamePrefix="react-select"
      components={{
        // @ts-expect-error TS(2322) FIXME: Type '((props: NoticeProps<unknown, boolean, Group... Remove this comment to see the full error message
        NoOptionsMessage,
        // @ts-expect-error TS(2322) FIXME: Type '((props: DropdownIndicatorProps<unknown, boo... Remove this comment to see the full error message
        DropdownIndicator,
        Option,
        MultiValueRemove,
        // @ts-expect-error TS(2322) FIXME: Type '((props: ClearIndicatorProps<unknown, boolea... Remove this comment to see the full error message
        ClearIndicator,
        ...props.components,
      }}
      placeholder={null}
    />
  );
};

export {
  components,
  type MenuPosition,
  type MultiValueProps,
  type OptionProps,
  type SingleValueProps,
} from "react-select";
export { Option, Select };
