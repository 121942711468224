import { Trans, useTranslation } from "react-i18next";

import { Button } from "../../../components/design-system/Button";
import { Dialog } from "../../../components/design-system/Dialog";

type SucessfullyOnboardedDialogProps = {
  onClose: () => void;
};

const SucessfullyOnboardedDialog = (props: SucessfullyOnboardedDialogProps) => {
  const i18n = useTranslation();
  return (
    <Dialog
      canClose
      isOpen
      onClose={() => props.onClose()}
      actions={
        <Button color="primary" variant="solid" onClick={() => props.onClose()}>
          {i18n.t("label.close")}
        </Button>
      }
      title={i18n.t("company.successfullyOnboarded.title")}
    >
      <p>
        <Trans i18nKey="company.successfullyOnboarded.description" />
      </p>
    </Dialog>
  );
};

export { SucessfullyOnboardedDialog };
