import { Navigate, Route, Routes } from "react-router-dom";

import { useSession } from "../../../../context/session";
import type { CompanyInformation } from "../../../../types/models/administration";
import type { CompanyInvolvement } from "../../../../types/models/company";
import * as monitoring from "../../../../utils/monitoring";
import { hasRequiredPermission } from "../../../../utils/permissions";
import { EntitiesPage } from "./entities";
import { ApprovalPolicyPage } from "./policy";
import { ChangeShareLedgerSettings } from "./policy/change";
import PreviewProposal from "./policy/preview-proposal";
import { SupportPage } from "./support";
import { UsersPage } from "./users";

const SentryRoutes = monitoring.withSentryReactRouterV6Routing(Routes);

type SettingsRoutesProps = {
  currentCompany: CompanyInvolvement | CompanyInformation;
};

const PATH_POLICY_SETTINGS = "policy";
const PATH_CHANGE_SHARE_LEDGER_SETTINGS = "change";
const PATH_PREVIEW_PROPOSAL_LEDGER_SETTINGS = "preview-proposal";
const PATH_ENTITIES = "entities";
const PATH_USERS = "users";
const PATH_SUPPORT = "support";

const SettingsRoutes: React.FunctionComponent<SettingsRoutesProps> = ({
  currentCompany,
}) => {
  const { user } = useSession();
  const hasWritePermissions = hasRequiredPermission(
    "Editor",
    currentCompany,
    user
  );
  let PATH_DEFAULT = PATH_ENTITIES;
  if (hasWritePermissions) {
    PATH_DEFAULT = PATH_POLICY_SETTINGS;
  }

  return (
    <SentryRoutes>
      <Route
        element={<ApprovalPolicyPage currentCompany={currentCompany} />}
        path={PATH_POLICY_SETTINGS}
      />
      <Route
        element={<EntitiesPage currentCompany={currentCompany} />}
        path={PATH_ENTITIES}
      />
      {hasWritePermissions && (
        <Route
          element={
            <ChangeShareLedgerSettings currentCompany={currentCompany} />
          }
          path={`${PATH_POLICY_SETTINGS}/${PATH_CHANGE_SHARE_LEDGER_SETTINGS}`}
        />
      )}
      <Route
        element={<PreviewProposal currentCompany={currentCompany} />}
        path={`${PATH_POLICY_SETTINGS}/${PATH_PREVIEW_PROPOSAL_LEDGER_SETTINGS}`}
      />
      <Route
        element={<UsersPage currentCompany={currentCompany} />}
        path={PATH_USERS}
      />
      <Route
        element={
          <SupportPage currentCompany={currentCompany as CompanyInvolvement} />
        }
        path={PATH_SUPPORT}
      />
      <Route element={<Navigate to={PATH_POLICY_SETTINGS} replace />} index />
      <Route element={<Navigate to={PATH_DEFAULT} />} path="*" />
    </SentryRoutes>
  );
};

export { SettingsRoutes };
