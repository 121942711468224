import type { CompanyHoldings } from "@capchapdev/rell-api";
import type { UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";

import { useSession } from "../../context/session";
import type { IRequestError } from "..";
import { getAuthHeader } from "../auth";
import { blockchainClient } from "./client";
import { sendQuery } from "./transaction";

const useCompanyHoldings = (
  version: "approved" | "latest" = "latest"
): UseQueryResult<CompanyHoldings[], IRequestError> => {
  const session = useSession();
  const refId = session.user?.refId ?? "";

  return useQuery<
    CompanyHoldings[],
    IRequestError,
    CompanyHoldings[],
    string[]
  >(
    ["companyHoldings", version, refId],
    () =>
      sendQuery(
        blockchainClient
          .query<CompanyHoldings[]>()
          .name("ledger.company_holdings_for_version")
          .addParameter("auth", getAuthHeader())
          .addParameter("version", version)
      ),
    { enabled: refId.length > 0 }
  );
};

export { useCompanyHoldings };
