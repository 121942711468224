import type { MultiValueRemoveProps } from "react-select";
import { components } from "react-select";

import { CrossIcon } from "../icons";

const MultiValueRemove = (props: MultiValueRemoveProps) => (
  <components.MultiValueRemove {...props}>
    <CrossIcon className="tw-h-3 tw-w-3" />
  </components.MultiValueRemove>
);

export { MultiValueRemove };
