import type { EntitiesMap } from "../../types/models/entities";
import type { Shareblock } from "../../types/models/shares";
import { formatNumber } from "../../utils/format";
import { calcSumWithinRange } from "../../utils/shares";
import { Badge } from "../design-system/Badge";
import { Description } from "../design-system/Description";
import type { OptionProps } from "../design-system/Select";
import { Option as SelectOption } from "../design-system/Select";
import { EntityItem } from "../EntityItem";
import { getEntityWithFallback } from "../EventList/EventsTable/EventsTable.utils";

type IOptionProps = OptionProps<Shareblock, true> & {
  entitiesMap: EntitiesMap;
};

const Option = (props: IOptionProps) => {
  const { data: block, entitiesMap } = props;
  const entityItem = getEntityWithFallback(entitiesMap, block.holder);

  if (!("type" in entityItem)) {
    console.error("No entity fallback found");

    return null;
  }

  return (
    <SelectOption {...props}>
      <div className="tw-grid tw-grid-cols-1 tw-gap-4 lg:tw-grid-cols-3">
        <div className="tw-flex tw-flex-wrap tw-items-center tw-gap-2">
          <Description
            description={`${formatNumber(calcSumWithinRange(block))}`}
            title={`${formatNumber(block.start)} - ${formatNumber(block.end)}`}
          />
          <div className="tw-flex tw-items-center">
            <Badge>{block.type}</Badge>
          </div>
        </div>
        <EntityItem value={entityItem} />
      </div>
    </SelectOption>
  );
};

export { Option };
