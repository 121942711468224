import type React from "react";
import { useTranslation } from "react-i18next";
import useMeasure from "react-use-measure";

import { useCompanyUtils } from "../../context/account";
import { formatNumber } from "../../utils/format";
import { clsxm } from "../../utils/tailwind";
import { Disclosure } from "../design-system/Disclosure";

type WrapperProps = { children: React.ReactNode; summary: React.ReactNode };

const EventFormWrapper = ({ children, summary }: WrapperProps) => {
  const i18n = useTranslation();
  const [summaryRef, bounds] = useMeasure();

  return (
    <div className="tw-gap-4 lg:tw-grid lg:tw-grid-cols-4">
      <div
        className="lg:tw-col-span-3 lg:!tw-pb-0"
        style={{ paddingBottom: bounds.height }}
      >
        {children}
      </div>
      <div className="tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-bg-white lg:tw-static lg:tw-bottom-auto">
        <div
          ref={summaryRef}
          className="tw-border-t tw-py-4 lg:tw-sticky lg:tw-top-48 lg:tw-rounded lg:tw-border"
        >
          <Disclosure className="lg:tw-hidden">
            {({ open }) => (
              <>
                <Disclosure.Button open={open} isReverse>
                  {i18n.t("label.summary")}
                </Disclosure.Button>
                <Disclosure.Panel className="tw-px-4 tw-py-2">
                  {summary}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <div className="tw-hidden tw-px-4 lg:tw-block">
            <h4 className="tw-mb-4">{i18n.t("label.summary")}</h4>
            {summary}
          </div>
        </div>
      </div>
    </div>
  );
};

const EventSummarySection = ({
  title,
  children,
}: {
  title: React.ReactNode;
  children: React.ReactNode;
}) => (
  <div className="tw-space-y-2">
    <h6>{title}</h6>
    <div className="tw-flex-wrap tw-justify-between tw-space-y-2">
      {children}
    </div>
  </div>
);

type EventSummaryMetric = {
  label: string;
  before: number;
  after: number;
  format?: "currency" | "number";
};

const EventSummarySectionList = ({
  metrics,
}: {
  metrics: EventSummaryMetric[];
}) => {
  const i18n = useTranslation();
  const { formatCurrency } = useCompanyUtils();

  return (
    <div className="tw-space-y-6">
      {metrics.map(({ label, before, after, format }) => {
        const formatFunc =
          format === "currency" ? formatCurrency : formatNumber;
        const change = after - before;

        return (
          <EventSummarySection title={label} key={label}>
            <LabelValue
              label={i18n.t("label.before")}
              value={formatFunc(before)}
            />
            <LabelValue
              label={i18n.t("label.change")}
              value={
                <span
                  className={
                    change < 0
                      ? "tw-text-error"
                      : change > 0
                      ? "tw-text-success"
                      : "tw-text-body"
                  }
                >
                  {`${change > 0 ? "+" : ""}${formatFunc(change)}`}
                </span>
              }
            />
            <LabelValue
              label={i18n.t("label.after")}
              value={formatFunc(after)}
            />
          </EventSummarySection>
        );
      })}
    </div>
  );
};

const LabelValue = ({
  label,
  value,
  align = "left",
}: {
  label: React.ReactNode;
  value: React.ReactNode;
  align?: "left" | "right";
}) => (
  <div
    className={clsxm("tw-flex tw-justify-between tw-gap-1 tw-text-sm", {
      "tw-text-right": align === "right",
    })}
  >
    <div className="tw-text-secondary">{label}</div>
    <div className="tw-overflow-hidden tw-text-ellipsis tw-font-semibold">
      {value}
    </div>
  </div>
);

export type { EventSummaryMetric as TEventSummaryMetric };
export { EventFormWrapper, EventSummarySectionList };
