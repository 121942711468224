import type { CompanyInvolvement } from "../types/models/company";

const sortByName = (companies: CompanyInvolvement[]): CompanyInvolvement[] => {
  const companiesCopy = [...companies];

  return companiesCopy.sort((a, b) => a.name.localeCompare(b.name));
};

const STATUS_SORT_ORDER = {
  APPROVED: 0,
  PENDING_EVENTS: 1,
  PENDING_ONBOARDING: 2,
} as const;

const statusSortOrder = ({ ledgerApproved, status }: CompanyInvolvement) => {
  const statusKey =
    typeof ledgerApproved === "string"
      ? "APPROVED"
      : status === "Onboarded"
      ? "PENDING_EVENTS"
      : "PENDING_ONBOARDING";

  return STATUS_SORT_ORDER[statusKey];
};

const sortByStatus = (
  companies: CompanyInvolvement[]
): CompanyInvolvement[] => {
  const companiesCopy = [...companies];

  return companiesCopy.sort((a, b) => statusSortOrder(a) - statusSortOrder(b));
};

function sortAlphabeticallyByValue<Type>(
  array: Array<Type>,
  valueResolver: (entry: Type) => string | null | undefined
): Type[] {
  return array.sort((a, b) => {
    const nameA = valueResolver(a)?.toUpperCase() ?? "";
    const nameB = valueResolver(b)?.toUpperCase() ?? "";

    return nameA > nameB ? 1 : -1;
  });
}

export { sortAlphabeticallyByValue, sortByName, sortByStatus };
