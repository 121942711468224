import type { UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";

import { useSession } from "../../context/session";
import type { IRequestError } from "..";
import { getAuthHeader } from "../auth";
import { blockchainClient } from "./client";
import { sendQuery } from "./transaction";

const useUserConfirmedLedgerAsOfficial = (
  orgNumber: string = "",
  options: UseQueryOptions<unknown, IRequestError, 0 | 1, string[]> = {}
): UseQueryResult<0 | 1, IRequestError> => {
  const session = useSession();
  const refId = session.user?.refId ?? "";

  return useQuery<unknown, IRequestError, 0 | 1, string[]>(
    ["userConfirmedLedgerAsOfficial", orgNumber, refId],
    () =>
      sendQuery(
        blockchainClient
          .query<0 | 1>()
          .name("ledger.user_has_confirmed_ledger_as_official")
          .addParameter("auth", getAuthHeader())
          .addParameter("org_number", orgNumber)
      ),
    {
      enabled:
        options?.enabled !== undefined
          ? options.enabled
          : refId?.length > 0 && orgNumber.length > 0,
      ...options,
    }
  );
};

export { useUserConfirmedLedgerAsOfficial };
