import { useState } from "react";

import type { AllowedToggle } from "../utils/toggles-utils";
import {
  getEnabledFeatureToggles,
  updateFeatureToggle,
} from "../utils/toggles-utils";

const useToggles = () => {
  const [enabledFeatures, setEnabledFeatures] = useState(
    getEnabledFeatureToggles()
  );
  return {
    isFeatureEnabled: (featureName?: AllowedToggle) =>
      !!featureName && !!enabledFeatures[featureName],
    toggleFeature: (featureName: AllowedToggle, isTurnedOn: boolean) => {
      updateFeatureToggle(featureName, isTurnedOn);
      setEnabledFeatures(getEnabledFeatureToggles());
    },
  };
};

export { useToggles };
