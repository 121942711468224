import { Trans, useTranslation } from "react-i18next";

import type { EntitiesMap } from "../../../../types/models/entities";
import type { ShareSplitEvent } from "../../../../types/models/events";
import { formatNumber } from "../../../../utils/format";
import { Description } from "../../../design-system/Description";
import { CrossIcon, SplitIcon } from "../../../design-system/icons";
import AuditLine from "./AuditLine";

export const ShareSplitAuditLine = (props: {
  event: ShareSplitEvent;
  label: string;
  entitiesMap: EntitiesMap;
  open: boolean;
  onOpenChange: (value: boolean) => void;
}) => {
  const diff = props.event.ratio.x - props.event.ratio.y;
  const isReverse = diff > 0;
  const i18n = useTranslation();
  const createdBy = props.entitiesMap[props.event.user.id];

  return (
    <AuditLine
      title={i18n.t(
        isReverse ? "events.reverseSplit.title" : "events.split.title"
      )}
      icon={isReverse ? <CrossIcon /> : <SplitIcon />}
      description={
        <Trans
          i18nKey={
            isReverse ? "events.reverseSplit.content" : "events.split.content"
          }
          values={{
            x: props.event.ratio.x,
            y: props.event.ratio.y,
            shares: formatNumber(props.event.shares.total),
          }}
        />
      }
      panelContent={
        <div className="tw-flex tw-w-full tw-items-center tw-gap-6 tw-px-6 md:tw-flex md:tw-w-full">
          <div className="tw-flex tw-items-center">
            <Description
              title={i18n.t("auditLog.createdBy")}
              description={createdBy!.name}
              theme="grayBlack"
            />
          </div>
          <div className="tw-flex tw-items-center">
            <Description
              title={i18n.t("auditLog.events.split.ratio")}
              description={`${props.event.ratio.y}:${props.event.ratio.x}`}
              theme="grayBlack"
            />
          </div>
          <div className="tw-flex tw-items-center">
            <Description
              title={i18n.t("auditLog.events.split.additionalShares")}
              description={formatNumber(props.event.shares.total)}
              theme="grayBlack"
            />
          </div>
        </div>
      }
      {...props}
    />
  );
};
