import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../../components/design-system/Button";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  SortIcon,
} from "../../../components/design-system/icons";
import { SortBy, SortOrder } from "../Options";

type OptionsTableHeaderProps = {
  sortBy: SortBy;
  sortOrder: SortOrder;
  onSort: (key: SortBy) => void;
};

const OptionsTableHeader: React.FC<OptionsTableHeaderProps> = ({
  sortBy,
  sortOrder,
  onSort,
}) => {
  const { t } = useTranslation();

  const renderSortIcons = (key: SortBy) => (
    <div className="tw-ml-1 tw-flex tw-flex-col tw-gap-0">
      <ChevronUpIcon
        className={
          sortBy === key && sortOrder === "asc"
            ? "tw-text-neutral-800"
            : "tw-text-neutral-300"
        }
        size={12}
      />
      <ChevronDownIcon
        className={
          sortBy === key && sortOrder === "desc"
            ? "tw-text-neutral-800"
            : "tw-text-neutral-300"
        }
        size={12}
      />
    </div>
  );

  const getCurrentSortLabel = (): string => {
    return `${t("label.program")} ${t(
      sortOrder === "asc" ? "sortBy.name.asc" : "sortBy.name.desc"
    )}`;
  };

  return (
    <thead>
      <tr id="pc-sort" className="tw-border-b max-md:tw-hidden">
        <th className="tw-py-3 tw-pr-6 tw-text-left tw-text-sm tw-font-normal tw-text-secondary">
          <button
            type="button"
            onClick={() => onSort("program")}
            className="tw-flex tw-items-center"
          >
            {t("label.program")}
            {renderSortIcons("program")}
          </button>
        </th>
        <th className="tw-px-6 tw-py-3 tw-text-left tw-text-sm tw-font-normal tw-text-secondary">
          <button
            type="button"
            onClick={() => onSort("options")}
            className="tw-flex tw-items-center"
          >
            {t("options.participants.options")}
            {renderSortIcons("options")}
          </button>
        </th>
        <th className="tw-px-6 tw-py-3 tw-text-left tw-text-sm tw-font-normal tw-text-secondary">
          <button
            type="button"
            onClick={() => onSort("startDate")}
            className="tw-flex tw-items-center"
          >
            {t("options.programs.startDate")}
            {renderSortIcons("startDate")}
          </button>
        </th>
        <th className="tw-px-6 tw-py-3 tw-text-left tw-text-sm tw-font-normal tw-text-secondary">
          <button
            type="button"
            onClick={() => onSort("excersizeEnd")}
            className="tw-flex tw-items-center"
          >
            {t("options.programs.endDate")}
            {renderSortIcons("excersizeEnd")}
          </button>
        </th>
        <th className="tw-px-6 tw-py-3 tw-text-left tw-text-sm tw-font-normal tw-text-secondary">
          <button
            type="button"
            onClick={() => onSort("type")}
            className="tw-flex tw-items-center"
          >
            {t("options.create.type")}
            {renderSortIcons("type")}
          </button>
        </th>
      </tr>
      <tr id="mobile-sort" className="md:tw-hidden">
        <td colSpan={6}>
          <Button
            className="tw-border-none tw-text-sm"
            onClick={() => onSort("program")}
          >
            <div className="tw-flex tw-items-center tw-gap-1 tw-text-secondary">
              {getCurrentSortLabel()}
              <SortIcon size={24} />
            </div>
          </Button>
        </td>
      </tr>
    </thead>
  );
};

export default OptionsTableHeader;
