import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { useEntitiesQuery } from "../../api/rest/entities";
import { AuditLog } from "../../components/AuditLog";
import { Loading } from "../../components/design-system/Loading";
import { NoData } from "../../components/NoData";
import useLatestVersion from "../../hooks/useLatestVersion";
import type { CompanyInformation } from "../../types/models/administration";
import type { CompanyInvolvement } from "../../types/models/company";
import type { LedgerVersion } from "../../types/models/shares";

type AuditProps = {
  currentCompany: CompanyInvolvement | CompanyInformation;
};

const Audit: React.FunctionComponent<AuditProps> = ({ currentCompany }) => {
  const i18n = useTranslation();

  // Infer the current version from the URL query string, or use the latest
  // version if the query string is not present or invalid.
  const [searchParams] = useSearchParams();
  const latestVersion = useLatestVersion();
  const currentVersionString =
    (searchParams.get("version") as LedgerVersion) ??
    latestVersion.formatedValue;
  const entitiesQuery = useEntitiesQuery(currentCompany.orgNumber);
  const entitiesData = entitiesQuery.data ?? [];
  const entitiesMap = Object.fromEntries(entitiesData.map((e) => [e.id, e]));
  const { isLoading, isSuccess } = entitiesQuery;
  const errorCode = entitiesQuery.error?.errors[0]?.message.code;

  return (
    <>
      {errorCode ? (
        <NoData
          description={i18n.t(errorCode)}
          title={i18n.t("error.fetch")}
          type="error"
        />
      ) : isLoading ? (
        <Loading />
      ) : isSuccess && currentVersionString !== undefined ? (
        <AuditLog
          currentCompany={currentCompany}
          currentVersion={currentVersionString}
          entitiesMap={entitiesMap}
        />
      ) : (
        <NoData
          description={i18n.t("error.verification.missingVersion")}
          title={i18n.t("error.fetch")}
          type="error"
        />
      )}
    </>
  );
};

export { Audit };
