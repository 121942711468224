import { LogoIcon } from "../design-system/icons";

const PublicPageLayout = ({ children }: { children: React.ReactNode }) => (
  <div className="tw-flex tw-flex-col tw-items-center tw-p-6 lg:tw-h-screen">
    <LogoIcon />
    <div className="tw-flex tw-flex-1 tw-items-center tw-pt-16 lg:tw-p-4">
      {children}
    </div>
  </div>
);

export { PublicPageLayout };
