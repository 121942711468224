import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import type { useUpdateUsersMutation } from "../../api/rest/users";
import type { UserRole } from "../../types/models/users";
import { formatRefId } from "../../utils/format";
import { Button } from "../design-system/Button";
import { Dialog } from "../design-system/Dialog";
import { FormErrorList } from "../design-system/FormGroup";

type UpgradeUsersDialogProps = {
  onClose: () => void;
  mutation: ReturnType<typeof useUpdateUsersMutation>;
  allUserIds: string[];
  viewerApprovers: UserRole[];
};

const formId = "edit-user-form";

const UpgradeUserModal: React.FunctionComponent<UpgradeUsersDialogProps> = ({
  onClose,
  mutation,
  allUserIds,
  viewerApprovers,
}) => {
  const i18n = useTranslation();
  const { handleSubmit } = useForm({ mode: "onChange" });
  const { isLoading, error } = mutation;

  return (
    <Dialog
      isOpen
      title={i18n.t("label.upgradeUsers.title")}
      onClose={onClose}
      isLoading={isLoading}
      className="tw-p-8"
    >
      <div className="tw-flex tw-flex-col tw-gap-y-6 tw-pt-2">
        <div>
          {viewerApprovers.map((approver) => {
            return (
              <div
                className="tw-flex tw-items-center tw-justify-between tw-py-4 tw-text-sm"
                key={approver.user.id}
              >
                <div>
                  <p>{approver.user.name}</p>
                  <span className="tw-text-gray-500">
                    {formatRefId({
                      refId: approver.user.refId,
                      countryCode: approver.user.countryCode,
                      type: "Private",
                    })}
                  </span>
                </div>
                <span>{approver.role}</span>
              </div>
            );
          })}
        </div>
        <form
          className="tw-space-x-3 tw-space-y-0"
          onSubmit={(event) => {
            event.stopPropagation();

            return handleSubmit(() => {
              mutation.mutate({
                Role: "Editor",
                UserIds: allUserIds,
              });
            })(event);
          }}
          id={formId}
        >
          <Button onClick={onClose} disabled={isLoading}>
            {i18n.t("label.cancel")}
          </Button>
          <Button
            type="submit"
            variant="solid"
            color="primary"
            isLoading={isLoading}
          >
            {i18n.t("label.upgradeUsers.button")}
          </Button>
        </form>
        {error && <FormErrorList error={error} />}
      </div>
    </Dialog>
  );
};

export { UpgradeUserModal };
