import { useQuery } from "@tanstack/react-query";

import { useSession } from "../../context/session";
import type { CompanyInformation } from "../../types/models/administration";
import type { IRequestError } from "..";
import { getAuthHeader } from "../auth";
import { blockchainClient } from "./client";
import { sendQuery } from "./transaction";

const getAdminCompanies = (
  offset: number,
  limit: number
): Promise<CompanyInformation[]> =>
  sendQuery(
    blockchainClient
      .query<CompanyInformation[]>()
      .name("ledger.list_companies")
      .addParameter("auth", getAuthHeader())
      .addParameter("skip", offset)
      .addParameter("take", limit)
  );

const useAdminCompaniesQuery = () => {
  const { user } = useSession();

  return useQuery<unknown, IRequestError, CompanyInformation[], string[]>(
    ["adminCompanies", user?.refId ?? ""],
    () => getAdminCompanies(0, Number.MAX_SAFE_INTEGER),
    { enabled: !!user?.refId && user?.role === "Administrator" }
  );
};

export { useAdminCompaniesQuery };
