import { Entity } from "../../types/models/entities";
import {
  ReverseShareSplitEvent,
  ShareSplitEvent,
  TParentEvent,
} from "../../types/models/events";
import { OptionsProgram } from "../../types/models/options";
import { ShareHolderWithMetricsAndEntity } from "../../types/models/shares";
import { calcSumWithinRange } from "../../utils/shares";

type ShareHolderWithDilution = ShareHolderWithMetricsAndEntity & {
  dilutedShares?: number;
  dilutedVotes?: number;
};

const getTotalVotes = (
  shareHolders: ShareHolderWithMetricsAndEntity[],
  shareTypes: Record<string, number>
) => {
  return shareHolders.reduce(
    (sum, holder) =>
      sum +
      (holder?.blocks.reduce((holderSum, block) => {
        const size = calcSumWithinRange(block);

        return holderSum + size * (shareTypes[block.type] ?? 0);
      }, 0) ?? 0),
    0
  );
};

const getMatchingPrograms = (optionsPrograms: OptionsProgram[], id: string) =>
  optionsPrograms
    .map((p) => {
      const matchingParticipant = (p.participants || []).find(
        (x) => x.participant.id === id
      );
      if (matchingParticipant) {
        return { program: p, participant: matchingParticipant };
      }
      return undefined;
    })
    .filter((x) => x !== undefined);

const getMatchingSharesByProgram = (
  optionsPrograms: OptionsProgram[],
  id: string
) => {
  const matches = getMatchingPrograms(optionsPrograms, id);
  return matches.reduce<{
    [key: string]: number;
  }>((acc, { program, participant }) => {
    acc[program.title] = participant.amountOfOptions;
    return acc;
  }, {});
};

const getDilutionTotals = (
  currentShares: number,
  shareHolders: ShareHolderWithMetricsAndEntity[],
  shareTypes: Record<string, number>,
  optionsPrograms: OptionsProgram[]
) => {
  let totalShares = currentShares;
  let totalVotes = getTotalVotes(shareHolders, shareTypes);
  for (const program of optionsPrograms) {
    if (program) {
      const totalOptions = (program.participants || []).reduce(
        (accumulator, p) => accumulator + p.amountOfOptions,
        0
      );
      totalShares += totalOptions;
      totalVotes += totalOptions * shareTypes[program.shareType]!;
    }
  }
  return { totalShares, totalVotes };
};

const getShareHolderDilution = (
  id: string,
  currentShares: number,
  currentVotes: number,
  optionsPrograms: OptionsProgram[],
  shareTypes: Record<string, number>,
  shareSplits: (ShareSplitEvent | ReverseShareSplitEvent)[]
): { dilutedShares: number; dilutedVotes: number; hasSplit: boolean } => {
  const matches = getMatchingPrograms(optionsPrograms, id);
  let dilutedShares = currentShares;
  let dilutedVotes = currentVotes;
  let hasSplit = false;
  for (const { participant, program } of matches) {
    const relevantSplits = shareSplits.filter(
      (e) =>
        new Date(e.date.split(".")[0] as string) > new Date(program.startDate)
    );
    const multiplier = relevantSplits.reduce(
      (prev, curr) => prev * (curr.ratio.y / curr.ratio.x),
      1
    );
    if (multiplier !== 1) {
      hasSplit = true;
    }
    dilutedShares += participant.amountOfOptions * multiplier;
    dilutedVotes +=
      participant.amountOfOptions * multiplier * shareTypes[program.shareType]!;
  }
  return { dilutedShares, dilutedVotes, hasSplit };
};

const getShareHoldersWithDilution = (
  shareHolders: ShareHolderWithMetricsAndEntity[],
  optionsPrograms: OptionsProgram[],
  shareTypes: Record<string, number>,
  entitiesMap: Record<string, Entity>,
  events: TParentEvent[]
): {
  shareHoldersWithDilution: ShareHolderWithDilution[];
  hasSplit: boolean;
} => {
  const shareSplits = events.filter(
    (e) => e.type === "ShareSplit" || e.type === "ReverseShareSplit"
  );
  const allParticipants = optionsPrograms
    .map((i) => (i.participants || []).map((x) => ({ program: i.id!, ...x })))
    .flat();
  const currentShareholders = shareHolders.map((s) => ({
    ...s,
    sharesByType: {
      ...s.sharesByType,
      ...getMatchingSharesByProgram(optionsPrograms, s.id),
    },
    ...getShareHolderDilution(
      s.id,
      s.nrOfShares,
      s.nrOfVotes,
      optionsPrograms,
      shareTypes,
      shareSplits
    ),
  }));
  const nonCurrentShareholders = allParticipants
    .filter((a) => !shareHolders.find((s) => s.id === a.participant.id))
    .map((p) => {
      const entity = entitiesMap[p.participant.id]!;
      const { dilutedShares, dilutedVotes, hasSplit } = getShareHolderDilution(
        p.participant.id,
        0,
        0,
        optionsPrograms,
        shareTypes,
        shareSplits
      );
      const optionsByProgram = getMatchingSharesByProgram(
        optionsPrograms,
        p.participant.id
      );
      return {
        id: entity.id,
        refId: entity.refId,
        type: entity.type,
        since: "",
        unmaskedRefId: entity.refId,
        blocks: [],
        entity,
        nrOfVotes: 0,
        nrOfShares: 0,
        sharesByType: optionsByProgram,
        dilutedShares,
        dilutedVotes,
        hasSplit,
      };
    });

  const seen = new Set();
  const nonDuplicateNonCurrentShareHolders = nonCurrentShareholders.filter(
    (item) => {
      const duplicate = seen.has(item.id);
      seen.add(item.id);
      return !duplicate;
    }
  );
  const shareHoldersWithDilution = [
    ...currentShareholders,
    ...nonDuplicateNonCurrentShareHolders,
  ];
  const hasSplit = shareHoldersWithDilution.some((x) => x.hasSplit);
  return { shareHoldersWithDilution, hasSplit };
};

export { getDilutionTotals, getShareHoldersWithDilution, getTotalVotes };
export type { ShareHolderWithDilution };
