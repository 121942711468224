import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import coverImg from "./coverImg.png";

const EmptyState = () => {
  const i18n = useTranslation();
  return (
    <div>
      <img src={coverImg} className="tw-w-full" alt="policyBanner" />
      <h2 className="tw-pt-8 tw-font-medium">
        {i18n.t("settings.shareLedger.emptyState.title")}
      </h2>
      <p className="tw-pt-2 tw-text-sm tw-text-neutral-800">
        <Trans
          i18nKey="settings.shareLedger.emptyState.description1"
          components={{
            aktiebolagsLagLink: (
              <Link
                className="tw-underline"
                to="https://www.riksdagen.se/sv/dokument-och-lagar/dokument/svensk-forfattningssamling/aktiebolagslag-2005551_sfs-2005-551/#K5"
                target="_blank"
                rel="noreferrer noopener"
              />
            ),
          }}
        />
        <br />
        <br />
        {i18n.t("settings.shareLedger.emptyState.description2")}
        <br />
        <br />
        {i18n.t("settings.shareLedger.emptyState.description3")}
      </p>
      <p className="tw-flex tw-flex-col tw-items-end tw-py-6">
        <Link
          to="./change"
          className="tw-rounded-sm tw-bg-primary tw-p-4 tw-text-white"
        >
          {i18n.t("label.continue")}
        </Link>
      </p>
    </div>
  );
};

export default EmptyState;
