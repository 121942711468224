import ledgerImgUrl from "./ledgerImg.png";

const LedgerIpad: React.FC = () => {
  return (
    <img
      alt="ledgerIpad"
      className="tw-right-0 tw-ml-auto tw-mr-[-140px] max-md:tw-hidden"
      src={ledgerImgUrl}
    />
  );
};

export default LedgerIpad;
