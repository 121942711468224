import { clsxm } from "../../utils/tailwind";
import { CheckIcon } from "../design-system/icons";

type SelectionMenuItemProps = {
  checked?: boolean;
  children: React.ReactNode;
};

const SelectionMenuItem: React.FunctionComponent<SelectionMenuItemProps> = ({
  checked = false,
  children,
}) => (
  <div className="tw-flex tw-gap-4">
    <div className={clsxm({ "tw-invisible": !checked })}>
      <CheckIcon />
    </div>
    <span>{children}</span>
  </div>
);

export { SelectionMenuItem };
