import { z } from "zod";

const EnvironmentSchema = z
  .enum(["local", "dev", "staging", "production"])
  .optional()
  .default("local");
type Environment = z.infer<typeof EnvironmentSchema>;
type Config = {
  environment: Environment;
  apiUrl: string;
  adminUrl: string;
  searchUrl: string;
  blockchainUrls: string[];
  blockchainRID: string | undefined;
  chainId: number;
  renewSessionsWhenBlocksLeft: number;
  featureToggles: Record<string, string>;
};

const configs: Record<Environment, Config> = {
  local: {
    environment: "local",
    apiUrl: `http://${window.location.hostname}:5001`,
    adminUrl: `http://${window.location.hostname}:5002`,
    searchUrl: `http://${window.location.hostname}:5006`,
    blockchainUrls: [`http://${window.location.hostname}`],
    blockchainRID: undefined,
    chainId: 0,
    renewSessionsWhenBlocksLeft: 150,
    featureToggles: Object.freeze({
      ENABLE_MOCK_AUTH: "ENABLE_MOCK_AUTH",
      ENABLE_MOCK_SIGN: "ENABLE_MOCK_SIGN",
      ENABLE_OWNERSHIP_FOR_TRUID: "ENABLE_OWNERSHIP_FOR_TRUID",
      ENABLE_AUDIT_LOG: "ENABLE_AUDIT_LOG",
    }),
  },
  dev: {
    environment: "dev",
    apiUrl: "https://dev-services.capchap.se/auth",
    adminUrl: "https://dev-services.capchap.se/admin",
    searchUrl: "https://search-dev.kvanta.com",
    blockchainUrls: ["https://bc3-dev.capchap.dev/bc/"],
    blockchainRID: import.meta.env.VITE_BLOCKCHAINRID,
    chainId: -1,
    renewSessionsWhenBlocksLeft: 150,
    featureToggles: Object.freeze({
      ENABLE_MOCK_AUTH: "ENABLE_MOCK_AUTH",
      ENABLE_MOCK_SIGN: "ENABLE_MOCK_SIGN",
      ENABLE_OWNERSHIP_FOR_TRUID: "ENABLE_OWNERSHIP_FOR_TRUID",
      ENABLE_AUDIT_LOG: "ENABLE_AUDIT_LOG",
    }),
  },
  staging: {
    environment: "staging",
    apiUrl: "https://auth-s.capchap.se",
    adminUrl: "https://admin-s.capchap.se",
    searchUrl: "https://search-s.kvanta.com",
    blockchainUrls: ["https://bc1-s.capchap.se/bc/"],
    blockchainRID: import.meta.env.VITE_BLOCKCHAINRID,
    chainId: 0,
    renewSessionsWhenBlocksLeft: 150,
    featureToggles: Object.freeze({
      ENABLE_MOCK_AUTH: "ENABLE_MOCK_AUTH",
      ENABLE_MOCK_SIGN: "ENABLE_MOCK_SIGN",
      ENABLE_OWNERSHIP_FOR_TRUID: "ENABLE_OWNERSHIP_FOR_TRUID",
      ENABLE_AUDIT_LOG: "ENABLE_AUDIT_LOG",
    }),
  },
  production: {
    environment: "production",
    apiUrl: "https://auth.capchap.se",
    adminUrl: "https://admin.capchap.se",
    searchUrl: "https://search.kvanta.com",
    blockchainUrls: ["https://bc1-p.capchap.se/bc/"],
    blockchainRID: import.meta.env.VITE_BLOCKCHAINRID,
    chainId: 0,
    renewSessionsWhenBlocksLeft: 150,
    featureToggles: Object.freeze({}),
  },
};

const {
  environment,
  apiUrl,
  adminUrl,
  searchUrl,
  blockchainUrls,
  blockchainRID,
  chainId,
  renewSessionsWhenBlocksLeft,
  featureToggles,
} = configs[EnvironmentSchema.parse(import.meta.env.VITE_ENVIRONMENT)];

export type { Environment };
export {
  adminUrl,
  apiUrl,
  blockchainRID,
  blockchainUrls,
  chainId,
  configs,
  environment,
  featureToggles,
  renewSessionsWhenBlocksLeft,
  searchUrl,
};
