import { CheckSquare } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";

import { PageWrapper } from "../../../components/PageWrapper";

type DoneProps = {
  name: string;
  updated: boolean;
};

const Done = ({ name, updated }: DoneProps) => {
  const i18n = useTranslation();

  return (
    <PageWrapper className="tw-flex tw-h-[calc(100vh-10rem)] tw-max-w-3xl tw-flex-col tw-justify-start tw-px-0 tw-pb-0 tw-pt-6">
      <div className="tw-flex tw-min-h-full tw-flex-col tw-items-center tw-justify-center tw-gap-2 tw-align-middle">
        <CheckSquare className="tw-h-20 tw-w-20" />
        <h3>
          {i18n.t(
            updated ? "options.done.title.update" : "options.done.title",
            { name }
          )}
        </h3>
        <p>{i18n.t("options.done.description")}</p>
      </div>
    </PageWrapper>
  );
};

export { Done };
