import OriginalHotjar from "@hotjar/browser";

// ^ This is the only place we should import Hotjar from.
import { environment } from "../config";
import type { LoggedInUser } from "../types/models/auth";

type InitOpts = Parameters<typeof OriginalHotjar.init>[2];

const {
  init: originalInit,
  identify: originalIdentify,
  ...OriginalHotjarRest
} = OriginalHotjar;

/**
 * Custom init function that only enables Hotjar in production and uses the
 * siteId we received.
 *
 * @param opts
 * @returns
 */
const customInit = (opts?: InitOpts): boolean => {
  // Bail if we're not in production
  if (environment !== "production") {
    return false;
  }

  const siteId = 3518046;
  const hotjarVersion = 6;

  return originalInit(siteId, hotjarVersion, opts);
};

/**
 * Convert from our user object to the Hotjar user object and identify the user
 * in Hotjar.
 *
 * @param user The user to identify in Hotjar
 * @returns
 */
const customIdentify = (
  user?: LoggedInUser,
  additionalUserInfo?: Record<number | string, Date | boolean | number | string>
): boolean => {
  if (environment !== "production") {
    return false;
  }

  const userId: string | null = user?.id ?? null;
  const role = String(user?.role);
  const userInfo = { ...user, role, ...additionalUserInfo };

  return originalIdentify(userId, userInfo);
};

const CustomHotjar = {
  ...OriginalHotjarRest,
  identify: customIdentify,
  init: customInit,
};

export { CustomHotjar as Hotjar };
