/* eslint-disable no-param-reassign */
/* eslint-disable no-multi-assign */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */

import { environment } from "../config";

export type Visitor = {
  id: string;
  name: string;
};

export type Account = {
  id: string;
};

const pendo = (apiKey: string, visitor: Visitor, account: Account): void => {
  if (environment === "production" && apiKey) {
    (function initializePendo(
      p: { [key: string]: any },
      e: Document,
      n: string,
      d: string
    ) {
      let w: number;
      let x: number;

      const o: any = (p[d] = p[d] || {});
      o._q = o._q || [];

      const v = [
        "initialize",
        "identify",
        "updateOptions",
        "pageLoad",
        "track",
      ];
      for (w = 0, x = v.length; w < x; ++w) {
        (function setupFunction(m) {
          if (typeof m !== "undefined") {
            o[m] =
              o[m] ||
              function pendoMethodWrapper(...args: any[]) {
                o._q[m === v[0] ? "unshift" : "push"]([m, ...args]);
              };
          }
        })(v[w]);
      }

      const y: HTMLScriptElement = e.createElement(n) as HTMLScriptElement;
      y.async = true;
      y.src = `https://cdn.pendo.io/agent/static/${apiKey}/pendo.js`;
      const z: Element | null | undefined = e.getElementsByTagName(n)[0];
      if (z && z instanceof HTMLElement && z.parentNode) {
        z.parentNode.insertBefore(y, z);
      }
    })(
      window as unknown as { [key: string]: any },
      document,
      "script",
      "pendo"
    );

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    "pendo" in window &&
      (window.pendo as any).initialize({
        visitor,
        account,
      });
  }
};

export { pendo };
