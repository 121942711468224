import {
  FloatingPortal,
  Placement,
  useFloating,
  useHover,
  useInteractions,
  useRole,
} from "@floating-ui/react";

import { clsxm } from "../../../utils/tailwind";

type NewTooltipProps = {
  children: React.ReactElement;
  content: React.ReactNode;
  placement?: Placement;
  isOpen: boolean;
  className?: string;
};

const AnnotationPanel = ({
  children,
  content,
  placement = "top",
  isOpen = true,
  className,
}: NewTooltipProps) => {
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    placement,
  });
  const hover = useHover(context);

  // Role props for screen readers
  const role = useRole(context, { role: "tooltip" });

  // Merge all the interactions into prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    role,
  ]);

  return (
    <>
      <span ref={refs.setReference} {...getReferenceProps()}>
        {children}
      </span>
      {isOpen && (
        <FloatingPortal>
          <div
            className={clsxm(
              "tw-rounded tw-bg-white tw-px-2 tw-py-2 tw-text-sm tw-text-black tw-shadow-lg",
              className
            )}
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            {content}
          </div>
        </FloatingPortal>
      )}
    </>
  );
};

export { AnnotationPanel };
