import { useTranslation } from "react-i18next";

import { useApproveLedgerMutation } from "../../api/rest/company";
import { FORBIDDEN } from "../../api/rest/utils";
import { useSession } from "../../context/session";
import type { CompanyInformation } from "../../types/models/administration";
import type { CompanyInvolvement } from "../../types/models/company";
import type { LedgerVersion } from "../../types/models/shares";
import { isGreaterLedgerVersion } from "../../utils/date";
import { hasRequiredPermission } from "../../utils/permissions";
import { Button } from "../design-system/Button";
import { Description } from "../design-system/Description";
import { Dialog } from "../design-system/Dialog";
import { FormErrorList } from "../design-system/FormGroup";
import { notify } from "../design-system/Notifications";

type ApproveSharesProps = {
  onSuccess: () => void;
  onClose: () => void;
  currentCompany: CompanyInvolvement | CompanyInformation;
  version: { value: LedgerVersion; label: string };
  isRollback: boolean;
  versionLabel?: string;
};

const ApproveShares = ({
  currentCompany,
  version,
  isRollback,
  versionLabel,
  onClose,
  onSuccess,
}: ApproveSharesProps) => {
  const i18n = useTranslation();
  const { user } = useSession();
  const hasRole = hasRequiredPermission("Editor", currentCompany, user);
  const isApproved =
    !isRollback &&
    !isGreaterLedgerVersion(version.value, currentCompany.ledgerApproved);
  let validationError:
    | ""
    | "error.verification.snapshot.alreadyApproved"
    | "error.verification.unauthorized" = "";
  if (isApproved) {
    validationError = "error.verification.snapshot.alreadyApproved";
  }
  if (!hasRole) {
    validationError = "error.verification.unauthorized";
  }

  const approveLedgerMutation = useApproveLedgerMutation(
    currentCompany.orgNumber || "",
    version.value,
    {
      onSuccess: () => {
        notify(
          <Description
            title={i18n.t(
              isRollback
                ? "shares.rollback.success.title"
                : "shares.approve.success.title"
            )}
            description={
              !isRollback &&
              i18n.t("shares.approve.success.description", {
                version: version.label,
              })
            }
          />,
          { type: "success" }
        );
        onSuccess();
      },
    }
  );
  const serverError =
    approveLedgerMutation.error ||
    (validationError
      ? { status: FORBIDDEN, errors: [{ message: { code: validationError } }] }
      : null);
  const { isLoading, mutate } = approveLedgerMutation;

  return (
    <Dialog
      isOpen
      title={i18n.t(
        isRollback ? "label.approveRollback" : "label.approvePending"
      )}
      onClose={onClose}
      isLoading={isLoading}
      actions={
        <>
          <Button disabled={isLoading} onClick={onClose}>
            {i18n.t("label.cancel")}
          </Button>
          <Button
            type="submit"
            variant="solid"
            color={isRollback ? "danger" : "primary"}
            isLoading={isLoading}
            disabled={!!validationError}
            onClick={() => {
              mutate();
            }}
          >
            {i18n.t("label.approve")}
          </Button>
        </>
      }
    >
      <>
        <div>
          {i18n.t(
            isRollback
              ? "shares.approveRollback.confirm.content"
              : "shares.approve.confirm.content",
            {
              version: versionLabel || version.label,
            }
          )}
        </div>
        {serverError && <FormErrorList error={serverError} />}
      </>
    </Dialog>
  );
};

export { ApproveShares };
