type LinkListProps = {
  children: JSX.Element[];
};

const LinkList = ({ children }: LinkListProps) => (
  <>
    {children.map((c, i) => (
      <>
        {c}
        {i < children.length - 1 && <hr className="tw-border-b-1 tw-py-0" />}
      </>
    ))}
  </>
);

export default LinkList;
