import { useTranslation } from "react-i18next";

import { Ti18nKey } from "../../i18n";
import { Alert } from "../design-system/Alert";

const DistributionProgress = ({
  diff,
  title,
  allString = "onboarding.shareClasses.validation.all",
  moreString = "onboarding.shareClasses.validation.moreToDistribute",
  tooManyString = "onboarding.shareClasses.validation.tooMany",
}: {
  diff: number;
  title?: string;
  allString?: Ti18nKey;
  moreString?: Ti18nKey;
  tooManyString?: Ti18nKey;
}) => {
  const i18n = useTranslation();

  return (
    <div className="tw-flex">
      <Alert type={diff === 0 ? "success" : diff < 0 ? "neutral" : "error"}>
        {title && <span className="tw-mr-2 tw-font-semibold">{title}</span>}
        {diff === 0
          ? i18n.t(allString)
          : diff < 0
          ? i18n.t(moreString, {
              count: Math.abs(diff),
            })
          : i18n.t(tooManyString, {
              count: diff,
            })}
      </Alert>
    </div>
  );
};

export { DistributionProgress };
