import type { Ti18nKey } from "../i18n";
import { i18n } from "../i18n";
import type { NonEmptyArray } from "../types";
import { FORBIDDEN, INTERNAL_SERVER_ERROR, UNAUTHORIZED } from "./rest/utils";

type Message = { code: Ti18nKey; text?: string };

type RequestErrorElement = { field?: string; message: Message };

type IRequestError = {
  readonly status: Response["status"];
  readonly errors: NonEmptyArray<RequestErrorElement>;
};

class RequestError extends Error implements IRequestError {
  errors: IRequestError["errors"];

  status: Response["status"];

  constructor(error: IRequestError) {
    super();
    this.name = "RequestError";
    this.message = "Request failed";
    this.errors = error.errors;
    this.status = error.status;
  }
}

type ErrorType = "general" | "unauthenticated" | "unauthorized";

const Errors: { [key in ErrorType]: IRequestError } = {
  unauthenticated: {
    errors: [
      {
        message: {
          code: "error.verification.unauthenticated",
          text: i18n.t("error.verification.unauthenticated"),
        },
      },
    ],
    status: UNAUTHORIZED,
  },
  unauthorized: {
    errors: [
      {
        message: {
          code: "error.verification.unauthorized",
          text: i18n.t("error.verification.unauthorized"),
        },
      },
    ],
    status: FORBIDDEN,
  },
  general: {
    errors: [
      { message: { code: "error.general", text: i18n.t("error.general") } },
    ],
    status: INTERNAL_SERVER_ERROR,
  },
};

export type { IRequestError, RequestErrorElement };
export { Errors, RequestError };
