import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

import { clsxm } from "../../../utils/tailwind";

type Position = "bottom" | "left" | "right" | "top";

type DrawerProps = {
  position?: Position;
  isOpen: boolean;
  onClose: () => void;
};

const transitions = {
  top: {
    enterFrom: "-tw-translate-y-full",
    enterTo: "tw-translate-y-0",
    leaveFrom: "tw-translate-y-0",
    leaveTo: "-tw-translate-y-full",
  },
  right: {
    enterFrom: "tw-translate-x-full",
    enterTo: "tw-translate-x-0",
    leaveFrom: "tw-translate-x-0",
    leaveTo: "tw-translate-x-full",
  },
  bottom: {
    enterFrom: "tw-translate-y-full",
    enterTo: "tw-translate-y-0",
    leaveFrom: "tw-translate-y-0",
    leaveTo: "tw-translate-y-full",
  },
  left: {
    enterFrom: "-tw-translate-x-full",
    enterTo: "tw-translate-x-0",
    leaveFrom: "tw-translate-x-0",
    leaveTo: "-tw-translate-x-full",
  },
};

const Drawer: React.FunctionComponent<React.PropsWithChildren<DrawerProps>> = ({
  isOpen,
  position = "right",
  onClose,
  children,
}) => (
  <Transition show={isOpen} as={Fragment}>
    <Dialog onClose={onClose}>
      <Transition.Child
        as={Fragment}
        enter="tw-ease-out tw-duration-300"
        enterFrom="tw-opacity-0"
        enterTo="tw-opacity-100"
        leave="tw-ease-in tw-duration-200"
        leaveFrom="tw-opacity-100"
        leaveTo="tw-opacity-0"
      >
        <div className="tw-fixed tw-inset-0 tw-bg-gray-800 tw-bg-opacity-30" />
      </Transition.Child>

      <div className="tw-fixed tw-inset-0">
        <div
          className={clsxm([
            "tw-flex tw-min-h-full",
            position === "top" && "tw-items-start tw-justify-center",
            position === "right" &&
              "tw-items-stretch tw-justify-end md:tw-items-center",
            position === "bottom" && "tw-items-end tw-justify-center",
            position === "left" &&
              "tw-items-stretch tw-justify-start md:tw-items-center",
          ])}
        >
          <Transition.Child
            as={Fragment}
            enter="tw-ease-out tw-duration-300"
            enterFrom={clsxm([transitions[position].enterFrom, "tw-scale-95"])}
            enterTo={clsxm([transitions[position].enterTo, "tw-scale-100"])}
            leave="tw-ease-in tw-duration-100"
            leaveFrom={clsxm([transitions[position].leaveFrom, "tw-scale-100"])}
            leaveTo={clsxm([transitions[position].leaveTo, "tw-scale-95"])}
          >
            <Dialog.Panel
              className={clsxm(
                "tw-z-50 tw-bg-white tw-shadow-lg",
                position === "top" && "tw-rounded-b-md",
                position === "right" && "tw-rounded-l-md",
                position === "bottom" && "tw-rounded-t-md",
                position === "left" && "tw-rounded-r-md",
                {
                  "tw-w-full md:tw-w-auto":
                    position === "top" || position === "bottom",
                }
              )}
            >
              {children}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition>
);

export type { DrawerProps };
export { Drawer };
