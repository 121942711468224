import { Trans, useTranslation } from "react-i18next";

import { useCompanyUtils } from "../../../../context/account";
import type { CompanyInformation } from "../../../../types/models/administration";
import type { CompanyInvolvement } from "../../../../types/models/company";
import type { EntitiesMap } from "../../../../types/models/entities";
import type { CompanyFoundationEvent } from "../../../../types/models/events";
import { formatNumber } from "../../../../utils/format";
import { Description } from "../../../design-system/Description";
import { FoundationIcon } from "../../../design-system/icons";
import AuditLine from "./AuditLine";
import { ShareAllocationEventList } from "./ShareAllocationEventList";

export const CompanyFoundationAuditLine = (props: {
  event: CompanyFoundationEvent;
  label: string;
  currentCompany: CompanyInvolvement | CompanyInformation;
  entitiesMap: EntitiesMap;
  open: boolean;
  onOpenChange: (value: boolean) => void;
}) => {
  const i18n = useTranslation();
  const { formatCurrency } = useCompanyUtils();
  const createdBy = props.entitiesMap[props.event.user.id];

  return (
    <AuditLine
      title={i18n.t("events.companyFoundation.title")}
      icon={<FoundationIcon />}
      description={
        <Trans
          i18nKey="events.issue.content"
          values={{
            shares: formatNumber(props.event.shares.total),
            capital: formatCurrency(props.event.shares.capital),
          }}
        />
      }
      panelContent={
        <>
          <div className="tw-flex tw-w-full tw-items-center tw-gap-6 tw-px-6 md:tw-flex md:tw-w-full">
            <div className="tw-flex tw-items-center">
              <Description
                title={i18n.t("auditLog.createdBy")}
                description={createdBy!.name}
                theme="grayBlack"
              />
            </div>
            <div className="tw-flex tw-items-center">
              <Description
                title={i18n.t("auditLog.events.shares")}
                description={formatNumber(props.event.shares.total)}
                theme="grayBlack"
              />
            </div>
            <div className="tw-flex tw-items-center">
              <Description
                title={i18n.t("auditLog.events.capital")}
                description={formatCurrency(props.event.shares.capital)}
                theme="grayBlack"
              />
            </div>
          </div>
          <div className="tw-flex tw-w-full tw-items-center tw-gap-6 tw-px-6 tw-pt-4 md:tw-flex md:tw-w-full">
            <div className="tw-flex tw-items-center">
              <Description
                title={i18n.t("auditLog.events.allocation")}
                description={
                  <ShareAllocationEventList
                    currentCompany={props.currentCompany}
                    entitiesMap={props.entitiesMap}
                    parentEventId={props.event.id}
                  />
                }
                theme="grayBlack"
              />
            </div>
          </div>
        </>
      }
      {...props}
    />
  );
};
