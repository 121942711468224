import { useTranslation } from "react-i18next";

import { useEntitiesQuery } from "../../../../api/rest/entities";
import { Button } from "../../../../components/design-system/Button";
import { Dialog } from "../../../../components/design-system/Dialog";

type VerificationEmailSentProps = {
  orgNumber: string;
};

const VerificationEmailSent = ({ orgNumber }: VerificationEmailSentProps) => {
  const i18n = useTranslation();
  const entitiesQuery = useEntitiesQuery(orgNumber);
  return (
    <Dialog
      canClose
      isOpen
      actions={
        <Button
          color="primary"
          variant="solid"
          isLoading={entitiesQuery.isLoading}
          onClick={() => entitiesQuery.refetch()}
        >
          {i18n.t("label.close")}
        </Button>
      }
      onClose={() => entitiesQuery.refetch()}
      isLoading={entitiesQuery.isLoading}
      title={i18n.t(
        "userActionRequired.AddContactDetails.emailVerificationSent.title"
      )}
    >
      <p>
        {i18n.t(
          "userActionRequired.AddContactDetails.emailVerificationSent.subText"
        )}
      </p>
      <p>
        {i18n.t(
          "userActionRequired.AddContactDetails.emailVerificationSent.timeout"
        )}
      </p>
    </Dialog>
  );
};

export { VerificationEmailSent };
