import { getCountries as getCountryCodes } from "react-phone-number-input/input";

import { getLocale } from "../i18n";

type Country = { name: string; emoji: string };

let countries: Record<string, Country> = {};

const countryCodeToFlagEmoji = (countryCode: string) =>
  String.fromCodePoint(
    ...[...countryCode.toUpperCase()].map((c) => c.charCodeAt(0) + 0x1f1a5)
  );

const getCountryName = (countryCode: string) => {
  const locale = getLocale();
  const countryName = new Intl.DisplayNames([locale], { type: "region" });

  return countryName.of(countryCode);
};

const getCountries = () => {
  const hasCountries = Object.keys(countries).length > 0;

  if (hasCountries) {
    return countries;
  }
  const codes = getCountryCodes().filter(
    (code) => !["AQ", "BQ", "EH", "AC", "TA"].includes(code)
  );
  const countryValues: [string, Country][] = codes.map((code) => [
    code,
    { name: getCountryName(code) || code, emoji: countryCodeToFlagEmoji(code) },
  ]);
  const sorted = countryValues.sort(([_codeA, countryA], [_codeB, countryB]) =>
    countryA.name.localeCompare(countryB.name)
  );
  countries = Object.fromEntries(sorted);

  return countries;
};

export { getCountries, getCountryName };
