import { Popover as HeadlessUiPopover } from "@headlessui/react";
import type { FunctionComponent } from "react";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

import type { Ti18nKey } from "../../i18n";
import type { Status } from "../../types/models/approval";
import { clsxm } from "../../utils/tailwind";
import type { Approver } from "../ActionBanner";
import { Drawer } from "../design-system/Drawer";

const statusTranslationKey: Record<Status, Ti18nKey> = {
  approved: "label.approved",
  pending: "label.pending",
};

const backgroundClasses: Record<Status, string> = {
  approved: "tw-bg-green-300",
  pending: "tw-border-solid tw-border-2 tw-border-orange-300 tw-bg-orange-100",
};

const BadgeContent: React.FunctionComponent<{
  isMobile?: boolean;
  approvers: Approver[];
}> = ({ isMobile = false, approvers }) => {
  const i18n = useTranslation();

  return (
    <div className="tw-grid tw-auto-cols-fr tw-grid-cols-[1fr_auto] tw-gap-x-12 tw-gap-y-6">
      {approvers.map(({ key, name, status, isCurrentUser }) => {
        const formattedName = [
          name,
          isCurrentUser ? ` (${i18n.t("label.you")})` : undefined,
        ]
          .filter(Boolean)
          .join(" ");

        const translatedStatus = i18n.t(statusTranslationKey[status]);

        return (
          <Fragment key={key}>
            <div className="max-sm:tw-text-sm">{formattedName}</div>
            <div
              className={
                isMobile ? "tw-flex tw-items-center tw-gap-4" : "tw-text-right"
              }
            >
              {isMobile && (
                <div
                  className={clsxm(
                    "tw-h-3 tw-w-3 tw-rounded-full",
                    backgroundClasses[status]
                  )}
                />
              )}
              <div className="max-sm:tw-text-sm">{translatedStatus}</div>
            </div>
          </Fragment>
        );
      })}
    </div>
  );
};

const ApprovalBadge: FunctionComponent<{
  approved: number;
  total: number;
  approvers: Approver[];
}> = ({ approved, total, approvers }) => {
  const i18n = useTranslation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <HeadlessUiPopover className="tw-relative" data-testid="approvals-ratio">
      <button
        className="max-sm:tw-text-s tw-rounded-sm tw-py-1 tw-text-sm max-sm:tw-text-secondary sm:tw-hidden sm:tw-bg-gray-200 sm:tw-px-2"
        type="button"
        onClick={() => setIsDrawerOpen(true)}
      >
        {i18n.t("approvalBadge.ratio", { approved, total })}
      </button>
      <HeadlessUiPopover.Button className="tw-rounded-sm tw-bg-gray-200 tw-px-2 tw-py-1 tw-text-sm max-sm:tw-hidden">
        {i18n.t("approvalBadge.ratio", { approved, total })}
      </HeadlessUiPopover.Button>
      <Drawer
        isOpen={isDrawerOpen}
        position="bottom"
        onClose={() => setIsDrawerOpen(false)}
      >
        <div className="tw-px-4 tw-py-14 tw-pb-32">
          <BadgeContent approvers={approvers} isMobile />
        </div>
      </Drawer>
      <HeadlessUiPopover.Panel
        className="tw-absolute tw-z-10 tw-mt-2 tw-w-max tw-rounded-md tw-border tw-border-gray-200 tw-bg-white tw-p-4 tw-shadow-lg"
        data-testid="approvals-panel"
      >
        <BadgeContent approvers={approvers} />
      </HeadlessUiPopover.Panel>
    </HeadlessUiPopover>
  );
};

export { ApprovalBadge };
