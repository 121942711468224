import type { Ref, SVGProps } from "react";
import { forwardRef } from "react";

const Logo = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    ref={ref}
    fill="currentColor"
    height="34"
    viewBox="0 0 149 34"
    width="149"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M.1 15.44h3.5l.69-.69 7.94-7.94 2.35-2.35 2.35 2.35 7.94 7.94.69.69h3.5L14.58.96.1 15.44Zm28.96 3.32h-3.5l-.69.69-7.94 7.94-2.35 2.35-2.35-2.35-7.94-7.94-.7-.69H.1l14.48 14.48 14.48-14.48Zm-14.48-9.6L6.64 17.1l7.94 7.94 7.94-7.94-7.94-7.94Z"
      fillRule="evenodd"
    />
    <path d="M41.28 25.07V9h2.62v7.42L51.24 9h3.7l-6.96 7.03 6.98 9.04h-3.33l-5.5-7.18-2.23 2.25v4.93h-2.62Zm23.82 0L57.48 9h2.9l5.83 12.28L71.99 9h2.92l-7.63 16.07H65.1Zm24.82 0-1.3-2.8h-9.15l-1.28 2.8h-2.87L82.78 9h2.55l7.46 16.07h-2.87Zm-9.23-5.42h6.7l-3.35-7.23-3.35 7.23Zm16.38 5.42V9h2.41l9.5 11.71V9.01h2.62v16.06h-2.4L99.68 13.4v11.68h-2.62Zm24.8 0V11.62h-6.09V9h14.77v2.62h-6.07v13.45h-2.61Zm24.06 0-1.3-2.8h-9.14l-1.29 2.8h-2.87L138.8 9h2.55l7.47 16.07h-2.88Zm-9.22-5.42h6.7l-3.35-7.23-3.35 7.23Z" />
  </svg>
);

const ForwardRef = forwardRef(Logo);

export { ForwardRef as Logo };
