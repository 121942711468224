import { type FormEventHandler,useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useUpdateMyContactDetailsMutation } from "../../../api/rest/entities";
import { Button } from "../../../components/design-system/Button";
import { Dialog } from "../../../components/design-system/Dialog";
import {
  FormError,
  FormGroup,
  FormLabel,
} from "../../../components/design-system/FormGroup";
import { Input } from "../../../components/design-system/Input";
import {
  isPossiblePhoneNumber,
  PhoneInput,
} from "../../../components/design-system/PhoneInput";

type FormValues = {
  email: string;
  phone: string;
};

const formId = "add-contact-details-form";

const AddContactDetails = ({
  orgNumber,
  orgName,
}: {
  orgNumber: string;
  orgName: string;
}) => {
  const i18n = useTranslation();
  const [isOpen, setIsOpen] = useState(true);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormValues>({
    mode: "onSubmit",
  });

  const mutation = useUpdateMyContactDetailsMutation(orgNumber);

  const updateContactDetails: FormEventHandler<HTMLFormElement> = (event) => {
    event.stopPropagation();

    return handleSubmit((data) => {
      mutation.mutate(data, {
        onSuccess: () => {
          setIsOpen(false);
        },
      });
    })(event);
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      actions={
        <>
          <Button
            color="primary"
            form={formId}
            isLoading={mutation.isLoading}
            type="submit"
            variant="solid"
          >
            {i18n.t("label.continue")}
          </Button>
        </>
      }
      isLoading={mutation.isLoading}
      title={i18n.t("ownership.AddContactDetails.title")}
    >
      <p>{i18n.t("ownership.AddContactDetails.description", { orgName })}</p>
      <form
        className="tw-mt-4 tw-space-y-4"
        id={formId}
        onSubmit={updateContactDetails}
      >
        <FormGroup>
          <FormLabel htmlFor="email">{i18n.t("label.email")}</FormLabel>
          <Input
            id="email"
            {...register("email", {
              required: i18n.t("error.validation.required"),
              validate: (value) => {
                if (!value) {
                  return false;
                }
                const check = z.string().email().safeParse(value);

                return check.success ? true : i18n.t("error.validation.format");
              },
            })}
          />
          <FormError>{errors.email?.message}</FormError>
        </FormGroup>

        <FormGroup>
          <FormLabel htmlFor="phone">{i18n.t("label.phone")}</FormLabel>
          <Controller
            control={control}
            name="phone"
            render={({ field: { onChange, value, name }, fieldState }) => (
              <>
                <PhoneInput
                  id="phone"
                  name={name}
                  value={value}
                  onChange={onChange}
                />
                <FormError>{fieldState.error?.message}</FormError>
              </>
            )}
            rules={{
              required: i18n.t("error.validation.required"),
              validate: (value) => {
                if (!value) {
                  return true;
                }

                return isPossiblePhoneNumber(value)
                  ? true
                  : i18n.t("error.validation.format.phone");
              },
            }}
          />
        </FormGroup>
      </form>
    </Dialog>
  );
};

export { AddContactDetails };
