import type { ComponentType } from "react";

type ExtractProps<T> = T extends ComponentType<infer P> ? P : T;

type DeepPartial<T> = T extends object
  ? {
      [K in keyof T]?: DeepPartial<T[K]>;
    }
  : T;

type NonEmptyArray<T> = [T, ...T[]];

function assertNonEmptyArray<T>(arr: T[]): asserts arr is NonEmptyArray<T> {
  const [first] = arr;
  if (first === undefined) {
    throw TypeError("Array must not be empty");
  }
}

function isNonEmptyArray<T>(arr: T[]): arr is NonEmptyArray<T> {
  try {
    assertNonEmptyArray(arr);
  } catch (error) {
    return false;
  }

  return true;
}

export type { DeepPartial, ExtractProps, NonEmptyArray };
export { assertNonEmptyArray, isNonEmptyArray };
