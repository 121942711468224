import type { ClearIndicatorProps } from "react-select";
import { components } from "react-select";

import { CrossIcon } from "../icons";

const ClearIndicator = (props: ClearIndicatorProps) => (
  <components.ClearIndicator {...props}>
    <CrossIcon className="tw-h-4 tw-w-4" />
  </components.ClearIndicator>
);

export { ClearIndicator };
