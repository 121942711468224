import { useTranslation } from "react-i18next";

import type { Ti18nKey } from "../../i18n";
import type { Status } from "../../types/models/approval";
import { clsxm } from "../../utils/tailwind";

const statusTranslationKey: Record<Status, Ti18nKey> = {
  approved: "label.approved",
  pending: "label.pending",
};

const StatusBadge: React.FunctionComponent<{
  status: Status;
}> = ({ status }) => {
  const i18n = useTranslation();

  return (
    <span
      className={clsxm(
        "tw-inline-flex tw-whitespace-nowrap tw-rounded-sm tw-px-2 tw-py-1 tw-text-xs",
        {
          "tw-bg-green-100 tw-text-green-900": status === "approved",
          "tw-bg-amber-100 tw-text-amber-900": status === "pending",
        }
      )}
    >
      {i18n.t(statusTranslationKey[status])}
    </span>
  );
};

export { StatusBadge };
