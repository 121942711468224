import { useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FormError } from "../../../../../../components/design-system/FormGroup";
import { Input } from "../../../../../../components/design-system/Input";
import { PolicyAlternativesNew } from "../../../../../../components/design-system/PolicyAlternativesNew/PolicyAlternativesNew";
import { SelectMultiEntities } from "../../../../../../components/SelectMultiEntities/SelectMultiEntities";
import type { CompanyInformation } from "../../../../../../types/models/administration";
import type { CompanyInvolvement } from "../../../../../../types/models/company";
import type { UserListResponse } from "../../../../../../types/models/users";
import type { Policy } from "../../../../../../utils/policy";

type ApprovalPolicyForm = {
  rule: Policy;
  approvers?: string[];
  percentage?: number;
};

const ChangeApprovalPolicy = ({
  currentCompany,
  formId,
  existingPolicy,
  currentValues,
  onSubmit,
  users,
}: {
  currentCompany: CompanyInvolvement | CompanyInformation;
  formId: string;
  existingPolicy?: ApprovalPolicyForm;
  currentValues: ApprovalPolicyForm;
  onSubmit: (data: ApprovalPolicyForm) => void;
  users: UserListResponse;
}) => {
  const i18n = useTranslation();
  const percentageInputRef = useRef<HTMLInputElement>(null);
  const userData = users.filter((x) =>
    ["BoardMember", "Administrator", "Editor"].includes(x.role)
  );
  const { handleSubmit, control, setError } = useForm<ApprovalPolicyForm>({
    mode: "onSubmit",
    defaultValues:
      currentValues.rule !== "None" ? currentValues : existingPolicy,
  });

  const hasChanges = (data: ApprovalPolicyForm) =>
    !existingPolicy ||
    data.rule !== existingPolicy.rule ||
    (data.rule === "Percentage" &&
      data.percentage !== existingPolicy.percentage) ||
    (data.rule === "Manual" &&
      data.approvers?.length !== existingPolicy.approvers?.length &&
      data.approvers?.every((approver) =>
        existingPolicy.approvers?.includes(approver)
      ));

  return (
    <form
      id={formId}
      onSubmit={(event) => {
        event.stopPropagation();
        return handleSubmit((data) => {
          if (!hasChanges(data)) {
            setError("rule", {
              type: "manual",
              message: i18n.t("error.verification.policy.unchanged"),
            });
            return;
          }
          onSubmit(data);
        })(event);
      }}
    >
      <div>
        <div className="tw-flex tw-justify-between">
          <h3>{i18n.t("initiatePolicy.title")}</h3>
        </div>
        <p className="tw-flex tw-justify-between tw-pb-8 tw-pt-2">
          {i18n.t("initiatePolicy.subTitle")}
        </p>
      </div>
      <div className="tw-flex tw-flex-col tw-gap-2">
        <Controller
          name="rule"
          control={control}
          rules={{ required: i18n.t("error.validation.required") }}
          render={({ field: { value, onChange }, fieldState }) => (
            <>
              <PolicyAlternativesNew
                title={i18n.t("initiatePolicy.anyTitle")}
                description={i18n.t("initiatePolicy.anyDescription")}
                value="Any"
                checked={value === "Any"}
                onChange={onChange}
              />
              <PolicyAlternativesNew
                title={i18n.t("initiatePolicy.allTitle")}
                description={i18n.t("initiatePolicy.allDescription")}
                value="All"
                checked={value === "All"}
                onChange={onChange}
              />
              <PolicyAlternativesNew
                title={i18n.t("initiatePolicy.percentageOption")}
                description={i18n.t("initiatePolicy.percentageDescription")}
                value="Percentage"
                checked={value === "Percentage"}
                onChange={onChange}
              >
                <Controller
                  name="percentage"
                  control={control}
                  rules={{
                    required: i18n.t("error.validation.required"),
                    min: {
                      value: 0,
                      message: i18n.t("error.validation.range.min", { min: 0 }),
                    },
                    max: {
                      value: 100,
                      message: i18n.t("error.validation.range.max", {
                        max: 100,
                      }),
                    },
                  }}
                  render={({ field, fieldState: percentageFieldState }) => (
                    <div className="tw-pb-5">
                      <Input
                        ref={percentageInputRef}
                        type="number"
                        className="tw-mx-5"
                        min="0"
                        max="100"
                        value={field.value ?? ""}
                        onChange={(e) => {
                          if (e.target.value === "") {
                            field.onChange("");
                            return;
                          }
                          const newValue = e.target.valueAsNumber;
                          const isNumber = !Number.isNaN(newValue);
                          if (isNumber) {
                            field.onChange(newValue);
                          }
                        }}
                        placeholder="% E.g. 50"
                      />
                      <FormError className="tw-mx-5">
                        {percentageFieldState.error?.message}
                      </FormError>
                    </div>
                  )}
                />
              </PolicyAlternativesNew>
              <PolicyAlternativesNew
                title={i18n.t("initiatePolicy.manualTitle")}
                description={i18n.t("initiatePolicy.manualDescription")}
                value="Manual"
                checked={value === "Manual"}
                onChange={onChange}
              >
                <Controller
                  name="approvers"
                  control={control}
                  rules={{ required: i18n.t("error.validation.required") }}
                  render={({ field, fieldState: approverFieldState }) => (
                    <div className="tw-px-4 tw-pb-5">
                      <SelectMultiEntities
                        value={field.value}
                        onChange={(selectedIds) => field.onChange(selectedIds)}
                        currentCompany={currentCompany}
                        usersList={userData}
                        menuPlacement="top"
                      />
                      <FormError>{approverFieldState.error?.message}</FormError>
                    </div>
                  )}
                />
              </PolicyAlternativesNew>
              <FormError>{fieldState.error?.message}</FormError>
            </>
          )}
        />
      </div>
    </form>
  );
};

export { ChangeApprovalPolicy };
