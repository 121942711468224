import type { UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";

import { isNonEmptyArray } from "../../types";
import type {
  ShareAllocationEvent as ChildEvent,
  ShareTransferEvent,
  TParentEvent as ParentEvent,
} from "../../types/models/events";
import type { LedgerVersion } from "../../types/models/shares";
import type { IRequestError } from "..";
import { unmaskValues } from "../rest/company";
import type { UseQueryOptions } from "../types";
import { blockchainClient } from "./client";
import { sendQuery } from "./transaction";

type Pagination = { hasNextPage: boolean };

export type ParentEventsResponse = {
  data: ParentEvent[];
  pagination: Pagination;
};

const getChildEvents = (parentId: string): Promise<ChildEvent[]> =>
  sendQuery(
    blockchainClient
      .query<ChildEvent[]>()
      .name("events.get_children")
      .addParameter("parent_id", parentId)
  );

const getParentEvents = (
  orgNumber: string,
  offset: number,
  limit: number
): Promise<ParentEventsResponse> =>
  sendQuery(
    blockchainClient
      .query<ParentEventsResponse>()
      .name("events.get_parents")
      .addParameter("org_number", orgNumber)
      .addParameter("skip", offset)
      .addParameter("take", limit)
  );

const getVersions = (orgNumber: string) =>
  sendQuery(
    blockchainClient
      .query<string[]>()
      .name("ledger.get_available_snapshots")
      .addParameter("org_number", orgNumber)
  );

const extractRefIdFromUnmaskedValue = (
  unmaskedValue: string | undefined
): string | undefined => unmaskedValue?.split(":").pop();

const unmaskRefId = <T extends { refId: string }>(
  obj: T,
  refIdMap: Record<string, string>
): T & { unmaskedRefId: string | undefined } => ({
  ...obj,
  unmaskedRefId: extractRefIdFromUnmaskedValue(refIdMap[obj.refId]),
});

const useParentEventsQuery = ({
  offset,
  limit,
  orgNumber = "",
  onSuccess,
}: {
  offset: number;
  limit: number;
  orgNumber: string | undefined;
  onSuccess?: (data: ParentEvent[]) => Promise<void> | undefined;
}): UseQueryResult<ParentEventsResponse, IRequestError> =>
  useQuery<ParentEventsResponse, IRequestError, ParentEventsResponse, string[]>(
    ["parentEvents", orgNumber, offset.toString(), limit.toString()],
    async (): Promise<ParentEventsResponse> => {
      const parentEventsResponse = await getParentEvents(
        orgNumber,
        offset,
        limit
      );

      /**
       * !IMPORTANT! We're patching the response here, because we need to have
       * the unmasked refIds later on. We're adding a property to each
       * EventEntity called "unmaskedRefId" which contains the unmasked refId
       * found in the entityMap, but more importantly, this is not added to the
       * type-definition, because we don't want to pollute it since we'll remove
       * it at a later point anyways.
       */
      const maskedRefIds = parentEventsResponse.data
        .map((parentEvent) => {
          if (parentEvent.type === "ShareTransfer") {
            return parentEvent.shares.map(({ recipient, sender }) => [
              recipient.refId,
              sender.refId,
            ]);
          }
          if (parentEvent.type === "SharePledgedUpdate") {
            return parentEvent.ranges.after.map(({ creditor }) => [
              creditor.refId,
            ]);
          }
          return [];
        })
        .flat(2);
      const uniqueMaskedIds = [...new Set(maskedRefIds)];
      const unmasked = await unmaskValues(orgNumber, uniqueMaskedIds);

      // For all ShareTransfer-events, add the unmasked refIds to the entities.
      const patchedData: ParentEvent[] = parentEventsResponse.data.map(
        (parentEvent): ParentEvent => {
          if (parentEvent.type === "ShareTransfer") {
            const patchedShares = parentEvent.shares.map((share) => ({
              ...share,
              recipient: unmaskRefId(share.recipient, unmasked),
              sender: unmaskRefId(share.sender, unmasked),
            }));

            if (!isNonEmptyArray(patchedShares)) {
              return parentEvent;
            }

            const result: ShareTransferEvent = {
              ...parentEvent,
              shares: patchedShares,
            };

            return result;
          }

          if (parentEvent.type === "SharePledgedUpdate") {
            return {
              ...parentEvent,
              ranges: {
                after: parentEvent.ranges.after.map((range) => ({
                  ...range,
                  creditor: unmaskRefId(range.creditor, unmasked),
                })),
                before: parentEvent.ranges.before,
              },
            };
          }

          return parentEvent;
        }
      );

      return {
        ...parentEventsResponse,
        data: patchedData,
      };
    },
    {
      enabled: !!orgNumber,
      onSuccess(data) {
        if (onSuccess) {
          onSuccess(data.data);
        }
      },
    }
  );

const useChildEventsQuery = (orgNumber: string, parentId: string) =>
  useQuery(
    ["childEvents", orgNumber, parentId],
    async () => {
      const childEvents = await getChildEvents(parentId);
      const uniqueMaskedRefIds = Array.from(
        new Set(
          childEvents
            .map((e) =>
              e.type === "ShareAllocation" ? e.recipient.refId : null
            )
            .filter((x) => x !== null)
        )
      );
      const unmasked = await unmaskValues(orgNumber, uniqueMaskedRefIds);
      const patchedChildEvents = childEvents.map((e) => {
        if (e.type === "ShareAllocation") {
          return {
            ...e,
            recipient: {
              ...e.recipient,
              unmaskedRefId: unmaskRefId(e.recipient, unmasked).unmaskedRefId,
            },
          };
        }
        return e;
      });
      return patchedChildEvents;
    },
    { enabled: !!orgNumber && !!parentId }
  );

const useVersionsQuery = (
  orgNumber: string,
  options?: UseQueryOptions<unknown, IRequestError, LedgerVersion[], string[]>
) =>
  useQuery(["versions", orgNumber], () => getVersions(orgNumber), {
    enabled: options?.enabled !== undefined ? options.enabled : !!orgNumber,
    ...options,
  });

export {
  extractRefIdFromUnmaskedValue,
  unmaskRefId,
  useChildEventsQuery,
  useParentEventsQuery,
  useVersionsQuery,
};
