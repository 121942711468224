import { useTranslation } from "react-i18next";

import { useRestoreLedgerMutation } from "../../api/rest/company";
import { useCompanyEvents } from "../../hooks/useCompanyEvents";
import type { CompanyInformation } from "../../types/models/administration";
import type { CompanyInvolvement } from "../../types/models/company";
import type { TParentEvent } from "../../types/models/events";
import type { LedgerVersion } from "../../types/models/shares";
import { isAfterVersion, type ValueLabelPair } from "../../utils/events-utils";
import { Button } from "../design-system/Button";
import { Description } from "../design-system/Description";
import { Dialog } from "../design-system/Dialog";
import { FormErrorList } from "../design-system/FormGroup";
import { notify } from "../design-system/Notifications";

type RestoreSharesProps = {
  onSuccess: () => void;
  onClose: () => void;
  currentCompany: CompanyInvolvement | CompanyInformation;
  currentVersion: LedgerVersion;
  version?: ValueLabelPair;
  events: TParentEvent[];
};

const RestoreShares = ({
  currentCompany,
  currentVersion,
  version,
  events,
  onClose,
  onSuccess,
}: RestoreSharesProps) => {
  const i18n = useTranslation();

  const latestApproval = events.find((e) => e.type === "LedgerApproval");
  const { approvedEvents } = useCompanyEvents(currentCompany.orgNumber);
  const hasApprovedEvents = approvedEvents.length > 0;
  const rollbackApprovedChanges =
    version === undefined
      ? hasApprovedEvents
      : latestApproval
      ? isAfterVersion(latestApproval.date, version.value)
      : false;

  const restoreLedgerMutation = useRestoreLedgerMutation(
    currentCompany.orgNumber || "",
    rollbackApprovedChanges,
    currentVersion,
    version ? version.value : version,
    {
      onSuccess: () => {
        notify(
          <Description
            title={i18n.t(
              rollbackApprovedChanges
                ? "shares.restore.pending.title"
                : version
                ? "shares.restore.success.title"
                : "shares.reset.success.title"
            )}
            description={
              !rollbackApprovedChanges &&
              (version
                ? i18n.t("shares.restore.success.description", {
                    version: version.label,
                  })
                : i18n.t("shares.reset.success.description"))
            }
          />,
          { type: rollbackApprovedChanges ? "info" : "success" }
        );
        onSuccess();
      },
    }
  );
  const { isLoading, mutate } = restoreLedgerMutation;

  return (
    <Dialog
      isOpen
      title={i18n.t(
        version
          ? rollbackApprovedChanges
            ? "shares.restore.title.approved"
            : "shares.restore.title"
          : "shares.reset.title"
      )}
      onClose={onClose}
      isLoading={isLoading}
      actions={
        <>
          <Button disabled={isLoading} onClick={onClose}>
            {i18n.t("label.cancel")}
          </Button>
          <Button
            variant="solid"
            color="primary"
            isLoading={isLoading}
            onClick={() => {
              mutate();
            }}
          >
            {i18n.t("shares.restore.label")}
          </Button>
        </>
      }
    >
      <div className="tw-space-y-4">
        <div className="tw-text-base">
          {version
            ? i18n.t("shares.restore.confirm.content", {
                version: version.label,
              })
            : i18n.t("shares.reset.confirm.content")}
        </div>
        {restoreLedgerMutation.error && (
          <FormErrorList error={restoreLedgerMutation.error} />
        )}
      </div>
    </Dialog>
  );
};

export { RestoreShares };
