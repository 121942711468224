import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  useActiveApprovalRuleQuery,
  useApprovalInfoQuery,
} from "../../../../../api/blockchain/company";
import { useUsersQuery } from "../../../../../api/blockchain/users";
import { useEntitiesQuery } from "../../../../../api/rest/entities";
import { List, ListHeader } from "../../../../../components/design-system/List";
import { Loading } from "../../../../../components/design-system/Loading";
import { NoData } from "../../../../../components/NoData";
import { AddUser } from "../../../../../components/Users/Add";
import { UsersList } from "../../../../../components/Users/List";
import { useSession } from "../../../../../context/session";
import type { CompanyInformation } from "../../../../../types/models/administration";
import type { CompanyInvolvement } from "../../../../../types/models/company";
import { getApprovers } from "../../../../../utils/approvalUtils";
import { hasRequiredPermission } from "../../../../../utils/permissions";

type UsersProps = { currentCompany: CompanyInvolvement | CompanyInformation };
type Approvers = {
  name: string;
  status: "approved" | "pending";
  key: string | number;
  isCurrentUser: boolean;
}[];
const UsersPage = ({ currentCompany }: UsersProps) => {
  const i18n = useTranslation();
  const { user } = useSession();

  const usersQuery = useUsersQuery(currentCompany.orgNumber);
  const handleUsersChange = () => {
    usersQuery.refetch();
  };
  const errorCode = usersQuery.error?.errors[0]?.message.code;
  const activeApprovalRule = useActiveApprovalRuleQuery(
    currentCompany.orgNumber
  );
  const canEdit = hasRequiredPermission("Administrator", currentCompany, user);
  const manualApprovers = useMemo(() => {
    if (activeApprovalRule.data?.rule !== "SpecificUsers") {
      return [];
    }

    return activeApprovalRule.data.users.flatMap(({ id }) =>
      typeof id === "string" ? [id] : []
    );
  }, [activeApprovalRule.data]);

  const approvalInfo = useApprovalInfoQuery(currentCompany.orgNumber, "");

  const entitiesQuery = useEntitiesQuery(currentCompany.orgNumber);

  if (
    approvalInfo.isLoading ||
    entitiesQuery.isLoading ||
    usersQuery.isLoading
  ) {
    return <Loading />;
  }

  if (
    !approvalInfo.isSuccess ||
    !entitiesQuery.isSuccess ||
    !usersQuery.isSuccess ||
    !user
  ) {
    return null;
  }

  let approvers: Approvers | undefined;
  if (approvalInfo.data && approvalInfo.data.rule !== "None") {
    approvers = getApprovers(
      approvalInfo.data,
      entitiesQuery.data,
      usersQuery.data,
      user.id
    );
  }

  return (
    <List
      className="tw-w-2xl"
      header={
        <ListHeader
          className="tw-mb-4 md:tw-px-8"
          title={i18n.t("label.users")}
          actions={
            canEdit && (
              <div className="">
                <AddUser
                  currentCompany={currentCompany}
                  onSuccess={handleUsersChange}
                />
              </div>
            )
          }
        />
      }
    >
      {usersQuery.isLoading && <Loading />}
      {usersQuery.data && (
        <UsersList
          currentCompany={currentCompany}
          value={usersQuery.data}
          onChange={handleUsersChange}
          manualApprovers={manualApprovers}
          approvers={approvers}
          canEdit={canEdit}
        />
      )}
      {errorCode && (
        <NoData
          type="error"
          title={i18n.t("error.fetch")}
          description={i18n.t(errorCode)}
        />
      )}
    </List>
  );
};

export { UsersPage };
