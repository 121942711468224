import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import type { useRegisterTestCompanyMutation } from "../../../api/rest/administration";
import { Button } from "../../../components/design-system/Button";
import { Dialog } from "../../../components/design-system/Dialog";
import {
  FormError,
  FormErrorList,
  FormGroup,
  FormLabel,
} from "../../../components/design-system/FormGroup";
import { Input } from "../../../components/design-system/Input";
import { SelectCountry } from "../../../components/SelectCountry";
import type { NewTestCompany } from "../../../types/models/administration";

const formId = "edit-company-form";
type EditCompanyDialogProps = {
  title: string;
  onClose: () => void;
  mutation: ReturnType<typeof useRegisterTestCompanyMutation>;
  defaultValues: NewTestCompany;
};

const EditCompanyDialog = ({
  title,
  onClose,
  mutation,
  defaultValues,
}: EditCompanyDialogProps) => {
  const i18n = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<NewTestCompany>({ mode: "onChange", defaultValues });
  const { isLoading } = mutation;

  return (
    <Dialog
      isOpen
      title={title}
      onClose={() => {
        mutation.reset();
        onClose();
      }}
      isLoading={isLoading}
      actions={
        <>
          <Button
            onClick={() => {
              mutation.reset();
              onClose();
            }}
            disabled={isLoading}
          >
            {i18n.t("label.cancel")}
          </Button>
          <Button
            type="submit"
            variant="solid"
            color="primary"
            isLoading={isLoading}
            form={formId}
          >
            {i18n.t("label.save")}
          </Button>
        </>
      }
    >
      <form
        className="tw-space-y-4"
        onSubmit={(event) => {
          event.stopPropagation();

          return handleSubmit((data) => {
            mutation.mutate(data);
          })(event);
        }}
        id={formId}
      >
        <FormGroup>
          <FormLabel htmlFor="name">{i18n.t("label.name")}</FormLabel>
          <Input
            id="name"
            {...register("name", {
              validate: (value) => {
                if (!value.trim()) {
                  return i18n.t("error.validation.required");
                }

                return true;
              },
              setValueAs: (value) => value.trim(),
            })}
          />
          <FormError>{errors.name?.message}</FormError>
        </FormGroup>
        <FormGroup>
          <FormLabel htmlFor="countryCode">{i18n.t("label.country")}</FormLabel>
          <Controller
            name="countryCode"
            control={control}
            render={({ field: { onChange, value, name }, fieldState }) => (
              <>
                <SelectCountry value={value} name={name} onChange={onChange} />
                <FormError>{fieldState.error?.message}</FormError>
              </>
            )}
            rules={{ required: i18n.t("error.validation.required") }}
          />
        </FormGroup>
        {mutation.error && <FormErrorList error={mutation.error} />}
      </form>
    </Dialog>
  );
};

export default EditCompanyDialog;
