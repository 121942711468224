import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useDeleteEntityRelationshipMutation } from "../../../api/rest/entities";
import type { CompanyInformation } from "../../../types/models/administration";
import type { CompanyInvolvement } from "../../../types/models/company";
import type { Entity } from "../../../types/models/entities";
import { Button } from "../../design-system/Button";
import { Dialog } from "../../design-system/Dialog";
import { FormErrorList } from "../../design-system/FormGroup";
import { TrashIcon } from "../../design-system/icons";

type RemoveRepresentativeProps = {
  value: Entity;
  parent: Entity;
  currentCompany: CompanyInvolvement | CompanyInformation;
  onSuccess?: () => void;
};

const RemoveRepresentative = ({
  value,
  parent,
  currentCompany,
  onSuccess,
}: RemoveRepresentativeProps) => {
  const [isOpen, setOpen] = useState(false);
  const deleteEntityRelationshipMutation = useDeleteEntityRelationshipMutation(
    currentCompany.orgNumber,
    parent.id,
    value.id,
    {
      onSuccess: () => {
        setOpen(false);
        if (onSuccess) {
          onSuccess();
        }
      },
    }
  );
  const i18n = useTranslation();

  return (
    <>
      <Button variant="clean" size="sm" onClick={() => setOpen(true)}>
        <TrashIcon className="tw-h-6 tw-w-6" />
      </Button>
      {isOpen && (
        <Dialog
          isOpen
          title={i18n.t(
            parent.type === "Private"
              ? "entity.remove.trustee.title"
              : "entity.remove.representative.title"
          )}
          onClose={() => setOpen(false)}
          isLoading={deleteEntityRelationshipMutation.isLoading}
          actions={
            <>
              <Button
                disabled={deleteEntityRelationshipMutation.isLoading}
                onClick={() => setOpen(false)}
              >
                {i18n.t("label.cancel")}
              </Button>
              <Button
                variant="solid"
                color="danger"
                isLoading={deleteEntityRelationshipMutation.isLoading}
                onClick={() => {
                  deleteEntityRelationshipMutation.mutate();
                }}
              >
                {i18n.t(
                  parent.type === "Private"
                    ? "entity.remove.trustee.title"
                    : "entity.remove.representative.title"
                )}
              </Button>
            </>
          }
        >
          <div className="tw-space-y-4">
            <div className="tw-text-base">
              {i18n.t(
                parent.type === "Private"
                  ? "entity.remove.trustee.description"
                  : "entity.remove.representative.description"
              )}
            </div>
            {deleteEntityRelationshipMutation.error && (
              <FormErrorList error={deleteEntityRelationshipMutation.error} />
            )}
          </div>
        </Dialog>
      )}
    </>
  );
};

export { RemoveRepresentative };
