import type { BadgeProps } from "../Badge";
import { Badge } from "../Badge";

type BadgeListProps = {
  labels: string[];
  limit?: number;
  color?: BadgeProps["color"];
  showRestEnabled?: boolean;
  hidePlusWhenExpanded?: boolean;
};

const BadgeList = ({
  labels,
  limit = 2,
  color = "none",
  showRestEnabled = false,
  hidePlusWhenExpanded = false,
}: BadgeListProps) => {
  const initialLabelsToRender = labels.slice(0, limit);
  const restOfLabels = labels.slice(limit);
  const labelsToRender = showRestEnabled ? restOfLabels : initialLabelsToRender;
  const diff = labels.length - limit;

  return (
    <div className="tw-flex tw-flex-wrap tw-gap-2">
      {labelsToRender.map((label) => {
        return (
          <Badge className="tw-text-sm" key={label} color={color}>
            {label}
          </Badge>
        );
      })}
      {!showRestEnabled && !hidePlusWhenExpanded && diff > 0 && (
        <Badge className="tw-text-sm" color={color}>{`+${diff}`}</Badge>
      )}
    </div>
  );
};

export { BadgeList };
