import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import { useEntitiesQuery } from "../../../api/rest/entities";
import type { CompanyInformation } from "../../../types/models/administration";
import type { CompanyInvolvement } from "../../../types/models/company";
import type {
  Role,
  UserListResponse,
  UserRole,
} from "../../../types/models/users";
import { formatRefId } from "../../../utils/format";
import { sortAlphabeticallyByValue } from "../../../utils/sort";
import { clsxm } from "../../../utils/tailwind";
import { supportUserId } from "../../../utils/user";
import { Badge } from "../../design-system/Badge";
import { ListItem } from "../../design-system/List";
import { TooltipV2 } from "../../design-system/Tooltip/TooltipV2";
import { EntityItem } from "../../EntityItem";
import { NoData } from "../../NoData";
import { DeleteUser } from "../Delete";
import { EditUser } from "../Edit";

type Approvers = {
  name: string;
  status: "approved" | "pending";
  key: string | number;
  isCurrentUser: boolean;
}[];

type EntitiesListProps = {
  currentCompany: CompanyInvolvement | CompanyInformation;
  value: UserListResponse;
  onChange: () => void;
  manualApprovers?: string[];
  approvers?: Approvers;
  canEdit: boolean;
};

type NamedUserRole = UserRole & { name: string };

const UsersList: React.FunctionComponent<EntitiesListProps> = ({
  currentCompany,
  value,
  onChange,
  manualApprovers,
  approvers,
  canEdit,
}) => {
  const i18n = useTranslation();
  const entitiesQuery = useEntitiesQuery(currentCompany.orgNumber);
  const usersList = useMemo(() => {
    let supportUser: NamedUserRole | null = null;
    const usersWithName: Array<NamedUserRole> = value.reduce((result, user) => {
      if (user.user.id === supportUserId) {
        supportUser = { ...user, name: i18n.t("label.users.supportUser") };
        return result;
      }
      let name =
        user.user.name ??
        formatRefId({
          refId: user.user.refId,
          countryCode: user.user.countryCode,
          type: "Private",
        });
      if (entitiesQuery.isSuccess) {
        const entityName = entitiesQuery.data?.find(
          (entity) =>
            entity.id === user.user.id || entity.refId === user.user.refId
        )?.name;
        if (entityName) {
          name = entityName;
        }
      }
      return [
        ...result,
        {
          ...user,
          name,
        },
      ];
    }, [] as NamedUserRole[]);

    const sortedUsers = sortAlphabeticallyByValue(
      usersWithName,
      (user) => user.name
    );

    if (supportUser) {
      sortedUsers.push(supportUser);
    }

    return sortedUsers;
  }, [value, entitiesQuery.isSuccess, entitiesQuery.data, i18n]);

  const isMobileorTabletDevice = useMediaQuery({
    query: "(max-width: 768px)",
  });

  console.log({ isMobileorTabletDevice });

  if (usersList.length === 0) {
    return <NoData />;
  }

  const roleLabels: Record<Role, string> = {
    Viewer: i18n.t("role.viewer"),
    Editor: i18n.t("role.editor"),
    Administrator: i18n.t("role.administrator"),
    BoardMember: i18n.t("role.boardMember"),
  };

  return (
    <>
      {usersList.map(({ user, role, name }) => {
        const isApprover = approvers?.some(
          (approver) => approver.key === user.id
        );

        return (
          <ListItem
            className={clsxm({
              "tw-space-y-3 tw-p-0": isMobileorTabletDevice,
            })}
            key={user.id}
          >
            <div className="tw-flex tw-items-center tw-justify-between tw-gap-2 max-md:tw-hidden">
              <EntityItem
                value={{
                  name,
                  type: "Private",
                  countryCode: user.countryCode,
                  refId: roleLabels[role],
                  id: "",
                }}
                shouldFormatRefId={false}
                hasFlag={user.id !== supportUserId}
              />
              <div className="tw-flex tw-items-center tw-gap-2">
                {manualApprovers?.includes(user.id) === true && (
                  <Badge color="success" className="tw-mr-2">
                    {i18n.t("label.users.manualApprover.badge")}
                  </Badge>
                )}

                {role === "BoardMember" ||
                !canEdit ||
                user.id === supportUserId ? (
                  <div className="tw-m-1 tw-w-20" />
                ) : (
                  <>
                    <EditUser
                      currentCompany={currentCompany}
                      value={{ user, role }}
                      onSuccess={onChange}
                      isApprover={isApprover}
                    />
                    <TooltipV2
                      content={
                        isApprover
                          ? i18n.t("label.users.disableHover.tooltip")
                          : null
                      }
                      theme="light"
                      placement="bottom-start"
                      breakText
                    >
                      <DeleteUser
                        currentCompany={currentCompany}
                        user={user}
                        onSuccess={onChange}
                        disabled={isApprover}
                      />
                    </TooltipV2>
                  </>
                )}
              </div>
            </div>
            <button
              type="button"
              className="tw-relative tw-w-full tw-rounded tw-p-4 tw-text-left md:tw-hidden"
              style={{
                boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.08)",
              }}
            >
              <div className="tw-flex tw-items-center tw-justify-between tw-gap-2 ">
                <div className="tw-flex tw-flex-col">
                  <EntityItem
                    value={{
                      name,
                      type: "Private",
                      countryCode: user.countryCode,
                      refId: roleLabels[role],
                      id: "",
                    }}
                    shouldFormatRefId={false}
                    hasFlag={user.id !== supportUserId}
                  />

                  {manualApprovers?.includes(user.id) === true && (
                    <div className="tw-flex tw-pt-4">
                      <Badge color="blue" className="tw-inline-block">
                        {i18n.t("label.users.manualApprover.badge")}
                      </Badge>
                    </div>
                  )}
                </div>

                <div className="tw-flex tw-items-center tw-gap-2">
                  {role === "BoardMember" ||
                  !canEdit ||
                  user.id === supportUserId ? (
                    <div className="tw-m-1 tw-w-20" />
                  ) : (
                    <>
                      <EditUser
                        currentCompany={currentCompany}
                        value={{ user, role }}
                        onSuccess={onChange}
                        isApprover={isApprover}
                      />
                      <TooltipV2
                        content={
                          isApprover
                            ? i18n.t("label.users.disableHover.tooltip")
                            : null
                        }
                        theme="light"
                        placement="bottom-start"
                        breakText
                      >
                        <DeleteUser
                          currentCompany={currentCompany}
                          user={user}
                          onSuccess={onChange}
                          disabled={isApprover}
                        />
                      </TooltipV2>
                    </>
                  )}
                </div>
              </div>
            </button>
          </ListItem>
        );
      })}
    </>
  );
};

export { UsersList };
