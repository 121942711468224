import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useShareTypesQuery } from "../../api/blockchain/company";
import { Actions } from "../../components/Actions";
import { Button } from "../../components/design-system/Button";
import { Dialog } from "../../components/design-system/Dialog";

type FormActionsProps = {
  isLoading: boolean;
  hasError?: boolean;
  formId: string;
  orgNumber: string;
  selectedShareTypes: string[];
  disabled?: boolean;
};

const FormActions = ({
  isLoading,
  hasError,
  formId,
  orgNumber,
  selectedShareTypes,
  disabled,
}: FormActionsProps) => {
  const [showConditionsWarning, setShowConditionsWarning] = useState(false);
  const i18n = useTranslation();
  const shareTypesQuery = useShareTypesQuery(orgNumber, "");

  const sharesHaveConditions = useMemo(() => {
    let haveShareTypesWithCondition = false;
    const shareTypes = shareTypesQuery.data || [];
    for (const selectedShareType of selectedShareTypes) {
      const shareType = shareTypes.find(
        (type) => type.name === selectedShareType
      );
      if (shareType) {
        haveShareTypesWithCondition = Object.values(shareType.condition).some(
          (condition) => !!condition
        );
      }
      if (haveShareTypesWithCondition) {
        break;
      }
    }

    return haveShareTypesWithCondition;
  }, [selectedShareTypes, shareTypesQuery.data]);

  useEffect(() => {
    if (hasError) {
      setShowConditionsWarning(false);
    }
  }, [hasError]);

  return (
    <>
      <Actions>
        <Link to="../">
          <Button>{i18n.t("label.cancel")}</Button>
        </Link>
        {sharesHaveConditions ? (
          <Button
            type="button"
            variant="solid"
            color="primary"
            onClick={() => !hasError && setShowConditionsWarning(true)}
          >
            {i18n.t("label.save")}
          </Button>
        ) : (
          <Button
            type="submit"
            variant="solid"
            color="primary"
            isLoading={isLoading}
            disabled={disabled}
          >
            {i18n.t("label.save")}
          </Button>
        )}
      </Actions>
      {showConditionsWarning && (
        <Dialog
          isOpen
          title={i18n.t("events.transfer.conditions.warning.title")}
          onClose={() => setShowConditionsWarning(false)}
          isLoading={isLoading}
          actions={
            <>
              <Button
                onClick={() => setShowConditionsWarning(false)}
                disabled={isLoading}
              >
                {i18n.t("label.cancel")}
              </Button>
              <Button
                type="submit"
                variant="solid"
                color="primary"
                isLoading={isLoading}
                form={formId}
              >
                {i18n.t("label.continue")}
              </Button>
            </>
          }
        >
          {i18n.t("events.transfer.conditions.warning.description")}
        </Dialog>
      )}
    </>
  );
};

export default FormActions;
