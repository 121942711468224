import type { PersonalInformationResponse } from "@capchapdev/admin-api";
import { useQuery } from "@tanstack/react-query";

import { getUserFromStorage } from "../../context/session";
import type { PersonInformation } from "../../types/models/person";
import { PersonInformationSchema } from "../../types/models/person";
import * as monitoring from "../../utils/monitoring";
import type { IRequestError } from "..";
import useClient, { NoContentSchema, URL } from "./client";

const getMyPersonInformation = async () => {
  const client = useClient({ hasAuth: true });

  const response = await client<PersonalInformationResponse>(
    `${URL.ADMIN}/Person/Me`
  );

  const result = PersonInformationSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { response, result },
    });

    return response as PersonInformation;
  }

  return result.data;
};

const updateLanguage = async (language: string) => {
  const client = useClient({ hasAuth: true });

  const response = await client<undefined>(`${URL.ADMIN}/Entities/Language`, {
    body: { language },
    method: "PATCH",
  });

  const result = NoContentSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { response, result },
    });
  }
};

const useMyPersonInformationQuery = () =>
  useQuery<PersonInformation, IRequestError>(
    ["personMe", getUserFromStorage()?.refId ?? ""],
    getMyPersonInformation,
    { staleTime: Infinity, enabled: getUserFromStorage()?.refId !== undefined }
  );

export { updateLanguage, useMyPersonInformationQuery };
