import { getKeyPair } from "../../context/session";
import * as monitoring from "../../utils/monitoring";
import { Errors, RequestError } from "..";
import { BAD_REQUEST } from "../rest/utils";
import { blockchainClient } from "./client";

type Query<TData> = {
  send: () => Promise<TData>;
  getName: () => string;
  getParameters: () => Map<string, unknown>;
};

const sendTransaction = async (rawTx: string) => {
  const keyPair = getKeyPair();
  if (!keyPair) {
    throw new RequestError(Errors.unauthenticated);
  }

  return blockchainClient
    .fromRawTx(rawTx)
    .sign(keyPair)
    .send()
    .catch((error) => {
      if (!(error instanceof Error)) {
        throw new RequestError(Errors.general);
      }

      let requestError;
      try {
        requestError = new RequestError({
          errors:
            "shortReason" in error && typeof error.shortReason === "string"
              ? JSON.parse(error.shortReason)
              : undefined,
          status: BAD_REQUEST,
        });
      } catch (e) {
        requestError = new RequestError(Errors.general);
      }
      requestError.name =
        "operation" in error && typeof error.operation === "string"
          ? `Error in operation ${JSON.stringify(error.operation)}`
          : error.name;
      requestError.message = error.message;
      monitoring.captureException(requestError, { extra: { ...error } });
      throw new RequestError(requestError);
    });
};

const getParameters = <TData>(query: Query<TData>) => {
  const parameters = query.getParameters();
  parameters.delete("auth");
  return JSON.stringify(Object.fromEntries(parameters));
};

const sendQuery = <TData>(query: Query<TData>) =>
  query.send().catch((error) => {
    const requestError =
      error instanceof RequestError
        ? new RequestError({
            status: error.status,
            errors: error.errors,
          })
        : new RequestError(Errors.general);
    requestError.name = `Blockchain Query Error - ${query.getName()}`;
    requestError.message = error?.message ?? "Blockchain query failed";

    monitoring.captureException(requestError, {
      extra: {
        ...error,
        message: error?.message,
        parameters: getParameters(query),
      },
    });

    throw requestError;
  });

export type { Query };
export { sendQuery, sendTransaction };
