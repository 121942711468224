import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useAdminCompaniesQuery } from "../../api/blockchain/administration";
import { Badge } from "../../components/design-system/Badge";
import { Description } from "../../components/design-system/Description";
import { SearchIcon } from "../../components/design-system/icons";
import { Input } from "../../components/design-system/Input";
import {
  List,
  ListHeader,
  ListItem,
} from "../../components/design-system/List";
import { Loading } from "../../components/design-system/Loading";
import { EntityItem } from "../../components/EntityItem";
import { NoData } from "../../components/NoData";
import { PageWrapper } from "../../components/PageWrapper";
import { useAccount } from "../../context/account";
import type { CompanyInformation } from "../../types/models/administration";
import { isTestCompany } from "../../utils/company";
import { dateToIsoString } from "../../utils/date";
import AddCompany from "./AddCompany";
import { useFilteredList, useSortedList } from "./Administration.utils";
import { ClearCache } from "./ClearCache";
import CompanyActions from "./CompanyActions";

const AdministrationPage = () => {
  const companiesQuery = useAdminCompaniesQuery();
  const {
    companies: companyInvolvementsQuery,
    myPersonInformation: myPersonInformationQuery,
  } = useAccount();
  const i18n = useTranslation();
  const errorCode = companiesQuery.error?.errors[0]?.message.code;

  const [keyword, setKeyword] = useState<string>("");

  const sortedCompanies = useSortedList({
    list: companiesQuery.data ?? [],
    sortFn: (a: CompanyInformation, b: CompanyInformation) => {
      const isATestCompany = isTestCompany(a.orgNumber);
      const isBTestCompany = isTestCompany(b.orgNumber);
      if (isATestCompany) {
        if (isBTestCompany) {
          return a.name.localeCompare(b.name);
        }

        return 1;
      }
      if (isBTestCompany) {
        return -1;
      }

      return a.name.localeCompare(b.name);
    },
  });

  const filteredCompanies = useFilteredList({
    list: sortedCompanies,
    keyword,
    fields: ["name", "orgNumber"],
  });

  return (
    <PageWrapper className="max-sm:tw-py-0" data-testid="administration">
      <div>
        <h1 className="max-sm:tw-hidden">{i18n.t("label.administration")}</h1>
        <div className="tw-my-6 tw-flex tw-gap-2 max-sm:tw-flex-col">
          <Input
            className="tw-w-52 max-sm:tw-w-full"
            placeholder={i18n.t("label.search")}
            prefix={<SearchIcon />}
            type="search"
            value={keyword ?? ""}
            onChange={(event) => {
              setKeyword(event.target.value);
            }}
          />
          <div className="tw-flex tw-gap-2">
            <AddCompany
              onSuccess={() => {
                companiesQuery.refetch();
                companyInvolvementsQuery?.refetch();
                myPersonInformationQuery?.refetch();
              }}
            />
            <ClearCache />
          </div>
        </div>
        <div className="max-lg:tw-overflow-x-auto">
          <div className="tw-min-w-[68rem]">
            <List header={<ListHeader title={i18n.t("label.companies")} />}>
              {companiesQuery.isLoading && <Loading />}
              {filteredCompanies?.map((company) => {
                return (
                  <ListItem
                    className="tw-py-6"
                    key={`${company.orgNumber}.${company.name}`}
                    data-testid={`${company.name}-admin-row`}
                    interactive
                  >
                    <Link to={`../companies/${company.orgNumber}`}>
                      <div className="tw-grid tw-grid-cols-4">
                        <div className="tw-flex tw-items-center tw-px-4">
                          <EntityItem
                            displayIcon={false}
                            hasFlag={false}
                            value={{
                              name: company.name,
                              refId: company.orgNumber,
                              countryCode: company.countryCode,
                              id: "",
                              type: "Company",
                            }}
                          />
                          {isTestCompany(company.orgNumber) && (
                            <div>
                              <Badge>{i18n.t("label.test")}</Badge>
                            </div>
                          )}
                        </div>
                        <div className="tw-flex tw-items-center tw-px-4">
                          <Description
                            title={i18n.t("label.createdAt")}
                            description={dateToIsoString(
                              new Date(company.created)
                            )}
                          />
                        </div>
                        <div className="tw-flex tw-items-center tw-px-4">
                          <Description
                            title={i18n.t("label.support")}
                            description={company.supportRole ?? "-"}
                          />
                        </div>
                        <div className="tw-flex tw-items-center tw-justify-end tw-px-4">
                          <CompanyActions
                            orgNumber={company.orgNumber}
                            id={`${company.name}-actions`}
                          />
                        </div>
                      </div>
                    </Link>
                  </ListItem>
                );
              })}
              {companiesQuery.isSuccess &&
                companiesQuery.data?.length === 0 && <NoData />}
              {companiesQuery.isSuccess &&
                companiesQuery.data?.length > 0 &&
                filteredCompanies.length === 0 && (
                  <div className="tw-flex tw-w-full tw-flex-col tw-items-center tw-gap-2 tw-px-2 tw-py-8">
                    <div className="tw-text-center">
                      <div className="tw-text-lg">
                        {i18n.t("administration.search.noResult.title")}
                      </div>
                      <div className="tw-text-secondary">
                        {i18n.t("administration.search.noResult.description")}
                      </div>
                    </div>
                  </div>
                )}
              {!companiesQuery.isLoading && errorCode && (
                <NoData
                  type="error"
                  title={i18n.t("error.fetch")}
                  description={i18n.t(errorCode)}
                />
              )}
            </List>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export { AdministrationPage };
